const EN = {
	"lng" : "en",
	"Login": "Login",
	"Register": "Register",
	"Activate": "Activate",
	"Username": "Username",
	"Password": "Password", 
	"This is the home content" : "This is the home content",  
	"User not found, check email and password" : "User not found, check email and password",
	"Friends" : "Friends",
	"User not found": "User not found",
	"About me": "About me",
	"Password": "Password",
	"Sign up": "Sign up",
	"Forgot password": "Forgot password",
	"Nickname": "Nickname",
  	'Repeat password': 'Repeat password',
	'Super': 'Super',
	'Influencer': 'Influencer',
	'Business': 'Business',
	'Association': 'Association',
	'Ambassador’s nickname': 'Ambassador’s nickname',
	'Ambassador’s username': 'Ambassador’s username',
  	'Activation code': 'Activation code',
  	'Profile': 'Profile',
  	'Settings': 'Settings',
  	Logout: 'Logout',
  	Tags : 'Tags',
  	'Preferred tags' : 'Preferred tags',
  	'Add new tag' : 'Add new tag',
  	Save: 'Save',
  	Socialization: 'Socialization',
  	Notifications: 'Notifications',
  	Personalization: 'Personalization',
  	Privacy: 'Privacy',
  	'Help and support': 'Help and support',
  	'Terms and conditions': 'Terms and conditions',
  	'Privacy policy': 'Privacy policy',
	guest_modal_generic : 'Do you want to continue discovering Launchyoo? If you are already registered, log in, and if not, just create your account and start enjoying your new social network!',    	
	guest_modal_comment : 'Do you want to comment on the post? If you are already registered in Launchyoo, log in, and if not, just create your account and start enjoying your new social network!',
	guest_modal_like_love : 'Do you like this post? If you are already registered in Launchyoo, log in, and if not, just create account and start enjoying your new social network!',
	guest_modal_binary_star : 'Do you want to give your opinión about this post? If you are already registered in Launchyoo, log in, and if not, just create your account and start enjoying your new social network!',
	"Send a comment" : "Send a comment",
	cookies_bar: "At Launchyoo we use or own and third party cookies to analyze your browsing habits and to	develop statistical analysis.",
	cookies_bar_link: "More information in the Cookies Policy.",
	Accept: "Accept",
	Reject: "Reject",
	User: "User",
	"Join the discussion": "Join the discussion",
	"You left the group": "You left the group",
	"You joined the group": "You joined the group",
	"Subscription awaiting approval": "Subscription awaiting approval",
	Subscribe: "Subscribe",
	Leave: "Leave",
	"Pending request": "Pending request",
	"Invitation sent": "Invitation sent",
	"Already in that group": "Already in that group",
	helpProfileDetails : 'Choose the profile:', // Auth
	helpProfileSuper : 'USER, if you want to use Launchyoo to keep in touch with friends and family, follow characters of your interest, read news or just have fun watching a video or reading a blog. Choose it as well if you are a blogger or podcasters.', // Auth
	helpProfileInfluencer : 'INFLUENCER, if you want to entertain your followers, keep them aware of your life, your activities and your tastes.', // Auth
	helpProfileBusiness : 'BUSINESS. For companies, large or small, business and professionals.', // Auth
	helpProfileAssociation : 'ASOCIACIÓN, if it is the case of a non-profit organization or one that develops social, educational, cultural, outreach, informative or leisure activities.', // Auth
	helpAmbassador : 'If you sign up in Launchyoo because somebody has suggested you to do so, write his or her profile name here. Advantages await him/her and you as well, if someone will write your name.', // Auth
	"Read more": "Read more",
  }

export default EN;