// INITIAL CONFIG
export function initConfig(payload) {
  console.log(" action/ INIT_CONFIG");
  return { type: "INIT_CONFIG", payload }
};

// LOGIN
export function usersLogin(payload) {
  console.log(" action/ USERS_LOGIN");
  return { type: "USERS_LOGIN", payload }
};

export function usersLoginSuccess(payload) {
  console.log(" action/ USERS_LOGIN_SUCCESS");
  return { type: "USERS_LOGIN_SUCCESS", payload }
};

export function usersLoginError(payload) {
  console.log(" action/ USERS_LOGIN_ERROR");
  return { type: "USERS_LOGIN_ERROR", payload }
};

// REGISTER
export function usersRegister(payload) {
  console.log(" action/ USERS_REGISTER");
  return { type: "USERS_REGISTER", payload }
};

export function usersRegisterSuccess(payload) {
  console.log(" action/ USERS_REGISTER_SUCCESS");
  return { type: "USERS_REGISTER_SUCCESS", payload }
};

export function usersRegisterError(payload) {
  console.log(" action/ USERS_REGISTER_ERROR");
  return { type: "USERS_REGISTER_ERROR", payload }
};

// ACTIVATE
export function usersActivate(payload) {
  console.log(" action/ USERS_ACTIVATE");
  return { type: "USERS_ACTIVATE", payload }
};

export function usersActivateSuccess(payload) {
  console.log(" action/ USERS_ACTIVATE_SUCCESS");
  return { type: "USERS_ACTIVATE_SUCCESS", payload }
};

export function usersActivateError(payload) {
  console.log(" action/ USERS_ACTIVATE_ERROR");
  return { type: "USERS_ACTIVATE_ERROR", payload }
};

export function usersResendActivation(payload) {
  console.log(" action/ USERS_RESEND_ACTIVATION");
  return { type: "USERS_RESEND_ACTIVATION", payload }
};

// LOGOUT
export function usersLogout(payload) {
  console.log(" action/ USERS_LOGOUT");
  return { type: "USERS_LOGOUT", payload }
};

// LANGUAGE
export function utilityLanguage(payload) {
  console.log(" action/ CHANGE_LANGUAGE");
  return { type: "UTILITY_LANGUAGE", payload }
};

// UTILITY
export function cleanOnce(payload) {
  console.log(" action/ CLEAN_ONCE");
  return { type: "CLEAN_ONCE", payload }
};


// SETTINGS
export function saveSettings(payload) {
  return { type: "SAVE_SETTINGS", payload }
};