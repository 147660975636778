import axios from "axios";
import qs from "querystring";
import endpoint from "../config/endpoint";
import { usersLoginSuccess } from "../actions/index";
import {store} from './../store';

import { toast } from 'react-toastify';
import i18n from "../i18n";


export function apiMiddleware({ dispatch }) {
  return function(next) {
    return function(action) {

	    console.log(action);
	    
	    // INIT CONFIG
	    if (action.type === "INIT_CONFIG") {	        
		  	dispatch({ type: "UTILITY_LOADING" });
			let state = store.getState();
			console.log("INIT_CONFIG");
			let exec = (state.user.auth == true && state.user.token !== false) ? true : false;
			if ( exec === true ){
			    const reqBody = { lang: i18n.language };
		        const cfg = { headers: { 'Content-Type': 'application/json', 'Authorization': state.user.token } };
				axios.post(endpoint.users+'/initconfig', reqBody, cfg)
				  .then((result) => {
				  	console.log(result);
				  	return dispatch({ type: "INIT_CONFIG_LOADED",  payload : { tags: result.data.userTags, userSettings: result.data.userSettings, appSettings: result.data.appSettings, userRow: result.data.userRow, avatar: result.data.avatar } } );
				  })
				  .catch((err) => {
				  	return dispatch({ type: "INIT_CONFIG_NOT_LOADED", payload : {} } );
				  })  
			}else{
					return dispatch({ type: "INIT_CONFIG_NOT_LOADED", payload : {} } );		
			}
	  	}
      	// END "SETTINGS"


	    // LOGIN
	    if (action.type === "USERS_LOGIN") {
	        
	        console.log(" middleware/ USERS_LOGIN");
		  	dispatch({ type: "UTILITY_LOADING" });

		    const reqBody = { email: action.payload.email, password: action.payload.password };
	        const cfg = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
			axios.post(endpoint.users+'/login', qs.stringify(reqBody), cfg)
			  .then((result) => {
			  	console.log(result);
			  	console.log('middleware/ axios login success');
			  	return dispatch({ type: "USERS_LOGIN_SUCCESS",  payload : {token: result.data.token, auth: true } } );
			  })
			  .catch((err) => {
			  	console.log('middleware/ axios login error');
			  	toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 10000 } );
			  	return dispatch({ type: "USERS_LOGIN_ERROR", payload : { user : { token: '', auth: false } } } );
			  })  
	  	}
      	// END "USERS_LOGIN"

	    // REGISTRATION
	    if (action.type === "USERS_REGISTER") {
	        
	        console.log(" middleware/ USERS_REGISTER");
		  	dispatch({ type: "UTILITY_LOADING" });

		    const reqBody = { name: action.payload.name, email: action.payload.email, password: action.payload.password, repassword: action.payload.repassword, profileType: action.payload.profileType, referrer: action.payload.referrer, checkCookies: action.payload.checkCookies, lang: action.payload.lang };
	        const cfg = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
			axios.post(endpoint.users+'/register', qs.stringify(reqBody), cfg)
			  .then((result) => {
			  	console.log('middleware/ axios register success');
			  	toast.success(i18n.t(result.data.success), { autoClose : 10000 } );
			  	return dispatch({ type: "USERS_REGISTER_SUCCESS",  payload : { redirect: "/activate", auth: false } } );
			  })
			  .catch((err) => {
			  	console.log('middleware/ axios register error');
			  	let errorMessage = "An error has occurred try again later";
			  	if ( typeof err.response !== "undefined"){
			  		errorMessage = err.response.data.errorMessage;
			  	}
			  	toast.error(i18n.t(errorMessage), { autoClose : 10000 } );
			  	return dispatch({ type: "USERS_REGISTER_ERROR", payload : {token: '', auth: false } } );
			  })  
	  	}
      	// END "USERS_REGISTER"

	    // ACTIVATION
	    if (action.type === "USERS_ACTIVATE") {	        
	        console.log(" middleware/ USERS_ACTIVATE");
		  	dispatch({ type: "UTILITY_LOADING" });

		    const reqBody = { email: action.payload.email, atoken: action.payload.atoken, lang: i18n.language };
	        const cfg = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
			axios.post(endpoint.users+'/activation', qs.stringify(reqBody), cfg)
			  .then((result) => {
			  	console.log('middleware/ axios activation success');
			  	toast.success(i18n.t(result.data.success), { autoClose : 10000 } );
			  	return dispatch({ type: "USERS_ACTIVATE_SUCCESS",  payload : { redirect: "/login", auth: false } } );
			  })
			  .catch((err) => {
			  	console.log('middleware/ axios activation error');
			  	let errorMessage = "An error has occurred try again later";
			  	if ( typeof err.response !== "undefined"){
			  		errorMessage = err.response.data.errorMessage;
			  	}
			  	toast.error(i18n.t(errorMessage), { autoClose : 10000 } );
			  	return dispatch({ type: "USERS_ACTIVATE_ERROR", payload : {token: '', auth: false } } );
			  })  
	  	}

	    if (action.type === "USERS_RESEND_ACTIVATION") {	        
	        console.log(" middleware/ USERS_RESEND_ACTIVATION");

		    const reqBody = { email: action.payload.email, atoken: action.payload.atoken, lang: i18n.language };
	        const cfg = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
			axios.post(endpoint.users+'/activationresend', qs.stringify(reqBody), cfg)
			  .then((result) => {
			  	console.log('middleware/ axios activation resend success');
			  	toast.success(i18n.t(result.data.success), { autoClose : 10000 } );
			  })
			  .catch((err) => {
			  	console.log('middleware/ axios activation resend error');
			  	let errorMessage = "An error has occurred try again later";
			  	if ( typeof err.response !== "undefined"){
			  		errorMessage = err.response.data.errorMessage;
			  	}
			  	toast.error(i18n.t(errorMessage), { autoClose : 10000 } );
			  })  
	  	}	  	
      	// END "ACTIVATION"


	    // SETTINGS
	    if (action.type === "SAVE_SETTINGS") {	        
			
			let state = store.getState();

		  	dispatch({ type: "UTILITY_LOADING" });

		    const reqBody = { type: action.payload.type, dataObj: action.payload.dataObj, lang: i18n.language };
	        const cfg = { headers: { 'Content-Type': 'application/json', 'Authorization': state.user.token } };
			axios.post(endpoint.users+'/savesettings', reqBody, cfg)
			  .then((result) => {
			  	toast.success(i18n.t(result.data.success), { autoClose : 10000 } );
			  	
			  	if( action.payload.type == 'tags'){
				  	return dispatch({ type: "SAVE_SETTINGS_TAGS_SUCCESS",  payload : { redirect: "/settings", tags: result.data.userTags } } );
			  	}else if ( action.payload.type == 'position' ){
				  	return dispatch({ type: "SAVE_SETTINGS_POSITION_SUCCESS",  payload : { redirect: "/settings", userSettings: result.data.userSettings } } );			  		
			  	}else if ( action.payload.type == 'socialization' ){
				  	return dispatch({ type: "SAVE_SETTINGS_SOCIALIZATION_SUCCESS",  payload : { redirect: "/settings", userSettings: result.data.userSettings } } );			  		
			  	}else if ( action.payload.type == 'notification' ){
				  	return dispatch({ type: "SAVE_SETTINGS_NOTIFICATION_SUCCESS",  payload : { redirect: "/settings", userSettings: result.data.userSettings } } );			  		
			  	}else if ( action.payload.type == 'privacy' ){
				  	return dispatch({ type: "SAVE_SETTINGS_PRIVACY_SUCCESS",  payload : { redirect: "/settings", userSettings: result.data.userSettings } } );			  		
			  	}else{
				  	return dispatch({ type: "SAVE_SETTINGS_SUCCESS",  payload : { redirect: "/settings" } } );			  		
			  	}

			  })
			  .catch((err) => {
			  	let errorMessage = "An error has occurred try again later";
			  	if ( typeof err.response !== "undefined"){
			  		errorMessage = err.response.data.errorMessage;
			  	}
			  	toast.error(i18n.t(errorMessage), { autoClose : 10000 } );
			  	return dispatch({ type: "SAVE_SETTINGS_ERROR", payload : {token: '', auth: false } } );
			  })  
	  	}
      	// END "SETTINGS"
     	
     	return next(action);

    };
  };
}
