
import React, { Component } from "react";

import ReactMarkdown from "react-markdown";

import i18n from "../../i18n";



export default class PrivacyPolicy extends Component {

  constructor(props) {
    super(props);
  }


  render(){

    return (
    	<div className="termsExtended">

        { i18n.language == 'es' ?
          <ReactMarkdown source={esText} />          
          :
          i18n.language == 'pt' ?
          <ReactMarkdown source={ptText} />          
          :
          <ReactMarkdown source={enText} />          
        }

    	</div>
    );
  }
}

const enText = `
**Launchyoo's Privacy Policy**

**1\. Who is the Data Controller for processing personal data you provide us?**

The Data Controller responsible for processing through our website/application is PECH Network Services, S.L.U., at the address C/ Ramón Llull 31, 4°, 11, 46021, Valencia (Spain), duly registered in the Mercantile Registry of Valencia, Volume 10,642; Book 7923; Folio 96; Sheet V- 186,754; with Tax ID Number: B40561185. Hereinafter, PECH NETWORK.

You can contact PECH NETWORK directly in relation to this Privacy Policy, at the email: customercare@launchyoo.com.

PECH NETWORK is the owner of the social network Launchyoo (hereinafter, either the Platform or Launchyoo).

**2\. What data do we collect?**

For the provision of the social network service that you request with your registration, PECH NETWORK will collect and process personal data about you and your use of Launchyoo with the purposes and legitimacy stated further on.

**2.1. Information collected during the registration process:** During registration on Launchyoo we request the following information:

- A nickname of your choice (as long as it is available and has not been previously chosen by another registered user)

- Email address. Voluntarily, you can complete your profile with a picture or a background image that do not necessarily need to identify you, and you can indicate your age and gender.

- In any case, in order to verify your identity, we may ask you to provide us with supporting documentation (such as document of national identity or passport)

- In any case, in order to verify your identity, we may ask you to provide us with supporting documentation (such as document of national identity or passport)

- Data about your hobbies and interests (tags)

- If you purchase any of the annual subscriptions, data will be collected from the methods of payment used.

- The fields marked as obligatory must be completed otherwise we will not be able to register you on Launchyoo.

- The personal data you voluntarily provide us with must be real, true, exact and updated. 

**2.2. Information collected for Launchyoo's use:** In order to use Launchyoo's services we can have access to the following information:

- Your device's IP number

- Communications and interactions with other users of the Platform

- The content that you make available to the public, if you do not delete it, and the metadata of files you add to the platform (date and place of file creation, device it was done on, etc.).

- Geolocation: To use specific services of Launchyoo, such as active socialisation (which shows you Launchyoo users with similar interests, who are near you or finds events nearby). To activate the geolocation of your device you will need to consent by using the functionalities we offer you. 

**2.3. Information collected in the forms on Launchyoo:** In the forms available on Launchyoo we only collect contact identification data, such as name, email and telephone number, allowing us to contact you to handle your request.

The fields marked as obligatory must be completed so we can handle your request.

**2.4. Information collected from using cookies:** data from cookies stored on your device.

You can find further information in our [Cookies Policy](http://app.launchyoo.com/cookies)

**2.5. Warning about the risks of sharing information on social networks:** PECH NETWORK want to warn you about the importance of using the Platform properly, assessing what information you share, as well as who can have access to it, and always keeping in mind that your privacy can be compromised if you do not make responsible use of the platform

We remind you that you cannot publish any information of any third party (personal data, photographs, etc.) without their prior authorisation.

Personal data that reveals racial or ethnic origin, political views, religious or philosophical beliefs, trade union membership and information relating to health and sex life are considered special categories of data. Sensitive personal information can only be published on the Internet with explicit consent from the person concerned or if this person has made this data public.

PECH NETWORK will not ask you for information of this type, due to its sensitive nature. If you strictly voluntarily decide to publish this kind of information, we suggest you assess the consequences of doing so before publication.

If you create a profile within Launchyoo, your profile picture, your background image, your nickname, the number of friends, following or followers, your type of profile, your “About Me” or brief biography, and the feed of your last posts, might be viewed by any internet user, even if not a member of Launchyoo.

The members of Launchyoo will have access to your real name if you have indicated this in the privacy settings, as well as your age, gender and the Album of files that you have shared, along the abovementioned information

**2.6. Warning about the risks of sharing information on social networks:** PECH NETWORK want to warn you about the importance of using the Platform properly, assessing what information you share, as well as who can have access to it, and always keeping in mind that your privacy can be compromised if you do not make responsible use of the platform.

We remind you that you cannot publish any information of any third party (personal data, photographs, etc.) without their prior authorisation.

Personal data that reveals racial or ethnic origin, political views, religious or philosophical beliefs, trade union membership and information relating to health and sex life are considered special categories of data. Sensitive personal information can only be published on the Internet with explicit consent from the person concerned or if this person has made this data public.

PECH NETWORK will not ask you for information of this type, due to its sensitive nature. If you strictly voluntarily decide to publish this kind of information we suggest you assess the consequences of doing so before publication.

**3\. Why do we process your data and what is the legal basis for doing it?**

Set out below, we explain what we will use your data for, as well as the legitimacy of the processing, in other words, the legal basis allowing us to process your data in accordance with the regulations on data protection.

**3.1. Registered users on Launchyoo:** If you register on Launchyoo, data collected will be processed:

**a) In order to be able to use the Platform of Launchyoo:** to be able to create your Launchyoo profile and make use of the Platform, sharing content and interacting with other Launchyoo users.

If you also purchase a subscription to Launchyoo's payment services, your data will be processed to facilitate administrative management.

The legal basis for the processing of data collected for these purposes, is legitimated in the execution of a contract, consisting of the application for registration on the Platform for use in accordance with Launchyoo's [Terms and Conditions of Use](https://app.launchyoo.com/terms) which you must read and if you agree, accept, through the features that we offer you.

**b) User segmentation for direct marketing purposes:** Segmentation is a practice that involves creating user profiles by age, sex, tastes, hobbies and interests which allows us to display advertising consistent with these parameters and be able to facilitate information about users, events and activities based on this information.

The legal basis to carry out user segmentation is the legitimate interest to provide the service requested by the user and through direct marketing. Regarding third party advertising, the legal basis will be the execution of a contract or pre-contract with the user, as for the service to be free, it requires funding from third party advertising.

This segmentation will also ensure that the sending of commercial communications shall correspond to your interests.

Under no circumstance shall the development of these profiles include the introduction of automated decision-making, where it produces a legal effect or a similarly significant effect on you.

The legal basis for this processing is in the legitimate interest of PECH NETWORK for direct marketing purposes. We remind you that you have the right, at any time, to oppose the process of segmentation for commercial purposes.

**c) Dispatch of commercial communications:** Sending commercial communications (including electronically) about Launchyoo's new developments, promotions and/or services, as long as you have not objected to receiving communications, which you can do at any time, by sending an email to the address indicated in section 1.

The legal basis will be the legitimate interest for direct marketing purposes.

Sending commercial communications from our advertisers to your mailbox in Launchyoo's direct messaging service.

In this case, the legal basis will be the contract implementation of the use of the Platform, which involves the sending of commercial communications from advertisers, exclusively within the environment of the Platform.

**d) Conducting surveys or market studies** related to your activity on Launchyoo.

The legal basis is the legitimate interest in direct marketing.

**3.2. Non-registered users on Launchyoo:**

**a) Users requesting information:** If you request information from PECH NETWORK through forms available on the Website, your personal data will be used to meet this request. The legal basis for processing data is our legitimate interest in responding to your request.

The legal basis will be the legitimate interest responding to the customer's request for information.

**b) Dispatch of newsletters and/or commercial communications by PECH NETWORK:** We will send you commercial communications providing that you have given unambiguous, specific, free and informed consent, by checking the corresponding checkbox. 

The legal basis for the processing of data in this case is consent given, which may be withdrawn at any time by sending an email to the address indicated in section 1.

**4\. How long will we keep your personal data?:** The personal data will be kept as long as there is a mutual interest in maintaining the purpose of the processing, and during the period of time in which responsibilities could be derived for the services provided to the interested party.

When data is no longer required for such purposes, it will be deleted using adequate security measures to guarantee the pseudonymisation of the data or the total destruction thereof.

**5\. Do we give your personal data to third parties?** Data will not be communicated to third parties, except for legal obligation or prior authorisation.

PECH NETWORK has contracted certain services from companies with whom they have signed a Data Processing Agreement, in accordance with the applicable legislation regarding data protection.

Only PECH NETWORK and those responsible for the processing will have access to personal data and under no circumstances will this data be transferred, shared or sold to any third party.

Notwithstanding the above, PECH NETWORK reserves the right to carry out statistical studies of consumer habits based on user behaviour on the Platform. The results of these studies will provide dissociated data (which does not allow the identification of a data subject) and may be transferred to third parties.

**6\. Do we carry out international data transfers?**

International data transfer refers to when the data collected is processed outside the borders of the European Economic Area (constituted by the European Union plus Ireland, Norway and Liechtenstein).

Personal data collected by PECH NETWORK is processed entirely in the territory of the European Union, therefore we do not carry out international data transfers.

**7\. What are your rights regarding data protection?**

In accordance with current legislation regarding data protection, you have the following rights concerning data collected by PECH NETWORK:

If the data processing is based on consent, you have the right to withdraw consent at any time.

You have the right to ACCESS your personal data which we are processing.

You have the right to RECTIFICATION of your personal data. You can rectify your personal data by accessing the Control Panel of your user profile.

You have the right to request the DELETION of your data, when, among other reasons, it is no longer necessary for the purpose for which it was collected.

In certain circumstances, you have the right to request the RESTRICTION OF PROCESSING of your personal data, in which case we shall retain it solely for the exercise or defence of claims.

In certain circumstances and for reasons related to your particular situation, you may also exercise the right to OBJECT to our processing of your data. We will stop processing your personal data, unless compelling legitimate grounds are given, or the exercise or defence of legal claims.

In certain circumstances and for reasons related to your particular situation, you may also exercise the right to data PORTABILITY. This is complementary to the right of access, as the data subject can obtain the data collected by PECH NETWORK in a format that is structured, commonly used and machine-readable, which may be transmitted directly to another entity at the request of the interested party.

You may exercise these rights by any means that enables reliable acknowledgement of the sending and receipt of your request, including a photocopy of your ID and/or any other document confirming your identity. The request should be sent to the email customercare@launchyoo.comor the following address:

**PECH Network Services, S.L.U.**

C/ Ramón Llull 31, 4°, 11,

46021, Valencia (Spain)

If you consider that any of your rights have been violated, as an interested party you have the right to file a claim with the Spanish Data Protection Agency (AEPD), located at C/ Jorge Juan 6, 28001-Madrid https://www.agpd.es/or the electronic office: https://sedeagpd.gob.es/sede-electronica-web/.

**8\. Security and update of personal data:** With the aim of safeguarding the security of your personal information, we have taken all the necessary technical and organisational measures to ensure the security of the personal data provided. This is done to prevent its alteration, loss and/or unauthorised processing or access, as required by regulation, in the case of lack of total security.

In order to keep the data updated, it is essential for you to notify us of any modifications.

**9\. Confidentiality:** all personnel participating in any of the processing stages shall process and handle your data under strict care and confidentiality. 

**Latest version: June 2020**
`;









const esText = `
**Política de privacidad de Launchyoo**

**1\. ¿Quién es el Responsable del Tratamiento de los datos que nos proporcionas?**

La responsable del tratamiento de los datos recabados a través de nuestra web/app es la mercantil PECH Network Services, S.L.U., con domicilio en C/ Ramón Llull, 31, 4, 11, 46021, Valencia (España), debidamente inscrita en el Registro Mercantil de Valencia, al Tomo 10.642, Libro 7923, Folio 96, Hoja V- 186.754, con C.I.F.: B40561185. En adelante, PECH NETWORK.

Puede contactar de forma directa y efectiva con PECH NETWORK en relación con la presente política de privacidad en la dirección de correo electrónico: customercare@launchyoo.com.

PECH NETWORK es la titular de la red social Launchyoo (en adelante, la Plataforma o Launchyoo, indistintamente).

**2\. ¿Qué datos recabamos?**

Para la prestación del servicio de red social que solicitas con tu registro, PECH NETWORK recabará y tratará datos personales sobre ti y sobre el uso que haces de Launchyoo con las finalidades y las bases de legitimación que se dirán más adelante.

**2.1. Información recabada en el proceso de registro:** Con ocasión del registro en Launchyoo te solicitamos los siguientes datos:

- Un nickname o alias que elijas (siempre y cuando no se encuentre ocupado por haber sido elegido de forma previa por otro usuario registrado) 

- Un correo electrónico. De forma voluntaria, puedes ir completando tu perfil con una fotografía o una imagen de fondo, pero no necesariamente en las que se te identifique, y puedes hacer constar tu edad y tu género.

- En algún caso, con la finalidad de verificar tu identidad o prestar un servicio de pago, podemos solicitar que nos facilites tu nombre real y documentación acreditativa de ésta (como el documento nacional de identidad o pasaporte).

- Datos de tus aficiones e intereses (tags)

- En caso de contratación de cualquiera de las suscripciones anuales, se recabarán datos de los medios de pago utilizados.

Los campos marcados como obligatorios deberán completarse o en caso contrario no podremos tramitar tu alta en Launchyoo.

Los datos personales que nos facilites de forma voluntaria deben ser reales, veraces, exactos y actualizados

**2.2. Información recabada por el uso de Launchyoo:** Por la utilización de los servicios de Launchyoo podemos tener acceso a la siguiente información:

- IP de tu dispositivo

- Comunicaciones e interacciones con el resto de usuarios de la Plataforma

- Contenidos que pongas a disposición del público con acceso, en caso de que no los hayas borrado, a los metadatos de los archivos que subes a la plataforma (fecha y lugar de creación del archivo, dispositivo con el que se ha realizado, etc.)

- Geolocalización: Para la utilización de determinados servicios de Launchyoo, tales como socialización activa (mostrarte usuarios de Launchyoo afines a tus gustos cerca de ti o detectar eventos cerca de ti). Para activar la geolocalización de tu dispositivo será necesario que consientas a tal fin mediante las funcionalidades que ponemos a tu disposición. 

**2.3. Información recabada en los formularios de Launchyoo:** En los formularios dispuestos en Launchyoo únicamente recabamos datos identificativos de contacto que nos permiten contactar contigo para atender tu solicitud, tales como nombre, correo electrónico y/o teléfono.

Los campos marcados como obligatorios deberán completarse para poder atender tu solicitud.

**2.4. Información recabada por la utilización de cookies:** datos provenientes de las cookies almacenadas en tu dispositivo.

Puedes obtener más información en nuestra [Política de cookies](http://app.launchyoo.com/cookies)

**2.5. Advertencia sobre los riesgos de compartir información en redes sociales:** Desde PECH NETWORK queremos advertirte de la necesidad de utilizar de forma adecuada la Plataforma, valorando qué información compartes, así como quién puede tener acceso a la misma, teniendo siempre presente que tu intimidad y privacidad pueden verse comprometidas si no haces un uso responsable de la misma.

Te recordamos que no puedes publicar información de terceras personas (datos personales, fotografías, etc.)  sin contar con su autorización previa.

Los datos que revelan el origen racial o étnico, las opiniones políticas, las convicciones religiosas o filosóficas, la pertenencia sindical y los datos relativos a la salud y a la vida sexual se consideran categorías especiales de datos. Los datos personales sensibles sólo pueden publicarse en Internet con el consentimiento explícito de la persona interesada o si esta misma persona ha hecho públicos estos datos.

En PECH NETWORK no te solicitaremos información de estas características, por su carácter sensible. Si decides de forma exclusivamente voluntaria publicar este tipo de información te sugerimos que valores las consecuencias de hacerlo antes de su publicación.

Si creas un perfil en Launchyoo, tu foto de perfil, tu imagen de fondo, tu nickname, tu tipología de perfil, el número de amigos y seguidores, tu “About Me” o breve biografía, así como el feed de tus últimas publicaciones podrá ser visualizado por cualquier internauta, aunque no sea miembro de Launchyoo.

Los miembros de Launchyoo tendrán acceso a tu nombre real si así lo has indicado en las opciones de privacidad, edad, género y al álbum de archivos que hayas colgado, además de la información antes indicada.

**2.6. Advertencia sobre los riesgos de compartir información en redes sociales:** Desde PECH NETWORK queremos advertirte de la necesidad de utilizar de forma adecuada la Plataforma, valorando qué información compartes, así como quién puede tener acceso a la misma, teniendo siempre presente que tu intimidad y privacidad pueden verse comprometidas si no haces un uso responsable de la misma.

Te recordamos que no puedes publicar información de terceras personas (datos personales, fotografías, etc.) sin contar con su autorización previa.

Los datos que revelan el origen racial o étnico, las opiniones políticas, las convicciones religiosas o filosóficas, la pertenencia sindical y los datos relativos a la salud y a la vida sexual se consideran categorías especiales de datos. Los datos personales sensibles sólo pueden publicarse en Internet con el consentimiento explícito de la persona interesada o si esta misma persona ha hecho públicos estos datos.

En PECH NETWORK no te solicitaremos información de estas características, por su carácter sensible. Si decides de forma exclusivamente voluntaria publicar este tipo de información te sugerimos que valores las consecuencias de hacerlo antes de su publicación.

**3\. ¿Para qué tratamos tus datos y sobre qué base legal lo hacemos?**

A continuación, detallamos para qué utilizaremos tus datos, así como la legitimación del tratamiento, o lo que es lo mismo, cuál es la base legal que permite que tratemos tus datos conforme a lo previsto en la normativa de aplicación en materia de protección de datos.

**3.1. Usuarios registrados en Launchyoo:** Si te registras en Launchyoo los datos recabados serán tratados:

**a) Para que puedas hacer uso de la Plataforma de Launchyoo:** para poder crear tu perfil en Launchyoo y que puedas hacer uso de la Plataforma, compartiendo contenidos e interactuando con el resto de usuarios de Launchyoo.

Si además has contratado alguna suscripción de los servicios de pago de Launchyoo, tus datos serán tratados para posibilitar la gestión administrativa de ésta.

La base jurídica para el tratamiento de los datos recabados para esas finalidades, se legitima en la ejecución de un contrato, consistente en la solicitud de alta en la Plataforma para la utilización de acuerdo con lo dispuesto en los [Términos y Condiciones de uso de Launchyoo](https://app.launchyoo.com/terms) que debes leer y en caso de estar conforme, aceptar, a través de las funcionalidades que ponemos a tu disposición.

**b) Segmentación de usuarios con fines de marketing directo:** La segmentación es una práctica consistente en establecer perfiles de usuarios por edad, sexo, gustos, aficiones e intereses que nos permitirá mostrarte publicidad acorde a esos parámetros y poder facilitarte información sobre usuarios, eventos y actividades acordes con esa información.

La base jurídica para la realización de esa segmentación de usuarios es el interés legítimo para poder prestar el servicio solicitado por el usuario y por mercadotecnia directa y en relación con la publicidad de terceros anunciantes, la base jurídica será la ejecución de un contrato o precontrato con el usuario, por cuanto el servicio para que sea gratuito requiere financiarse con la publicidad de terceros anunciantes.

Dicha segmentación también permitirá que el envío de comunicaciones comerciales sea acorde a los intereses mostrados por ti.

En ningún caso, la elaboración de esos perfiles supondrá la adopción de decisiones individuales automatizadas que produzcan efectos jurídicos sobre ti o te afecten significativamente de modo similar.

La base jurídica de dicho tratamiento se encuentra en el interés legítimo de PECH NETWORK para mercadotecnia directa. Te recordamos que estás facultado a oponerte a dicho tratamiento de segmentación para fines comerciales en cualquier momento.

**c) Envío de comunicaciones comerciales:** Remisión de comunicaciones comerciales (incluido por medios electrónicos) sobre novedades, promociones y/o servicios de Launchyoo, siempre y cuando no te hayas opuesto al envío de tales comunicaciones, cosa que puedes hacer en cualquier momento dirigiendo un correo electrónico a la dirección indicada en el apartado 1. anterior.

La base jurídica será el interés legítimo con fines de mercadotecnia directa.

Envío de comunicaciones comerciales de nuestros anunciantes a tu buzón en el servicio de mensajería directa de Launchyoo.

En este caso, la base jurídica será la ejecución del contrato de uso de la Plataforma, que conlleva el envío de comunicaciones comerciales de nuestros anunciantes, exclusivamente en el entorno de la Plataforma.

**d) Realización de encuestas o estudios de mercado** relacionados con tu actividad en Launchyoo.

La base jurídica es el interés legítimo por mercadotecnia directa.

**3.2. Usuarios no registrados en Launchyoo:**

**a) Usuarios que solicitan información:** Si solicitas información a PECH NETWORK, a través de los formularios dispuestos en el Sitio Web, tus datos serán utilizados para atender dicha solicitud de información, sobre la base jurídica del interés legítimo del Responsable para dar cumplida respuesta esta.

La base jurídica será el interés legítimo para tender la solicitud de información por el cliente.

**b) Envío de newsletters y/o de comunicaciones comerciales de PECH NETWORK:** Te enviaremos comunicaciones comerciales siempre que hayas prestado tu consentimiento inequívoco, específico, libre e informado, a través del marcado de la correspondiente casilla de aceptación. 

La base jurídica del tratamiento en este caso es el consentimiento prestado, que podrá ser revocado en cualquier momento dirigiendo un correo electrónico a la dirección indicada en el apartado 1. anterior.

**4\. ¿Por cuánto tiempo conservaremos tus datos personales?:** Los datos personales proporcionados se conservarán mientras exista un interés mutuo para mantener las finalidades del tratamiento y durante el plazo por el cual pudieran derivarse responsabilidades por los servicios prestados al interesado.

Cuando ya no sea necesario para tales fines, se suprimirán con medidas de seguridad adecuadas para garantizar la seudonimización de los datos o la destrucción total de estos.

**5\. ¿Cedemos tus datos a terceros ajenos a nosotros?** Los datos no se comunicarán a ningún tercero ajeno a PECH NETWORK, salvo obligación legal o salvo que nos hayas autorizado a ello.

PECH NETWORK tiene contratados determinados servicios con empresas con las que tiene suscrito un contrato de Encargado de Tratamiento, conforme a lo establecido en la normativa aplicable en materia de protección de datos.

Únicamente PECH NETWORK y los encargados de tratamiento designados por ella, tendrán acceso a sus datos, y bajo ningún concepto, estos datos serán cedidos, compartidos, transferidos, ni vendidos a ningún tercero.

Sin perjuicio de lo anterior, PECH NETWORK se reserva la posibilidad de realizar estudios estadísticos de hábitos de consumo sobre la base del comportamiento de los usuarios en la Plataforma. Los resultados de dichos estudios arrojarán datos disociados (aquellos que no permite la identificación de un afectado o interesado) y podrán ser cedidos a terceros.

**6\. ¿Realizamos transferencias internacionales de datos?**

Se entiende por transferencia internacional de datos cuando los datos recabados son tratados fuera de las fronteras del Espacio Económico Europeo (constituido por la Unión Europea más Islandia, Noruega y Liechtenstein).

Los datos personales recabados por PECH NETWORK son tratados íntegramente en el territorio de la Unión Europea, por lo que no realizamos transferencias internacionales de datos personales.

**7\. ¿Cuáles son tus derechos en materia de protección de datos?**

Conforme a lo establecido en la normativa de aplicación en materia de protección de datos, dispones de los siguientes derechos en relación con los datos personales recabados por PECH NWETWORK:

En el caso de que el tratamiento de datos se halle fundamentado en el consentimiento, tienes derecho a revocar ese consentimiento en cualquier momento.

Tienes derecho de ACCESO a tus datos personales que estamos tratando.

Tienes derecho de RECTIFICACIÓN de sus datos personales. Puedes rectificar tus datos personales accediendo al Panel de Control de tu perfil de usuario.

Tienes derecho a solicitar la SUPRESIÓN de tus datos, cuando, entre otros motivos, no sean necesarios para los fines que fueron recabados.

Tienes derecho a solicitar, en determinadas circunstancias, la LIMITACIÓN DEL TRATAMIENTO de tus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.

En determinadas circunstancias y por motivos relacionados con tu situación particular, también podrás ejercitar tu derecho de OPOSICIÓN al tratamiento de tus datos. Dejaremos de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.

En determinadas circunstancias y por motivos relacionados con tu situación particular, también podrás solicitar tu derecho a la PORTABILIDAD de los datos. Se trata de un derecho complementario al derecho de acceso, ya que permite obtener los datos recabados por PECH NETWORK en un formato estructurado, de uso común y de lectura mecánica, pudiendo ser transmitidos de forma directa a otra entidad previa petición del Interesado.

Podrás ejercer tales derechos solicitando por cualquier medio que deje constancia de su envío y de su recepción, expresando claramente tu voluntad en tal sentido y acompañando copia del DNI y/o cualquier otra documentación acreditativa de tu identidad, dirigiéndote la dirección de correo electrónico customercare@launchyoo.com o a la siguiente dirección postal:

**PECH Network Services, S.L.U.**

C/ Ramón Llull, 31, 4, 11,

46021, Valencia (España)

Además, en caso de que consideres que se ha vulnerado cualquiera de tus derechos, como interesado, estás facultado para presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD), sita en C/ Jorge Juan, 6, 28001-Madrid https://www.agpd.es/ o a través de la sede electrónica de la AEPD: https://sedeagpd.gob.es/sede-electronica-web/.

**8\. Seguridad y actualización de tus datos personales:** Con el objetivo de salvaguardar la seguridad de tus datos personales, te informamos que se han adoptado todas las medidas de índole técnica y organizativa necesarias para garantizar la seguridad de los datos personales suministrados. Todo ello para evitar su alteración, pérdida, y/o tratamientos o accesos no autorizados, tal como exige la normativa, si bien la seguridad absoluta no existe.

Es importante que, para que podamos mantener tus datos personales actualizados, nos informes siempre que se produzca una modificación de estos.

**9\. Confidencialidad:** te informamos asimismo de que sus datos serán tratados con el máximo celo y confidencialidad por todo el personal que intervenga en cualquiera de las fases del tratamiento. 

**Última versión: Junio 2020**
`;











const ptText = enText;