import React, { Component } from 'react'
import {Link} from "react-router-dom";

import endpoint from "../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import {store} from './../../store';

import _ from 'lodash';

//import EditorBase from '../../components/editor/base';
import EditorBase from '../../components/editor/base_V2';

import ThePost from './components/ThePost';
import TheSharedPost from './components/TheSharedPost';
import ThePostPlaceholder from './components/ThePostPlaceholder';

import WallFloatingMenu from './components/WallFloatingMenu';

import IntersectionVisible    from 'react-intersection-visible'

import i18n from "../../i18n";

import { toast } from 'react-toastify';

class Group extends Component {

  constructor(props) {
    super();

    this.state = {
      thePosts: [],
      theGroup: [],
      read: 0,
      write: 0,
      subscribed: 0,
      owner: 0,
      theSubscriberRequest: [],
      isLoading: true,
      intersectionVisible: true,
    };
    
    this.getPosts 		= this.getPosts.bind(this);
    this.onRefresh 		= this.onRefresh.bind(this);
	this.loadMore 		= _.debounce(this.loadMore.bind(this), 500);
    this.groupSubscribe = this.groupSubscribe.bind(this);
    this.groupLeave 	= this.groupLeave.bind(this);
    this.groupModerate 	= this.groupModerate.bind(this);

    this.store 			= store.getState();

  }
  
	componentDidMount(){
		this.setState({ isLoading : true });
		this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.group !== prevProps.match.params.group) {
			this.setState({ isLoading : true });
			this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
		}
	}


	getPosts( fetchNew = false ){

        return new Promise((resolve, reject) => {

        	if ( fetchNew == true ){
				var cursor = this.state.thePosts[0] ?  -1 * this.state.thePosts[0][0].id : 0;
        	}else{
        		var cursor = 0;
        	}

			if ( typeof this.props.match.params.cursor !== 'undefined'){
				cursor = this.props.match.params.cursor;
			}

			const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
				axios.get(endpoint.posts+'/group/'+this.props.match.params.group+'/'+cursor, cfg)
		  		.then(res => {
		  			this.setState({
						thePosts			: [...res.data.thePosts, ...this.state.thePosts],
						theGroup			: res.data.theGroup,
						read				: res.data.read,
					    write				: res.data.write,
      					subscribed			: res.data.subscribed,
      					owner				: res.data.owner,
      					theSubscriberRequest: res.data.theSubscriberRequest
					});
					resolve();
		  		})
		  		.catch(err => {
		  			this.setState({
						intersectionVisible: false
					});
					resolve();
				})  
		})

	}

	onRefresh(){
		this.setState({ isLoading : true });
            this.getPosts( true )
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );

	}  

	loadMore( entries )
    {
        this.setState({ isLoading : true });

        if (this.state.thePosts.length > 0){
			var cursor = this.state.thePosts[this.state.thePosts.length - 1][0].id;
        }else{
        	var cursor = 0;
        }

		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
			axios.get(endpoint.posts+'/group/'+this.props.match.params.group+'/'+cursor, cfg)
	  		.then(res => {
				this.setState({ 
					thePosts	: [...this.state.thePosts, ...res.data.thePosts],
					theGroup	: res.data.theGroup,
					read		: res.data.read,
				    write		: res.data.write,
  					subscribed	: res.data.subscribed,
  					owner		: res.data.owner
				});
				
				if( res.data.thePosts.length < 1){
					setTimeout( () => { this.setState({ isLoading : false, intersectionVisible: false }) },1100 )
				}else{
					setTimeout( () => { this.setState({ isLoading : false, intersectionVisible: true }) },1000 )
				}

	  		})
	  		.catch( setTimeout( () => { this.setState({ isLoading : false, intersectionVisible: false }) },1000 ) )

    }


	groupSubscribe(){
		const reqBody = { group_slug: this.props.match.params.group };
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };

		axios.post(endpoint.posts+'/groupsubscribe', reqBody, cfg)
			.then((result) => {
			  	toast.info( result.data.subStatus == 1 ? i18n.t('You joined the group') : i18n.t('Subscription awaiting approval'), { autoClose : 2500 } );
				this.setState({ isLoading : true });
	            this.getPosts( false )
	            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
	            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
			})
			.catch((err) => {
			  	console.log(err.response);
			  	toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 2500 } );
			})
	}


	groupLeave(){
		const reqBody = { group_slug: this.props.match.params.group, action: 'leave' };
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };

		axios.post(endpoint.posts+'/groupleave', reqBody, cfg)
			.then((result) => {			  	
			  	toast.info(i18n.t('You left the group'), { autoClose : 2500 } );
				this.setState({ isLoading : true });
	            this.getPosts( false )
	            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
	            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
			})
			.catch((err) => {
			  	//console.log(err.response.data);
			  	toast.error(i18n.t('An error occurred, try again later'), { autoClose : 2500 } );

			})
	}


	groupModerate( action, subscriberId){
		const reqBody = { group_slug: this.props.match.params.group, action: action, subscriberId: subscriberId };
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };

		axios.post(endpoint.posts+'/groupmoderate', reqBody, cfg)
			.then((result) => {
				if ( action == 'accept' ){
				  	toast.success(i18n.t('Subscription approved'), { autoClose : 2500 } );
				}else if ( action == 'decline' ){
				  	toast.warning(i18n.t('Subscription rejected'), { autoClose : 2500 } );
				}
				this.setState({ isLoading : true });
	            this.getPosts( false )
	            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
	            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
			})
			.catch((err) => {
			  	console.log(err.response);
			  	toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 2500 } );
			})
	}	

  render () {

	let subscribeBtn = (<div className="subscribeBtn subscribe" onClick={this.groupSubscribe}><i className="fal fa-plus"></i><span>{i18n.t("Subscribe")}</span></div>);
	if (this.state.subscribed == 1){
		subscribeBtn = (<div className="subscribeBtn unsubscribe" onClick={this.groupLeave}><i className="fal fa-minus"></i><span>{i18n.t("Leave")}</span></div>);
	}else if(this.state.subscribed == 0){
		subscribeBtn = (<div className="subscribeBtn moderation"><i className="fal fa-ellipsis"></i><span>{i18n.t("Pending request")}</span></div>);
	}

    return (
    	<div className="wall group">

    		{
    			this.state.theGroup.length > 0 ?
    			(
    				<div className="groupInfo">
    					<h2>{this.state.theGroup[0].title}</h2>
    					<div className="owner">
    						<Link to={"/profile/"+this.state.theGroup[0].owner_username}>
	    						<span className="by">{i18n.t("by")}</span>
		    					<img src={ this.state.theGroup[0].owner_avatar ? endpoint.mediaRoot +'/'+ this.state.theGroup[0].owner_avatar : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
    							<span>{this.state.theGroup[0].owner_username}</span>
    						</Link>
    					</div>
    					<div className="desc">{this.state.theGroup[0].description}</div>
    					<div className="action">{ this.state.owner ? null : subscribeBtn }</div>
    				</div>
    			)
    			:
    			null
    		}

    		{ this.state.owner && this.state.theSubscriberRequest.length > 0 ?

	    		<div className="groupPendingSubscriptions"> 
		    		<div className="headline">{i18n.t('Pending subscriptions')}<span>{this.state.theSubscriberRequest.length}</span></div>
		    		<div className="col">
	    			{ 
	    				(this.state.theSubscriberRequest || []).map( (moderate, i) => {
			    			return (
			    				<div className="row"  key={'theModerate'+i}>
									{ moderate.subscriberAvatar !== false && typeof moderate.subscriberAvatar !== "undefined" ? (<Link to={"/profile/"+moderate.subscriberUsername}><img className="avatar" src={endpoint.mediaRoot+'/'+moderate.subscriberAvatar} /></Link>) : (<Link to={"/profile/"+moderate.subscriberUsername}><img className="avatar" src={endpoint.mediaRoot+'/placeholder-avatar.jpg'} /></Link>) }
			    					<div className="action">
				    					<h3><Link to={"/profile/"+moderate.subscriberUsername}>{moderate.subscriberUsername}</Link></h3>
			    						<i className="accept fal fa-check-circle" onClick={ () => { this.groupModerate('accept', moderate.subscriberId) } }></i>
			    						<i className="decline fal fa-times-circle" onClick={ () => { this.groupModerate('decline', moderate.subscriberId) } }></i>
			    					</div>
			    				</div>
			    			)
	    				}) 
	    			 }
	    			 </div>
	    		</div>
	    		:
	    		null

    		}

    		{/* 'read:'+this.state.read+' write:'+this.state.write+' subscribed:'+this.state.subscribed+' owner:'+this.state.owner */}

    		<div style={{width:'100%', height: '20px'}}></div>

			{ this.state.write  ? <EditorBase group={this.props.match.params.group} /> : (<div>BOTTONE ISCRIVITI</div>) }


    		<div className="refresh" onClick={this.onRefresh} ><i className={ this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt" }></i></div>

    		{ 
    			this.state.thePosts.length == 0  && this.state.isLoading == true && this.state.read ? (<ThePostPlaceholder/>) : null
    		}

    		{ 
    			this.state.thePosts.length == 0 && this.state.isLoading == false && this.state.read ? (<div>no post</div>) : null
    		}

    		{ !this.state.read ? (<div>{ i18n.t("Subscribe") }</div>) : null }

    		{ (this.state.thePosts || []).map( (post, i) => {
		    		return post[0].type == 'share' ? <TheSharedPost thePost={post} key={'thePost'+i} autoLoadComments={true} group_slug={this.props.match.params.group} /> : <ThePost thePost={post} key={'thePost'+i} autoLoadComments={true} group_slug={this.props.match.params.group}  />
		    		/*return <ThePost thePost={post} key={'thePost'+i} />*/
    			} 
	    	)}

	    	{ this.state.thePosts && this.state.isLoading == false && this.state.intersectionVisible ? 
	    		(
		    		<IntersectionVisible onShow={ e => this.loadMore( e ) }>
			    		<div className="refresh" ><i className={ this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt" }></i></div>
		        	</IntersectionVisible>
	    		)
	    		:
	    		null
	    	}

    	</div>
    )
  }
}

export default Group;
