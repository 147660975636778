import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import Tagsform from './components/tagsform';
import Positionform from './components/positionform';
import Socializationform from './components/socializationform';
import Notificationform from './components/notificationform';
import Privacyform from './components/privacyform';
import Supportform from './components/supportform';
import UIform from './components/uiform';

import i18n from "../../i18n";

const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


class ConnectedSettings extends Component {

	constructor(props) {
		super(props);

	    this.state = {
	      activeTab: "tags"
	    };

	}

	componentDidMount(){
	}

 	handleTab = (tabValue) => {
  		this.setState((prevState) => {
  		  return {activeTab: tabValue};
  		})
    }

	render(){
		return(
			<div className="settings-wrapper">
				{ (this.props.user.auth !== true) ? (<Redirect to='/login' />) : ("") }
				<SettingsTab active={this.state.activeTab} handleTab={this.handleTab} />
				<SettingsContent active={this.state.activeTab}/>
			</div>
		);
	}
}



const Settings = connect(mapStateToProps)(ConnectedSettings);
export default Settings;


class SettingsTab extends Component{

	constructor(props) {
		super(props);
		this.switchActiveTab = this.switchActiveTab.bind(this);
	}

	componentDidMount(){
		this.setState({activeTab: "tags"});
	}

	switchActiveTab(tab){
		this.props.handleTab(tab);
	}

	render(){
		return(
			<div className="tabs">
			  <ul>
			    <li className={ this.props.active == "tags" ? "is-active" : "is-inactive" }>
			      <a onClick={ () => { this.switchActiveTab("tags") } } >
			        <span className="icon is-small"><i className="fal fa-tags" aria-hidden="true"></i></span>
			        <span>{ i18n.t("Tags") }</span>
			      </a>
			    </li>		    
			    <li className={ this.props.active == "position" ? "is-active" : "is-inactive" }>
			      <a onClick={ () => { this.switchActiveTab("position") } }>
			        <span className="icon is-small"><i className="fal fa-map-marker-alt" aria-hidden="true"></i></span>
			        <span>{ i18n.t("Position") }</span>
			      </a>
			    </li>
			    <li className={ this.props.active == "socialization" ? "is-active" : "is-inactive" }>
			      <a onClick={ () => { this.switchActiveTab("socialization") } }>
			        <span className="icon is-small"><i className="fal fa-comment-alt-smile" aria-hidden="true"></i></span>
			        <span>{ i18n.t("Socialization") }</span>
			      </a>
			    </li>
			    <li className={ this.props.active == "ui" ? "is-active" : "is-inactive" }>
			      <a onClick={ () => { this.switchActiveTab("ui") } }>
			        <span className="icon is-small"><i className="fal fa-palette" aria-hidden="true"></i></span>
			        <span>{ i18n.t("Personalization") }</span>
			      </a>
			    </li>
				<li className={ this.props.active == "notification" ? "is-active" : "is-inactive" }>
			      <a onClick={ () => { this.switchActiveTab("notification") } }>
			        <span className="icon is-small"><i className="fal fa-bells" aria-hidden="true"></i></span>
			        <span>{ i18n.t("Notifications") }</span>
			      </a>
			    </li>
			    {/* <li className={ this.props.active == "privacy" ? "is-active" : "is-inactive" }>
			      <a onClick={ () => { this.switchActiveTab("privacy") } }>
			        <span className="icon is-small"><i className="fal fa-lock-alt" aria-hidden="true"></i></span>
			        <span>{ i18n.t("Privacy") }</span>
			      </a>
			    </li> */}
			    <li className={ this.props.active == "support" ? "is-active" : "is-inactive" }>
			      <a onClick={ () => { this.switchActiveTab("support") } }>
			        <span className="icon is-small"><i className="fal fa-question-circle" aria-hidden="true"></i></span>
			        <span>{ i18n.t("Help and support") }</span>
			      </a>
			    </li>
			    <li>
			      <Link to="/terms/" target="_blank">
			        <span className="icon is-small"><i className="fal fa-file-contract" aria-hidden="true"></i></span>
			        <span>{ i18n.t("Terms and conditions") }</span>
			      </Link>
			    </li>
			    <li>
			      <Link to="/privacy/" target="_blank">
			        <span className="icon is-small"><i className="fal fa-file-contract" aria-hidden="true"></i></span>
			        <span>{ i18n.t("Privacy policy") }</span>
			      </Link>
			    </li>
			  </ul>
			</div>
		);		
	}
	
}



class SettingsContent extends Component {

	constructor(props) {
		super(props);

	    this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(event) {
	    event.preventDefault();
		console.log('submit');
	}

	handleChange(event) {
	    event.preventDefault();
		console.log('change');
	}


	render(){

		let content = "";

		if (this.props.active == "tags" ){
			content = (<Tagsform/>);
      	}
		if (this.props.active == "position" ){
			content = (<Positionform/>);
      	}
		if (this.props.active == "socialization" ){
			content = (<Socializationform/>);
      	}
		if (this.props.active == "notification" ){
			content = (<Notificationform/>);
      	}
		if (this.props.active == "privacy" ){
			content = (<Privacyform/>);
      	}
		if (this.props.active == "support" ){
			content = (<Supportform/>);
      	}
		if (this.props.active == "ui" ){
			content = (<UIform/>);
      	}
		return(
			<div className="settings-content">
				{ content }
			</div>
		);
	}
}





