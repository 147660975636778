import axios from "axios";
import qs from "querystring";
import endpoint from "../config/endpoint";

const initialState = {
  articles: [],
  user : {
    auth : false,
    token : false
  },
  settings : {
    tags: false,
    userSettings: [{
      user_id: false,
      active_socialization         : 0,
      active_socialization_radius  : 0,
      active_socialization_age_min : 0,
      active_socialization_age_max : 0,
      active_socialization_gender  : '',
      position_visibility          : 0,
      position_label               : '',
      notify_mention_post          : 0,
      notify_mention_photo         : 0,
      notify_new_post_follow       : 0,
      notify_new_post_hot          : 0,
      notify_new_post_moderate     : 0,
      notify_new_post_private      : 0,
      notify_new_post_group        : 0,
      visibility_username          : 0,
      visibility_contact           : 0,
      ui_bkg_type                  : false,
      ui_bkg_image                 : false,
      ui_color_photo               : false,
      ui_color_video               : false,
      ui_color_audio               : false,
      ui_color_minimes             : false,
      ui_color_news                : false,
      ui_color_mix                 : false
    }],
    appSettings: {
      minimes_size    : 250,
    },
    userRow: false,
    avatar: false,
  },
  utility : {
    loading : false,
    message : false,
    redirect : false
  },
  language : 'en'

};

function rootReducer(state = initialState, action) {

  // LOADING
  if (action.type === "UTILITY_LOADING") {
    console.log('reducer UTILIY_LOADING');
    return { ...state,   utility : { loading : true } }
  }

  // LANGUAGE
  if (action.type === "UTILITY_LANGUAGE") {
    console.log('reducer UTILITY_LANGUAGE');
    console.log(action);
    return { ...state, language : action.payload }
  } 

    // INIT CONFIG
  if (action.type === "INIT_CONFIG_LOADED") {
    return { ...state,  settings : { ...state.settings, tags : action.payload.tags, userSettings : action.payload.userSettings, appSettings : action.payload.appSettings, userRow : action.payload.userRow, avatar : action.payload.avatar } , utility : { loading : false, } }
  } 

  if (action.type === "INIT_CONFIG_NOT_LOADED") {
    return { ...state,   utility : { loading : false } }
  } 

  // LOGIN
  if (action.type === "USERS_LOGIN") {
    //return { ...state, auth: 'modificato' }
  }

  if (action.type === "USERS_LOGOUT") {
    return { ...state,   user : { auth : false, token : false }, utility : { loading : false } }
    return { ...state,   initialState }
  }  

  if (action.type === "USERS_LOGIN_SUCCESS") {
    console.log("reducer/ USERS_SUCCESS - action"+ action);
    return { ...state, user: action.payload, utility : { loading : false } }
  }

  if (action.type === "USERS_LOGIN_ERROR") {
    console.log("reducer/ USERS_ERROR");
    return { ...state, user: action.payload.user, utility : { loading : false } }
  }  
  // END LOGIN

    // REGISTER
  if (action.type === "USERS_REGISTER") {
    //return { ...state, auth: 'modificato' }
  }

  if (action.type === "USERS_REGISTER_SUCCESS") {
    console.log("reducer/ USERS_SUCCESS - action"+ action);
    return { ...state, user: {auth: false}, utility : { loading : false, redirect: action.payload.redirect } }
  }

  if (action.type === "USERS_REGISTER_ERROR") {
    console.log("reducer/ USERS_ERROR");
    //return { ...state, user: action.payload, utility : { loading : false } }
    return { ...state, user: {auth: false}, utility : { loading : false, redirect: action.payload.redirect } }

  }  
  // END REGISTER
    // ACTIVATE
  if (action.type === "USERS_ACTIVATE") {
    //return { ...state, auth: 'modificato' }
  }

  if (action.type === "USERS_ACTIVATE_SUCCESS") {
    return { ...state, user: {auth: false}, utility : { loading : false, redirect: action.payload.redirect } }
  }

  if (action.type === "USERS_ACTIVATE_ERROR") {
    return { ...state, user: {auth: false}, utility : { loading : false } }
  }  
  // END ACTIVATE

  // UTILITY
  if (action.type === "CLEAN_ONCE") {
    console.log("reducer/ CLEAN_ONCE");
    //return { ...state, user: action.payload, utility : { loading : false } }
    return { ...state, utility : { loading : false, redirect: false, message: false } }
  }   


    // SETTINGS
  if (action.type === "SAVE_SETTINGS") {
    //return { ...state, auth: 'modificato' }
  }
  if (action.type === "SAVE_SETTINGS_TAGS_SUCCESS") {
    return { ...state, utility : { loading : false }, settings: { ...state.settings, tags : action.payload.tags } }
  }
  if (action.type === "SAVE_SETTINGS_POSITION_SUCCESS") {
    return { ...state, utility : { loading : false }, settings: { ...state.settings, userSettings : action.payload.userSettings } }
  }
  if (action.type === "SAVE_SETTINGS_SOCIALIZATION_SUCCESS") {
    return { ...state, utility : { loading : false }, settings: { ...state.settings, userSettings : action.payload.userSettings } }
  }
  if (action.type === "SAVE_SETTINGS_NOTIFICATION_SUCCESS") {
    return { ...state, utility : { loading : false }, settings: { ...state.settings, userSettings : action.payload.userSettings } }
  }  
  if (action.type === "SAVE_SETTINGS_PRIVACY_SUCCESS") {
    return { ...state, utility : { loading : false }, settings: { ...state.settings, userSettings : action.payload.userSettings } }
  }  
  if (action.type === "SAVE_SETTINGS_SUCCESS") {
    return { ...state, utility : { loading : false } }
  }
  if (action.type === "SAVE_SETTINGS_ERROR") {
    return { ...state, utility : { loading : false } }
  }  

  // END SETTINGS


  return state;
}
export default rootReducer;

