import React, { Component } from 'react';
import { connect } from "react-redux";

import i18n from "../../../i18n";

import {store} from './../../../store';
import endpoint from "../../../config/endpoint";
import axios from "axios";

import Player from '../../../components/wall/player';

import IntersectionVisible    from 'react-intersection-visible'

import { toast } from 'react-toastify';

import Masonry from 'react-masonry-component';

import Modal from 'react-responsive-modal';

const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


class ConnectedCollage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			theCollages		: [],
			zoom: false
		}

	    this.removeFromCollage = this.removeFromCollage.bind(this)
	    this.imgZoom = this.imgZoom.bind(this)

	    this.store = store.getState();

	}

	componentDidMount(){
		if (this.props.paramsUser){
			let streamEndpoint = endpoint.posts+'/collage/'+this.props.paramsUser;
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}else{
			let streamEndpoint = endpoint.posts+'/collage/self';
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}
	}


  componentDidUpdate(prevProps) {

    if (this.props.paramsUser !== prevProps.paramsUser) {
		if (this.props.paramsUser){
			let streamEndpoint = endpoint.posts+'/collage/'+this.props.paramsUser;
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}else{
			let streamEndpoint = endpoint.posts+'/collage/self';
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}

    }
  }

	userStreamInit(streamEndpoint){

		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(streamEndpoint+'/0', cfg)
			.then((result) => {
	  			this.setState({
					theCollages: result.data.theCollages
				});
			})
			.catch((err) => {
			  	console.log(err);
			})

	}	


	removeFromCollage(media_id) 
	{

		let reqBody = { media_id: media_id, action: 'remove' };

		const cfg = { 
			headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
	    };
		axios.post(endpoint.posts+'/moderatecollage', reqBody, cfg)
			.then((result) => {
			  	toast.warning(i18n.t('Removed from collage'), { autoClose : 2500 } );	
			  	this.userStreamInit(endpoint.posts+'/collage/self');	
			})
			.catch((err) => {
			})	

	}

	imgZoom(path){
		this.setState({zoom: path})
	}

	render(){

		let websiteRoot = window.location.protocol + "//" + window.location.host;


		var actionButton = ( i ) => {

			if ( !this.props.paramsUser ){
				return(
					<div className="collage add stream-collage-remove" onClick={ () => this.removeFromCollage(i) }>
						<i className="fa fa-times"></i>
					</div>
				)
			}else{
				return null
			}

		}

		return(
			<div className="profile-collage">

				<div className="profile-section-title"><span>Album</span></div>
				
				<div className="profile-collage-content">
			            <Masonry
			                className={'my-gallery-class'} // default ''
			                elementType={'div'} // default 'div'
			                options={{transitionDuration: 0}} // default {}
			                disableImagesLoaded={false} // default false
			                updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
			                imagesLoadedOptions={{ background: '.my-bg-image-el' }} // default {}
			            >

					    		{ (this.state.theCollages || []).map( (collage, i) => {
							    		return (
							    			<div className="theCollages" key={'theCollage'+i}> 
							    				{ collage.media_type == 'video' ? (<div onClick={ () => { window.location.href = websiteRoot + '/post/' + collage.media_parent } } className="video"><Player src={ endpoint.mediaRoot +'/'+ collage.media_url } />{actionButton(collage.id)}</div>) :  null }
							    				{ collage.media_type == 'audio' ? (<div onClick={ () => { window.location.href = websiteRoot + '/post/' + collage.media_parent } } className="audio"><div className="audioPlayer"><Player src={ endpoint.mediaRoot +'/'+ collage.media_url } /></div>{actionButton(collage.id)}</div>) :  null }
							    				{ collage.media_type == 'image' ? (<div onClick={ () => { window.location.href = websiteRoot + '/post/' + collage.media_parent /*this.imgZoom( endpoint.mediaRoot +'/'+ collage.media_url)*/ } } className="image"><img src={ endpoint.mediaRoot +'/'+ collage.media_url } />{actionButton(collage.id)}</div>) :  null }
							    			</div>
							    		)
					    			} 
						    	)}

			            </Masonry>

				        <Modal open={this.state.zoom ? true : false } onClose={ () => this.imgZoom(false) } classNames={{ modal: "imgZoomModal" }} center  showCloseIcon={true}>

				            {
				            	this.state.zoom ?
				            	<img src={this.state.zoom} />
				            	:
				            	null
				            }

				        </Modal>

				        {this.state.theCollages.length < 1 ? 'No media yet' : null}

				</div>


			</div>
		);
	}
}


const Collage = connect(mapStateToProps)(ConnectedCollage);
export default Collage;


