import React, { Component } from "react";
import { connect } from "react-redux";
import { saveSettings } from "../../../actions/index";
import { Link } from "react-router-dom";

import i18n from "../../../i18n";

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import _ from 'lodash';

const sliderVisibility = {
  0: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("Nobody"),
  },
  1: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("Friends"),
  }, 
  2: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("Friend of Friends"),
  },
  3: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("All"),
  }     
};

function mapDispatchToProps(dispatch) {
  return {
    saveSettings: settings => dispatch(saveSettings(settings))
  };
}

const mapStateToProps = state => {
  return { fetchedprivacy: state.settings.userSettings };
};


class Connectedprivacyform extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			visibility_username : this.props.fetchedprivacy[0].visibility_username,
			visibility_contact	: this.props.fetchedprivacy[0].visibility_contact
		}
	    this.handleSubmit 				= this.handleSubmit.bind(this);
	    this.handleChange 				= this.handleChange.bind(this);
	    this.sliderVisibilityUsername 	= this.sliderVisibilityUsername.bind(this);
	    this.sliderVisibilityContact 	= this.sliderVisibilityContact.bind(this);
	}

	handleSubmit(event) {
	    event.preventDefault();
    	const type = "privacy";
	    const dataObj = { visibility_username: this.state.visibility_username, visibility_contact: this.state.visibility_contact };
    	this.props.saveSettings({ type, dataObj });
    }

	handleChange(event) {
	    const fieldId = event.target.id;
	    const fieldValue = (event.target.getAttribute('type') == "checkbox") ?  event.target.checked : event.target.value; 
	    this.setState({ [fieldId]: fieldValue }, () => { console.log("state callback"); });
	}

	sliderVisibilityUsername(value) {
		this.setState({ visibility_username: value }, () => { console.log("visibility_username", value); });
	}	

	sliderVisibilityContact(value) {
		this.setState({ visibility_contact: value }, () => { console.log("visibility_contact", value); });
	}	

	componentDidMount(){
	}

  render() {

    return (
		<form onSubmit={ this.handleSubmit } className="settings-form">
			<h2>{  i18n.t("privacy") }</h2>
			<div className="intro">
				Choose your data visibility
			</div>

			<h3>{  i18n.t("Real Name visibility") }</h3>
			<div className="single-field one-line">
			    <Slider dots min={0} max={3} marks={sliderVisibility} step={1} onChange={ this.sliderVisibilityUsername } defaultValue={this.state.visibility_username} />
			</div>
			<br/><br/>

			<h3>{  i18n.t("Contact visibility") }</h3>
			<div className="single-field one-line">
			    <Slider dots min={0} max={3} marks={sliderVisibility} step={1} onChange={ this.sliderVisibilityContact } defaultValue={this.state.visibility_contact} />
			</div>
			<br/><br/>
			<button type="submit" className="button btn-primary">
				{ i18n.t("Save") }
			</button>
	     </form>
    );
  }
}


const privacyform = connect(mapStateToProps, mapDispatchToProps)(Connectedprivacyform);
export default privacyform;