import React, { Component } from 'react';
import { connect } from "react-redux";

import Logoutbutton from "../../components/auth/Logoutbutton";
import { Redirect } from "react-router-dom";

const mapStateToProps = state => {
  return { user: state.user };
};

class ConnectedLogout extends Component {

  constructor() {
    super();
  }

  render() {
    return (
      <div className="Home">
      	{ ( this.props.user.auth === true) ? null : (<Redirect to='/' />) }
      	<Logoutbutton/>
      </div>
    );
  }
}

const Logout = connect(mapStateToProps)(ConnectedLogout);
export default Logout;