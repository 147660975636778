import React, { Component } from 'react';
import { connect } from "react-redux";

import i18n from "../../../i18n";

import {store} from './../../../store';
import endpoint from "../../../config/endpoint";
import axios from "axios";

import ThePost from '../../wall/components/ThePost';
import TheSharedPost from '../../wall/components/TheSharedPost';

import IntersectionVisible    from 'react-intersection-visible'

const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


class ConnectedStream extends Component {

	constructor(props) {
		super(props);

		this.state = {
			thePosts		: []
		}

	    this.store = store.getState();

	}

	componentDidMount(){
		if (this.props.match.params.user){
			let streamEndpoint = endpoint.posts+'/user/'+this.props.match.params.user;
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}else{
			let streamEndpoint = endpoint.posts+'/user/self';
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}
	}

  componentDidUpdate(prevProps) {

    if (this.props.match.params.user !== prevProps.match.params.user) {
		if (this.props.match.params.user){
			let streamEndpoint = endpoint.posts+'/user/'+this.props.match.params.user;
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}else{
			let streamEndpoint = endpoint.posts+'/user/self';
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}

    }
  }

	userStreamInit(streamEndpoint){

		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(streamEndpoint+'/0', cfg)
			.then((result) => {
	  			this.setState({
					thePosts: result.data.thePosts
				});
			})
			.catch((err) => {
			  	console.log(err);
			})

	}	

	loadMore( entries )
    {
        this.setState({ isLoading : true });

        if (this.state.thePosts.length > 0){
			var cursor = this.state.thePosts[this.state.thePosts.length - 1][0].id;
        }else{
        	var cursor = 0;
        }


		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
			axios.get(window.streamEndpoint+'/'+cursor, cfg)
	  		.then(res => {
				this.setState({ thePosts: [...this.state.thePosts, ...res.data.thePosts]});
				setTimeout( () => { this.setState({ isLoading : false }) },1000 )
	  		})
	  		.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )

    }

	render(){


		return(

			<div className="profile profile-stream">

					<div className="sx sidebar"></div>
					
					<div className="wall">
						
						<div className="profile-stream-content">
				
				    		{ (this.state.thePosts || []).map( (post, i) => {
						    		//return <ThePost thePost={post} key={'thePost'+i} />
						    		return post[0].type == 'share' ? <TheSharedPost thePost={post} key={'thePost'+i} /> : <ThePost thePost={post} key={'thePost'+i} />
				    			} 
					    	)}

					    	{ this.state.thePosts.length > 0 ? 
					    		(
						    		<IntersectionVisible onShow={ e => this.loadMore( e ) }>
							    		<div className="refresh"><i className={ this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt" }></i></div>
						        	</IntersectionVisible>
					    		)
					    		:
					    		null
					    	}


						</div>

					</div>

					<div className="dx sidebar"></div>

			</div>
		);
	}
}


const FullStream = connect(mapStateToProps)(ConnectedStream);
export default FullStream;




/*
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import i18n from "../../i18n";

import ProfileCover from "./components/profilecover";
import Avatar from "./components/avatar";
import Feel from "./components/feel";
import ProfileInfo from "./components/profileinfo";

import {store} from './../../store';
import endpoint from "../../config/endpoint";
import axios from "axios";

import Moment from 'react-moment';

import ThePost from '../wall/components/ThePost';
import ThePostPlaceholder from '../wall/components/ThePostPlaceholder';

import IntersectionVisible    from 'react-intersection-visible'

const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


class ConnectedStream extends Component {

	constructor(props) {
		super(props);

		this.state = {
			covers 			: '',
			own				: '',
			avatar 			: '',
			friendship 		: '',
			profileFeel		: {},
			profileId 		: false,
			profileUsername : false,
			profileInfo 	: false,
			thePosts		: []
		}

	    this.handleUpdate 	= this.handleUpdate.bind(this);
	    this.addFriend 		= this.addFriend.bind(this);

	    this.store = store.getState();

	}

	handleUpdate(){

		if (this.props.match.params.user){
		    this.setState( { own: false } )
			let profileEndpoint = endpoint.users+'/profilegetdata/'+this.props.match.params.user;
			this.profileInit(profileEndpoint);
		}else{
		    this.setState( { own: true } )
			let profileEndpoint = endpoint.users+'/profilegetdata/';
			this.profileInit(profileEndpoint);
		}

	}	

	componentDidMount(){
		if (this.props.match.params.user){
		    this.setState( { own: false } )
			let profileEndpoint = endpoint.users+'/profilegetdata/'+this.props.match.params.user;
			this.profileInit(profileEndpoint);
			let streamEndpoint = endpoint.posts+'/user/'+this.props.match.params.user;
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}else{
		    this.setState( { own: true } )
			let profileEndpoint = endpoint.users+'/profilegetdata/';
			this.profileInit(profileEndpoint);
			// creare funzione per lettura posta da id in backend
			let streamEndpoint = endpoint.posts+'/user/self';
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}
	}


  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {

		if (this.props.match.params.user){
		    this.setState( { own: false } )			
			let profileEndpoint = endpoint.users+'/profilegetdata/'+this.props.match.params.user;
			this.profileInit(profileEndpoint);
			let streamEndpoint = endpoint.posts+'/user/'+this.props.match.params.user;
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}else{		   
		    this.setState( { own: true } )			
			let profileEndpoint = endpoint.users+'/profilegetdata/';
			this.profileInit(profileEndpoint);
			// creare funzione per lettura posta da id in backend
			let streamEndpoint = endpoint.posts+'/user/self';
			window.streamEndpoint = streamEndpoint;
			this.userStreamInit(streamEndpoint);
		}

    }
  }

	profileInit(profileEndpoint){

		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(profileEndpoint, cfg)
			.then((result) => {
		    	this.setState( { covers: result.data.covers, avatar : result.data.avatar, friendship: result.data.friendship, profileId: result.data.profileId, profileUsername: result.data.profileUsername, profileFeel: result.data.profileFeel, profileInfo: result.data.profileInfo } );
			})
			.catch((err) => {
			  	console.log(err);
			})

	}

	userStreamInit(streamEndpoint){

		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(streamEndpoint+'/0', cfg)
			.then((result) => {
	  			this.setState({
					thePosts: [...result.data.thePosts, ...this.state.thePosts]
				});
			})
			.catch((err) => {
			  	console.log(err);
			})

	}	

	loadMore( entries )
    {
        this.setState({ isLoading : true });

        if (this.state.thePosts.length > 0){
			var cursor = this.state.thePosts[this.state.thePosts.length - 1][0].id;
        }else{
        	var cursor = 0;
        }


		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
			axios.get(window.streamEndpoint+'/'+cursor, cfg)
	  		.then(res => {
				this.setState({ thePosts: [...this.state.thePosts, ...res.data.thePosts]});
				setTimeout( () => { this.setState({ isLoading : false }) },1000 )
	  		})
	  		.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )

    }

	addFriend( friendId ){

			const reqBody = { friendId: friendId };
			const cfg = { 
				headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
		    };

			axios.post(endpoint.users+'/addfriend', reqBody, cfg)
				.then((result) => {
				})
				.catch((err) => {
				})	

	}

	render(){


		var friendButton = '';

		if ( !this.state.own && this.state.friendship.hasOwnProperty('status') ){
			if ( this.state.friendship.status == 0 ){
				friendButton = (<div><i className="fal fa-user"></i> { i18n.t("Amicizia in attesa di conferma") } </div>);
			}else if ( this.state.friendship.status == 1 ){
				friendButton = ( <div> { i18n.t("Amici da") }  <Moment fromNow ago>{this.state.friendship.date}</Moment></div> );
			}else if ( this.state.friendship.status == 2 ){
				friendButton = (<div><i className="fal fa-user"></i> {i18n.t("Aggiungi agli amici")} </div>);
			}
		}else if (!this.state.own && !this.state.friendship.hasOwnProperty('status') ) {
			friendButton = (<div><i className="fal fa-user" onClick={ () => this.addFriend(this.state.profileId) }></i> {i18n.t("Aggiungi agli amici")} </div>);			
		}

		return(
			<div className="profile">
				<div className="sx sidebar"></div>
				
				<div className="profile-content">
					<div className="profile-cover-wrapper">
						<ProfileCover covers={this.state.covers} own={this.state.own} handleUpdate={this.handleUpdate} /> 
					</div>

					<div className="profile-menu-wrapper">
						<div className="col">						
							<Avatar avatar={this.state.avatar} profileFeel={this.state.profileFeel} own={this.state.own} handleUpdate={this.handleUpdate} />
						</div>
						<div className="col action">
							<div className="username">
								<span>{this.state.profileUsername}</span>
								<Feel profileFeel={this.state.profileFeel} own={this.state.own} handleUpdate={this.handleUpdate} />
							</div>
							<Link to={this.state.own ? "/profile" : "/profile/"+this.props.match.params.user } className="btn">
								<span className="icon is-small"><i className="fad fa-images" aria-hidden="true"></i></span>
					        	<span>Collage</span>
					        </Link>
							<Link to={this.state.own ? "/userstream" : "/userstream/"+this.props.match.params.user } className="btn active">
								<span className="icon is-small"><i className="fad fa-stream" aria-hidden="true"></i></span>
					        	<span>Stream</span>
					        </Link>
						</div>
					</div>
			
					<div className="profile-info-wrapper">						
						<ProfileInfo profileInfo={this.state.profileInfo} own={this.state.own} handleUpdate={this.handleUpdate} /> 
					</div>

		    		{ (this.state.thePosts || []).map( (post, i) => {
				    		return <ThePost thePost={post} key={'thePost'+i} />
		    			} 
			    	)}

			    	{ this.state.thePosts ? 
			    		(
				    		<IntersectionVisible onShow={ e => this.loadMore( e ) }>
					    		<div className="refresh"><i className={ this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt" }></i></div>
				        	</IntersectionVisible>
			    		)
			    		:
			    		null
			    	}


				</div>

				<div className="dx sidebar"></div>
			</div>
		);
	}
}



const Stream = connect(mapStateToProps)(ConnectedStream);
export default Stream;

*/


