import React, { Component } from "react";
import { connect } from "react-redux";
import { usersLogin, cleanOnce } from "../../actions/index";
import { Link } from "react-router-dom";

import endpoint from "../../config/endpoint";
import axios from "axios";

import i18n from "../../i18n";

function mapDispatchToProps(dispatch) {
  return {
    usersLogin: login => dispatch(usersLogin(login)),
    cleanOnce: () => dispatch(cleanOnce())
  };
}


class ConnectedLoginForm extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      featured: false,
      loginPageData: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.pageContent  = this.pageContent.bind(this);

  }

  componentDidMount(){
    this.pageContent();
  }

  pageContent(){
    axios.get(endpoint.users+'/loginpagecontent/')
      .then((result) => {
          this.setState( { 
            loginPageData    : result.data.loginPageData
          });
      })
      .catch((err) => {
          console.log(err);
      })
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    this.props.cleanOnce();
  }
  handleSubmit(event) {
    event.preventDefault();
    const { email } = this.state;
    const { password } = this.state;
    this.props.usersLogin({ email, password });
    this.setState({ email: email, password: password });
  }
  render() {
    const { email } = this.state;
    const { password } = this.state;


    return (
      <div className="loginFormV2">
        <div className="loginContent">
          {
            this.state.loginPageData.map((item, i)=>{
              if (i18n.language == item.lang && item.type == "not_logged_login_text"){
                return(
                  <div key={'lc'+i}>{item.content}</div>
                )                
              }
            })
          }
        </div>

        <div className="loginAction">

          <div className="loginActionInner">

            <form onSubmit={this.handleSubmit} className={!this.state.featured ? "login" : "login featured" }>
              <div className="form-group" style={{backgroundColor:'#FFF'}}>
                <h2>{  i18n.t("Login") }</h2>
                <input
                  type="text"
                  className="input-text"
                  id="email"
                  value={email}
                  onChange={this.handleChange}
                  placeholder="Email"
                  autoCorrect="off" autoCapitalize="none"
                  ref={(usernameInput) => this.usernameInput = usernameInput} 
                />
                <input
                  type="password"
                  className="input-text"
                  id="password"
                  value={password}
                  onChange={this.handleChange}
                  placeholder={  i18n.t("Password") }
                  autoCorrect="off" autoCapitalize="none"
                />          
                <button type="submit" className="btn-login">
                  {  i18n.t("Login") }
                </button>
                <div className="other-action">
                <Link to="/register">{ i18n.t("Sign up") }</Link> 
                <Link to="/forgot">{ i18n.t("Forgot password") }</Link> 
                </div>
              </div>
            </form>

            
            <Link to="/register" className="signUp">{ i18n.t("Sign up") }</Link> 
            <div className="logIn" onClick={ () => { this.usernameInput.focus(); this.setState({featured:true}) } }>{ i18n.t("Sign in") }</div> 

          </div>   

        </div>     

        
      </div>
    );
  }
}
//const Loginform = connect(null, mapDispatchToProps)(ConnectedLoginForm);
const Loginform = connect(null, mapDispatchToProps)(ConnectedLoginForm);
export default Loginform;