import React, { Component } from 'react'

import { Link, Redirect } from "react-router-dom";
import endpoint from "../../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import {store} from './../../../store';
import { connect } from "react-redux";
import Moment from 'react-moment';

import ThePost from './ThePost';
import TheSharedHeader from './TheSharedHeader';

import i18n from "../../../i18n";

const mapStateToProps = state => {
  return { language: state.language, user: state.user, settings: state.settings};
};


class ConnectedTheSharedPost extends Component {

  constructor(props) {
    super();

    this.state = {
      thePosts: '',
    };
    
    this.getPosts = this.getPosts.bind(this);

    this.store = store.getState();

  	}
  
	componentDidMount(){
		this.getPosts()
	}

	componentDidUpdate(prevProps) {
		if (true == false) {
			this.getPosts()
		}
	}  

	getPosts(){
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
			axios.get(endpoint.posts+'/getpost/'+this.props.thePost[0].share_id, cfg)
	  		.then(res => {
	  			this.setState({
					thePosts: res.data.thePosts || []
				});
	  	})  	
	}

	timeDifference(time) 
	{
		time = new Date(time).getTime()
		var diff =(time - (new Date()).getTime() ) / 1000;
		var roundDiff = Math.abs(Math.round(diff));

		if ( Number.isInteger( Math.round(roundDiff) ) && roundDiff < 86400 && roundDiff > 0 ){
			return true;  
		}else{
			return false;
		}
	}

	render () {

	  	const p = this.props.thePost[0] || {}; 

	/* COLORS */
    let ui_color_photo 	 = false;
    let ui_color_video 	 = false;
    let ui_color_audio 	 = false;
    let ui_color_minimes = false;
    let ui_color_news	 = false; 
    let ui_color_mix 	 = false;

    if ( this.props.settings.userSettings.length > 0){        
        
        let ui = this.props.settings.userSettings[0];      

        let isPhotoColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_photo);
        if (isPhotoColor){
          ui_color_photo = ui.ui_color_photo;
        }

        let isVideoColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_video);
        if (isVideoColor){
          ui_color_video = ui.ui_color_video;
        }

        let isAudioColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_audio);
        if (isAudioColor){
          ui_color_audio = ui.ui_color_audio;
        }

        let isMinimesColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_minimes);
        if (isMinimesColor){
          ui_color_minimes = ui.ui_color_minimes;
        }

        let isNewsColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_news);
        if (isNewsColor){
          ui_color_news = ui.ui_color_news;
        }

        let isMixColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_mix);
        if (isMixColor){
          ui_color_mix = ui.ui_color_mix;
        }

	    }
		/* COLORS */
		
		var postFilter = { icon : 'fad fa-share-all', to : '/wall/mix', iconColor: ui_color_news};


		var emotionIcon = false;

		if (p.feel == 'love'){
			emotionIcon = 'love fas fa-kiss-wink-heart';
		}else if (p.feel == 'angry'){
			emotionIcon = 'angry fas fa-angry';
		}else if (p.feel == 'sad'){
			emotionIcon = 'sad fas fa-frown';
		}else if (p.feel == 'joy'){
			emotionIcon = 'joy fas fa-smile-beam';
		}else if (p.feel == 'surprise'){
			emotionIcon = 'surprise fas fa-surprise';
		}else if (p.feel == 'lol'){
			emotionIcon = 'lol fas fa-grin-tears';
		}else if (p.feel == 'meh'){
			emotionIcon = 'meh fas fa-meh';
		}else if (p.feel == 'tongue'){
			emotionIcon = 'tongue fas fa-grin-tongue';
		}else if (p.feel == 'grimace'){
			emotionIcon = 'grimace fas fa-grimace';
		}



		return (
			<div className="theSharedPostV2"> 
				{this.props.settings.userSettings.length > 0 ?
					<React.Fragment>
						<TheSharedHeader thePostHeader={p} current_user={this.props.settings.userSettings[0].user_id} />
						{ this.state.thePosts.length > 0 ? <ThePost thePost={this.state.thePosts[0]}/> : <div className="notAvailable">{i18n.t('This content is no longer available')}</div> }
					</React.Fragment>
					:
					null
				}
			</div>
		)
	}

}

//export default ThePost;


const TheSharedPost = connect(mapStateToProps, null)(ConnectedTheSharedPost);
export default TheSharedPost;
