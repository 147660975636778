import React, { Component } from 'react';
import { connect } from "react-redux";



const mapStateToProps = state => {
  return { utility: state.utility };
};


class ConnectedLoading extends Component {

  constructor() {
    super();
  }

  render() {

  	const loadingClass =  ( this.props.utility.loading === true) ? "active" : "inactive";

    return (
      <div className={"Loading "+loadingClass}>
      	<div className="content">
      	  <i className="fas fa-spinner"></i>
      	</div>
      </div>
    );
  }
}


const Loading = connect(mapStateToProps)(ConnectedLoading);
export default Loading;