const PT = {
	"lng": "pt",
	"Login": "Iniciar sessão",
	"Register": "Registrar",
	"Activate": "Ativação",
	"Username": "PT Username",
	"Password": "PT Password",
	"This is the home content": "PT This is the home content PT",
	"User not found, check email and password": "Usuario no encontrado, consultar correo electrónico y contraseña",
	"User not found": "User not found",
	"About me": "Sobre mim",
	"Password": "Palavra-passe",
	"Sign up": "Criar uma conta",
	"Forgot password": "Forgot password",
	"Nickname": "Apelido",
	'Repeat password': 'Repete a Palavra-passe',
	'Profile types': 'Tipo de perfil',
	'Super': 'Super',
	'Influencer': 'Influencer',
	'Business': 'Business',
	'Association': 'Associação',
	'Ambassador’s nickname': 'Apelido do Embaixador',
	'Ambassador’s username': 'Apelido do Embaixador',
	'Activation code': 'Código de ativação',
	'Profile': 'Perfil',
	'Settings': 'Configurações',
	Logout: 'Terminar sessão',
	Tags: 'Tags',
	'Preferred tags': 'Etiquetas preferidas',
	'Add new tag': 'Adicionar nova etiqueta',
	Save: 'Salvar',
	Socialization: 'Socialização',
	Notifications: 'Notificações',
	Personalization: 'Personalização',
	Personalization: 'Personalization',
	Privacy: 'Privacidade',
	'Help and support': 'Suporte e ajuda',
	'Terms and conditions': 'Termos e condições',
	'Privacy policy': 'Política de privacidade',
	guest_modal_generic: 'Deseja continuar descobrindo o Launchyoo? Se você já está registrado, faça o login e, se não, crie sua conta e comece a desfrutar da sua nova rede social',
	guest_modal_comment: 'Deseja comentar sobre a publicação? Se você já está registrado no Launchyoo, faça login e, se não, crie sua conta e comece a desfrutar da sua nova rede social',
	guest_modal_like_love: 'Você gosta deste post? Se você já está registrado no Launchyoo, faça login e, se não, crie sua conta e comece a desfrutar da sua nova rede social',
	guest_modal_binary_star: 'Deseja comentar esta publicação? Se você já está registrado no Launchyoo, faça login e, se não, crie sua conta e comece a desfrutar da sua nova rede social',
	"Send a comment": "Envie um comentário",
	cookies_bar: "Na Launchyoo, usamos cookies próprios e de terceiros para analisar seus hábitos de navegação e fazer estudos estatísticos.",
	cookies_bar_link: "Mais informações na Política de Cookies.",
	Accept: "Aceitar",
	Reject: "Rejeitar",
	User: "Usuário",
	"Join the discussion": "Participar da discussão",
	"You left the group": "Você saiu do grupo",
	"You joined the group": "Você entrou no grupo",
	"Subscription awaiting approval": "Subscrição com aprovação pendente",
	Subscribe: "Se inscrever",
	Leave: "Sair",
	"Pending request": "Pedido pendente",
	"Invitation sent": "Convite enviado",
	"Already in that group": "Já nesse grupo",
	helpProfileDetails : 'Escolhe o perfil::', // Auth
	helpProfileSuper : 'USER, se queres usar a Launchyoo para te manteres em contacto com amigos e família, seguir as personalidades do teu interesse, ler notícias ou simplemente te divertires a ver um vídeo ou a ler um blog. Também se fores blogger ou podcaster.', // Auth
	helpProfileInfluencer : 'INFLUENCER, se procuras entreter os teus seguidores, mantê-los a par da tua vida, das tuas atividades e dos teus gostos.', // Auth
	helpProfileBusiness : 'BUSINESS. Para empresas, grandes ou pequenas, negócios e profissionais.', // Auth
	helpProfileAssociation : 'ASSOCIAÇÃO, caso se trate de uma entidade sem fins lucrativos ou que desenvolve atividades sociais, educativas, culturais, de divulgação, informação ou ócio.', // Auth
	helpAmbassador : 'Se te registares na Launchyoo porque alguém te sugeriu, escreve aqui o seu nome de perfil. Esperam-lhe vantagens e a ti também, se alguém escrever o teu nome.', // Auth
	"Read more": "Read more",
  }

export default PT;