import React, { Component } from 'react';
import { Switch, Route, withRouter } from "react-router-dom";

import { connect } from "react-redux";

import Home from '../pages/Home';

/* AUTH */
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import Logout from '../pages/auth/Logout';
import Activate from '../pages/auth/Activate';
import ResetPasswordForm from '../pages/auth/ResetPasswordForm';

import TermsAndConditions from '../pages/auth/TermsAndConditions';
import PrivacyPolicy from '../pages/auth/PrivacyPolicy';
import CookiesPolicy from '../pages/auth/CookiesPolicy';

import Terms from '../pages/auth/Terms';

/* SETTINGS */
import Settings from '../pages/settings/settings';

/* PROFILE */
//import Profile from '../pages/profile/profile';
import Profile from '../pages/profile/profile_v2';
import GuestProfile from '../pages/profile/guest_profile_v2';
import FullStream from '../pages/profile/components/stream';
//import Stream from '../pages/profile/stream';
import Friends from '../pages/profile/friends';

/* Wall */
import Post from '../pages/wall/post';
import Mix from '../pages/wall/mix';
import Tag from '../pages/wall/tag';
import Photo from '../pages/wall/photo';
import Minimes from '../pages/wall/minimes';
import Video from '../pages/wall/video';
import Audio from '../pages/wall/audio';
import News from '../pages/wall/news';

import Wall from '../pages/wall/wall_v2';
import GuestWall from '../pages/wall/guest_wall_v2';

import Bookmark from '../pages/wall/bookmark';

import Group from '../pages/wall/group';
import Groups from '../pages/wall/groups';


/* Admin */
import IndexAdmin from '../pages/admin/index';
import Flags from '../pages/admin/flags';
import Tickets from '../pages/admin/tickets';
import AdminPost from '../pages/admin/adminpost';
import AdminProfile from '../pages/admin/adminprofile';

/* Utility */
//import Sitemap from '../pages/utility/Sitemap';


/* TEST */
import Test from '../pages/Test';


const mapStateToProps = state => {
  return { user: state.user, settings: state.settings };
};

class ConnectedContainer extends Component {

  constructor() {
    super();

  }

	componentDidMount(){
	}

  componentDidUpdate(prevProps, prevState) {
    //console.log('FORCE REFRESH'+"\n", prevProps.user.token, this.props.user.token);
    if (prevProps.user.token == false && this.props.user.token){
      window.location.reload()
    }

  }


	render() {
		return(
	        <Switch>
          		<Route exact path="/" component={ Home } />
          		<Route exact path="/login/" component={Login} redirectTo="/login" param="test" />
          		<Route exact path="/register/" component={Register} redirectTo="/register" param="test" />
          		<Route exact path="/logout/" component={Logout} redirectTo="/logout" param="test" />
          		<Route exact path="/activate/" component={Activate} redirectTo="/activate" param="test" />
          		<Route path="/activate/:email/:atoken/" component={ Activate } param="test" />
          		<Route path="/activate/:email/" component={ Activate } param="test" />


              {/*<Route exact path="/terms/" component={Terms} redirectTo="/login" param="test" />*/}
              <Route exact path="/terms/" component={TermsAndConditions} redirectTo="/login" param="test" />
              <Route exact path="/privacy/" component={PrivacyPolicy} redirectTo="/login" param="test" />
              <Route exact path="/cookies/" component={CookiesPolicy} redirectTo="/login" param="test" />
          		
              <Route exact path="/forgot/" component={ResetPasswordForm} param="test" />
              <Route path="/forgot/:email/:atoken/" component={ ResetPasswordForm } param="test" />
              <Route path="/forgot/:email/" component={ ResetPasswordForm } param="test" />

              {/* <Route path="/sitemap/" component={ Sitemap } param="test" /> */}



              { this.props.user.auth === true ? 

              <React.Fragment>  

                <Route path="/settings/" component={ Settings } param="test" />

                <Route exact path="/profile/" component={ Profile } param="test" />
                <Route path="/profile/:user" component={ Profile } param="test" />
                <Route path="/mention/:user" component={ Profile } param="test" />


                <Route exact path="/userstream/" component={ FullStream } />
                <Route path="/userstream/:user" component={ FullStream } />

                <Route exact path="/group/" component={ Groups } />
                <Route exact path="/groups/" component={ Groups } />
                <Route path="/group/:group" component={ Group } />

                {/*
                <Route exact path="/bookmark/" component={ Bookmark } />
                <Route exact path="/bookmark/" component={ Bookmark } />
                */}
                { /*
                <Route exact path="/userstream/" component={ Stream } param="test" />
                <Route path="/userstream/:user" component={ Stream } param="test" />
                */ }

                <Route exact path="/friends/" component={ Friends } param="test" />

                <Route path="/post/:id" component={ Post } param="test" />

                {/*
                <Route exact path="/tag/:tag/" component={ Tag } param="test" />
                <Route path="/tag/:tag/:cursor" component={ Tag } param="test" />

                <Route exact path="/wall/mix/" component={ Mix } param="test" />
                <Route path="/wall/mix/:cursor" component={ Mix } param="test" />

                <Route exact path="/wall/photo/" component={ Photo } param="test" />
                <Route path="/wall/photo/:cursor" component={ Photo } param="test" />

                <Route exact path="/wall/minimes/" component={ Minimes } param="test" />
                <Route path="/wall/minimes/:cursor" component={ Minimes } param="test" />              

                <Route exact path="/wall/video/" component={ Video } param="test" />
                <Route path="/wall/video/:cursor" component={ Video } param="test" />              

                <Route exact path="/wall/audio/" component={ Audio } param="test" />
                <Route path="/wall/audio/:cursor" component={ Audio } param="test" />              

                <Route exact path="/wall/news/" component={ News } param="test" />
                <Route path="/wall/news/:cursor" component={ News } param="test" /> 
                */}


                <Route exact path="/wall/mix/" render={(props) => <Wall {...props} wallType={'mix'} />} />
                <Route path="/wall/mix/:cursor" render={(props) => <Wall {...props} wallType={'mix'} />} />            

                <Route exact path="/wall/photo/" render={(props) => <Wall {...props} wallType={'photo'} />} />
                <Route path="/wall/photo/:cursor" render={(props) => <Wall {...props} wallType={'photo'} />} />            

                <Route exact path="/wall/minimes/" render={(props) => <Wall {...props} wallType={'minimes'} />} />
                <Route path="/wall/minimes/:cursor" render={(props) => <Wall {...props} wallType={'minimes'} />} />            

                <Route exact path="/wall/video/" render={(props) => <Wall {...props} wallType={'video'} />} />
                <Route path="/wall/video/:cursor" render={(props) => <Wall {...props} wallType={'video'} />} />            

                <Route exact path="/wall/audio/" render={(props) => <Wall {...props} wallType={'audio'} />} />
                <Route path="/wall/audio/:cursor" render={(props) => <Wall {...props} wallType={'audio'} />} />            

                <Route exact path="/wall/news/" render={(props) => <Wall {...props} wallType={'news'} />} />
                <Route path="/wall/news/:cursor" render={(props) => <Wall {...props} wallType={'news'} />} />            

                <Route exact path="/bookmark/" render={(props) => <Wall {...props} wallType={'bookmark'} />} /> 

                <Route exact path="/tag/:tag/" render={(props) => <Wall {...props} wallType={'tag'} />} />
                <Route path="/tag/:tag/:cursor" render={(props) => <Wall {...props} wallType={'tag'} />} />


                <Route exact path="/admin/" component={ IndexAdmin } param="test" />
                <Route exact path="/admin/flags/" component={ Flags } param="test" />
                <Route exact path="/admin/tickets/" component={ Tickets } param="test" />
                <Route path="/admin/post/:id" component={ AdminPost } param="test" />
                <Route path="/admin/profile/:user" component={ AdminProfile } param="test" />

        			{/**<Route exact path="/products/" component={ Products } param="test" />
            		<Route path="/products/:id" component={ ProductDetails } param="test" />
        			<Route exact path="/logout/" component={Logout} redirectTo="/logout" param="test" />**/}
            		<Route exact path="/test/" component={ Test } param="test" />

              </React.Fragment>  

              :
              // guest
              <Switch> 
                <Route path="/post/:id" component={ Post } param="test" />
                <Route path="/profile/:user" component={ GuestProfile } param="test" />
                <Route path="/mention/:user" component={ GuestProfile } param="test" />
                <Route exact path="/wall/mix/" render={(props) => <GuestWall {...props} wallType={'mix'} />} />
                <Route path="/wall/mix/:cursor" render={(props) => <GuestWall {...props} wallType={'mix'} />} /> 

                           
                {/* <Route exact path="/wall/minimes/" render={(props) => <GuestWall {...props} wallType={'minimes'} />} />
                <Route path="/wall/minimes/:cursor" render={(props) => <GuestWall {...props} wallType={'minimes'} />} /> */}

                <Route path="*" component={Login} />
              </Switch> 

            }

	        </Switch>
		);
	}
}


//export default Container;

const Container = connect(mapStateToProps)(ConnectedContainer);
export default withRouter(Container);
