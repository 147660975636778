import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import i18n from "../../i18n";

import ProfileCover from "./components/profilecover";
import Avatar from "./components/avatar";
import ProfileInfo from "./components/profileinfo";

import {store} from './../../store';
import endpoint from "../../config/endpoint";
import axios from "axios";

import LazyLoad from 'react-lazyload';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import _ from 'lodash';

import { toast } from 'react-toastify';

const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};

const sliderRelationTherm = {
  1: {
    style: {
      color: '#209cee',
    },
    label: <i className="thermLabel fal fa-temperature-frigid"></i>,
  },
  2: {
    style: {
      color: '#ffc857',
    },
    label: <i className="thermLabel fad fa-thermometer-half"></i>,
  }, 
  3: {
    style: {
      color: '#ff3860',
    },
    label: <i className="thermLabel fad fa-temperature-hot"></i>,
  }           
};

class ConnectedFriends extends Component {

	constructor(props) {
		super(props);

		this.state = {
			theFriends 		: [],
			thePendingIn 	: [],
			thePendingOut 	: [],
			theBlocked 		: [],
			theFollowing	: [],
			theFollower		: []
		}

	    this.store = store.getState();

	    this.handleUpdate 		  = this.handleUpdate.bind(this);
	    this.friendsInit 		  = this.friendsInit.bind(this);
	    this.handleRelationTherm  = _.debounce(this.handleRelationTherm.bind(this), 500);
	    this.handleFollowingTherm = _.debounce(this.handleFollowingTherm.bind(this), 500);

	}

	handleUpdate(){
			this.friendsInit();
	}	

	componentWillMount(){
			this.friendsInit();
	}

	friendsInit(){

		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(endpoint.users+'/getfriend/', cfg)
			.then((result) => {
		    	this.setState( { 
		    		theFriends		: result.data.theFriends,
		    		thePendingIn 	: result.data.thePendingIn,
		    		thePendingOut	: result.data.thePendingOut,
		    		theBlocked 		: result.data.theBlocked,
			    		theFollower 	: result.data.theFollower,
			    		theFollowing 	: result.data.theFollowing
		    	} );
			})
			.catch((err) => {
			  	console.log(err);
			})

	}

	moderateFriend( friendId, friendStatus ){

			const reqBody = { friendId: friendId, friendStatus: friendStatus };
			const cfg = { 
				headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
		    };

			axios.post(endpoint.users+'/moderatefriend', reqBody, cfg)
				.then((result) => {
					this.friendsInit();
				})
				.catch((err) => {
				})	

	}

	removeFriend( friendId ){
			const reqBody = { friendId: friendId };
			const cfg = { 
				headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
		    };
			axios.post(endpoint.users+'/removefriend', reqBody, cfg)
				.then((result) => {
					this.friendsInit();
				})
				.catch((err) => {
				})	
	}

	removeFollowing( followingId ){
			const reqBody = { followingId: followingId };
			const cfg = { 
				headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
		    };
			axios.post(endpoint.users+'/removefollowing', reqBody, cfg)
				.then((result) => {
					this.friendsInit();
				})
				.catch((err) => {
				})	
	}


	handleRelationTherm( friendStrength, friendId ){

			const reqBody = { friendId: friendId, friendStrength: friendStrength };
			const cfg = { 
				headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
		    };

			axios.post(endpoint.users+'/friendstrength', reqBody, cfg)
				.then((result) => {
			    	this.setState( { 
			    		theFriends		: result.data.theFriends,
			    		thePendingIn 	: result.data.thePendingIn,
			    		thePendingOut	: result.data.thePendingOut,
			    		theBlocked 		: result.data.theBlocked,
			    		theFollower 	: result.data.theFollower,
			    		theFollowing 	: result.data.theFollowing
			    	} );

			    	if ( result.data.success.toLowerCase() !== 'done'){
    			  		toast.warning(i18n.t(result.data.success), { autoClose : 1500 } );
			    	}
				})
				.catch((err) => {
					console.log(err)
				})

	}	

	handleFollowingTherm( followingStrength, followingId ){

			const reqBody = { followingId: followingId, followingStrength: followingStrength };
			const cfg = { 
				headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
		    };

			axios.post(endpoint.users+'/followingstrength', reqBody, cfg)
				.then((result) => {
			    	this.setState( { 
			    		theFriends		: result.data.theFriends,
			    		thePendingIn 	: result.data.thePendingIn,
			    		thePendingOut	: result.data.thePendingOut,
			    		theBlocked 		: result.data.theBlocked,
			    		theFollower 	: result.data.theFollower,
			    		theFollowing 	: result.data.theFollowing
			    	} );

			    	if ( result.data.success.toLowerCase() !== 'done'){
    			  		toast.warning(i18n.t(result.data.success), { autoClose : 1500 } );
			    	}
				})
				.catch((err) => {
					console.log(err)
				})

	}	



	render(){

		return(
			<div className="friends">
				<div className="sx sidebar"></div>
				<div className="friends-content">

				{ this.store.settings.userRow.profile_type == 1 && !this.state.theFriends.length && !this.state.theFriends.length && !this.state.thePendingIn.length && !this.state.thePendingOut.length && !this.state.theBlocked.length && !this.state.theFollowing.length && !this.state.theFollower.length ?
					null /* i18n.t("Ancora nessun amico") */
					:
					null
				}
					
				{ this.store.settings.userRow.profile_type != 1 && !this.state.theFriends.length && !this.state.theFriends.length && !this.state.thePendingIn.length && !this.state.thePendingOut.length && !this.state.theBlocked.length && !this.state.theFollowing.length && !this.state.theFollower.length ?
					null /* i18n.t("Ancora nessun follower") */
					:
					null
				}
				

					{ this.state.thePendingIn.length ?
						(
							<div className="thePendingIn section">
								<div className="headline">{  i18n.t("Pending requests") } <span>{ this.state.thePendingIn.length }</span></div>
								<div className="content">
									{ this.state.thePendingIn.map( (friend, i) =>{
										return(
											<div className="single" key={'theFriends-'+i}>
								    			<div>
									    			<LazyLoad height={200} offset={100}>
									    				<img src={ friend.media_url ? endpoint.mediaRoot +'/'+ friend.media_url : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
									    			</LazyLoad>
								    			</div>
												<div className="action">
													<Link to={"/profile/"+friend.username}>{friend.username}</Link>
													<div className="menu">
														<Link to={"/profile/"+friend.username}><i className="fal fa-link link"></i></Link>
														<i onClick={ () => this.moderateFriend(friend.id, 'accept') }  className="fal fa-check accept"></i>
														<i onClick={ () => this.moderateFriend(friend.id, 'decline') }  className="fal fa-times decline"></i>
														<i onClick={ () => this.moderateFriend(friend.id, 'block') }  className="fal fa-ban block"></i>
													</div>
												</div>
												
											</div>
										)
									})}
								</div>
							</div>
						)
						:
						null
					}

					{ this.state.theFriends.length ?
						(
							<div className="theFriends section">
								<div className="headline">{  i18n.t("Friends") } <span>{ this.state.theFriends.length }</span></div>
								<div className="content">
									{ this.state.theFriends.map( (friend, i) =>{

										var tempColor = '#ffc857';
										if (friend.strength == 1){
											tempColor = '#209cee';
										}else if (friend.strength == 3){
											tempColor = '#ff3860';
										}

										return(
											<div className="single" key={'theFriends-'+i}>
								    			<div>
									    			<Link to={"/profile/"+friend.username}>
										    			<LazyLoad height={200} offset={100}>
										    				<img src={ friend.media_url ? endpoint.mediaRoot +'/'+ friend.media_url : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
										    			</LazyLoad>
									    			</Link>
								    			</div>	
									    		<div className="action">
													<Link to={"/profile/"+friend.username}>{friend.username}</Link>
													<div className="menu">
														<Link to={"/profile/"+friend.username}><i className="fal fa-link link"></i></Link>
														<i onClick={ () => this.removeFriend(friend.id) } className="fal fa-trash-alt remove"></i>
													</div>
												</div>

												<div className="relationTherm">
													<Slider 
														/* dots */
														disabled={false}
														min={1} max={3} state={1}
														defaultValue={friend.strength}
														marks={sliderRelationTherm}
														onChange={ (value) => this.handleRelationTherm(value, friend.id) } 
												        railStyle={{ backgroundColor: tempColor, height: '2px', opacity:'.75' }}
												        trackStyle={{ backgroundColor: tempColor, height: '2px' }}
												        handleStyle={{
												          backgroundColor: tempColor,
												          marginLeft: '-5px',
		    											  marginTop: '-4px',
		    											  border: '2px solid #ccc',
		    											  height: '10px',
		    											  width: '10px'
												        }}
												        dotStyle={{
											          	  backgroundColor: '#f7f7f7',
												          marginLeft: '-5px',
		    											  marginTop: '-4px',
		    											  border: '2px solid #ffffff',
		    											  height: '10px',
		    											  opacity: '.1'
												        }}
		       								 			/>
												</div>										
											</div>
										)
									})}
								</div>
							</div>
						)
						:
						null
					}

					{ this.state.theFollowing.length ?
						(
							<div className="thePendingIn section">
								<div className="headline">{  i18n.t("Following") } <span>{ this.state.theFollowing.length }</span></div>
								<div className="content">
									{ this.state.theFollowing.map( (friend, i) =>{

										var tempColor = '#ffc857';
										if (friend.strength == 1){
											tempColor = '#209cee';
										}else if (friend.strength == 3){
											tempColor = '#ff3860';
										}

										return(
											<div className="single" key={'theFriends-'+i}>
								    			<div>
									    			<LazyLoad height={200} offset={100}>
									    				<img src={ friend.media_url ? endpoint.mediaRoot +'/'+ friend.media_url : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
									    			</LazyLoad>
								    			</div>
												<div className="action">
													<Link to={"/profile/"+friend.username}>{friend.username}</Link>
													<div className="menu">
														<Link to={"/profile/"+friend.username}><i className="fal fa-link link"></i></Link>
														<i onClick={ () => this.removeFollowing(friend.id) } className="fal fa-trash-alt remove"></i>
													</div>
												</div>

											<div className="relationTherm">
												<Slider 
													/* dots */
													disabled={false}
													min={1} max={3} state={1}
													defaultValue={friend.strength}
													marks={sliderRelationTherm}
													onChange={ (value) => this.handleFollowingTherm(value, friend.id) } 
											        railStyle={{ backgroundColor: tempColor, height: '2px', opacity:'.75' }}
											        trackStyle={{ backgroundColor: tempColor, height: '2px' }}
											        handleStyle={{
											          backgroundColor: tempColor,
											          marginLeft: '-5px',
	    											  marginTop: '-4px',
	    											  border: '2px solid #ccc',
	    											  height: '10px',
	    											  width: '10px'
											        }}
											        dotStyle={{
										          	  backgroundColor: '#f7f7f7',
											          marginLeft: '-5px',
	    											  marginTop: '-4px',
	    											  border: '2px solid #ffffff',
	    											  height: '10px',
	    											  opacity: '.1'
											        }}
	       								 			/>
											</div>	
													
											</div>
										)
									})}
								</div>
							</div>
						)
						:
						null
					}

					{ this.state.theFollower.length ?
						(
							<div className="thePendingIn section">
								<div className="headline">{  i18n.t("Followers") } <span>{ this.state.theFollower.length }</span></div>
								<div className="content">
									{ this.state.theFollower.map( (friend, i) =>{
										return(
											<div className="single" key={'theFriends-'+i}>
								    			<div>
									    			<LazyLoad height={200} offset={100}>
									    				<img src={ friend.media_url ? endpoint.mediaRoot +'/'+ friend.media_url : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
									    			</LazyLoad>
								    			</div>
												<div className="action">
													<Link to={"/profile/"+friend.username}>{friend.username}</Link>
													<div className="menu">
														<Link to={"/profile/"+friend.username}><i className="fal fa-link link"></i></Link>
													</div>
												</div>
												
											</div>
										)
									})}
								</div>
							</div>
						)
						:
						null
					}

					{ this.state.thePendingOut.length ?
						(
							<div className="thePendingOut section">
								<div className="headline">{  i18n.t("Pending requests") } <span>{ this.state.thePendingOut.length }</span></div>
								<div className="content">
									{ this.state.thePendingOut.map( (friend, i) =>{
										return(
											<div className="single" key={'theFriends-'+i}>
								    			<div>
									    			<LazyLoad height={200} offset={100}>
									    				<img src={ friend.media_url ? endpoint.mediaRoot +'/'+ friend.media_url : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
									    			</LazyLoad>
								    			</div>	
												<div className="action">
													<Link to={"/profile/"+friend.username}>{friend.username}</Link>
													<div className="menu">
														<Link to={"/profile/"+friend.username}><i className="fal fa-link link"></i></Link>
														<i onClick={ () => this.removeFriend(friend.id) } className="fal fa-trash-alt remove"></i>
													</div>
												</div>
												
											</div>
										)
									})}
								</div>
							</div>
						)
						:
						null

					}


					{ this.state.theBlocked.length ?
						(
							<div className="thePendingIn section">
								<div className="headline">{  i18n.t("Blocked") } <span>{ this.state.theBlocked.length }</span></div>
								<div className="content">
									{ this.state.theBlocked.map( (friend, i) =>{
										return(
											<div className="single" key={'theFriends-'+i}>
								    			<div>
									    			<LazyLoad height={200} offset={100}>
									    				<img src={ friend.media_url ? endpoint.mediaRoot +'/'+ friend.media_url : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
									    			</LazyLoad>
								    			</div>	
												<div className="action">
													<Link to={"/profile/"+friend.username}>{friend.username}</Link>
													<div className="menu">
														<Link to={"/profile/"+friend.username}><i className="fal fa-link link"></i></Link>
														<i onClick={ () => this.removeFriend(friend.id) } className="fal fa-trash-alt remove"></i>
													</div>
												</div>
												
											</div>
										)
									})}
								</div>
							</div>
						)
						:
						null

					}

				</div>
				<div className="dx sidebar"></div>
			</div>
		);
	}
}



const Friends = connect(mapStateToProps)(ConnectedFriends);
export default Friends;




