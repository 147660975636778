import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

//import Loginform from "../../components/auth/Loginform";
import Loginform from "../../components/auth/Loginform_v2";

import i18n from "../../i18n";

const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


const ConnectedLogin = ({ user, language }) => {
	if ( user.auth === true ){
		window.location.href = "/";
	}else{
		return <Loginform lng={language} />
	}
}


const ConnectedLoginBKP = ({ user, language }) => (
	<div className="login">
		{ (user.auth === true) ? (<Redirect to='/' />) : (<Loginform lng={language} />) }

	</div>
);


const Login = connect(mapStateToProps)(ConnectedLogin);
export default Login;

