import React, { Component } from "react";

import endpoint from "../../config/endpoint";
import axios from "axios";
import { store } from "./../../store";

import Profile from "../profile/profile_v2";
import TheSharedPost from "../wall/components/TheSharedPost";

import { Redirect } from "react-router-dom";

import i18n from "../../i18n";

import Moment from "react-moment";
import Modal from "react-responsive-modal";
import Textarea from "react-textarea-autosize";
import { toast } from "react-toastify";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

class AdminProfile extends Component {
  constructor(props) {
    super();

    this.state = {
      exist: true,
      theAdmin: false,
      theUsersExtra: [],
    };

    this.store = store.getState();
    this.getUserExtraData = this.getUserExtraData.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  componentDidMount() {
    this.getUserExtraData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.user !== prevProps.match.params.user) {
      this.getUserExtraData();
    }
  }

  async getUserExtraData() {
    const cfg = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.store.user.token,
      },
    };

    axios
      .get(endpoint.admin + "/user/get_extra_data/" + this.props.match.params.user, cfg)
      .then((res) => {
        this.setState({
          theUsersExtra: res.data.theUsersExtra || [],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleUserBlock(blocked, userToggle) {
    const reqBody = { blocked: blocked, userToggle: userToggle };
    const cfg = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: this.store.user.token,
      },
    };

    axios
      .post(endpoint.admin + "/user/set_blocked", reqBody, cfg)
      .then((result) => {
        this.getUserExtraData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteUser() {
    confirmAlert({
      title: "Are you sure?",
      message: `If you click Yes, the user with id:"${this.state.theUsersExtra[0].id}" username: "${this.state.theUsersExtra[0].username}" email "${this.state.theUsersExtra[0].email}" and their contributions will be permanently deleted.`,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const reqBody = { userDelete: this.state.theUsersExtra[0].id };
            const cfg = {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: this.store.user.token,
              },
            };

            axios.post(endpoint.admin + "/user/delete", reqBody, cfg)
              .then((result) => {
                console.log(result);
                //window.location.href = window.location.origin+'/admin';
              })
              .catch((err) => {
                console.log(err);
              });
          },
        },
        {
          label: "No",
          onClick: () => toast.warn('Action cancelled', { autoClose : 2500 } ),
        },
      ],
    });
  }

  render() {
    if (!this.store.user.auth) {
      return <Redirect to={"/login/"}>login</Redirect>;
    }

    return (
      <div className="adminProfile">
        {this.state.theUsersExtra.length > 0 && (
          <div className="info">
            id: <b>{this.state.theUsersExtra[0].id}</b>
            {" | "}
            username: <b>{this.state.theUsersExtra[0].username}</b>
            {" | "}
            name: <b>{this.state.theUsersExtra[0].name !== null ? this.state.theUsersExtra[0].name : ""}</b>
            {" | "}
            email: <b>{this.state.theUsersExtra[0].email}</b>
            {" | "}
            blocked: <b>{this.state.theUsersExtra[0].blocked === 0 ? "unblocked" : "blocked"}</b>
            <div className="buttonBar">
            {this.state.theUsersExtra[0].blocked === 0 ? (
              <button class="sendPostButton block" onClick={() => this.toggleUserBlock(1, this.state.theUsersExtra[0].id)}>
                Block
              </button>
            ) : (
              <button class="sendPostButton unblock" onClick={() => this.toggleUserBlock(0, this.state.theUsersExtra[0].id)}>
                Unblock
              </button>
            )}
            <button
              class="sendPostButton deleteUser"
              onClick={ this.deleteUser }
            >
              Delete
            </button>
            </div>
          </div>
        )}
        <Profile match={{ params: { user: this.props.match.params.user } }} />
      </div>
    );
  }
}

export default AdminProfile;
