import React, { Component } from 'react';
import SlideMenu from "../components/SlideMenu";

import { Redirect } from "react-router-dom";

import i18n from "../i18n";

class Home extends Component {
  render() {
    return (
      <div className="Home">

            <Redirect to="wall/mix"></Redirect>

            {/*

            <p>{i18n.t("This is the home content")}</p>

            <p>{i18n.t("MBP 1st commit")}</p>

      	<h2>example: SlideMenu Component</h2>
      	<SlideMenu
      		cclass = "txt" 
      		nav={[
      			(<a href="#">Sliding Tab 1</a>),
      			(<a href="#">Sliding Tab 2</a>),
      			(<a href="#">Sliding Tab 3</a>),
      			(<a href="#">Sliding Tab 4</a>),
      			(<a href="#">Sliding Tab 5</a>),
      			(<a href="#">Sliding Tab 6</a>),
      			]}
      	/>

      	<h2>example: SlideMenu Component</h2>
      	<SlideMenu 
      		cclass = "txt-icon" 
      		nav={[
      			(<a href="#">Sliding Tab 1 <i className="fas fa-eye"></i></a>),
      			(<a href="#">Sliding Tab 2 <i className="fas fa-eye"></i></a>),
      			(<a href="#">Sliding Tab 3 <i className="fas fa-eye"></i></a>),
      			(<a href="#">Sliding Tab 4 <i className="fas fa-eye"></i></a>),
      			(<a href="#">Sliding Tab 5 <i className="fas fa-eye"></i></a>),
      			]}
      	/>

      	<h2>example: SlideMenu Component</h2>
      	<SlideMenu 
      		cclass = "icon" 
      		nav={[
      			(<a href="#"><i className="far fa-comments fa-3x"></i></a>),
      			(<a href="#"><i className="far fa-comment-dots fa-3x"></i></a>),
      			(<a href="#"><i className="far fa-comment-alt fa-3x"></i></a>),
      			(<a href="#"><i className="far fa-eye fa-3x"></i></a>),
      			(<a href="#"><i className="far fa-paper-plane fa-3x"></i></a>),
      			(<a href="#"><i className="far fa-envelope fa-3x"></i></a>)
      			]}
      	/>   

      	<h2>Scroll component</h2>
      	<p>Just to test the header behaviour on page with multiple scrollable content (horizontal and vertical) </p>
            */}
      </div>
    );
  }
}

export default Home;
