import React, { Component } from 'react'
import ReactPlayer from 'react-player'
 
class Player extends Component {

  constructor(props) {
    super();
  }
  

  render () {
    return <ReactPlayer 
    			  url={this.props.src} 
    			  controls={true} 
    			  pip={false} 
    			  width='100%'
            height='100%'
            config={{ file: { attributes: { controlsList: 'nodownload', disablepictureinpicture: 'disablePictureInPicture' }}}}
          	/>
  }
}

export default Player;
