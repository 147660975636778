import React, { Component } from 'react'

import endpoint from "../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import {store} from './../../store';

import _ from 'lodash';

import EditorBase from '../../components/editor/base';

import ThePost from './components/ThePost';
import TheSharedPost from './components/TheSharedPost';
import ThePostPlaceholder from './components/ThePostPlaceholder';

import WallFloatingMenu from './components/WallFloatingMenu';

import IntersectionVisible    from 'react-intersection-visible'


class Bookmark extends Component {

  constructor(props) {
    super();

    this.state = {
      thePosts: [],
      isLoading: true,
      intersectionVisible: true
    };
    
    this.getPosts = this.getPosts.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
	this.loadMore = _.debounce(this.loadMore.bind(this), 500);

    this.store = store.getState();

  }
  
	componentDidMount(){
		this.setState({ isLoading : true });
		this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.setState({ isLoading : true });
			this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
		}
	}


	getPosts( fetchNew = false ){

        return new Promise((resolve, reject) => {

        	if ( fetchNew == true ){
				var cursor = -1 * this.state.thePosts[0][0].id;
        	}else{
        		var cursor = 0;
        	}

			if ( typeof this.props.match.params.cursor !== 'undefined'){
				cursor = this.props.match.params.cursor;
			}

			const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
				axios.get(endpoint.posts+'/bookmark/'+cursor, cfg)
		  		.then(res => {
		  			this.setState({
						/*thePosts: res.data.thePosts || []*/
						thePosts: [...res.data.thePosts, ...this.state.thePosts]
					});
					resolve();
		  		})
		  		.catch( err =>{
		  			setTimeout( () => { this.setState({ isLoading : false, intersectionVisible: false }) },1000 ) 
		  		})

		})

	}

	onRefresh(){
		this.setState({ isLoading : true });
            this.getPosts(true)
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );

	}  

	loadMore( entries )
    {
        this.setState({ isLoading : true });

        if (this.state.thePosts.length > 0){
			var cursor = this.state.thePosts[this.state.thePosts.length - 1][0].id;
        }else{
        	var cursor = 0;
        }


		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
			axios.get(endpoint.posts+'/bookmark/'+cursor, cfg)
	  		.then(res => {
				this.setState({ thePosts: [...this.state.thePosts, ...res.data.thePosts]});
				setTimeout( () => { this.setState({ isLoading : false }) },1000 )
	  		})
	  		.catch( err =>{
	  			setTimeout( () => { this.setState({ isLoading : false, intersectionVisible: false }) },1000 ) 
	  		})
}

  render () {

    return (
    	<div className="wall bookmark">
    		<EditorBase/>

    		<div className="refresh" onClick={this.onRefresh} ><i className={ this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt" }></i></div>

    		{ 
    			this.state.thePosts.length == 0  && this.state.isLoading == true ? (<ThePostPlaceholder/>) : null
    		}

    		{ 
    			this.state.thePosts.length == 0 && this.state.isLoading == false ? (<div>no post</div>) : null
    		}

    		{ (this.state.thePosts || []).map( (post, i) => {
		    		return post[0].type == 'share' ? <TheSharedPost thePost={post} key={'thePost'+i} /> : <ThePost thePost={post} key={'thePost'+i} from_wall='bookmark' />
		    		/*return <ThePost thePost={post} key={'thePost'+i} />*/
    			} 
	    	)}

	    	{ this.state.thePosts && this.state.isLoading == false && this.state.intersectionVisible ? 
	    		(
		    		<IntersectionVisible onShow={ e => this.loadMore( e ) }>
			    		<div className="refresh"><i className={ this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt" }></i></div>
		        	</IntersectionVisible>
	    		)
	    		:
	    		null
	    	}

	    	<WallFloatingMenu active="bookmark"/>

    	</div>
    )
  }
}

export default Bookmark;
