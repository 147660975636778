import React, { Component } from 'react';


import i18n from "../../i18n";

import EditorBase from '../../components/editor/base';
import Player from '../../components/wall/player';

import endpoint from "../../config/endpoint";
import axios from "axios";

import {store} from './../../store';

import FilterableTable from 'react-filterable-table';
import 'react-filterable-table/dist/style.css';

import { Link, Redirect } from "react-router-dom";

import Moment from 'react-moment';
import Modal from 'react-responsive-modal';
import Textarea from 'react-textarea-autosize';
import { toast } from 'react-toastify';


class AdminIndex extends Component {

	constructor(props) {
		super(props);
	
		this.isAdmin   			= this.isAdmin.bind(this);
		this.handleAdminPostId  = this.handleAdminPostId.bind(this);
		this.handleAdminUsername  = this.handleAdminUsername.bind(this);

	    this.store = store.getState();

		this.state = {
			theAdmin: 		false,
			adminPostId: 	false,
			adminUsername: false
		};

	}

	componentDidMount(){
		this.isAdmin();
	}

	isAdmin(){
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(endpoint.admin+'/test', cfg)
  		.then(res => {
  			this.setState({
				theAdmin: res.data.theAdmin || false
			})
	  	}) 
		.catch((err) => {
			this.setState({
				theAdmin: false
			})					
		})	
	}

	handleAdminPostId( event ){
		this.setState({
				adminPostId: event.target.value
		})		
	}


	handleAdminUsername( event ){
		this.setState({
				adminUsername: event.target.value
		})		
	}

	render() {

  		if ( !this.store.user.auth ){
	      return (<Redirect to={"/login/"}>login</Redirect>);
	  	}  	

      	if (this.state.theAdmin == false ){
      		return(<div>{ i18n.t('Forbidden') }</div>);
      	}else{
      		if ( this.state.theAdmin.role !== 'admin'){
	      		return(<div>{ i18n.t('Forbidden') }</div>);
      		}
      	}

		return (
		  <div className="admin index">
		  	<ul className="navigationList">
				<li><Link to="/admin/flags">{ i18n.t('Modetate flags') }</Link><span>{ i18n.t('List and moderate all flags') }</span></li>
				<li><Link to="/admin/tickets">{ i18n.t('Tickets') }</Link><span>{ i18n.t('Help and support') }</span></li>
				<li><Link to={ this.state.adminPostId ? "/admin/post/"+this.state.adminPostId : "/admin/post/0" }>{ i18n.t('Manage post') }</Link><span><input type="number" value={ this.state.adminPostId } onChange={ this.handleAdminPostId } placeholder={ i18n.t('post id') } />{ i18n.t('Display post with admin privileges (display deleted, etc.. and moderate it)') }</span></li>
				<li><Link to={ this.state.adminUsername ? "/admin/profile/"+this.state.adminUsername : "/admin/profile/" }>{ i18n.t('Manage user') }</Link><span><input type="text" value={ this.state.adminUsername ? this.state.adminUsername : '' } onChange={ this.handleAdminUsername } placeholder={ i18n.t('username') } />{ i18n.t('Display user with admin privileges (block/unblock)') }</span></li>
		  	</ul>


		  </div>
		);
	}
}

export default AdminIndex;





