import React, { Component } from 'react'

import { Redirect } from "react-router-dom";
import endpoint from "../../../config/endpoint";

import {store} from './../../../store';


class ThePostPlaceholder extends Component {

  constructor(props) {
    super();

    this.store = store.getState();

  }


  render () {

    // if ( !this.store.user.auth ){
    //   return (<Redirect to={"/login/"}>login</Redirect>);
    // }   

    return (
    	<div className={"thePostV2 ThePostPlaceholder"} >
			<header>
    			<img src={ endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
    			<div className="title">
    				<h2><span className="text-placeholder"></span></h2>
    				<h3><span className="text-placeholder"></span></h3>
    			</div>
    			<div className="type"><i className="fal fa-sync-alt fa-spin"></i></div>
    		</header>
    		<section className="content">
    			<div className="theContent">  
		    		<span className="text-placeholder"></span>
		    		<span className="text-placeholder"></span>
		    		<span className="text-placeholder"></span>
		    		<span className="text-placeholder"></span>
		    	</div>

		    	<div className="theAction">
		    		<div className="like">
		    			<i className="fal fa-thumbs-up"></i>
		    		</div>
		    		<div className="love">
		    			<i className="fal fa-heart"></i>
		    		</div>
		    	</div>
    		</section>

    	</div>
    )
  }
}

export default ThePostPlaceholder;
