import React, { Component } from 'react';

import Textarea from 'react-textarea-autosize';
import ContentEditable from "react-contenteditable";
import sanitizeHtml from "sanitize-html";

import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import 'draft-js/dist/Draft.css'; 

import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin';
import Editor from 'draft-js-plugins-editor';
import 'draft-js-mention-plugin/lib/plugin.css';

import createEmojiPlugin from 'draft-js-emoji-plugin';
import 'draft-js-emoji-plugin/lib/plugin.css';

import createHashtagPlugin from 'draft-js-hashtag-plugin';
import 'draft-js-hashtag-plugin/lib/plugin.css';

import createLinkifyPlugin from 'draft-js-linkify-plugin';
import 'draft-js-linkify-plugin/lib/plugin.css';

import createCounterPlugin from 'draft-js-counter-plugin';
import 'draft-js-counter-plugin/lib/plugin.css';

import Modal from 'react-responsive-modal';

import Compressor from 'compressorjs';
import FileDrop from 'react-file-drop';
import media from "../../config/media";

import { toast } from 'react-toastify';
import i18n from "../../i18n";

import endpoint from "../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import {store} from './../../store';
import _ from 'lodash';

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";


const mentionPlugin = createMentionPlugin( { mentionPrefix : '@' } );

const emojiPlugin = createEmojiPlugin();

const hashtagPlugin = createHashtagPlugin();

const linkifyPlugin = createLinkifyPlugin();

const counterPlugin = createCounterPlugin();
const { CharCounter, WordCounter, LineCounter, CustomCounter } = counterPlugin;

const { EmojiSuggestions, EmojiSelect,  } = emojiPlugin;

const plugins = [mentionPlugin, emojiPlugin, hashtagPlugin, linkifyPlugin, counterPlugin];


const initialState = {
    editorState: EditorState.createEmpty(),
    charCount: 0,
    editorIsOpen: false,
    suggestions: [],
    media : [],
    mediaLoading : false,
    imgTaggingState: { all : false },
    imgTagSearch : '',
    imgSuggestions: [],
    imgTagsList: {},
    imgTagsCoord: false,
    savePostProgress: 0,
    sendFileProgress: 0,
    actionChoiceToggle: false,
    actionChoiceSelected: false
};   

const mapStateToProps = state => {
  return { settings: state.settings };
};

class ConnectedEditorBase extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editorState: EditorState.createEmpty(),
            charCount: 0,
            editorIsOpen: false,
            suggestions: [],
            media : [],
            mediaLoading : false,
            imgTaggingState: { all : false },
            imgTagSearch : '',
            imgSuggestions: [],
            imgTagsList: {},
            imgTagsCoord: false,
            savePostProgress: 0,
            sendFileProgress: 0,
            actionChoiceToggle: false,
            actionChoiceSelected: false,
            lyEditorState: '',
            mentionSuggest: false
        };


        this.handleActionChoiceToggle = this.handleActionChoiceToggle.bind(this);
     
        this.onChange = this.onChange.bind(this);
        this.onSearchChange = _.debounce(this.onSearchChange.bind(this), 350);
        this.onAddMention = this.onAddMention.bind(this);
        this.focus = this.focus.bind(this);
        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

        this.getCharCount = this.getCharCount.bind(this);


        this.mediaLoader = this.mediaLoader.bind(this);

        this.mediaDropPlaceholder = this.mediaDropPlaceholder.bind(this);
        this.mediaDrop = this.mediaDrop.bind(this);
        this.mediaUpload = this.mediaUpload.bind(this);
        this.saveMedia = this.saveMedia.bind(this);

        this.imageDrop = this.imageDrop.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.collageMedia = this.collageMedia.bind(this);
        this.compressImage = this.compressImage.bind(this);

        this.imgTagging = this.imgTagging.bind(this);
        this.imgTaggingOpen = this.imgTaggingOpen.bind(this);
        this.imgTaggingClose = this.imgTaggingClose.bind(this);
        this.imgSetTag = this.imgSetTag.bind(this);
        this.getTagPosition = this.getTagPosition.bind(this);
        this.imgConfirmTag = this.imgConfirmTag.bind(this);

        this.savePost = this.savePost.bind(this);
        this.checkPostState = this.checkPostState.bind(this);


        this.lyEditorOnChange = this.lyEditorOnChange.bind(this);
        this.lyEditorGetCharCount = this.lyEditorGetCharCount.bind(this);
        this.lyEditorOnSearchMention = this.lyEditorOnSearchMention.bind(this);
        this.lyEditorSetMention = this.lyEditorSetMention.bind(this);
        this.lyEditorSanitize = this.lyEditorSanitize.bind(this);


        this.store = store.getState();


    }

	handleActionChoiceToggle() {

		this.setState((prevState) => ({
		  actionChoiceToggle: !prevState.actionChoiceToggle
		}));

	};


	onChange(editorState) {
		this.setState({
			editorState,
		});  	
		//console.log(  editorState.getCurrentContent()  );
		//console.log(  convertToRaw( editorState.getCurrentContent() )  );

		this.getCharCount(editorState);
	}


	lyEditorOnChange(e) {

		this.setState({
			lyEditorState: e.target.value
		});  	
		//console.log(  editorState.getCurrentContent()  );
		//console.log(  convertToRaw( editorState.getCurrentContent() )  );

		var words = unescape(e.target.value).trim().split(" ");
		var lastWord = words[words.length - 1];
		if ( lastWord.startsWith("@") && lastWord.length > 2 && !e.target.value.endsWith(" ")  ) {
			this.lyEditorOnSearchMention( lastWord.replace('@', '') );
		}else{
			this.setState({ mentionSuggest: false });
		}


		this.lyEditorGetCharCount(e.target.value);
	}

  	lyEditorGetCharCount(editorState) {
  		console.log(editorState);
		const plainText = editorState;
		const regex = /(?:\r\n|\r|\n)/g;  // new line, carriage return, line feed
		const cleanString = plainText.replace(regex, '').trim();  // replace above characters w/ nothing		
		this.setState({
			charCount : cleanString.length
		}); 

  	}

	lyEditorOnSearchMention( value ) {

		//let state = store.getState();
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
 		axios.get(endpoint.posts+'/mentionsearch/'+value, cfg)
      		.then(res => {
      			this.setState({
					mentionSuggest: res.data.mentions || []
				});
				console.log(this.state.mentionSuggest);
      		})
      		.catch(err => {
      			console.log(err);
				this.setState({ mentionSuggest: false });
      		})

	};

	lyEditorSetMention( value ) {

		var words = this.state.lyEditorState.trim().split(" ");
		var lastWord = words[words.length - 1];

		if ( lastWord.startsWith("@") && lastWord.length > 2 ) {
			//let postContent = this.state.postContent.replace(lastWord, value);
			let postContent = this.state.lyEditorState.substring(0,this.state.lyEditorState.lastIndexOf(lastWord)) + value;

			this.setState({ lyEditorState : postContent, mentionSuggest: false });
		}else{
			this.setState({ mentionSuggest: false });
		}

	};

	  lyEditorSanitizeConf = {
	    allowedTags: ["br"],
	    allowedAttributes: { }
	  };

	  lyEditorSanitize(){
	    this.setState({ lyEditorState: sanitizeHtml(this.state.lyEditorState, this.lyEditorSanitizeConf) });
	  };


  	getCharCount(editorState) {
		const plainText = editorState.getCurrentContent().getPlainText('');
		const regex = /(?:\r\n|\r|\n)/g;  // new line, carriage return, line feed
		const cleanString = plainText.replace(regex, '').trim();  // replace above characters w/ nothing		
		this.setState({
			charCount : cleanString.length
		}); 

  	}

	onSearchChange({ value }) {

		//let state = store.getState();
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
 		axios.get(endpoint.posts+'/mentionsearch/'+value, cfg)
      		.then(res => {
      			this.setState({
					suggestions: res.data.mentions || []
				});
      	})

	};

	onAddMention() {
	};



	focus() {
		//this.editor.focus();
	};

    customCountFunction(str) {
        const wordArray = str.match(/\S+/g); // matches words according to whitespace
        return wordArray ? wordArray.length : 0;
    }

    onOpenModal(){
        this.setState({
            editorIsOpen: true
        });
    };

    onCloseModal(){
        this.setState({
            editorIsOpen: false
        });
    };

	mediaLoader (value) {
		if(typeof value !== 'undefined'){
		    this.setState(prevState => {
		      const mediaLoading = value;
		      return {
		        mediaLoading,
		        value: '',
		      };
		    });
		}else{
		    this.setState(prevState => {
		      const mediaLoading = !prevState.mediaLoading;
		      return {
		        mediaLoading,
		        value: '',
		      };
		    });			
		}
	}

	mediaDropPlaceholder (files) {

		let type = files[0].type || ''; 

		if ( type.includes("image/") ){
			this.mediaLoader(true);
			this.imageDrop (files);
		}else if ( type.includes("audio/") ){
			this.audioDrop (files);
		}else if ( type.includes("video/") || files[0].name.includes('.mkv') ){
			this.videoDrop (files);
		}else{
	  		toast.error(i18n.t('Formato file non supportato'), { autoClose : 2500 } );					
		}
	    
	}

	mediaUpload (e) {

		if (typeof e.target.files[0] == "undefined"){
			return;
		}

		let type = e.target.files[0].type || ''; 

		if ( type.includes("image/") ){
			this.mediaLoader(true);
			this.imageDrop (e.target.files, false);
		}else if ( type.includes("audio/") ){
			this.audioDrop (e.target.files);
		}else if ( type.includes("video/") ){
			this.videoDrop (e.target.files);
		}else{
	  		toast.error(i18n.t('Formato file non supportato'), { autoClose : 2500 } );					
		}

	}


	mediaDrop (files) {

		let type = files[0].type || ''; 

		if ( type.includes("image/") ){
			this.mediaLoader(true);
			this.imageDrop (files);
		}else if ( type.includes("audio/") ){
			this.audioDrop (files);
		}else if ( type.includes("video/") ){
			this.videoDrop (files);
		}else{
	  		toast.error(i18n.t('Formato file non supportato'), { autoClose : 2500 } );					
		}


	}

	imageDrop (files) {
	    this.compressImage(files, true);
	}

	audioDrop (files) {

		var _this = this;
		var reader = new FileReader();
		reader.onload = function () {
				_this.saveMedia(files[0], 'audio', files[0].name);
		}
		reader.readAsDataURL(files[0]);

	}

	videoDrop (files) {

		var _this = this;
		var reader = new FileReader();
		reader.onload = function () {
				_this.saveMedia(files[0], 'video', files[0].name);
		}
		reader.readAsDataURL(files[0]);

	}

	compressImage(e, drag = false ){
		var _this = this;
		var file = drag ?  e[0] : e.target.files[0];		
		 if (!file) {
	  		toast.error(i18n.t('Formato file non corretto'), { autoClose : 2500 } );		
			_this.mediaLoader(false);
		    return;
		  }			
		  new Compressor(file, {
		    quality: 0.9,
		    maxWidth: media.uploadMaxWidth,
		    maxHeight: media.uploadMaxHeight,
		    convertSize: 1000000,
		    success(result) {
				var reader = new FileReader();
				reader.onload = function () {
 					_this.saveMedia(reader.result, 'image', file.name);
				}
				reader.readAsDataURL(result);
		    },
		    error(err) {
		    	console.log(err.message);
	  			toast.error(i18n.t('Formato file non corretto'), { autoClose : 2500 } );		
				_this.mediaLoader(false);
		    },
		  });
	}

	saveMedia(file, type, name){

		let videoCount = 0;
		let audioCount = 0;
		let imageCount = 0;

		this.state.media.forEach( function(item){

			if 		(item.type == 'image'){ imageCount++ } 
			else if (item.type == 'audio'){ audioCount++ } 
			else if (item.type == 'video'){ videoCount++ } 

		})

		if ( videoCount > 0 && type == 'video' ){
	  		toast.error(i18n.t('Massimo 1 video per post'), { autoClose : 2500 } );	
			this.mediaLoader(false);
	  		return;				
		}

		if ( audioCount > 0 && type == 'audio' ){
	  		toast.error(i18n.t('Massimo 1 audio per post'), { autoClose : 2500 } );	
			this.mediaLoader(false);
	  		return;				
		}

		if ( this.state.media.length >= 4 ){
			this.mediaLoader(false);
	  		toast.error(i18n.t('Massimo 4 contenuti per post'), { autoClose : 2500 } );	
	  		return;				
		}

	    this.setState(prevState => {
			
			const mediaArray = new Array();
			mediaArray['type'] = type;
			mediaArray['file'] = file;
			mediaArray['name'] = name;

	      	const media = [ ...prevState.media, mediaArray ];

			return {
				media,
				value: '',
			};
	    });
		this.onOpenModal();
		this.mediaLoader(false);
	   	console.log(this.state.media);

	}


	deleteMedia (index) {

	   this.setState(prevState => {

	      const newMedia = [...prevState.media];
	      newMedia.splice(index, 1);

	      return {
	        media: newMedia
	      };
	    });

	   document.querySelector('.fileInput').value = "";

	}

	collageMedia (index) {

		console.log( this.state.media );

	   this.setState(prevState => {

	      const newMedia = [...prevState.media];
	      
	      if ( newMedia[index].collage == 1 ){
	      	newMedia[index].collage = 0
	      }else{
	      	newMedia[index].collage = 1
	      }

	      return {
	        media: newMedia
	      };
	    });
	}

	savePost() {

		var _this = this;
		
		let media = this.state.media.slice(0) || [];
		let postData = {};

		let audioIndex = false;
		let videoIndex = false;

		let progressBarModifier = 1;

		let i = 0;
		media.forEach( function(item){

			postData[i] = {};
			postData[i].type = item.type;
			postData[i].name = item.name;
			if ( postData[i].type.includes("image") ){ postData[i].file = item.file; }
			if ( postData[i].type.includes("audio") ){ audioIndex = i; progressBarModifier++; }
			if ( postData[i].type.includes("video") ){ videoIndex = i; progressBarModifier++; }
			
			// video upload solved
			postData[i].width = 0;
			postData[i].height = 0;			
			
			postData[i].collage = item.collage == 1 ? 1 : 0;

			i++;
		})

		//const postContent = this.state.editorState.getCurrentContent().getPlainText('');
		//const postRawContent = convertToRaw(this.state.editorState.getCurrentContent());

		const postContent = this.state.lyEditorState.replace(/<br\s*[\/]?>/gi, "\n");;
		const postRawContent = this.state.lyEditorState.replace(/<br\s*[\/]?>/gi, "\n");;

		const groupData = this.props.group ? this.props.group : false;

		//let state = store.getState();
	  	toast.info(i18n.t('Save post'), { autoClose : 2500 } );		
		const reqBody = { postData: postData, postContent: postContent, postRawContent: postRawContent, postAction: this.state.actionChoiceSelected, groupData: groupData };
		const cfg = { 
			headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token },
			onUploadProgress: function(progressEvent) {
	              var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total / progressBarModifier );
	              _this.setState({ savePostProgress : percentCompleted });
    	    }
        };
		axios.post(endpoint.posts+'/createpost', reqBody, cfg)
			.then((result) => {
				if (result.data.audioPresigned !== false){
					this.uploadFileToS3(result.data.audioPresigned, audioIndex, progressBarModifier);					
				}
				if (result.data.videoPresigned !== false){
					this.uploadFileToS3(result.data.videoPresigned, videoIndex, progressBarModifier);					
				}
				if (result.data.postStatus == "published"){
					this.setState(initialState);
					toast.dismiss();
					toast.success(i18n.t('Post pubblicato'), { autoClose : 2500 } );						
				}
				//_this.checkPostState();
			})
			.catch((err) => {
			  	console.log(err.response);
			  	toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 2500 } );
			  	this.setState({ savePostProgress : 0 });
			})
	}

	uploadFileToS3(presignedPostData, index, progressBarModifier){

		var _this = this;

	    const formData = new FormData();
	    Object.keys(presignedPostData.fields).forEach(key => {
	      formData.append(key, presignedPostData.fields[key]);
	    });

		formData.append("file", this.state.media[index].file);


    	var config = {
            onUploadProgress: function(progressEvent) {
              var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );

	          _this.setState({ sendFileProgress : { ..._this.state.sendFileProgress, [_this.state.media[index].name] : percentCompleted  } })

	        let totalProgress  = 0;
				for (var key in _this.state.sendFileProgress) {
  				totalProgress = totalProgress + _this.state.sendFileProgress[key];
			}

			if (progressBarModifier == 2 ){
			    _this.setState( prevState => ( { savePostProgress: 50 + totalProgress / 2} ) );
			}else if ( progressBarModifier == 3 ){
			    _this.setState( prevState => ( { savePostProgress: 34 + totalProgress / 3} ) );
			}

	          console.log(percentCompleted);
	          console.log(_this.state.sendFileProgress);
            }
      	};

		axios.post(presignedPostData.url,  formData, config)
			.then(function (response) { 
				console.log(response);
				_this.checkPostState();
			})
			.catch(function (response) { console.log(response) })

	}	

	checkPostState(){
		if (this.state.savePostProgress > 99){
			console.log("POST WITH MEDIA SAVED")
			this.setState(initialState);
			toast.dismiss();
			toast.success(i18n.t('Post salvato, riceverai una notifica quando verrà pubblicato'), { autoClose : 2500 } );					

		}
	}


	imgTagging(e){

		console.log(e.target.value); 

		this.setState({
			'imgTagSearch' : e.target.value
		});  

		//let state = store.getState();
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
 		axios.get(endpoint.posts+'/mentionsearch/'+e.target.value, cfg)
      		.then(res => {
      			this.setState({
					imgSuggestions: res.data.mentions || []
				});
      	})


	}

    imgSetTag(event){

		this.getTagPosition(event)
			.then( (response) => {
				
				this.setState({
					'imgTagsCoord' : response
				}, () => { console.log( 'imgTagsCoord then', this.state.imgTagsCoord ) } );  

		    	document.getElementById("imgTagInput").focus();

			})
			.catch( (err) => console.log(err) )
						



    };

    getTagPosition(event){

		return new Promise(function(resolve, reject) {

			var bounds = event.target.getBoundingClientRect();
			var x = event.clientX - bounds.left;
			var y = event.clientY - bounds.top;
			console.log( {x: x, y: y} );
			console.log( bounds.width, bounds.height );

			var tagCoord = { x : Math.round( x/bounds.width * 100 ), y : Math.round( y/bounds.height * 100 ) };

			if (tagCoord.x < 75 && tagCoord.y < 75){
		    	document.getElementById("imgTagInputWrapper").setAttribute("style", "left:"+tagCoord.x+"%; top:"+tagCoord.y+"%;" );
			}
			else if (tagCoord.x < 75 && tagCoord.y > 75){
		    	document.getElementById("imgTagInputWrapper").setAttribute("style", "left:"+tagCoord.x+"%; bottom:"+(100-tagCoord.y)+"%;" );
			}		
			else if (tagCoord.x > 75 && tagCoord.y > 75){
		    	document.getElementById("imgTagInputWrapper").setAttribute("style", "right:"+(100-tagCoord.x)+"%; bottom:"+(100-tagCoord.y)+"%;" );
			}
			else if (tagCoord.x > 75 && tagCoord.y < 75){
		    	document.getElementById("imgTagInputWrapper").setAttribute("style", "right:"+(100-tagCoord.x)+"%; top:"+tagCoord.y+"%;" );
			}

			resolve(tagCoord);

		});


    }

    imgConfirmTag(mediaIndex, suggestionIndex, action = 'add'){

		console.log('imgConfirmTag', mediaIndex, suggestionIndex);
		console.log(this.state.imgTagsCoord);


		this.setState(
			{ imgSuggestions: [] }, () => this.setState({ imgTagSearch : '' })
		);

		if ( action == 'add'){

		    this.setState(prevState => {
				
				const tagObj = prevState.imgTagsList || {};

				if ( !tagObj.hasOwnProperty(mediaIndex) ){
					tagObj[mediaIndex] = {}
				}

				tagObj[mediaIndex][suggestionIndex] = this.state.imgTagsCoord;

				console.log("tagObj", tagObj);

		      	const imgTagsList = tagObj;

				return {
					imgTagsList,
					value: '',
				};
		    }, () => { console.log( this.state.imgTagsList ) });



		}



		if ( action == 'remove'){

		   this.setState(prevState => {

		      const newMedia = [...prevState.imgTagsList];
		      newMedia.splice(suggestionIndex, 1);

		      return {
		        media: newMedia
		      };
		    });

		}

   		console.log(this.state.imgTagsList);


		//imgTagsList

    };    

    imgTaggingOpen(i){
        this.setState({
            imgTaggingState: {all : true, ["i"+i] : true }
        }, ()=> {console.log(this.state.imgTaggingState)} );
    };

    imgTaggingClose(){
        this.setState({
            imgTaggingState: {all : false}
        });
    };

  render() {

    const { MentionSuggestions } = mentionPlugin;

	const { editorIsOpen } = this.state;


	var postType = 'minimes';
	var imageCount = 0;
	var audioCount = 0;
	var videoCount = 0;

	this.state.media.forEach( function(item){
		if 		(item.type == 'image'){ imageCount++ } 
		else if (item.type == 'audio'){ audioCount++ } 
		else if (item.type == 'video'){ videoCount++ } 
	})
	
	if (this.state.charCount > this.store.settings.appSettings.minimes_size){
		postType = 'news';
	} if (this.state.charCount <= this.store.settings.appSettings.minimes_size){
		if ( imageCount == 0 && audioCount == 0 && videoCount == 0 ){
			postType = 'minimes';
		}else if( imageCount == 1 && audioCount == 0 && videoCount == 0 ){
			postType = 'photo';
		}else if( audioCount == 1 && imageCount == 0 && videoCount == 0 ){
			postType = 'audio';
		}else if( videoCount == 1 && imageCount == 0 && audioCount == 0 ){
			postType = 'video';
		}else{
			postType = 'news';
		}
	}
    
    return (

		<div className="theEditorWrapper">


			<div className={ this.state.mediaLoading ? 'theEditorPlaceholder loading' : 'theEditorPlaceholder' } onClick={this.onOpenModal}>
				<div className="margin">

					<FileDrop onDrop={this.mediaDropPlaceholder}>
						{ this.props.settings.avatar !== false && typeof this.props.settings.avatar !== "undefined" ? (<Link to="/profile"><img className="avatar" src={endpoint.mediaRoot+'/'+this.props.settings.avatar.media_url} /></Link>) : (<Link to="/profile"><img className="avatar" src={endpoint.mediaRoot+'/placeholder-avatar.jpg'} /></Link>) }
						<Editor
							editorState={this.state.editorState}
							onChange={this.onChange}
							ref={(element) => { this.editorPlaceholer = element; }}
							readOnly = {true}
							placeholder={i18n.t('Share something...')}
						/>


			        </FileDrop>

				</div> 		 	
			</div>

			<Modal open={editorIsOpen} onClose={this.onCloseModal} classNames={{ modal: "theEditorModal" }} center  showCloseIcon={false}>

				<div className="theEditorBase">

					<FileDrop onDrop={this.mediaDropPlaceholder}>

						<div onClick={this.focus} className="margin">
							<div className="theHead">{ i18n.t('New') +' '+postType } <i className="fal fa-times" onClick={this.onCloseModal}></i></div>
							<div className="theEditorBaseRoot">
								{ this.props.settings.avatar !== false && typeof this.props.settings.avatar !== "undefined" ? (<Link to="/profile"><img className="avatar" src={endpoint.mediaRoot+'/'+this.props.settings.avatar.media_url} /></Link>) : (<Link to="/profile"><img className="avatar" src={endpoint.mediaRoot+'/placeholder-avatar.jpg'} /></Link>) }
								{
								/*
								<Editor
									editorState={this.state.editorState}
									onChange={this.onChange}
									plugins={plugins}
									ref={(element) => { this.editor = element; }}
									readOnly = { this.state.imgTaggingState.all }
									placeholder={i18n.t('Condividi qualcosa...')}
								/>

								
								*/
								}
								<div className="lyEditorWrapper">
									<Textarea 
										minRows={3} 
										maxRows={10}
										className="lyEditor" 
										ref={(element) => { this.editor = element; }} 
										value={this.state.lyEditorState}
										onChange={ this.lyEditorOnChange } 
										placeholder={i18n.t('Share something...')}
									/>	
								</div>
								{
								/*							
								<div className="lyEditorWrapper">
									<ContentEditable
										className="lyEditor" 
								        html={this.state.lyEditorState} // innerHTML of the editable div
								        disabled={false} // use true to disable edition
								        onChange={this.lyEditorOnChange} // handle innerHTML change
								        onBlur={this.lyEditorSanitize}
								    />

							    </div>
								*/
							    }

		    					{
				        		this.state.mentionSuggest ? 
				        			(
				        				<div className="lyEditorMentions">
				        					{
						        				this.state.mentionSuggest.map( mention => {
						        					return( 
						        						<div key={mention.id} className="mention" onClick={ () => this.lyEditorSetMention('@'+mention.name) }>
															<img src={mention.avatar ? mention.avatar : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
						        							<span>@{mention.name}</span>
						        						</div>
						        						)
						        				})
					        				}
				        				</div>

				        			)
				        			:
				        			null


				        	}
		
							</div>

							<MentionSuggestions
								className="theMention"
								onSearchChange={this.onSearchChange}
								suggestions={this.state.suggestions}
								onAddMention={this.onAddMention}
							/>
							<div className="iconAction">
								<div className="uploadMedia">
									<label>
										<input type="file" name="file" className="fileInput" onChange={this.mediaUpload} name="imageLoader" style={{ display : 'none' }} accept="audio/*,video/*,image/*" />
											<i className="fal fa-camera-alt"></i>
											<i className="fal fa-video"></i>
											<i className="fal fa-microphone-alt"></i>
									</label>
								</div>
								{ /* <EmojiSuggestions /> */}
								<div className="actionChoicePanel">
									<div className="actualAction" onClick={ this.handleActionChoiceToggle } >
										{ this.state.actionChoiceSelected !== "star" && this.state.actionChoiceSelected !== "binary" ? (<React.Fragment><i className="fal fa-thumbs-up"></i><i className="fal fa-heart"></i></React.Fragment>) : null }
										{ this.state.actionChoiceSelected == "star" ? (<React.Fragment><i className="fal fa-star"></i><i className="fal fa-star"></i></React.Fragment>) : null }
										{ this.state.actionChoiceSelected == "binary" ? (<React.Fragment><i className="fal fa-thumbs-up"></i><i className="fal fa-thumbs-down"></i></React.Fragment>) : null }
									</div>
									<div className={ this.state.actionChoiceToggle ? "actionChoice active" : "actionChoice" } >
										<div className="singleAction" onClick={ () => this.setState({ actionChoiceSelected : "like-love", actionChoiceToggle : false }) }>
											<i className="fal fa-thumbs-up"></i><i className="fal fa-heart"></i>
										</div>
										<div className="singleAction" onClick={ () => this.setState({ actionChoiceSelected : "star", actionChoiceToggle : false }) }>
											<i className="fal fa-star"></i><i className="fal fa-star"></i>
										</div>
										<div className="singleAction" onClick={ () => this.setState({ actionChoiceSelected : "binary", actionChoiceToggle : false }) }>
											<i className="fal fa-thumbs-up"></i><i className="fal fa-thumbs-down"></i>
										</div>
									</div>
								</div>
								{ /*
									<div className="EmojiSuggestions">
										<EmojiSelect />
									</div>
									*/
								}
							</div>

							<div className="progressPanel">
								<div className="savePostProgressBar" style={ this.state.savePostProgress > 0 && this.state.savePostProgress < 100 ? { display: "block" } : { display : "none"} }><span>Saving post and image {Math.round( this.state.savePostProgress) }%</span><div className="bar" style={ { height : "100%", width : this.state.savePostProgress+"%" } } ></div></div>
								{ (this.state.media || []).map( (media, i) => {
									return media.type == 'audio' || media.type == 'video' ?
										(<div key={'progress'+i} className="sendFileProgressBar" style={ this.state.sendFileProgress[media.name] > 0 && this.state.sendFileProgress[media.name] < 100 ? { display: "block" } : { display : "none"} }><span>Saving {media.name}</span><div className="bar" style={ { height : "100%", width : this.state.sendFileProgress[media.name]+"%" } } ></div></div>)
										:
										''
								}) }
							</div>
							<div className="mediaPreview">
								{(this.state.media || []).map( (media, i) => (
		            					<div key={'m'+i} className={'single '+media.type} style={ media.type == 'image' ? {backgroundImage: "url(" + media.file + ")"} : {} }>
		            						<div className="info">
		            							{ media.name }
		            						</div>
		            						<div className="action">
		            							<div className={ media.collage == 1 ? "collage active" : "collage inactive" } onClick={ () => this.collageMedia(i) }>
			            							<i className="fa fa-images"></i>
			            							<i className="fas fa-check activeIcon statusIcon"></i>
			            							<i className="far fa-circle inactiveIcon statusIcon"></i>
		            							</div>
		            							<i className="fa fa-trash trash" onClick={ () => this.deleteMedia(i) }></i>
		            							{ media.type == 'image' ? (<i className="fa fa-tags img-tags" onClick={ () => this.imgTaggingOpen(i) }></i>) : '' }
		            							
		            							{ media.type == 'image' ? (
		            								<Modal open={this.state.imgTaggingState["i"+i] || false } onClose={this.imgTaggingClose} classNames={{ modal: "imgTagging" }} center  showCloseIcon={false} focusTrapped={false}>
		            									<div className="grid">
		            										<img src={media.file} alt="tagging" onClick={this.imgSetTag} /> 
		            										<div id="imgTagInputWrapper">
		            											<input type="text" name="imgTagInput" id="imgTagInput" onChange={this.imgTagging} value={this.state.imgTagSearch} />
		            											<div id="imgTagInputResults">
		            												{ 
		            													( this.state.imgSuggestions || [] ).map( ( suggestion, si) => {
		            														return (<div key={'s'+si} onClick={ () => {this.imgConfirmTag(i, suggestion.id) } }><img src={suggestion.avatar} /> <span>{suggestion.name}</span></div>)
		            													}	
		            													)
		            													
		            												}
		            											</div>
		            										</div>
		            									</div>
		            								</Modal>) : '' }
		            						</div>
		            					</div>
	          					))}
							</div>
							{
							/*
							<div><CharCounter limit={250} /> characters</div>
							<div><WordCounter limit={30} /> words</div>
							<div><LineCounter limit={10} /> lines</div>
							<div>
								<CustomCounter limit={40} countFunction={this.customCountFunction} />
								<span> words (custom function)</span>
							</div>

							<code>
							UPLOAD:
							** per tag:
							- inserire icona tag vicino a trash
							- se cliccata apre immagine in grande con pannello trasparente
							- al click apre selezione tag
							- aggiunge ad oggetto media: dettagli tag e posizione ( posizione click in relazione a foto e valori in percentuale: es. 1, 1 significa 1% top 1% left)
							- salvare i dati in media

							-- oppure

							-- griglia trasparenti di 100x100 con onclick valori 1-1, 1-2, 1-3... 2-1, 2-2
							</code>

							<code>
							cambiare tasto in funzione del tipo di messaggio:
							1 foto e meno di 250 caratteri -> foto
							1 video e meno di 250 -> video
							1 auto e meno di  250 -> podcast
							+ di 1 media || 1 media più di 250 -> news
							no media e meno di X* minimes

							X= numero medio da quello proposto da campione di utenti, variabile ogni settimana tra minimo e massimo.
							aggiungere opzione "da quanti caratteri vorresti fosse formato un minimes"
							</code>
							*/
							}
						</div>

					</FileDrop>
					<button className={this.state.savePostProgress !== 0 ? "disabled sendPostButton "+postType : "sendPostButton "+postType } onClick={this.savePost} disabled={ this.state.savePostProgress !== 0 ? true : false } >{i18n.t('Share')} {postType}</button>

				</div>
			</Modal>

		</div>


    );
  }
}

//export default EditorBase;


const EditorBase = connect(mapStateToProps)(ConnectedEditorBase);
export default withRouter(EditorBase);

