import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import i18n from "../../../i18n";


import { toast } from 'react-toastify';

import endpoint from "../../../config/endpoint";
import media from "../../../config/media";
import axios from "axios";
import {store} from './../../../store';

import Textarea from 'react-textarea-autosize';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


class Connectedprofileinfo extends Component {

	constructor(props) {
		super(props);
	
	    this.updateParent		= this.updateParent.bind(this);
	    this.switchEditBio		= this.switchEditBio.bind(this);
	    this.switchEditGender	= this.switchEditGender.bind(this);
	    this.switchEditBirth	= this.switchEditBirth.bind(this);
	    this.handleChange		= this.handleChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleDateChange 	= this.handleDateChange.bind(this);

		this.state = {
			editBio 	: false,
			editGender 	: false,
			editBirth 	: false,
			profileInfo : false
		}

	    this.store = store.getState();

	}

	updateParent(){
		this.props.handleUpdate();
	}

	switchEditBio(save){
		this.setState(prevState => ({
		  editBio: !prevState.editBio
		}));

		if(save){
			this.submitInfo('bio');
		}
	}

	switchEditGender(save){
		this.setState(prevState => ({
		  editGender: !prevState.editGender
		}));

		if(save){
			this.submitInfo('gender');
		}
	}


	switchEditBirth(save){
		this.setState(prevState => ({
		  editBirth: !prevState.editBirth
		}));

		if(save){
			this.submitInfo('birth');
		}
	}	


	handleChange(event) {
	    event.preventDefault();
	    this.setState({ [event.target.id]: event.target.value });
	    console.log(this.state);

	}

	handleSelectChange(selected) {
		if ( selected == null ){
			this.switchEditGender(false);
		}else{
			console.log(selected);	
		}
		
	}

	handleDateChange(date) {
		console.log(date);
	}	

	submitInfo(type) {

		let reqBody = {}
		if ( type == 'bio' ){
			reqBody = { bio: this.state.infoContent };	
		}else if ( type == 'gender' ){
			reqBody = { gender: 'my gender' };	
		}else if ( type == 'birth' ){
			reqBody = { birth: '11/11/1911' };	
		}
		
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.post(endpoint.users+'/profilesetdata', reqBody, cfg)
			.then((result) => {
				toast.dismiss();
			  	toast.success(i18n.t(result.data.success), { autoClose : 3000 } );
		    	this.updateParent()

			})
			.catch((err) => {
				toast.dismiss();
			  	//toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 3000 } );
			  	console.log(err);
			})

	}

	componentDidMount(){
	}


	render(){

		var currentDate = new Date();
		var date = currentDate.getDate();
		var month = currentDate.getMonth(); //Be careful! January is 0 not 1
		var year = currentDate.getFullYear();

		var startDate = new Date(year-110+'-'+month+'-'+date);
		var endDate = new Date(year-13+'-'+month+'-'+date);

		const selectOptions = [
		  { value: '', label: i18n.t('') },
		  { value: 'not-indicate', label: i18n.t('I prefer not to indicate') },
		  { value: 'male', label: i18n.t('Male') },
		  { value: 'female', label: i18n.t('Female') },
		  { value: 'custom', label: i18n.t('Custom') }
		]

		const bio = !this.state.profileInfo ? this.props.profileInfo.bio : this.state.profileInfo;
		const newBio = typeof this.state.infoContent !== "string" ? bio : this.state.infoContent;

		const editInfo = ( 
			<div>
				<Textarea minRows={3} maxRows={10} className="input-textarea profile-info" id="infoContent" value={newBio} onChange={ this.handleChange } />
				<i className="fas fa-check-circle btn save" onClick={ () => this.switchEditBio(true)} ></i>
			</div>
		);
		const showInfo = (
			<div className="profile-info">
				{bio}
				<i className="fal fa-edit btn save" onClick={ () => this.switchEditBio(false)} ></i>
			</div>
		);


	    const own = (
			<div className="own">
				{ !this.state.editBio ? showInfo : editInfo }

				{ !this.state.editGender ? 
					( <div>
						<i className="far fa-infinity genderIcon"></i> 
						{ this.props.profileInfo.gender } 
						<i className="fal fa-edit btn save" onClick={ () => this.switchEditGender(false)} ></i>
					</div> )
					:
					(<div>
						<Select id="genderChoice"
							options={selectOptions}
							isClearable={true} isSearchable={true}
							onChange={ this.handleSelectChange }
							defaultValue={selectOptions[0]}
							className="genderSelect"
							classNamePrefix="genderSelect"
						/>
						<i className="fas fa-check-circle btn save" onClick={ () => this.switchEditGender(true)} ></i>
					</div>)
				}

				{ !this.state.editBirth ? 
					( <div>
						<i className="fal fa-birthday-cake"></i>
						{ this.props.profileInfo.birth } 
						<i className="fal fa-edit btn save" onClick={ () => this.switchEditBirth(false)} ></i>
					</div> )
					:
					(<div>
						<i className="fal fa-birthday-cake"></i>
						<DatePicker
						    selected={this.state.startDate}
						    onChange={this.handleDateChange}
						    showYearDropdown
						    scrollableYearDropdown
		            		yearDropdownItemNumber={100}
		            		minDate={startDate}
		            		maxDate={endDate}
						/>						
						<i className="fas fa-check-circle btn save" onClick={ () => this.switchEditBirth(false)} ></i>
					</div>)
				}



				
			</div>
		);

	    const notOwn = (
			<div className="notOwn">
				<div className="profile-info">
					{bio}
				</div>
	    	</div>
	    );


		return(
				<div className="profile-info" id="profile-info">
					{this.props.own ? own : notOwn }
				</div>
		);
	}
}



const profileinfo = connect(mapStateToProps)(Connectedprofileinfo);
export default profileinfo;

