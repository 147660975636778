import React, { Component } from "react";
import { connect } from "react-redux";
import { usersActivate, usersResendActivation, cleanOnce } from "../../actions/index";
import { Link } from "react-router-dom";

import i18n from "../../i18n";

function mapDispatchToProps(dispatch) {
  return {
    usersActivate: activate => dispatch(usersActivate(activate)),
    usersResendActivation: activate => dispatch(usersResendActivation(activate)),
    cleanOnce: () => dispatch(cleanOnce())
  };
}


class ConnectedActivateForm extends Component {
  constructor(props) {
    super(props);
    console.log( props );
    if ( typeof props.params.email !== "undefined" && typeof props.params.atoken !== "undefined" ){
      this.state = {
        email: props.params.email,
        atoken: props.params.atoken
      }
    }else{
      this.state = {
        email: "",
        atoken: ""
      }
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleResend = this.handleResend.bind(this);

  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
    this.props.cleanOnce();
  }
  handleSubmit(event) {
    event.preventDefault();
    const { email } = this.state;
    const { atoken } = this.state;
    this.props.usersActivate({ email, atoken });
    this.setState({ email: email, atoken: atoken });
  }
  handleResend(event) {
    event.preventDefault();
    const { email } = this.state;
    const { atoken } = this.state;
    this.props.usersResendActivation({ email, atoken });
    this.setState({ email: email, atoken: atoken });
  }  

  render() {
    const { email } = this.state;
    const { atoken } = this.state;

    return (
      <div className="activateFormV2">
        <form onSubmit={this.handleSubmit} className="utility-form NOlogin-form">
          <div className="form-group" style={{backgroundColor:'#FFF'}}>
            <h2>{  i18n.t("Activate") }</h2>
            <input
              type="text"
              className="input-text"
              id="email"
              value={email}
              onChange={this.handleChange}
              placeholder="Email"
              autoCorrect="off" autoCapitalize="none"
            />
            <input
              type="text"
              className="input-text"
              id="atoken"
              value={atoken}
              onChange={this.handleChange}
              placeholder={ i18n.t("Activation code") }
              autoCorrect="off" autoCapitalize="none"
            />          
            <button type="submit" className="button btn-primary">
              { i18n.t("Activate") }
            </button>

            <div className="other-action">
              <p>Didn't get the activation email? Fill email field and click 
                 <a onClick={this.handleResend} className="utility-link">
                  {  i18n.t("Resend Activation") }
                </a>
              </p>
             </div>
             <div className="other-action"> 
              <Link to="/login">{ i18n.t("Login") }</Link> 
              <Link to="/forgot">{  i18n.t("Forgot password") }</Link> 
            </div>
           

          </div>
        </form>
      </div>
    );
  }
}

const Activateform = connect(null, mapDispatchToProps)(ConnectedActivateForm);
export default Activateform;