import React, { Component } from "react";
import { connect } from "react-redux";
import { saveSettings } from "../../../actions/index";
import { Link } from "react-router-dom";

import i18n from "../../../i18n";

import qs from "querystring";

import { toast } from 'react-toastify';


const mapStateToProps = state => {
  return { fetchedTags: state.settings.tags };
};


function mapDispatchToProps(dispatch) {
  return {
    saveSettings: settings => dispatch(saveSettings(settings))
  };
}


class ConnectedTagsform extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		newtag : "",
		type : "tags"
		}
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.handleChange = this.handleChange.bind(this);
	    this.handleRemove = this.handleRemove.bind(this);
	}

	handleSubmit(event) {
	    event.preventDefault();
    	const { newtag } = this.state;
    	const { type } 	 = this.state;
    	if ( this.state.newtag.length < 2){
		  	toast.error(i18n.t("tag too short, minimum 2 chars"), { autoClose : 3000 } );
    	}else{
	    	const dataObj = { action : "add", tag: newtag };
    		this.props.saveSettings({ type, dataObj });
	    	this.setState( { newtag : '' } )
    	}
    }

	handleRemove(removeTag) {
	    //event.preventDefault();
	    console.log(removeTag);
    	const { type } 	 = this.state;
    	const dataObj = { action : "remove", tag: removeTag };
    	this.props.saveSettings({ type, dataObj });
    }    

	handleChange(event) {
	    event.preventDefault();
	    this.setState({ [event.target.id]: event.target.value });
	}

  render() {
    const { savedtags } = this.state;
    return (
		<form onSubmit={ this.handleSubmit } className="settings-form">
			<h2>{  i18n.t("Tags") }</h2>
			<div className="intro">
				{  i18n.t("Preferred tags") }
				<br/><br/>
				{/*Inserisci alcuni tag che reputi interessanti, ci aiuteranno a fornirti i contenuti di tuo interesse.*/}
			</div>



				{  Array.isArray(this.props.fetchedTags) ? (<SavdedTags handleRemove={this.handleRemove} tags={this.props.fetchedTags} />) : 'No tags' }


			<div className="input-row">
				<input
				type="text"
				className="input-text"
				id="newtag"
				value={this.state.newtag}
				onChange={ this.handleChange }
				placeholder="Tags"
				autoCorrect="off" autoCapitalize="none"
				/>
			</div>

			<button type="submit" className="button btn-primary">
				{ i18n.t("Add new tag") }
			</button>
	     </form>
    );
  }
}


const Tagsform = connect(mapStateToProps, mapDispatchToProps)(ConnectedTagsform);
export default Tagsform;



function SavdedTags(props){

	return(
	<div className="field is-grouped is-grouped-multiline saved-tags">
		{
			props.tags.map( (tag, i) =>{
				let tagName = tag["tag"]
				let tagId = tag["id"]
				return(
					<div className="control" key={i}>
						<div className="tags has-addons">
						  <a className="tag is-info">{tagName.split("-").join(" ").toLowerCase()}</a>
						  <a onClick={ () => props.handleRemove(tagName) } className="tag is-delete"></a>
						</div>
					</div>
				)
			})
		}
	</div>
	)

}