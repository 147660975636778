import React, { Component } from "react";
import { connect } from "react-redux";
import { usersActivate, usersResendActivation, cleanOnce } from "../../actions/index";
import { Link, Redirect } from "react-router-dom";

import endpoint from "../../config/endpoint";
import axios from "axios";
import { toast } from 'react-toastify';

import i18n from "../../i18n";

function mapDispatchToProps(dispatch) {
  return {
  };
}


class ConnectedResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      atoken: '',
      requested: false,
      changed: false
    };

    this.handleChange             = this.handleChange.bind(this);
    this.handleSubmitRequest      = this.handleSubmitRequest.bind(this);
    this.handleSubmitChange       = this.handleSubmitChange.bind(this);

  }

  handleSubmitRequest(event){

    event.preventDefault();

    const reqBody = { userEmail: this.state.email, action: 'request', lang: i18n.language };
    const cfg = { 
      headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': 'none' }
        };

    axios.post(endpoint.users+'/passwordreset', reqBody, cfg)
      .then((result) => {
        this.setState({ 'requested' : true });
        toast.info(i18n.t('Check your inbox'), { autoClose : 2500 } );    
      })
      .catch((err) => {
        toast.error(i18n.t('There is an error, verify your data and retry'), { autoClose : 2500 } );    
      })  

  }

  handleSubmitChange(event){

    event.preventDefault();

    if ( this.state.newpassword !== this.state.repassword ){
      toast.error(i18n.t('Passwords do not macth'), { autoClose : 2500 } );    
      return;
    }

    if ( !this.state.newpassword ){
      toast.error(i18n.t('Write the new password'), { autoClose : 2500 } );    
      return;
    }else{
      if ( this.state.newpassword.length < 6 || this.state.newpassword.length > 30 ){
        toast.error(i18n.t('Password length must be between 6 and 30 characters'), { autoClose : 2500 } );  
        return;  
      }
    }

    const reqBody = { userEmail: this.state.email ? this.state.email : this.props.match.params.email, actionToken: this.state.atoken ? this.state.atoken : this.props.match.params.atoken, action: 'change', newPassword: this.state.newpassword, lang: i18n.language };
    const cfg = { 
      headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': 'none' }
        };

    axios.post(endpoint.users+'/passwordreset', reqBody, cfg)
      .then((result) => {
        this.setState({ 'changed' : true });
        toast.info(i18n.t('Check your inbox'), { autoClose : 2500 } );    
      })
      .catch((err) => {
        toast.error(i18n.t('There is an error, verify your data and retry'), { autoClose : 2500 } );    
      })  

  }  

  handleChange(event) {
    
    const target = event.target;
    const name   = target.name;

    this.setState( { [name] : target.value});
    console.log(this.state);
  }    

  render() {

    return (

      <div className="ResetPasswordFormV2">

      { this.state.changed ? <Redirect to="/login" /> : null }

      {
        (!this.props.match.params.email && !this.props.match.params.atoken ) ?

        (
          <form onSubmit={this.handleSubmitRequest} className="utility-form NOlogin-form">
            <div className="form-group" style={{backgroundColor:'#FFF'}}>

              <h2>{  i18n.t("Forgot password") }</h2>

                {!this.state.requested ?
                  (
                    <div>
                      <input
                      type="text"
                      className="input-text"
                      id="email"
                      name="email"
                      value={ this.state.email }
                      onChange={this.handleChange}
                      placeholder="Email"
                      autoCorrect="off" autoCapitalize="none"
                      />
                      
                      <div className="other-action">
                        <p>Reset your password by clicking "send reset email", you will receive an email with the temporary code to authorize the password change</p>
                      </div>

                      <button type="submit" className="button btn-primary">
                        { i18n.t("Send reset email") }
                      </button>
                     
                      <div className="other-action"> 
                        <Link to="/login">{ i18n.t("Login") }</Link> 
                        <Link to="/register">{  i18n.t("Sign up") }</Link> 
                      </div>

                    </div>


                  )
                  :
                  (
                    <div className="other-action">
                      <p>Request received, check you inbox</p>
                    </div>
                  )


                }

            </div>

          </form>

        )
        :
        (
            <form onSubmit={this.handleSubmitChange} className="login-form">
            
              <div className="form-group">

                <h2>{  i18n.t("Forgot password") }</h2>
                
                <input
                type="text"
                className="input-text"
                id="email"
                name="email"
                value={ this.state.email ? this.state.email : this.props.match.params.email }
                onChange={this.handleChange}
                placeholder="Email"
                autoCorrect="off" autoCapitalize="none"
                />

                <input
                type="text"
                className="input-text"
                id="atoken"
                name="atoken"
                value={ this.state.atoken ? this.state.atoken : this.props.match.params.atoken }
                onChange={this.handleChange}
                placeholder="Activation Code"
                autoCorrect="off" autoCapitalize="none"
                /> 
                
                <input
                type="password"
                className="input-text"
                id="newpassword"
                name="newpassword"
                value={this.state.newpassword}
                onChange={this.handleChange}
                placeholder="New password"
                autoCorrect="off" autoCapitalize="none"
                />         
                
                <input
                type="password"
                className="input-text"
                id="repassword"
                name="repassword"
                value={this.state.repassword}
                onChange={this.handleChange}
                placeholder="Retype password"
                autoCorrect="off" autoCapitalize="none"
                />                            
                
                <button type="submit" className="button btn-primary">
                  { i18n.t("Activate") }
                </button>

              </div>
            </form>

        )
      }


      </div>

      );
  }
}

const ResetPasswordForm = connect(null, mapDispatchToProps)(ConnectedResetPasswordForm);
export default ResetPasswordForm;