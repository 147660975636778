import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import i18n from "../../../i18n";


import { toast } from 'react-toastify';

import endpoint from "../../../config/endpoint";
import media from "../../../config/media";
import axios from "axios";
import {store} from './../../../store';

import Textarea from 'react-textarea-autosize';
import Select from 'react-select';

//import DatePicker from 'react-datepicker';
//import "react-datepicker/dist/react-datepicker.css";

import DatePicker from 'react-date-picker';

import Moment from 'react-moment';


const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


class Connectedprofileinfo extends Component {

	constructor(props) {
		super(props);
	
	    this.updateParent		= this.updateParent.bind(this);
	    this.switchEditBio		= this.switchEditBio.bind(this);
	    this.switchEditGender	= this.switchEditGender.bind(this);
	    this.switchEditBirth	= this.switchEditBirth.bind(this);
	    this.handleChange		= this.handleChange.bind(this);
			this.handleSelectChange = this.handleSelectChange.bind(this);
			this.handleDateChange 	= this.handleDateChange.bind(this);

			this.toggleEditUrls = this.toggleEditUrls.bind(this);
			this.handleEditUrls = this.handleEditUrls.bind(this);

		this.state = {
			editBio 		: false,
			editGender 	: false,
			editBirth 	: false,
			profileInfo : false,
			birth				: false,
			userProfileSettings : this.props.userProfileSettings,
			editUrls		: false,
		}

	    this.store = store.getState();

	}

	componentDidMount(){
		this.setState({birth: this.props.profileInfo.birth})
	}

	componentDidUpdate(prevProps){
		if (this.props.profileInfo.birth !== prevProps.profileInfo.birth ){
			this.setState({birth: this.props.profileInfo.birth})
		}
	}


	updateParent(){
		this.props.handleUpdate();
	}

	switchEditBio(save){
		this.setState(prevState => ({
		  editBio: !prevState.editBio
		}));

		if(save){
			this.submitInfo('bio');
		}
	}

	switchEditGender(save){
		this.setState(prevState => ({
		  editGender: !prevState.editGender
		}));

		if(save){
			this.submitInfo('gender');
		}
	}


	switchEditBirth(save){
		this.setState(prevState => ({
		  editBirth: !prevState.editBirth
		}));

		if(save){
			this.submitInfo('birth');
		}
	}	


	handleChange(event) {
	    event.preventDefault();
	    this.setState({ [event.target.id]: event.target.value });
	    console.log(this.state);

	}

	handleSelectChange(selected) {
		if ( selected == null ){
			this.switchEditGender(false);
		}else{
			console.log(selected);	
		}
		
	}

	handleDateChange(date) {
		this.setState({birth: new Date(date)})
	}	

	submitInfo(type) {

		let reqBody = {}
		if ( type == 'bio' ){
			reqBody = { bio: this.state.infoContent };	
		}else if ( type == 'gender' ){
			reqBody = { gender: this.state.gender };	
		}else if ( type == 'birth' ){
			let newDate = this.state.birth;
			if ( typeof newDate.getDate !== 'function' ) { return }
			newDate.setDate(newDate.getDate() + 1);
			reqBody = { birth: newDate };	
		}
		
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.post(endpoint.users+'/profilesetdata', reqBody, cfg)
			.then((result) => {
				toast.dismiss();
			  	toast.success(i18n.t(result.data.success), { autoClose : 3000 } );
		    	this.updateParent()

			})
			.catch((err) => {
				toast.dismiss();
			  	//toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 3000 } );
			  	console.log(err);
			})

	}

	toggleEditUrls() {
		this.setState({editUrls: !this.state.editUrls})
	}	

	async handleEditUrls(){
	
			let reqBody = {
				type: "url",
				dataObj: {
					url: {
						url_1: document.getElementById('url_1').value,
						url_2: document.getElementById('url_2').value,
						url_3: document.getElementById('url_3').value,
						url_4: document.getElementById('url_4').value,
					},
				},
			};
	
			const cfg = { headers: { Accept: "application/json", "Content-Type": "application/json", Authorization: this.store.user.token } };
			axios.post(endpoint.users + "/savesettings", reqBody, cfg)
				.then((result) => {
					window.location.reload();
				})
				.catch((err) => {
					console.log(err);
				});
		
	}


	render(){

 		var currentDate = new Date();
 		var birth = new Date();
		
		if (new Date(this.state.birth).toString() !== 'Invalid Date'){
			var birth = new Date(this.state.birth);
		}

		var date = currentDate.getDate();
		var month = currentDate.getMonth(); //Be careful! January is 0 not 1
		var year = currentDate.getFullYear();

		var startDate = new Date(year-110+'-'+month+'-'+date);
		var endDate = new Date(year-13+'-'+month+'-'+date);


		const bio = !this.state.profileInfo ? this.props.profileInfo.bio : this.state.profileInfo;
		const newBio = typeof this.state.infoContent !== "string" ? bio : this.state.infoContent;

		const editInfo = ( 
			<div>
				<Textarea minRows={3} maxRows={10} className="input-textarea profile-info" id="infoContent" value={newBio} onChange={ this.handleChange } />
				<i className="fal fa-paper-plane editIconSave" onClick={ () => this.switchEditBio(true)} ></i>
			</div>
		);
		const showInfo = (
			<div className="bio">
				{bio}
				<i className="fal fa-edit editIcon" onClick={ () => this.switchEditBio(false)} ></i>
			</div>
		);


	    const own = (
			<div className="own">
				{ !this.state.editBio ? showInfo : editInfo }

				<div className="profile-more-info">

					<div className="gender">
						{ !this.state.editGender ? 
							( <div>
								<i className="fas fa-egg genderIcon"></i> 
								{ this.props.profileInfo.gender } 
								<i className="fal fa-edit editIcon" onClick={ () => this.switchEditGender(false)} ></i>
							</div> )
							:
							(<div className="genderSelection">
								<button onClick={ () => this.setState({gender: 'male'}, () => this.switchEditGender(true) ) }>{ i18n.t('Male') }</button>
								<button onClick={ () => this.setState({gender: 'female'}, () => this.switchEditGender(true)) }>{ i18n.t('Female') }</button>
								<button onClick={ () => this.setState({gender: 'not-indicate'}, () => this.switchEditGender(true)) }>{ i18n.t('I prefer not to indicate') }</button>
								<input type="text" value={this.state.gender} onChange={ (e) => this.setState({gender: e.target.value }) } placeholder="custom gender" />
								<i className="fal fa-paper-plane editIconSave" onClick={ () => this.switchEditGender(true)} ></i>
							</div>)
						}
					</div>

					<div className="birth">

					{ !this.state.editBirth ? 
						( <div>
							<i className="fal fa-calendar-alt"></i>
							<Moment date={birth} format="DD/MM/YYYY" />
							<i className="fal fa-edit editIcon" onClick={ () => this.switchEditBirth(false)} ></i>
						</div> )
						:
						(<div>
							<i className="fal fa-calendar-alt"></i>	
							<DatePicker
	    	      				onChange={this.handleDateChange}
	        	  				value={birth}
	    					/>				
							<i className="fal fa-paper-plane editIconSave" onClick={ () => this.switchEditBirth('save')} ></i>
						</div>)
					}

					</div>

				</div>

				{!this.state.editUrls ?
					(
						<div className={"urls"}>
							{this.state.userProfileSettings && <a href={this.state.userProfileSettings[0].url_1}>{this.state.userProfileSettings[0].url_1}</a> }
							{this.state.userProfileSettings && <a href={this.state.userProfileSettings[0].url_2}>{this.state.userProfileSettings[0].url_2}</a> }
							{this.state.userProfileSettings && <a href={this.state.userProfileSettings[0].url_3}>{this.state.userProfileSettings[0].url_3}</a> }
							{this.state.userProfileSettings && <a href={this.state.userProfileSettings[0].url_4}>{this.state.userProfileSettings[0].url_4}</a> }
							<i className="fal fa-edit editIcon" onClick={this.toggleEditUrls} ></i>
						</div> 
					):
					(
						<div className={"urls"}>
							<input type="text" id="url_1" defaultValue={this.state.userProfileSettings[0].url_1} placeholder="url" />
							<input type="text" id="url_2" defaultValue={this.state.userProfileSettings[0].url_2} placeholder="url" />
							<input type="text" id="url_3" defaultValue={this.state.userProfileSettings[0].url_3} placeholder="url" />
							<input type="text" id="url_4" defaultValue={this.state.userProfileSettings[0].url_4} placeholder="url" />
						<i className="fal fa-paper-plane editIconSave" onClick={this.handleEditUrls} ></i>
					</div> 
					)
				}
				
				
			</div>
		);

	    const notOwn = (
			<div className="notOwn">
				<div className="profile-info">
					{bio}
				</div>
				
				<div className="profile-more-info">
					{ this.props.profileInfo.gender ? 
						<div className="gender">	
							<div>
								<i className="fas fa-egg"></i>
								{ this.props.profileInfo.gender } 
							</div>
						</div>
						:
						null
					}

					{ this.props.profileInfo.birth ? 
						<div className="birth">	
							<div>
								<i className="fal fa-calendar-alt"></i>
								<Moment date={birth} format="DD/MM/YYYY" />
							</div>
						</div>
						:
						null
					}

				</div>

				<div className={"urls"}>
						{this.state.userProfileSettings && <a href={this.state.userProfileSettings[0].url_1}>{this.state.userProfileSettings[0].url_1}</a> }
						{this.state.userProfileSettings && <a href={this.state.userProfileSettings[0].url_2}>{this.state.userProfileSettings[0].url_2}</a> }
						{this.state.userProfileSettings && <a href={this.state.userProfileSettings[0].url_3}>{this.state.userProfileSettings[0].url_2}</a> }
						{this.state.userProfileSettings && <a href={this.state.userProfileSettings[0].url_4}>{this.state.userProfileSettings[0].url_2}</a> }
					</div> 

	    	</div>
	    );


		return(
				<div className="profile-info" id="profile-info">
					<div className="aboutMe"><span>{i18n.t('About me')}</span></div>
					{this.props.own ? own : notOwn }
				</div>
		);
	}
}



const profileinfo = connect(mapStateToProps)(Connectedprofileinfo);
export default profileinfo;

