const ES = {
	"lng": "es",
	"Login": "Iniciar sesión",
	"Register": "Registration",
	"Activate": "Activación",
	"Username": "ES Username",
	"Password": "ES Password",
	"This is the home content": "ES This is the home content ES",
	"User not found, check email and password": "Usuario no encontrado, consultar correo electrónico y contraseña",
	"Friends": "Amigos",
	"User not found": "User not found",
	"About me": "Sobre mi",
	"Password": "Contraseña",
	"Sign up": "Crear una cuenta",
	"Forgot password": "Olvidé mi contraseña",
	"Nickname": "Apodo",
	'Repeat password': 'Repite la Contraseña',
	'Profile types': 'Tipo de perfil',
	'Super': 'Super',
	'Influencer': 'Influencer',
	'Business': 'Business',
	'Association': 'Asociación',
	'Ambassador’s nickname': 'Apodo del Embajador',
	'Ambassador’s username': 'Apodo del Embajador',
	'Activation code': 'Código de activación',
	'Profile': 'Perfil',
	'Settings': 'Ajustes',
	Logout: 'Cerrar sesión',
	Tags: 'Etiquetas',
	'Preferred tags': 'Etiquetas personales',
	'Add new tag': 'Añadir nueva etiqueta',
	Save: 'Salvar',
	Socialization: 'Socialización',
	Notifications: 'Notificaciones',
	Personalization: 'Personalización',
	Personalization: 'Personalization',
	Privacy: 'Privacidad',
	'Help and support': 'Soporte y ayuda',
	'Terms and conditions': 'Términos y condiciones',
	'Privacy policy': 'Política de privacidad',
	guest_modal_generic: '¿Quieres seguir descubriendo Launchyoo? Si ya te registraste inicia sesión, y si no, crea tu	cuenta y comienza a disfrutar de tu nueva red social',
	guest_modal_comment: '¿Quieres comentar la publicación? Si ya te registraste en Launchyoo inicia sesión, y si no, crea tu cuenta y comienza a disfrutar de tu nueva red social',
	guest_modal_like_love: '¿Te gusta esta publicación? Si ya te registraste en Launchyoo inicia sesión, y si no, crea tu cuenta y comienza a disfrutar de tu nueva red social',
	guest_modal_binary_star: '¿Quieres opinar sobre esta publicación? Si ya te registraste en Launchyoo inicia sesión, y si no, crea tu cuenta y comienza a disfrutar de tu nueva red social',
	"Send a comment": "Enviar un comentario",
	cookies_bar: "En Launchyoo utilizamos cookies propias y de terceros para el análisis de tus hábitos de navegación y para hacer estudios estadísticos.",
	cookies_bar_link: "Más información en la Política de Cookies",
	Accept: "Aceptar",
	Reject: "Rechazar",
	User: "Usuario",
	"Join the discussion": "Únete a la discusión",
	"You left the group": "Dejaste el grupo",
	"You joined the group": "Te uniste al grupo",
	"Subscription awaiting approval": "Suscripción pendiente de aprobación",
	Subscribe: "Suscribir",
	Leave: "Salir",
	"Pending request": "Solicitud pendiente",
	"Invitation sent": "Invitación enviada",
	"Already in that group": "Ya en ese grupo",
	helpProfileDetails : 'Elige el perfil:', // Auth
	helpProfileSuper : 'USUARIO, si quieres usar Launchyoo para mantenerte en contacto con amigos y familia, seguir a personajes de tu interés, leer noticias o simplemente divertirte viendo un video o leyendo un blog. También si eres blogger o podcasters.', // Auth
	helpProfileInfluencer : 'INFLUENCER, si buscas entretener a tus seguidores, mantenerles al tanto de tu vida, tus actividades y tus gustos.', // Auth
	helpProfileBusiness : 'BUSINESS. Para empresas, grandes o pequeñas, negocios y profesionales.', // Auth
	helpProfileAssociation : 'ASOCIACIÓN, si se trata de una entidad sin ánimo de lucro o que desarrolla actividades sociales, educativas, culturales, de divulgación, información u ocio.', // Auth
	helpAmbassador : 'If you sign up in Launchyoo because somebody has suggested you to do so, write his or her profile name here. Advantages await him/her and you as well, if someone will write your name.', // Auth
	"Read more": "Leer más",
  }

export default ES;