import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import ActivateForm from "../../components/auth/ActivateForm";

import i18n from "../../i18n";

const mapStateToProps = state => {
  return { user: state.user, language: state.language, utility: state.utility};
};

/*
const ConnectedActivate = ({ user, language }) => (

	<div className="login">
			{ (user.auth === true) ? (<Redirect to='/' />) : (<ActivateForm lng={language} />) }
	</div>
);
*/

class ConnectedActivate extends Component {
	
	constructor(props) {
		super(props);
	}

	componentWillMount(){
	}

	render() {
		console.log("this.props.utility.redirect");
		console.log(this.props.utility.redirect);
		return(
			<div className="login">
					{ (this.props.utility.redirect !== false && typeof this.props.utility.redirect !== "undefined" ) ? (<Redirect to='/login' />) : (<ActivateForm lng={this.props.language} params={this.props.match.params} />) }
			</div>
		);
	}
}

const Activate = connect(mapStateToProps)(ConnectedActivate);
export default Activate;


