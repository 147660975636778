
import React, { Component } from "react";

import ReactMarkdown from "react-markdown";

import i18n from "../../i18n";



export default class CookiesPolicy extends Component {

  constructor(props) {
    super(props);
  }


  render(){

    return (
    	<div className="termsExtended">

        { i18n.language == 'es' ?
          <ReactMarkdown source={esText} />          
          :
          i18n.language == 'pt' ?
          <ReactMarkdown source={ptText} />          
          :
          <ReactMarkdown source={enText} />          
        }

    	</div>
    );
  }
}


const enText = `
**Cookies Policy**

**1\. Identification of the Data Controller:** The Data Controller responsible for processing is PECH Network Service, S.L.U.,at the address C/ Ramón Llull, 31, 4°, 11, 46021, Valencia (Spain), duly registered in the Mercantile Registry of Valencia, Volume 10.642, Book 7923, Folio 96, Sheet V- 186.754., with Tax ID Number: B40561185. Hereinafter, PECH NETWORK.

You can contact PECH NETWORK directly in relation to the present Cookies Policy, at the email: 

customercare@launchyoo.com

**2\. What is a cookie?**: A cookie is a small file that websites send to the terminal (computer, *smartphone, tablet,* etc,.) from which the user connects, in order to store browsing information carried out from said device, which may, where appropriate, be retrievedlater.

**2.1. What are the different types of cookies?:** There are different types of cookies, but the same cookie can be included in more than one category.

**2.1.1. Types of cookies affected by regulations:** We can distinguish between 'exempt' cookies and 'non-exempt' cookies: 

**'Exempt' cookies:** These are essential and strictly necessary for the Website to function correctly and allow you to use the different options and services offered.Thus, it can be understood that these cookies are excluded from the scope of Article 22.2 of the LSSI (Law on Information Society Services and Electronic Commerce), and therefore it would not be necessary to inform and obtain the consent for their use. Among the exempt cookies, we distinguish:

"User input" cookies 

User identification or authentication cookies (only session cookies)

User security cookies 

Multimedia content player session cookies 

Load-balancing session cookies 

User interface customisation cookies

Plug-in cookies for exchanging social media content 

**"Non-exempt" cookies:** These are cookies subject to the scope of Article 22.2 of the LSSI-CE (Law on Information Society Services and Electronic Commerce) and therefore,it is obligatory to inform and obtain the consent for their use. This would be any other type of cookie not described as exempt cookies in the preceding paragraph.

**2.1.2. Types of cookies depending on the entity managing them:** Depending on the entity that manages the device or domain from where the cookies are sent, and which processes the data obtained, we can distinguish:

**- Own cookies:** These cookies are those that are sent to the user's terminal from a device or domain managed by the owner/publisher of the Website and from which the service requested by the user is provided.

**- Third party cookies:** Those that are sent to the user's terminal equipment from a computer or domain that is not managed by the owner/publisher of the Website, but by another entity that processes the information obtained through the cookies.  

In the event that the cookies are installed from a device or domain managed by the owner/publisheritself, but the information collected through them is managed by a third party, they cannot be considered to be the publisher's cookies. 

**2.1.3. Types of cookies according to the length of time they remain active: **

**- Session cookies:** This is a type of cookie designed to collect and store data while the user accesses a website.These cookies tend to be used to store information that is only kepttoprovide the service requested by the user on one single occasion.

**- Persistent cookies:** This isa type of cookie in which the data is still stored in the terminal and can be accessed and processed during a period defined by the person responsible for the cookie, which can range from a few minutes to several years.

**2.1.4. Type of cookie according to its purpose:** Depending on the purpose for which the data obtained through the cookies is processed, we can distinguish: 

**- Technical cookies:** These allow the user to navigate a website, platform or application, and use the different options and services available on it, such as controllingtraffic and data communications, identifying the session, accessingrestricted areas,remembering the elements of an order, executing the purchase process of an order, submitting an application for registration or participation in an event, storing content for the broadcasting of videos or sound, or sharing content via social networks.

**- Secure cookies:** These allow you to use security features while browsing.

**- Customisation cookies:** These are cookies that allow users to access the service with certain general characteristics based on a predefined set of criteria in the user's terminal, such as language, the type of browser through which you access the service, the regional configuration from where the service is accessed, etc.

**- Analysis cookies:** These are cookies that allow the person responsible to monitor and analyse the behaviour of the users of the website to which they are linked. The information collected through this type of cookie is used to measure the activity of the websites, application orplatform, and to elaboratenavigation profiles of the users of saidsites, applications and platforms, in order to introduce improvements based onthe analysis of service user'susage data.

**- Advertising cookies:** Those that allow the management, in the most efficient way possible, of the advertising spaces that, where applicable, have beenincorporated into the website, application or platform from which the requested service is provided, based on criteria such as edited content or the frequency with which the ads are displayed. 

**- Behavioural advertising cookies:** These cookies allow the management, in the most efficient way possible, of the advertising spaces that, where applicable, the editor has included on the website, application or platform from which the service is provided. These cookies store information about the user's behaviour, obtained through the continuous observation of their browsing habits, and make it possible to develop a specific user profile and to display related advertising.

**3\. What cookies do we use on our LAUNCHYOO?:**

We use the following cookies:

- **TYPE OF COOKIE: **ANALYTICAL
**ENTITY THAT HANDLES THEM: **Google Analytics
**NAME:** _ga
**PURPOSE: **Used to distinguish unique users by assigning a randomly generated number as a client identifier.
**DURATION: **2 years
**+INFO: **http://www.google.com/intl/en/policies/privacy/

- **TYPE OF COOKIE: **ANALYTICAL
**ENTITY THAT HANDLES THEM: **Google Analytics
**NAME:** _gat
**PURPOSE: **Limits the collection of data on high traffic sites.
**DURATION: **10 minutes
**+INFO: **http://www.google.com/intl/en/policies/privacy/

- **TYPE OF COOKIE: **ANALYTICAL
**ENTITY THAT HANDLES THEM: **Google Analytics
**NAME:** _gid
**PURPOSE: **Stores and updates a unique value for each page visited.
**DURATION: **1 day
**+INFO: **http://www.google.com/intl/en/policies/privacy/


**4\. Information on how to block and/or deactivate cookies:** The user can reject the installation of cookies through the functionalities established in the first layer of information of the Cookies Policy. 

Furthermore, at any time the user can manage the use of cookies through the browser settings installed on their computer. In the following links, information can be foundabout what cookies the user has installed on their computer and how to install, block or delete them from their device: 

Chrome: 

http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647

Explorer:

https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies

Firefox:

http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we

Safari: 

https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES

There are third party tools, available online, that allow users to detect cookies on each website they visit and manage their deactivation, as well as block them, such as Ghostery (https://www.ghostery.com/). 

Web browsers are the tools in charge of storing cookies and the place from which the user may exercise their right to delete or deactivate them. This Website cannot guarantee the correct or incorrect manipulation of cookies by the aforementioned browsers. 

In some cases it is necessary to install cookies so that the browser does not forget the user's decision of notto accept them.

**5\. Purpose of the processing of personal data: **We refer to the information contained in the table of cookies installed on the Website.

**6\. Conservation term: **The information obtained through the installation of cookies is kept for a period of two years.

**7\. Legitimation: **The processing of your data is done on the legal basis of consent.

**8\. Recipients: **The data obtained through the installation of cookies are not transferred to any third party outside PECH NETWORK, unless there is a legal obligation or your consent is expressly requested.

However, due to the use of third party plug-ins on the website, data communication may occur to the third parties specified in the cookie table installed on the Website.

**9\. Rights: **As an interested party, you are entitled to exercise the following rights:             

-   Right to revoke the consent given at any time.
-   Right of ACCESS to your personal data.
-   Right to request the RECTIFICATION of inaccurate data.      
-   Right to request the DELETION of your data, when, among other reasons, the data is no longer necessary for the purposes for which they were collected.         
-   In certain circumstances, you may request the LIMITATION OF THE PROCESSING of your data, in which case we will only keep them for the exercise or defense of claims.         
-   In certain circumstances and for reasons related to your particular situation, you may exercise your OPPOSITION right to the processing of your data. We will stop processing the data, except for imperative legitimate reasons, or the exercise or defense of possible claims.         
-   In certain circumstances and for reasons related to your particular situation, you may request your right to the PORTABILITY of the data. It is a complementary right to the right of access, since it allows obtaining the data collected in a structured format, for common use and for mechanical reading, and can be transmitted directly to another entity upon request of the Interested Party.         

              You may exercise such rights by requesting them by any mean that records your shipment and receipt, clearly expressing your will in that regard and accompanying a copy of your ID and/or any other documentation proving your identity, sending the information to the e-mail address **customercare@launchyoo.com **or to the following postal address:

**PECH Network Services, SLU**

C / Ramón Llull, 31, 4, 11,

46021, Valencia (Spain)

In addition, in case you consider that any of your rights have been violated, as an interested party you are entitled to file a claim with the Spanish Agency for Data Protection (AEPD), located at C/ Jorge Juan, 6, 28001-Madrid https://www.agpd.es/ or through the electronic headquarters of the AEPD: https://sedeagpd.gob.es/sede-electronica-web/ .

**Latest Version:June 2020**
`;









const esText = `
**Política de Cookies de Launchyoo**

**1\. Identificación del Responsable de Tratamiento:** La responsable del tratamiento de los datos recabados a través de la plataforma de Launchyoo (en adelante Launchyoo o la Plataforma, indistintamente) es PECH Network Services, S.L.U, C/ Ramón Llull, 31, 4, 11, 46021, Valencia (España), Inscrita en el Registro Mercantil de Valencia, al Tomo 10.642, Libro 7923, Folio 96, Hoja V- 186.754.En adelante, PECH NETWORK.

Puedes contactar con PECH NETWORK en relación con esta política de cookies en la dirección de correo electrónico: customercare@launchyoo.com

**2\. ¿Qué son las cookies?**: Una cookie es un dispositivo o pequeño archivo que el servidor de este Sitio Web envía al navegador del terminal (ordenador, *smartphone, tablet,* etc.) desde el que te conectas, con la finalidad de almacenar información sobre la navegación que efectúas desde dicho terminal, que podrá, en su caso, ser recuperada con posterioridad. 

**2.1. ¿Qué tipos de cookies existen?:** Existen diferentes categorías de cookies, pero una misma cookie puede estar incluida en más de una categoría. 

**2.1.1. Tipos de cookies afectadas por la norma de aplicación:**

Podemos distinguir entre cookies "exceptuadas" y "no exceptuadas":

**Cookies "exceptuadas":** Serían imprescindibles y estrictamente necesarias para el correcto funcionamiento del Sitio Web y para la utilización de las diferentes opciones y servicios que ofrece. Así pues, puede entenderse que estas cookies quedan excluidas del ámbito de aplicación del artículo 22.2 de la LSSI, y por lo tanto, no sería necesario informar ni obtener el consentimiento sobre su uso. Entre las cookies exceptuadas podemos distinguir:

Cookies de "entrada del usuario"

Cookies de autenticación o identificación de usuario (únicamente de sesión) 

Cookies de seguridad del usuario

Cookies de sesión de reproductor multimedia

Cookies de sesión para equilibrar la carga

Cookies de personalización de la interfaz de usuario

Cookies de complemento (plug-in) para intercambiar contenidos sociales.

**Cookies "no exceptuadas":** Serían aquellas sujetas al ámbito de aplicación del artículo 22.2 de la LSSI-CE y por lo tanto es preceptivo informar de su utilización y obtener el consentimiento del usuario sobre su uso. Se trataría de cualquier otro tipo de cookies no descritas como cookies exceptuadas en el apartado anterior.

**2.1.2. Tipos de cookies según la entidad que las gestione:** Según quien sea la entidad que gestione el equipo o dominio desde donde se envían las cookies y trate los datos que se obtengan, podemos distinguir:

**- Cookies propias:** Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el titular/editor del Sitio Web y desde el que se presta el servicio solicitado por el usuario.

**- Cookies de tercero:** Son aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el titular/editor del Sitio Web, sino por otra entidad que trata los datos obtenidos través de las cookies.

En caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio titular/editor del Sitio Web pero la información que se recoja mediante éstas sea gestionada por un tercero, no pueden ser consideradas como cookies propias.

**2.1.3. Tipos de cookie según el plazo de tiempo que permanecen activadas:**

**- Cookies de sesión:** Son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión.

**- Cookies persistentes:** Son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.

**2.1.4. Tipo de cookies según su finalidad:** Según la finalidad para la que se traten los datos obtenidos a través de las cookies, podemos distinguir entre:

**- Cookies técnicas:** Son aquéllas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, almacenar contenidos para la difusión de videos o sonido o compartir contenidos a través de redes sociales.

**- Cookies de seguridad:** Aquellas que permiten utilizar elementos de seguridad durante la navegación.

**- Cookies de personalización:** Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.

**- Cookies de análisis:** Son aquéllas que permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.

**- Cookies publicitarias:** Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.

**- Cookies de publicidad comportamental:** Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función de este.

**3\. ¿Qué cookies utilizamos en LAUNCHYOO?:**

Utilizamos las siguientes cookies:

- **TIPO DE COOKIES: **ANALÍTICAS
**ENTIDAD QUE LAS GESTIONA: **Google Analytics
**NAME:** _ga
**FINALIDAD: **Used to distinguish unique users by assigning a randomly generated number as a client identifier.
**PERSISTENCIA: **2 años
**+INFO: **http://www.google.com/intl/en/policies/privacy/

- **TIPO DE COOKIES: **ANALÍTICAS
**ENTIDAD QUE LAS GESTIONA: **Google Analytics
**NAME:** _gat
**FINALIDAD: **Limits the collection of data on high traffic sites.
**PERSISTENCIA: **10 minutos
**+INFO: **http://www.google.com/intl/en/policies/privacy/

- **TIPO DE COOKIES: **ANALÍTICAS
**ENTIDAD QUE LAS GESTIONA: **Google Analytics
**NAME:** _gid
**FINALIDAD: **Stores and updates a unique value for each page visited.
**PERSISTENCIA: **1 día
**+INFO: **http://www.google.com/intl/en/policies/privacy/

** 4\. Información sobre cómo configurar, desactivar y/o bloquear las cookies:**

El usuario, podrá rechazar y/o configurar la instalación de cookies a través de las funcionalidades establecidas en la primera capa de información sobre el uso de cookies.

Además, el usuario podrá -en cualquier momento- gestionar el uso de cookies a través del navegador instalado en su terminal. En los enlaces siguientes puede encontrarse información sobre qué cookies tiene el usuario instaladas en su navegador, permitir su instalación, bloquearlas o eliminarlas de su equipo: 

Chrome, desde: 

http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647

Explorer, desde:

https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies

Firefox, desde:

http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-we

Safari, desde: 

https://support.apple.com/kb/PH19214?viewlocale=es_ES&locale=es_ES

Opera, desde: http://help.opera.com/Windows/11.50/es-ES/cookies.html

Existen herramientas de terceros, disponibles on line, que permiten a los usuarios detectar las cookies en cada sitio web que visita y gestionar su bloqueo, como por ejemplo Ghostery (https://www.ghostery.com/). 

Los navegadores web son las herramientas encargadas de almacenar las cookies y desde este lugar el usuario puede ejercitar su derecho a eliminación o desactivación de éstas. Este Sitio Web no puede garantizar la correcta o incorrecta manipulación de las cookies por parte de los mencionados navegadores.

En algunos casos es necesario instalar cookies para que el navegador no olvide la decisión del usuario de no aceptación de éstas.

**5\. Finalidad del tratamiento de los datos personales:** Nos remitimos a la información que consta en la tabla de cookies que instala el Sitio Web.

**6\. Plazo de conservación:** La información obtenida a través de la instalación de cookies se conserva por un plazo de dos años.

**7\. Legitimación:** El tratamiento de tus datos se realiza sobre la base jurídica del consentimiento.

**8\. Destinatarios:** Los datos obtenidos a través de la instalación de cookies no se ceden a ningún tercero ajeno a PECH NETWORK, salvo que exista una obligación legal o que se solicite tu consentimiento de forma expresa. 

No obstante por la utilización de plugins y complementos de terceros en el sitio web, puede producirse una comunicación de datos a los terceros especificados en la tabla de cookies que instala el Sitio Web.

**9\. Derechos:** Como interesado estás facultado para ejercitar los siguientes derechos:

- Derecho a revocar en cualquier momento el consentimiento prestado.

- Derecho de ACCESO a tus datos personales.

-   Derecho de solicitar la RECTIFICACIÓN de los datos inexactos.
-   Derecho de solicitar la SUPRESIÓN de tus datos, cuando, entre otros motivos, los datos ya no sean necesarios para los fines por los que fueron recogidos.
-   En determinadas circunstancias, podrás solicitar la LIMITACIÓN DEL TRATAMIENTO de tus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
-   En determinadas circunstancias y por motivos relacionados con tu situación particular, podrás ejercitar tu derecho de OPOSICIÓN al tratamiento de tus datos. Dejaremos de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
-   En determinadas circunstancias y por motivos relacionados con tu situación particular, podrá solicitar su derecho a la PORTABILIDAD de los datos. Se trata de un derecho complementario al derecho de acceso, ya que permite obtener los datos recabados en un formato estructurado, de uso común y de lectura mecánica, pudiendo ser transmitidos de forma directa a otra entidad previa petición del Interesado.

Podrás ejercer tales derechos solicitando por cualquier medio que deje constancia de su envío y de su recepción, expresando claramente tu voluntad en tal sentido y acompañando copia del DNI y/o cualquier otra documentación acreditativa de tu identidad, dirigiéndote a la dirección de correo electrónico **customercare@launchyoo.com** o a la siguiente dirección postal:

**PECH Network Services, S.L.U.**

C/ Ramón Llull, 31, 4, 11,

46021, Valencia (España)

Además, en caso de que consideres que se ha vulnerado cualquiera de tus derechos, como interesado, estás facultado para presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD), sita en C/ Jorge Juan, 6, 28001-Madrid https://www.agpd.es/ o a través de la sede electrónica de la AEPD: https://sedeagpd.gob.es/sede-electronica-web/.

**Última versión: Junio 2020**
`;











const ptText = enText;