import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

//import Registerform from "../../components/auth/Registerform";
import Registerform from "../../components/auth/Registerform_v2";

import i18n from "../../i18n";

const mapStateToProps = state => {
  return { user: state.user, language: state.language, redirect: state.utility.redirect };
};


const ConnectedRegister = ({ user, language, redirect }) => (
	<div className="login">
		{ (user.auth === true) ? (<Redirect to='/' />) : (<Registerform lng={language} />) }
		{ ( typeof redirect !== "undefined" && redirect !== false) ? (<Redirect to='/activate' />) : ('') }
	</div>
);


const Register = connect(mapStateToProps)(ConnectedRegister);
export default Register;

