import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import i18n from "../../i18n";

import { toast } from 'react-toastify';

import ProfileCover from "./components/profilecover";
import Avatar from "./components/avatar";
import Feel from "./components/feel";
import ProfileInfo from "./components/profileinfo_v2";

import {store} from './../../store';
import endpoint from "../../config/endpoint";
import axios from "axios";

import Moment from 'react-moment';

import Streampreview from './components/stream_preview_v2';
import Collage from './components/collage_v2';

import FsLightbox from 'fslightbox-react';

const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


class ConnectedProfile extends Component {

	constructor(props) {
		super(props);

		this.state = {
			covers 				: '',
			own					: '',
			avatar 				: '',
			friendship 			: '',
			following 			: '',
			userProfileSettings : [],
			profileFeel			: {},
			profileId 			: false,
			profileUsername 	: false,
			profileInfo 		: false,
			loaded				: false,
			nameEditing			: false,
			newName				: false,
			lightboxVisible		: false
		}

	    this.handleUpdate 	= this.handleUpdate.bind(this);
	    this.addFriend 		= this.addFriend.bind(this);

	    this.nameInput 				= React.createRef();

	    this.store = store.getState();

	}

	handleUpdate(){

		if (this.props.match.params.user){
		    this.setState( { own: false } )
			let profileEndpoint = endpoint.users+'/profilegetdata/'+this.props.match.params.user;
			this.profileInit(profileEndpoint);
		}else{
		    this.setState( { own: true } )
			let profileEndpoint = endpoint.users+'/profilegetdata/';
			this.profileInit(profileEndpoint);
		}

	}	

	componentDidMount(){

		this.handleUpdate();

	}


  componentDidUpdate(prevProps) {

    if (this.props.location !== prevProps.location) {
		this.handleUpdate();
    }
  }

	profileInit(profileEndpoint){

		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(profileEndpoint, cfg)
			.then((result) => {
		    	//console.log('QUIIIII',result);
		    	this.setState( { covers: result.data.covers, avatar : result.data.avatar, friendship: result.data.friendship, following: result.data.following, profileId: result.data.profileId, profileUsername: result.data.profileUsername, profileFeel: result.data.profileFeel, profileInfo: result.data.profileInfo, loaded: true, newName: result.data.profileInfo.name, followingsCount: result.data.followingsCount, followersCount: result.data.followersCount, friendsCount: result.data.friendsCount, userProfileSettings: result.data.userProfileSettings  } );
			})
			.catch((err) => {
			  	if( typeof err.response !== 'undefined'){
			  		if (err.response.data.errorMessage == -1 ){
				  		this.setState({profileId: -1, loaded: true}); 
			  		}
			  	}
			})

	}


	addFriend( friendId ){

			const reqBody = { friendId: friendId };
			const cfg = { 
				headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
		    };

			axios.post(endpoint.users+'/addfriend', reqBody, cfg)
				.then((result) => {
					this.handleUpdate();
				})
				.catch((err) => {
				})	

	}

	addFollowing( followingId ){

			const reqBody = { followingId: followingId };
			const cfg = { 
				headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
		    };

			axios.post(endpoint.users+'/addfollowing', reqBody, cfg)
				.then((result) => {
					this.handleUpdate();
				})
				.catch((err) => {
				})	

	}


	submitInfo(type) {

		let reqBody = {}
		if ( type == 'name' ){
			reqBody = { name: this.state.newName ? this.state.newName : '' };	
		}else{
			return;
		}
		
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.post(endpoint.users+'/profilesetdata', reqBody, cfg)
			.then((result) => {
				toast.dismiss();
			  	toast.success(i18n.t(result.data.success), { autoClose : 3000 } );
		    	this.handleUpdate();
			})
			.catch((err) => {
				toast.dismiss();
			  	//toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 3000 } );
			  	console.log(err);
			})

	}


	render(){
		
		const ownType = this.store.settings.userRow.profile_type;
		const userType = this.state.profileInfo.profile_type;

		const namePrivacy 		= this.state.userProfileSettings.length > 0	? this.state.userProfileSettings[0].visibility_username : 3;
		const contactPrivacy	= this.state.userProfileSettings.length > 0	? this.state.userProfileSettings[0].visibility_contact : 3;

		var friendship = false;
		if ( typeof this.state.friendship !== "undefined"){
			friendship = !this.state.friendship.hasOwnProperty('status') ? false : this.state.friendship.status == 1 ? true : false;			
		}

		console.log('own profile type', ownType, 'user profile type', userType);

		var relationButton = false;

		// FRIEND TO FRIEND
		if ( !this.state.own && this.state.friendship.hasOwnProperty('status') && ownType == 1 && userType == 1){
			if ( this.state.friendship.status == 0 ){
				relationButton = (
								<div className="btn">
									<span className="icon is-small"><i className="fal fa-user"></i></span>
									<span>{ i18n.t("Pending") }</span>
								</div>);
			}else if ( this.state.friendship.status == 1 ){
				relationButton = (
								<div className="btn">
									<span className="icon is-small"><i className="fal fa-user"></i></span>
									<span>{ i18n.t("Friends") } <Moment fromNow ago>{this.state.friendship.date}</Moment></span>
								</div>);
			}else if ( this.state.friendship.status == 2 ){
				relationButton = (
								<div className="btn" onClick={ () => this.addFriend(this.state.profileId) }>
									<span className="icon is-small"><i className="fal fa-user"></i></span>
									<span>{i18n.t("Add to friends")}</span>
								</div>);
			}
		}else if (!this.state.own && !this.state.friendship.hasOwnProperty('status') && ownType == 1 && userType == 1 ) {
				relationButton = (
								<div className="btn" onClick={ () => this.addFriend(this.state.profileId) }>
									<span className="icon is-small"><i className="fad fa-user-plus"></i></span>
									<span>{i18n.t("Add to friends")}</span>
								</div>);
		}


		// FRIEND TO FOLLOW
		if ( !this.state.own && this.state.following.hasOwnProperty('status') && ownType == 1 && ( userType == 2 || userType == 3 || userType == 4 ) ){

				if ( this.state.following.status == 1 ){
					relationButton = (
									<div className="btn">
										<span className="icon is-small"><i className="fad fa-rss"></i></span>
										<span>{i18n.t("Follow from")} <Moment fromNow ago>{this.state.following.date}</Moment></span>
									</div>);
				}
				else if (this.state.following.status !== 1){
					relationButton = (
									<div className="btn" onClick={ () => this.addFollowing(this.state.profileId) }>
										<span className="icon is-small"><i className="fad fa-rss"></i></span>
										<span>{i18n.t("Follow")}</span>
								</div>);
				}
		}else if( !this.state.own && !this.state.following.hasOwnProperty('status') && ownType == 1 && ( userType == 2 || userType == 3 || userType == 4 ) ){
					relationButton = (
									<div className="btn" onClick={ () => this.addFollowing(this.state.profileId) }>
										<span className="icon is-small"><i className="fad fa-rss"></i></span>
										<span>{i18n.t("Follow")}</span>
								</div>);
		}



		// FRIEND TO FOLLOW
		if ( !this.state.own && this.state.following.hasOwnProperty('status') && ownType !== 1 && ( userType == 2 || userType == 3 || userType == 4 ) ){

				if ( this.state.following.status == 1 ){
					relationButton = (
									<div className="btn">
										<span className="icon is-small"><i className="fad fa-rss"></i></span>
										<span>{i18n.t("Follow from")} <Moment fromNow ago>{this.state.following.date}</Moment></span>
									</div>);
				}
				else if (this.state.following.status !== 1){
					relationButton = (
									<div className="btn" onClick={ () => this.addFollowing(this.state.profileId) }>
										<span className="icon is-small"><i className="fad fa-rss"></i></span>
										<span>{i18n.t("Follow")}</span>
								</div>);
				}
		}else if( !this.state.own && !this.state.following.hasOwnProperty('status') && ownType !== 1 && ( userType == 2 || userType == 3 || userType == 4 ) ){
					relationButton = (
									<div className="btn" onClick={ () => this.addFollowing(this.state.profileId) }>
										<span className="icon is-small"><i className="fad fa-rss"></i></span>
										<span>{i18n.t("Follow")}</span>
								</div>);
		}


		return(
			<div className="profileV2">

				<div className="sx sidebar"></div>

				{ this.state.loaded && this.state.profileId !== -1 && this.state.profileId ?
				
					<div className="profile-content">

						<div className="profile-cover-wrapper">
							<ProfileCover covers={this.state.covers} own={this.state.own} handleUpdate={this.handleUpdate}  lightboxVisible={ () => this.setState({lightboxVisible: !this.state.lightboxVisible}) } /> 
						</div>

						<div className="profile-avatar-wrapper">
							<Avatar avatar={this.state.avatar} profileFeel={this.state.profileFeel} own={this.state.own} handleUpdate={this.handleUpdate} lightboxVisible={ () => this.setState({lightboxVisible: !this.state.lightboxVisible}) } />
							<div className="username">
								<span>{this.state.profileUsername}</span>
								<Feel profileFeel={this.state.profileFeel} own={this.state.own} handleUpdate={this.handleUpdate} />
							</div>

							<div className="name-type">
								{ (this.state.profileInfo.name !== null || this.state.own ) || (this.state.profileInfo.name !== null && namePrivacy == 3 ) || ( this.state.profileInfo.name !== null && namePrivacy > 0 && friendship) || (this.state.profileInfo.name !== null && this.state.own) ? 
									<div className="name">
										{ !this.state.nameEditing ? <span>{this.state.profileInfo.name}</span> : <input className="editable" type="text" id="newName" ref={this.nameInput} onChange={ (event)=>this.setState({ [event.target.id]: event.target.value }) } value={this.state.newName} />}
										{this.state.own ? 
											!this.state.nameEditing ?
											<i className="fal fa-edit editIcon" onClick={ ()=>{this.setState({nameEditing: true}, ()=> { this.nameInput.current.focus()} )} }></i>
											:
											<i className="fal fa-paper-plane editIconSave" onClick={ ()=>{this.setState({nameEditing: false}, this.submitInfo('name'))} }></i>											
										:  
										null
									}
										<span className="sep">|</span>
									</div>
									:
									null	
								}			
								<div className="type">{this.state.profileInfo.profile_type == 4 ? i18n.t('Association') : this.state.profileInfo.profile_type == 3 ? i18n.t('Business') : this.state.profileInfo.profile_type == 2 ? i18n.t('Influencer') : i18n.t("User")}</div>
							</div>

						</div>

						{ 
							relationButton ?
								<div className="profile-relation">
								        {relationButton}
								</div>							
							:
							null
						}

						<div className="profileCounter">
							{ this.state.friendsCount && this.state.profileInfo.profile_type == 1 ?
								<div className="counter">
									<span className="num">{this.state.friendsCount.count}</span>
									<span className="val">friends</span>
								</div>
								:
								null
							}
							{ this.state.followingsCount && this.state.profileInfo.profile_type == 1 ?
								<div className="counter">
									<span className="num">{this.state.followingsCount.count}</span>
									<span className="val">following</span>
								</div>
								:
								null
							}
							{ this.state.followersCount && this.state.profileInfo.profile_type !== 1 ?
								<div className="counter">
									<span className="num">{this.state.followersCount.count}</span>
									<span className="val">followers</span>
								</div>
								:
								null
							}
						</div>


						<div className="profile-info-wrapper">						
							<ProfileInfo profileInfo={this.state.profileInfo} own={this.state.own} userProfileSettings={this.state.userProfileSettings} handleUpdate={this.handleUpdate} /> 
						</div>

						<Streampreview paramsUser={this.props.match.params.user} />
						<Collage paramsUser={this.props.match.params.user} />

					</div>
					:
					this.state.loaded ?
					<div className="profile-content"><span className="userNotFound">{ i18n.t("User not found") }</span></div>
					:
					<div className="profile-content"><span className="userNotFound"><i className="fal fa-spinner-third fa-spin"></i></span></div>

				}

				<div className="dx sidebar"></div>


				<FsLightbox
		          toggler={this.state.lightboxVisible}
		          sources={[
		            this.state.avatar ? endpoint.mediaRoot+'/'+this.state.avatar.media_url : endpoint.mediaRoot+'/'+this.state.placeholder,
		            this.state.covers ? endpoint.mediaRoot+'/'+this.state.covers[0].media_url : endpoint.mediaRoot+'/'+this.state.placeholder,
		          ]}
		          type="image"
		        />

			</div>
		);
	}
}



const Profile = connect(mapStateToProps)(ConnectedProfile);
export default Profile;




