import React, { Component } from 'react';
import './App.css';

import { connect } from "react-redux";
//import store from "./store/index";
import {store} from "./store/index";

import { BrowserRouter as Router } from 'react-router-dom';

//import Head from "./template-parts/Head";
//import Foot from "./template-parts/Foot";
import Head from "./template-parts/Head_v2";
import Foot from "./template-parts/Foot_v2";
import Container from './template-parts/Container';
import Loading from './template-parts/Loading';

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

import { initConfig } from "./actions/index";

import { Redirect } from "react-router-dom";


const mapStateToProps = state => {
  return { language: state.language, user: state.user, settings: state.settings};
};

function mapDispatchToProps(dispatch) {
  return {
    initConfig: () => dispatch(initConfig())
  };
}


class ConnectedApp extends Component {

  constructor(props) {
    super();
    props.initConfig();
  }

  componentDidMount(){
    //this.props.initConfig();
  }


  render() {

    if ( this.props.user.auth == true && this.props.user.token !== false ){
      // Enable if some problems happen
      //this.props.initConfig();
    }

    i18n.changeLanguage(this.props.language);

    /*
    let bkgColor = '#FFFFFF';

    if ( this.props.settings.userSettings.length > 0){        
        let ui = this.props.settings.userSettings[0];        
        let isColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_bkg_value);

        if (isColor){
          bkgColor = ui.ui_bkg_value;
        }
    }


    let appStyle = {backgroundColor: bkgColor}
    */


    return (
      <I18nextProvider i18n={i18n}>
        <Router>
            <div className="App">
              <Head/>
              <div className="Container">
                <Container/>
              </div>
              {/* <Foot/> */}
              <Loading/>
              <ToastContainer position="bottom-right" />
            </div>
        </Router>
      </I18nextProvider>
    );
  }
}


const App = connect(mapStateToProps, mapDispatchToProps)(ConnectedApp);
export default App;
