import React, { Component } from 'react';


import i18n from "../../i18n";

import endpoint from "../../config/endpoint";
import axios from "axios";

import {store} from './../../store';

import FilterableTable from 'react-filterable-table';
import 'react-filterable-table/dist/style.css';

import { Link, Redirect } from "react-router-dom";

import Moment from 'react-moment';
import Modal from 'react-responsive-modal';
import Textarea from 'react-textarea-autosize';
import { toast } from 'react-toastify';


class Tickets extends Component {

	constructor(props) {
		super(props);
	
		this.getTickets 				= this.getTickets.bind(this);
		this.onOpenModal 			= this.onOpenModal.bind(this);
		this.onCloseModal 			= this.onCloseModal.bind(this);
		this.handleOptionsChange 	= this.handleOptionsChange.bind(this);
	    this.handleModalTextChange  = this.handleModalTextChange.bind(this);

	    this.store = store.getState();

		this.state = {
			theAdmin: false,
			theTickets: [],
			modalIsOpen: false,
			modalOptions: false,
			modalText: false
		};

	}

	componentWillMount(){
		this.getTickets();
	}

	getTickets(){
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(endpoint.admin+'/tickets/get', cfg)
  		.then(res => {
  			this.setState({
          theTickets: res.data.theTickets || false
			})
	  	}) 

	}

    onOpenModal(options){
        this.setState({
            modalIsOpen: 	true,
            modalOptions: 	options,
            modaltext: false,
        });
    };

    onCloseModal(){
        this.setState({
            modalIsOpen: 	false,
            modalOptions: 	false,
            modaltext: 		false
        });
    };


    handleOptionsChange(newOption){
        this.setState({
            modalOptions: { ...this.state.modalOptions, status: newOption }
        });
    };

	handleModalTextChange( event ){
	  	this.setState({ modalText : event.target.value });
	}




  render() {


	if ( !this.store.user.auth ){
      return (<Redirect to={"/login/"}>login</Redirect>);
  	}  	

  	const _this = this;

	// Fields to show in the table, and what object properties in the data they bind to
	const fields = [
		{ name: 'id', displayName: "Id", inputFilterable: true, sortable: true },
		{ name: 'subject', displayName: "Subject", inputFilterable: false, exactFilterable: false, sortable: false },
		{ name: 'content', displayName: "Content", inputFilterable: false, exactFilterable: false, sortable: false },
		{ name: 'user_id', displayName: "User", inputFilterable: true, exactFilterable: true, sortable: true, render: renderAuthorLink },
		{ name: 'date', displayName: "Date", inputFilterable: false, exactFilterable: false, sortable: false, render: renderDate },
	];

	function renderPostLink(props) {	 
	    return ( <span title={props.value}><Link to={"/admin/post/"+props.value}><i className="fa fa-link"></i></Link> {props.value} </span>);
	};

	function renderAuthorLink(props) {	 
	    return ( <span title={props.value}><Link to={"/admin/profile/"+props.record.username}><i className="fa fa-link"></i></Link> {props.value} </span>);
	};

	function renderDate(props) {
	    return ( <span title={props.value}><Moment format="D MMM YYYY HH:mm" withTitle>{props.value}</Moment></span>);
	};


    return (
      <div className="admin flags">

   		<div className="flagsTable">
			<FilterableTable
				namespace="flagFilterableTable"
				initialSort="id"
				initialSortDir={false}
				data={this.state.theTickets}
				fields={fields}
				noRecordsMessage="There are no people to display"
				noFilteredRecordsMessage="No people match your filters!"
			/>
		</div>



			<Modal open={this.state.modalIsOpen} onClose={this.onCloseModal} classNames={{ modal: "postActionModal" }} center  showCloseIcon={false}>

    			<div className="shareNote flag">
    				<div className="theHead">{i18n.t('Moderate posts')} - {i18n.t('Set new post status')}<i className="fal fa-times" onClick={this.onCloseModal}></i></div>
    				<div></div>
		    		<div className="typeChoice">
		    			<div className={this.state.modalOptions.status == 'investigated' 	? 'active' : null } onClick={ () => this.handleOptionsChange('investigated')}><i className="fad fa-search"></i>{i18n.t('Investigated')}</div>
		    			<div className={this.state.modalOptions.status == 'suspended' 		? 'active' : null } onClick={ () => this.handleOptionsChange('suspended') 	}><i className="fad fa-pause-circle"></i>{i18n.t('Suspended')}</div>
		    			<div className={this.state.modalOptions.status == 'deleted' 		? 'active' : null } onClick={ () => this.handleOptionsChange('deleted') 	}><i className="fad fa-trash-alt"></i>{i18n.t('Deleted')}</div>
		    			<div className={this.state.modalOptions.status == 'published' 		? 'active' : null } onClick={ () => this.handleOptionsChange('published')   }><i className="fad fa-play-circle"></i>{i18n.t('Published')}</div>

		    		</div>
		    		<Textarea minRows={1} maxRows={10} className="input-textarea" placeholder={"Additional information"} value={this.state.modalText ? this.state.modalText : '' } onChange={ this.handleModalTextChange } />
		    		<button className="sendPostButton flag" >{i18n.t('Save moderation')}</button>
    			</div>

			</Modal>

      </div>
    );
  }
}

export default Tickets;

