import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import i18n from "../../i18n";

import { toast } from 'react-toastify';

import ProfileCover from "./components/profilecover";
import Avatar from "./components/avatar";
import Feel from "./components/feel";

import { store } from './../../store';
import endpoint from "../../config/endpoint";
import axios from "axios";

import Moment from 'react-moment';

import Streampreview from './components/guest_stream_preview_v2';

import FsLightbox from 'fslightbox-react';

const mapStateToProps = state => {
	return { user: state.user, language: state.language };
};


class ConnectedProfile extends Component {

	constructor(props) {
		super(props);

		this.state = {
			covers: '',
			own: false,
			avatar: '',
			friendship: '',
			following: '',
			userProfileSettings: [],
			profileFeel: {},
			profileId: false,
			profileUsername: false,
			profileInfo: false,
			loaded: false,
			nameEditing: false,
			newName: false,
			lightboxVisible: false
		}

		this.handleUpdate = this.handleUpdate.bind(this);

		this.nameInput = React.createRef();

		this.store = store.getState();

	}

	handleUpdate() {
		let profileEndpoint = endpoint.guestusers + '/profilegetdata/' + this.props.match.params.user;
		this.profileInit(profileEndpoint);
	}

	componentDidMount() {
		this.handleUpdate();
	}


	componentDidUpdate(prevProps) {

		if (this.props.location !== prevProps.location) {
			this.handleUpdate();
		}
	}

	profileInit(profileEndpoint) {

		//const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } };
		axios.get(profileEndpoint, cfg)
			.then((result) => {
				//console.log('QUIIIII', result);
				this.setState({ covers: result.data.covers, avatar: result.data.avatar, friendship: result.data.friendship, following: result.data.following, profileId: result.data.profileId, profileUsername: result.data.profileUsername, profileFeel: result.data.profileFeel, profileInfo: result.data.profileInfo, loaded: true, newName: result.data.profileInfo.name, followingsCount: result.data.followingsCount, followersCount: result.data.followersCount, friendsCount: result.data.friendsCount, userProfileSettings: result.data.userProfileSettings });
			})
			.catch((err) => {
				if (typeof err.response !== 'undefined') {
					if (err.response.data.errorMessage == -1) {
						this.setState({ profileId: -1, loaded: true });
					}
				}
			})

	}




	render() {


		return (
			<div className="profileV2">

				<div className="sx sidebar"></div>

				{this.state.loaded && this.state.profileId !== -1 && this.state.profileId ?

					<div className="profile-content">

						<div className="profile-cover-wrapper">
							<ProfileCover covers={this.state.covers} own={this.state.own} handleUpdate={this.handleUpdate} lightboxVisible={() => this.setState({ lightboxVisible: !this.state.lightboxVisible })} />
						</div>

						<div className="profile-avatar-wrapper">
							<Avatar avatar={this.state.avatar} profileFeel={this.state.profileFeel} own={this.state.own} handleUpdate={this.handleUpdate} lightboxVisible={() => this.setState({ lightboxVisible: !this.state.lightboxVisible })} />
							<div className="username">
								<span>{this.state.profileUsername}</span>
								<Feel profileFeel={this.state.profileFeel} own={this.state.own} handleUpdate={this.handleUpdate} />
							</div>

							<div className="name-type">
								<div className="type">{this.state.profileInfo.profile_type == 4 ? i18n.t('Association') : this.state.profileInfo.profile_type == 3 ? i18n.t('Business') : this.state.profileInfo.profile_type == 2 ? i18n.t('Influencer') : i18n.t("User")}</div>
							</div>

						</div>


						<div className="profileCounter">
							{this.state.friendsCount && this.state.profileInfo.profile_type == 1 ?
								<div className="counter">
									<span className="num">{this.state.friendsCount.count}</span>
									<span className="val">friends</span>
								</div>
								:
								null
							}
							{this.state.followingsCount && this.state.profileInfo.profile_type == 1 ?
								<div className="counter">
									<span className="num">{this.state.followingsCount.count}</span>
									<span className="val">following</span>
								</div>
								:
								null
							}
							{this.state.followersCount && this.state.profileInfo.profile_type !== 1 ?
								<div className="counter">
									<span className="num">{this.state.followersCount.count}</span>
									<span className="val">followers</span>
								</div>
								:
								null
							}
						</div>


						<div className="profile-info-wrapper">
							<div className="notOwn">
								<div className="profile-info">
									{this.state.profileInfo.bio}
								</div>
							</div>
						</div>

						<Streampreview paramsUser={this.props.match.params.user} />

					</div>
					:
					this.state.loaded ?
						<div className="profile-content"><span className="userNotFound">{i18n.t("User not found")}</span></div>
						:
						<div className="profile-content"><span className="userNotFound"><i className="fal fa-spinner-third fa-spin"></i></span></div>

				}

				<div className="dx sidebar"></div>


				<FsLightbox
					toggler={this.state.lightboxVisible}
					sources={[
						this.state.avatar ? endpoint.mediaRoot + '/' + this.state.avatar.media_url : endpoint.mediaRoot + '/' + this.state.placeholder,
						this.state.covers ? endpoint.mediaRoot + '/' + this.state.covers[0].media_url : endpoint.mediaRoot + '/' + this.state.placeholder,
					]}
					type="image"
				/>

			</div>
		);
	}
}



const Profile = connect(mapStateToProps)(ConnectedProfile);
export default Profile;




