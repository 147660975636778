import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import ChangeLanguage from '../components/ChangeLanguage';

import axios from "axios";

import { store } from './../store';
import endpoint from "../config/endpoint";

import i18n from "../i18n";

import Moment from 'react-moment';

import logo from '../img/logo.png';
import iconWall from '../img/icona_wall.svg';
import iconFriends from '../img/icona_amici.svg';
import iconGroups from '../img/icona_gruppi.svg';
import iconNotify from '../img/notifica.svg';
import iconInfo from '../img/icona_info.svg';
import iconFaq from '../img/FAQ.svg';
import iconMenu from '../img/icona_menu.svg';
import iconSearch from '../img/search.svg';

import CookieConsent from "react-cookie-consent";

const mapStateToProps = state => {
  return { user: state.user, settings: state.settings };
};

class ConnectedHead extends Component {

  constructor() {
    super();

    this.state = {
      toggleActive: false,
      isSearching: false,
      theSearch: false,
      search: '',
      searchFocus: false,
      notification: [],
      notificationOpen: false
    };

    this.store = store.getState();

    this.handleToggle = this.handleToggle.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.liveUpdate = this.liveUpdate.bind(this);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.notificationRead = this.notificationRead.bind(this);

    this.onFocusSearch = this.onFocusSearch.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);

    this.sendPageview = this.sendPageview.bind(this);
    this.configureGA = this.configureGA.bind(this);

  }

  async componentDidUpdate(prevProps) {

    const cookieConsent = this.getCookie('lyAppCookieConsent');

    if (cookieConsent === "true" && typeof window.gtag === "undefined") {
      await this.configureGA(true);
    }
    if (this.props.location !== prevProps.location) {
      //console.log('route changed');
      this.setState((prevState) => {
        return { toggleActive: false, searchFocus: false, notificationOpen: false };
      })

      if (cookieConsent === "true" && typeof window.gtag !== "undefined") {
        this.sendPageview();
      }

      this.toTop();
    }

  }

  async configureGA(sendPageview = false) {

    const script = await document.createElement("script");

    script.src = "https://www.googletagmanager.com/gtag/js?id=UA-148464928-6";
    script.async = true;

    await document.body.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    window.gtag = gtag;
    window.gtag('js', new Date());

    await window.gtag('config', 'UA-148464928-6', { 'anonymize_ip': true });

    if (sendPageview) {
      this.sendPageview();
    }


  }

  sendPageview() {
    var href = window.location.href;
    var path = window.location.pathname;

    //console.log(href, path);

    window.gtag('config', 'UA-148464928-6', {
      'page_location': href,
      'page_path': path
    })

  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  toTop() {
    //console.log('POP?', this.props.history.action);
    if (window.location.href.includes('/login') || window.location.href.includes('/register')) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }



  componentDidMount() {
    this.liveUpdate()
    setInterval(this.liveUpdate, 120000);
  }

  toggleNotification() {
    this.setState((prevState) => {
      if (!prevState.notificationOpen) {
        this.notificationRead();
      }
      return { notificationOpen: !prevState.notificationOpen };
    })

  }


  notificationRead() {

    const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token } };
    axios.get(endpoint.users + '/notificationread/', cfg)
      .then(res => {
        this.liveUpdate();
      })


  }

  liveUpdate() {

    if (this.store.user.token == false) {
      return
    }

    const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token } };
    axios.get(endpoint.users + '/live/', cfg)
      .then(res => {
        this.setState({
          notification: res.data.notification || []
        });
      })


  }

  handleToggle(event) {
    event.preventDefault();
    this.setState((prevState) => {
      return { toggleActive: !prevState.toggleActive };
    })
  }

  handleClose(event) {
    event.preventDefault();
    this.setState((prevState) => {
      return { toggleActive: false };
    })
  }

  onFocusSearch(ev) {
    this.setState({ searchFocus: true })
  };

  onBlurSearch(ev) {
    this.setState({ searchFocus: false })
  };


  onChangeSearch(ev) {
    this.setState({ search: ev.target.value }, () => {
      if (this.state.search.length >= 3) {
        this.onSearch();
      }
    }
    );

  };

  onSearch() {

    const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token } };

    let searchTerm = this.state.search.replace("#", "_");

    this.setState({ isSearching: true });

    axios.get(endpoint.posts + '/search/' + searchTerm, cfg)
      .then(res => {
        this.setState({
          theSearch: res.data.theSearch || []
        });
        this.setState({ isSearching: false });
      })
      .catch(err => {
        this.setState({ theSearch: false, isSearching: false });
      })

  };


  render() {

    //console.log("this.store.settings.avatar", this.store.settings.avatar);

    var notificationCount = 0;
    const notification = (
      <div className={this.state.notificationOpen ? 'notificationV2 open' : 'notificationV2'}>
        {
          (this.state.notification || []).map((notify, i) => {
            if (notify.hasOwnProperty("status")) {
              if (notify.status == 0) {
                notificationCount++;
              }
            }
            return (
              <div key={"notify-" + i} className={"notify " + (notify.status == 0 ? "new" : "opened")}>
                <Link to={'/' + notify.target || "#"} onClick={this.toggleNotification}>
                  <div className="image">{notify.image ? (<img src={endpoint.mediaRoot + '/' + notify.image} />) : (<i className="fas fa-bells"></i>)} </div>
                  <div className="text"><div>{notify.text}</div> <div className="date"><Moment fromNow ago>{notify.date}</Moment></div></div>
                </Link>
              </div>
            )
          })
        }
      </div>
    );

    const profileMenuState = this.state.toggleActive === true ? 'active' : 'inactive';
    const profileMenu = (
      <div className={'profile ' + profileMenuState}>
        <div className="overlay"></div>
        <div className="inner">
          <ul>
            <a href="#" onClick={this.handleClose} className="close"><i className="fal fa-times fa-duo"></i></a>
            <li><Link to="/profile">{i18n.t("Profile")}<i className="fal fa-eye fa-duo"></i></Link></li>
            <li><Link to="/friends">{i18n.t("Friends")}<i className="fal fa-user-friends"></i></Link></li>
            <li><Link to="/groups">{i18n.t("Groups")}<i className="fal fa-users"></i></Link></li>
            <li><Link to="/settings">{i18n.t("Settings")}<i className="fal fa-cog fa-duo"></i></Link></li>
            <li><Link to="/logout">{i18n.t("Logout")}<i className="fal fa-sign-out-alt fa-duo"></i></Link></li>
            <li><ChangeLanguage /></li>
          </ul>
        </div>
      </div>);

    const notLoggedMenu = (
      <div className={'profile ' + profileMenuState}>
        <div className="overlay" onClick={this.handleClose}></div>
        <div className="inner">
          <ul>
            <a href="#" onClick={this.handleClose} className="close"><i className="fal fa-times fa-duo"></i></a>
            <li><Link to="/login">{i18n.t("Login")}<i className="fad fa-sign-in-alt fa-duo"></i></Link></li>
            <li><Link to="/register">{i18n.t("Sign up")}<i className="fad fa-bullseye-pointer"></i></Link></li>
            <li><ChangeLanguage /></li>
          </ul>
        </div>
      </div>);


    return (
      <header className="headerV2">
        {(this.props.user.auth === true) ?
          <div className="logged">
            <h1 className="headLogo"><Link to="/"><img src={logo} alt="LaunchYoo" /></Link></h1>
            <div className="headSearch"><input type="text" placeholder="Search" id="footer-search" value={this.state.search} onChange={this.onChangeSearch} onFocus={this.onFocusSearch} /><div className={this.state.search.length > 3 ? "iconSearch hide" : "iconSearch"}><img src={iconSearch} /></div></div>
            <div className="headHomeLinks">
              <div className="headUser">
                <Link to="/profile">
                  {(this.props.settings.avatar !== false && typeof this.props.settings.avatar !== "undefined") || this.props.settings.avatar.length > 0 ?
                    <img className="avatar" src={endpoint.mediaRoot + '/' + this.props.settings.avatar.media_url} />
                    :
                    <img className="avatar" src={endpoint.mediaRoot + '/placeholder-avatar.jpg'} />
                  }
                  <div className="usernameAndType">
                    <span className="username">{this.props.settings.userRow.username}</span>
                    <span className="type">{this.props.settings.userRow.profile_type == 2 ? i18n.t("Influencer") : this.props.settings.userRow.profile_type == 3 ? i18n.t("Business") : this.props.settings.userRow.profile_type == 4 ? i18n.t("Association") : i18n.t("User")}</span>
                  </div>
                </Link>
              </div>
              <div className="sep"></div>
              <div className="headHomeLink">
                <Link to="/">
                  <img src={iconWall} alt="Wall" />
                  <span>Home</span>
                </Link>
              </div>
              <div className="sep"></div>
              <div className="headIcon noMobile">
                <Link to="/friends">
                  <img src={iconFriends} alt="Friends" />
                </Link>
              </div>
              <div className="headIcon noMobile">
                <Link to="/groups">
                  <img src={iconGroups} alt="Groups" />
                </Link>
              </div>
              <div className="sep"></div>
              <div className="headIcon notificationPanelV2" onClick={this.toggleNotification}>
                <img src={iconNotify} alt="Notification" />
                {notificationCount > 0 ? notificationCount > 9 ? <div className="counter">9+</div> : <div className="counter">{notificationCount}</div> : null}
                {notification}
              </div>
              <div className="headIcon" onClick={this.handleToggle}>
                <img src={iconMenu} alt="Menu" />
              </div>
            </div>
          </div>
          :
          <div className="notLogged">
            <h1 className="headLogo"><Link to="/"><img src={logo} alt="LaunchYoo" /></Link></h1>
            <div className="headSearch"></div>
            <div className="headHomeLinks">
              <div className="headHomeLink">
                <Link to="/login">
                  <span className="fad fa-sign-in-alt" />
                </Link>
              </div>
              <div className="sep"></div>
              <div className="headHomeLink">
                <Link to="/register">
                  <span className="fad fa-bullseye-pointer" />
                </Link>
              </div>          
              <div className="sep"></div>
              <div className="headIcon" onClick={this.handleToggle}>
                <img src={iconMenu} alt="Menu" />
              </div>
            </div>
          </div>
        }
        <ul className="main-nav">
          {(this.props.user.auth === true) ? null : (<li><Link to="/login"><i className="fal fa-sign-in-alt"></i></Link></li>)}
          <li>{(this.props.user.auth === true) ?
            (

              <div className="profileWrapper">
                <a href="#" className="profileToggle" onClick={this.handleToggle}>
                  <i className="fal fa-ellipsis-v fa-duo"></i>
                </a>
                {profileMenu}
              </div>

            ) : (

              <div className="profileWrapper">
                <a href="#" className="profileToggle" onClick={this.handleToggle}>
                  <i className="fal fa-ellipsis-v fa-duo"></i>
                </a>
                {notLoggedMenu}
              </div>

            )
          }</li>
          { /* this.props.user.auth === true  && this.store.settings.avatar !== false && typeof this.store.settings.avatar !== "undefined" || this.store.settings.avatar.length == 0 ? (<li><Link to="/profile"><img className="avatar" src={endpoint.mediaRoot+'/'+this.props.settings.avatar.media_url} /></Link></li>) : null */}
          {this.props.user.auth === true && this.props.settings.avatar !== false && typeof this.props.settings.avatar !== "undefined" || this.props.settings.avatar.length == 0 ? (<li><Link to="/profile"><img className="avatar" src={endpoint.mediaRoot + '/' + this.props.settings.avatar.media_url} /></Link></li>) : null}

        </ul>

        {this.state.theSearch.length && this.state.searchFocus > 0 ?

          <div className="searchResultsV2">
            {
              this.state.theSearch.map((search, i) => {
                let searchPath = search.type == 'users' ? '/profile/' + search.slug : search.type == 'tag' ? '/tag/' + search.slug : search.type == 'group' ? '/group/' + search.slug : false;
                let searchIcon = search.type == 'users' ? 'user' : search.type == 'tag' ? 'tag' : search.type == 'group' ? 'group' : 'search';
                return (
                  <div key={'search-' + i} className="item">
                    <Link to={searchPath}>
                      <div className="first">
                        <i className={'fal fa-' + searchIcon}></i>
                        <span>{search.display ? search.display : search.slug}</span>
                      </div>
                      <div className="second">
                        <span>{search.type == 'users' ? '@' : search.type == 'tag' ? '#' : search.type == 'group' ? '&' : null}{search.slug}</span>
                      </div>
                    </Link>
                  </div>
                )
              })
            }
            <i className="fal fa-times-circle searchClose" onClick={() => { this.setState({ searchFocus: false }) }}></i>
          </div>

          :
          null
        }

        <CookieConsent
          location="bottom"
          buttonText={i18n.t("Accept")}
          declineButtonText={i18n.t("Reject")}
          cookieName="lyAppCookieConsent"
          style={{ background: "#005C9DEE" }}
          buttonStyle={{ color: "#005C9D", backgroundColor: '#FFFFFF', fontSize: "12px", lineHeight: "15px", borderRadius: "5px", fontWeight: "bold" }}
          declineButtonStyle={{ color: "#005C9D", backgroundColor: '#FFFFFF99', fontSize: "10px", lineHeight: "15px", borderRadius: "5px" }}
          expires={365}
          debug={false}
          enableDeclineButton={true}
          flipButtons={false}
          onAccept={() => this.configureGA(true)}
        >

          <div className="cookieConsentContent">
            <span>{i18n.t("cookies_bar")}</span><br />
            <Link to={'/cookies'}>{i18n.t("cookies_bar_link")}</Link>
          </div>

        </CookieConsent>

      </header>

    );
  }
}

const Head = connect(mapStateToProps)(ConnectedHead);
export default withRouter(Head);


