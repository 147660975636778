import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import Lottie from 'lottie-react-web';
import heartAnimation from './../../img/animations/heart.json';
import thumbUpAnimation from './../../img/animations/thumb-up.json';
import thumbRateUpAnimation from './../../img/animations/thumb-rate-up.json';
import thumbRateDownAnimation from './../../img/animations/thumb-rate-down.json';
import starAnimation from './../../img/animations/star.json';
import commentAnimation from './../../img/animations/comment.json';
import profileAnimation from './../../img/animations/profile.json';
import wallEditorAnimation from './../../img/animations/wall-editor.json';

class GuestModal extends Component {
    constructor(props) {
        super(props)

        this.state = {
        }
    }

    // post/comment
    // post/bookmark
    // post/share
    // -> post/like
    // -> post/love
    // post/stats
    // -> post/up
    // -> post/down
    // -> post/star

    render() {

        let content = { text: i18n.t('guest_modal_generic'), icon: false, animation: profileAnimation, animationClass: 'default' };
        switch (this.props.modalType) {
            case 'post/love':
                content = { text: i18n.t('guest_modal_like_love'), icon: false, animation: heartAnimation, animationClass: 'default' }
                break;
            case 'post/like':
                content = { text: i18n.t('guest_modal_like_love'), icon: false, animation: thumbUpAnimation, animationClass: 'default' }
                break;
            case 'post/up':
                content = { text: i18n.t('guest_modal_binary_star'), icon: false, animation: thumbRateUpAnimation, animationClass: 'default' }
                break;
            case 'post/down':
                content = { text: i18n.t('guest_modal_binary_star'), icon: false, animation: thumbRateDownAnimation, animationClass: 'default' }
                break;
            case 'post/star':
                content = { text: i18n.t('guest_modal_binary_star'), icon: false, animation: starAnimation, animationClass: 'default' }
                break;
            case 'post/comment':
                content = { text: i18n.t('guest_modal_comment'), icon: false, animation: commentAnimation, animationClass: 'default' }
                break;
            case 'wall/editor':
                content = { text: i18n.t('guest_modal_generic'), icon: false, animation: wallEditorAnimation, animationClass: 'wall-editor' }
                break;
            default:
                break;
        }


        return (
            <Modal open={this.props.modalIsOpen} onClose={() => this.props.onClose()} classNames={{ modal: "guestModal" }} center showCloseIcon={false} focusTrapped={false} overlayId="guestModalOverlay" >
                <div className="container">
                    <div className="title"><span className="b">launch</span><span className="r">yoo</span></div>
                    {content.icon && <div className="icon"><i className={content.icon}></i></div>}
                    <div className={"animation "+content.animationClass}>
                        {content.animation && this.props.modalIsOpen &&
                            <Lottie
                                options={{
                                    animationData: content.animation,
                                    loop: false,
                                }}

                                speed={1.5}
                            />}
                    </div>
                    <div className="subtitle">{content.text} {this.props.modalType}</div>
                    <div className="choice">
                        <Link to={"/register/"} className="action up">{i18n.t('Register')} <i className="fad fa-bullseye-pointer"></i></Link>
                        <Link to={"/login/"} className="action in">{i18n.t('Login')} <i className="fad fa-sign-in-alt fa-duo"></i></Link>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default GuestModal