import React, { Component } from "react";

import ReactMarkdown from "react-markdown";

import i18n from "../../i18n";



export default class TermsAndConditions extends Component {

  constructor(props) {
    super(props);
  }


  render(){

    return (
    	<div className="termsExtended">

        { i18n.language == 'es' ?
          <ReactMarkdown source={esText} />          
          :
          i18n.language == 'pt' ?
          <ReactMarkdown source={ptText} />          
          :
          <ReactMarkdown source={enText} />          
        }

    	</div>
    );
  }
}

const enText = `
**Terms and Conditions of Launchyoo**

**1\. Identifying Data of the service provider**

**2\. What is Launchyoo?**

**3\. Registration and profile configuration**

**4\. Use of the Launchyoo platform**

**5\. Intellectual and Industrial Property of the contents of Launchyoo**

**6\. Advertising and promotions on Launchyoo**

**7\. Disclaimer of Warranty and Limitation of Liability**

**8\. Link Policy**

**9\. Right of exclusion**

**10\. Terms of Payment Services on Launchyoo**

**11\. Creating advertising campaigns**

**12\. Update of the Terms and Conditions of Use**

**13\. Applicable Legislation and Jurisdiction**

**14\. Translation of Legal Texts**

------------------------------------------------------

Below are the Terms and Conditions applicable to the use and purchase of the services of this website/app by users who want to use them, and must be accepted if in agreement, through the functionalities provided for this purpose:

**1\. Identifying Data of the service provider**: The owner of the Launchyoo platform (hereinafter, the Platform or Launchyoo) and the provider of information society services is:

PECH Network Services, SLU.

Tax ID Number: B40561185

C / Ramón Llull , 31, 4, 11, 46021, Valencia (Spain )

Registered in the Mercantile Registry of Valencia, Volume 10.642, Book 7923, Folio 96, Sheet V- 186.754.

Contact email address: customercare@launchyoo.com

Hereinafter, "**we**", "**our**" or "**PECHNETWORK**".

   **2\. What is Launchyoo? : **Launchyoo is a social network that you can access via the web or the application, once the registration process has been successfully completed, in order to share content online and be able to interact with other profiles depending on how you configure yours and always in accordance with these Terms and Conditions.

   **2.1. Description of features:**

**Walls: ** Walls are the different areas in which different profiles can interact on Launchyoo.

              You can access the different walls through the tabs provided for this purpose on the Platform. You can set the color of the tab associated to each wall.

              The walls correspond to each type of information that profiles can share

              - Wall of photographs

              - Wall of short messages (MiniMes)

              - Video wall

              - Audio and Podcast wall

              - Blog and News wall

              - Events wall

              - Mixed wall: This allows you to view a combination of the content published on the rest of the Walls.

              Depending on your type of profile, you can carry out the following actions on each wall:

              - **Share content: **  publish content on your walls. The content you share on the walls will be accessible on Internet and indexed by search engines, and will be viewed by the rest of Launchyoo users, as well as any internet user. In no case the latter will have access to your age, gender, Album of files (apart from the profile picture and/or background image) and your real name.

              - **Save publications: ** save any publication to be able to check it at any subsequent date.

              - **Interact with other users' posts: **  indicate “I like it” (like), “I love it” (love), vote through “Stars” or indicate appreciation through “Yes/No”, share or comment on other users' posts.

              - **Report *fake news***

- **Report unsuitable content, ** in the event of a violation of these Terms and Conditions.

              - **Delete ** the content you have shared.

              In addition, there will be **particular features **for each type of Wall depending on its nature:

On the Photo Wall, you can tag any user who appears on it, as long as the tagged person accepts through Launchyoo’s relevant feature. The tag will then be made public.

Any other user may be tagged in texts and this tag will be communicated to them, but their authorisation will not be requested to make it public.

**Configuration of notifications: **  You can configure the notifications of the activity on Launchyoo (if someone names you in a comment, tags you in a photo, publications are shared in a group that you are part of, a profile you follow has published something, etc.). You can select activate to receive these notifications on the application, on your smartphone or by email. You can also deactivate these notifications at any time.

**Creation of events: **  On Launchyoo you can describe an event, the place and time of it and post it on the Wall of Events, so that other users can read this information and indicate if they wish to participate.

**Active socialization: ** Launchyoo can show you suggestions of like-minded people nearby, with similar interests. In order to make use of active socialization, all of the following requirements must be met: You must provide Launchyoo with the minimum number of tastes or interests (personal tags) configured in the system. You must enable geolocation on your device. For this purpose, we will ask for your consent to activate the geolocation of your device. You must select the “distance range” that you want the application to consider, in order to be shown users with similar interests to your own. It is also possible to indicate a range of age and gender.

**Advertising**: On the Walls you will see advertising from third party advertisers, personalized to your tastes and interests.

Under no circumstance will we reveal your identity to advertisers.

We allow advertisers to set up their advertising campaigns on Launchyoo, indicating a target audience to direct the advertisements by age and/or sex, as well as by interests. In addition, we provide advertisers with reports on the performance of their advertisement campaigns, with anonymized information on how Launchyoo users interact with their advertising content. Anonymous information should be understood as a set of aggregate data that under no circumstance identify the user (we do not share data such as your name, surname, email, telephone, personal tags, etc). You can stop seeing advertising on Launchyoo through an annual subscription of the advertisement removal service, in accordance with provisions in these Terms and Conditions. Some of the features may be temporarily inactive. We will let you know when they are ready.

**2.2. How is the social network Launchyoo financed?**

Launchyoo's free services are financed, in addition to PECH NETWORK's own resources:

a) With contracting by third-party advertisers (brands, businesses, companies, organizations, etc) of the advertising services we offer, therefore we will show you publicity from those advertisers or products related to your interests, except if you purchase the annual advertisement removal subscription mentioned above.

We do not sell or communicate your personal data to those third-party advertisers.

b) By users who purchase the profile improvement (upgrade) in accordance with these Terms and Conditions.

**3\. Registration and profile configuration: **  Registration on Launchyoo is only allowed for people over 16 years old. We will guide you at all times during the process of creating your profile.

For clarity assurance and for the purposes of the present conditions, we refer to “USER” (in capital letters) as one of the types of Launchyoo profiles, having “user” (in lower case) the proper meaning of such term

It is forbidden to create fake profiles or ones that impersonate the identity of another person. PECH NETWORK may request the user to provide documentation proving the identity of the profile creator, in order to avoid third parties being harmed.

It is also prohibited for the user to have more than one profile of the same type on Launchyoo. In other words, the user can have a USER profile and any of the BUSINESS, INFLUENCER or ASSOCIATION profiles, if they meet the requirements established for this.

PECH NETWORK reserves the right to block and/or cancel profiles in the event that it detects any breach of the aforementioned prohibitions.

**3.1. Select the type of profile: **  To register with Launchyoo you must access the profile creation menu and select the type of Profile you are interested in. By default, the USER profile is created, but you can choose between a variety of types of profiles depending on your characteristics and desires:

**a) USER Profiles. ** with which you will be able to perform the following actions within Launchyoo:

-   Make as many publications as you want on the wall of short messages (MiniMes)
-   Upload as many photos as you want
-   Upload a limited amount of videos per week
-   Upload a limited amount of audio files per week 
-   Make a limited amount of publications per week on the Blogs & News' wall
-   Create a limited amount of events per week
-   Ask other users for friendship
-   Assign "temperature" to your contacts: cold, moderate or hot (maximum of 50 for the latter) depending on the desired activity with the contact in question.

The system allows you to improve your USER profile, being such improvement subject to an annual payment subscription, choosing amongst any of the upgraded USER profiles that are described next

**VIDEO LOVER user, **  who can carry out the same actions as the USER, but can also upload videos to LAUNCHYOO without a weekly limit. This is a payment service and is subject to an annual subscription.

**AUDIO LOVER user**, you can carry out the same actions as the USER, but can also upload audios to LAUNCHYOO on the Audio&Podcasts wall with no weekly limit. This is a paid service and is subject to an annual subscription.

**EVENT CREATOR user**, which can carry out the same actions as the USER, but can also create new events without a weekly limit. This is a paid service and is subject to an annual subscription.

**BLOGGER user**, who can carry out the same actions as the USER, but can also make publications on the Blog&News wall without any weekly limitation. This is a paid service and is subject to an annual subscription.

**SUPER user**, who can carry out all the USER’s actions, without any quantitative limit.

However, during the first year to count from the date of registration in Launchyoo, you will have free use of all the benefits of the SUPER USER profile. After this period, limits will be set as per the USER profile stated above, and you must therefore pay an annual subscription for any of the other user profiles to enjoy their benefits, if desired.

**AMBASSADORS: **  During the process of profile creation, if a number of registered users have indicated they have registered with Launchyoo following your recommendation, you will receive Ambassador status, which will imply:

- This distinction will appear on your user profile.

- After the free period with SUPER USER benefits (one year from the date of registration on Launchyoo) you can also opt for any other profile higher than USER, free of charge and for one year, (except the SUPER USER, INFLUENCER, BUSINESS and ASSOCIATION profiles).

To become an AMBASSADOR, PECH NETWORK will take into account the activity of the recommended users and under no circumstance will grant this status if it is verified that the users who have made the recommendation (referral) are false or created from the same IP-Address. In any case, AMBASSADOR status will be granted one month after registration of the users that have recommended the profile, and providing they remain active on Launchyoo.

              **b) INFLUENCER profile**, which can carry out all SUPER USER actions without quantitative limitations, with the exception that you cannot ask other users for friendship. However, it will be the users who, where appropriate, decide to follow the INFLUENCER profile. It will not be possible to modify the privacy attributes of the profile. These are public profiles.

**c) BUSINESS profile** is designed for companies and/or professionals. If you want your business or company to be active on Launchyoo, you must register with this type of profile.

The BUSINESS profile will allow you to carry out all the actions of the SUPER USER with the following exceptions:

- You will not be able to ask other users for friendship, but it will be the users who, where appropriate, decide to follow the BUSINESS profile.

- Furthermore, you cannot modify the privacy attributes of your profile. These are public profiles.

d) **ASSOCIATION** **profile** is designed for non-profit organizations or those that develop activities in social networks for social, educational, cultural, dissemination of knowledge, informative and/or equivalent purposes. If your entity meets these requirements, you can register in this profile mode.

The ASSOCIATION profile will allow you to carry out all actions of the SUPER USER with the following exceptions:

- You will not be able to ask other users for friendship, but it will be the users who, where appropriate, will decide to follow the BUSINESS profile.

- Furthermore, you cannot modify the privacy attributes of your profile. These are public profiles.

If you register with a BUSINESS or ASSOCIATION profile, we reserve the right to ask you to certify your status as a professional, company and/or association. If you do not give certification, we could convert your BUSINESS or ASSOCIATION profile into a USER profile.

**3.2. Providing data: **  To complete the registration process you must mandatorily provide, through the form available for that purpose, the following data:

- A *nickname * or alias of your choice (as long as it is available and has not been previously chosen by another registered user).

- An e-mail

All the information you provide us with must be truthful, accurate, complete and updated. You will be held responsible for breaching such requirements when providing this information.

The creation of false profiles is not allowed under any circumstance, notwithstanding the possibility of making public use of the nickname or alias, but the real name and surnames will need to be facilitated on occasion of the registry process.

You will be able to hide your real name from the rest of LAUNCHYOO users or choose who will be able to see it.

You can include a profile picture and a background image, as well as a descriptive text about your tastes or an introductory text (“About me”).

We will also ask you to indicate your tastes and interests in order to personalize your profile. This is what we call personal tags.

During the registration process, you can indicate if you are joining Launchyoo due to the suggestion of a user that has already registered, stating their nickname or real name so we can give them promotional advantages. This is the Ambassador program previously mentioned.

You must also choose a password that you will have to type in duplicate to avoid typing errors. You will be the only person responsible for the custody of your username and password, committing to keep it confidential without distributing it to any third party, and must take the necessary measures to do so. If you suspect or know that your access codes on Launchyoo are being used by a third party, you should change your password and notify PECH NETWORK as soon as possible.

We provide you with information regarding our processing of your personal data, such treatment being legitimized by the corresponding legal bases (execution of a contract or pre-contract, consent or legitimate interest).

We have functionalities so you can give your informed, free, specific and unambiguous consent for the purposes that require it.

All of this is in accordance with our [Privacy Policy](https://app.launchyoo.com/privacy).

Finally, you should read these Terms and Conditions and if you are satisfied with their content, check the corresponding box as proof of having understood and accepted them.

We will send you an email in order to confirm your email address.

**3.3. Profile configuration: **  Once you have successfully completed the registration and activation, you will have access to your profile’s Control Panel, through which you can edit and modify your information.

**Event creation calendar: ** You will have access to the Calendar in which you can publish public or private events depending on the profile you have.

**List of contacts and profiles followed: ** You will have access to the list of contacts and profiles that you follow.

**"How I feel today": ** If you wish, you can complete the “How I feel today” field on your profile with the options offered by the system: Tired, Bored, Happy, Angry, In love, etc. This configuration will last 24 hours from the moment you indicate it.

**Geolocation**: If you wish, you can show the position of your device, as follows:

a) Geolocation through your device's GPS

b) Manual indication on the map

We will always ask for your consent to activate geolocation and you can turn it off at any time through the settings on your device.

There are certain features that will not work correctly if you deactivate geolocation, such as detecting events from other users or the suggestion of contacts that are close to your geolocated position (“active socialization”).

**Contact management:**

The Platform allows groups to be created among registered users on Launchyoo, assigning administrator roles (to be able, for example, to include other members) in accordance with these Terms and Conditions.
There are three types of groups. The type of group cannot be modified once created:

- Public group: Visible and accessible to all.         

- Private group: Visible to all but accessible only if you are invited.         

- Secret group: Not visible and accessible only if invited.         

You can decline being part of any of these groups at any time.

**3.4. Pending configurations and updates: **On the platform, there are specific features or icons that will tell you if any configuration is pending or if there is an update.

If you need to block a user, contact the email address customercare@launchyoo.com

**3.5. Blocking a user. ** If you need to block a user, contact the email address customercare@launchyoo.com.

**3.6.  To cancel the service: ** You can request to unsubscribe as a user of Launchyoo at any time by writing to the email address customercare@launchyoo.com As a result of the cancelation of the service, we will permanently remove the registered user profile and all content and interactions with other users, events created, on Launchyoo. We cannot guarantee that such content will be removed from any other website or from search engines indexing. The request to unsubscribe from the service will under no circumstance entitle you to request repayment of the amounts corresponding to the annual subscriptions.

**4\. Use of the Launchyoo platform: **The use of Launchyoo obliges you to respect the applicable legislation, the rights of third parties and to make proper use of the content and services we offer. It is completely prohibited to use Launchyoo to carry out any of the following actions:

a) To engage in activities contrary to the Law, good faith and public order such as uploading or sharing offensive or slanderous content, which violate intellectual and/or industrial property rights, and that infringe the data protection law, etc.

b) To spread content or propaganda that is xenophobic, pornographic, apologetic of terrorism, which is against Human Rights, is criminal or violates laws protecting minors.

c) To cause damage to PECH NETWORK's physical (hardware) and logical (software) systems, its suppliers or third parties (viruses, system overloads, computer attacks, etc.).

d) To use techniques of crawling, screenscraping, web scraping or any other programming technique or automated means that allow extracting information or data hosted, stored or accessible on the Website without express written authorization from PECH NETWORK.

e) To circumvent the measures set in place to protect the services, damage the systems or make unauthorized access to them, to perform reverse engineering to try to access the source code or algorithms of the system and any other action that aims to damage or interfere with PECH NETWORK's systems and/or applications.

f) To try to access and, where possible, use other users' email accounts and modify or manipulate their messages.

Failure to comply with these obligations will lead to the assumption of responsibility for damages and losses that may apply, and the immediate blocking of the user's profile, without the right to request the return of the amounts paid due to annual subscriptions.

PECH NETWORK reserves the right to inform the competent authority of any violation of the applicable legislation.

**4.1. Obligations and limitations in the publication of content:**

**4.1.1. You must be the holder of the exploitation rights of intellectual property: **To publish or insert content on Launchyoo, you must withhold lawful ownership of all rights to exploit these, which allow reproduction, distribution, public communication -even in the modality of making it available- and transformation, through any electronic means.

**4.1.2. You must have authorization from the people who appear in your publications: **Even if you are the rightful owner of exploitation rights over the content you publish or share on Launchyoo, you must obtain the corresponding authorization and/or permission from the natural persons who appear in these publications, regarding their image rights. This is to avoid generating illegitimate interference in their honor, their personal and family privacy and in their own image or in violation of their data protection rights.

**4.1.3. User specific statement: **By the mere fact of publishing or sharing content on Launchyoo, you specifically declare:

- You hold all the rights of exploitation over such content or you have the corresponding license, authorization and/or permission from the legitimate owner, for its publication and assignment of the rights to third parties.

- You have the appropriate authorization of the natural persons that appear in such content for publication on Launchyoo.

**4.2. Assignment of rights over content: **With the acceptance of these Terms and Conditions, you declare to be the owner of the rights over any content you post or share by any means on Launchyoo. By publishing or sharing content on Launchyoo, you grant PECH NETWORK and Launchyoo users a license for the entire world - free, unpaid, royalty-free, sub-licensable (with PECH NETWORK's right to assign those rights), transferable and not exclusive to host, use, distribute, modify, publish, copy, show or publicly display and transform the content you have published. As well as to create works derived from this, during the legal term of the protection of the rights assigned, for their use within the Launchyoo environment.

You can revoke the license by deleting the content subject to intellectual property that you would have shared on Launchyoo.

However, due to the technical functioning, the complete removal of all generated copies on different servers will occur progressively. If the contents have been shared by third parties, they will remain with respect to those relationships. The rights holder must contact those third parties for the shared content to be deleted.

**4.3. Reservation of the right to remove inappropriate content: **PECH NETWORK reserves the right to remove all content, in any of its forms (text, commentary, photograph, phonogram, audiovisual work , etc.), contrary to the Law, good faith, public order and respect for the dignity of a person. Content which is discriminatory, xenophobic, racist, pornographic or that harms children and adolescents, as well as content considered inappropriate to the characteristics and purposes of the Platform.

Since PECH NETWORK cannot control all the publications by Launchyoo users, it will not assume any responsibility for such content unless it has actual knowledge of the insertion of inappropriate content in the terms established by the application legislation.

**4.4. Use of third-party trademarks by Launchyoo users: **On Launchyoo profiles, it is forbidden to use registered distinctive signs such as trademarks or trade names, of which the user is not a legitimate owner and that constitute an infringement of trademark law and legislation on fair competition.

PECH NETWORK reserves the right to block profiles that make unauthorized use of registered distinctive signs owned by third parties for which there is no proper authorization, as well as remove content that violates trademark or fair competition legislation.

**4\. 5. Reporting content and/or profiles: **Any illegal or inappropriate use of the content published on the Platform or Launchyoo profiles may be reported through the mechanisms available on the platform or by sending an email to the address customercare@launchyoo.com.

Any Launchyoo user may report the inappropriate nature of any content and/or activity published on the Platform through the report mechanism provided with each publication and/or activity published on Launchyoo (Flag).

If a user considers content and/or activity to be inappropriate, they should press the "report" button, fill out the form that appears, indicate the causes of complaint to be made and attach the documents deemed appropriate to provide a basis to the report.

The Launchyoo Content Control Department will be in charge of studying and resolving the case given the facts made known in the report.

PECH NETWORK may inform the competent authority of certain illegal or criminal acts.

**4.6. Prohibition of sending unsolicited electronic communications: **It is expressly prohibited for Launchyoo users to use the services provided by the platform:

- To send commercial communications (SPAM) through electronic means to other users, which are unauthorized or not expressly requested.

- To harass other users.      

- For any other purpose other than the object of Launchyoo.      

We reserve the right to block and/or cancel the profile of the user who ignores any of these prohibitions.

**4.7. Conditions for groups of users and events on Launchyoo: **The creation of groups or events on Launchyoo must meet all of the following requirements:

- They will not have an illicit, deceptive or fraudulent purpose.

- They may not impersonate the identity of a brand, company, organization or public figure unless authorized to do so.

- They will not promote or facilitate services subject to the legislation on gambling.

- They will not incentivize users to provide false opinions in exchange for a payment of any kind nor for any other misuse of the Platform.

- They cannot be based on discriminatory criteria that violate Fundamental Rights.

In case of non-compliance with the aforementioned requirements, PECH NETWORK reserves the right to block and/or eliminate those groups and/or events, communicating the reasons to the user promoting them.

**5\. Intellectual and Industrial Property of Launchyoo content:**

**5.1. Intellectual Property: ** PECH NETWORK has exclusive exploitation rights of all the designs (color combinations, structure and design) drawings, images, audiovisual content, text, software, source code and/or applications necessary for the operation, access and use of Launchyoo. Therefore, the reproduction, distribution, public communication and transformation, including any means of making available, in any form or by any technical means, of such content, without express written authorization by PECH NETWORK is strictly prohibited.

**5.2. Industrial Property: **  Launchyoo's word and figurative marks are distinctive signs registered by PECH NETWORK.

Any unauthorized use thereof will enable PECH NETWORK to exercise as many legal actions as may correspond in defense of its legitimate interests.

The utilization of the Launchyoo logo is allowed only to communicate its use on different media, through exclusive use of these made available to users on this [link](https://launchyoo.com/)

**6\. Advertising and promotions on Launchyoo:**

**6.1. Advertising: **On Launchyoo, publications by users that contain promotional content are subject to the Law 34/1988 of 11th November on General Advertising, the Law 3/1991 of 10th January on Unfair Competition and any concordant regulation. It is their sole responsibility that the promotional action they carry out, complies with these provisions, assuming fully and exclusively any consequence that derives from failure to comply. 

In particular, you should take the necessary precautions so that the relative content of the product, brand or service being promoted through Launchyoo is not considered a deceptive, covert or pyramid-selling business practice, and must include content clearly identifiable by the consumer or user who can identify it is advertising content, if such is the case.

We decline any responsibility for the breach, by users, of the applicable legislation regarding advertising.

**6.2. Draws and contests on Launchyoo:** Users are allowed to carryout draws and/or promotional contests through Launchyoo as long as all of the following conditions are met:

- The Launchyoo user accepts that the organization of the draw or contest is carried out under their exclusive responsibility, being solely responsible for complying with all legal requirements for the organization of the competition or draw(legal bases, tax obligations, administrative authorizations where appropriate or necessary, etc.).

- The legal basis of the contest or draw must expressly state that it is not organized, sponsored, administered or in any way related to Launchyoo, exonerating us from any liability arising from the draw or contest.

- Under no circumstance must the draws and contests base their participation in sharing publications on the users' walls, tagging users and/or any other similar action that uses Launchyoo's features.

In case of breach of any of the above requirements, PECH NETWORK may withdraw any contest or draw organized using Launchyoo.

**7\.** **Disclaimer of Warranty and Limitation of Liability****: **We cannot guarantee the availability and continuity of operation of Launchyoo due to the possibility of Internet problems, server crashes, failures, breakdowns, slowness or errors in the access and use of the services and other possible contingencies or unforeseeable situations, whether attributable to PECH NETWORK or to third parties.

Likewise, the service may be temporarily suspended for security reasons or for maintenance and updating of the Platform itself.

When reasonably possible, we will warn in advance about the interruptions in the operation of the Platform, access to its content, or the provision of services.

We cannot absolutely guarantee the veracity, accuracy, completeness, timeliness, legality, reliability and usefulness of the content and services of the Platform provided or transferred by third parties outside this entity.

We do not assume, nor identify ourselves with the opinions, comments or actions of the users, nor do we hold any editorial activity in this area. The users (and, where appropriate, their legal representatives) are solely responsible for such opinions, comments or actions on the Platform, except with regard to the applicable legislation.

Despite the multiple prohibitions, rules and recommendations we address to Launchyoo users, we cannot guarantee, nor do we make ourselves responsible in any way for them to make appropriate and diligent use of the Platform in accordance with these Terms and Conditions. However, we can implement security and detection systems in this area. We will adopt necessary corrective measures from the moment we become aware of possible breaches on this matter, ensuring the overall legitimacy of the services, content, images and comments that may be uploaded onto the social network, without this representing any obligation of any kind of control by PECH NETWORK.

Launchyoo users (and, where appropriate, their respective legal officers ) are, in general, the only ones responsible for the actions, comments, images, content and information that they publish or with which they contribute on the Platform, as well as the proper management of the profile and of the activities and/or events they publish.

PECH NETWORK is not a hosting service or file hosting. Therefore, if for any reason, the service stops being provided, sufficient notice will be given so you can download the files you had uploaded to the Platform. Once this period has elapsed, PECH NETWORK will delete all the content you have uploaded to the Platform.

**8\. Link policy:**

**8.1. Links from other websites: **If you intend to link from a website of another Internet portal to any of our websites, you must meet the following conditions:

- It is prohibited to reproduce all or part of the services or content of the website, or create deep-links, IMG or image links and frames with the Launchyoo website without our prior express authorization in writing.

- No false, inaccurate or incorrect information will be included on the Launchyoo website, or its services or content. Except for signs that form part of the link, the website to which the link is established will not contain any trademark, trade name, establishment name, company name, logo, slogan or other distinguishing signs belonging to Launchyoo, unless expressly authorized.

- Establishment of a link will not imply the existence of a relationship between Launchyoo and the owner of the website or portal from which the link is established, nor will it imply Launchyoo's knowledge and acceptance of the services and content offered on said website or portal.

- We will not be held responsible for the content or services made available to the public on the website or portal from which the hyperlink is made, nor the information and statements included therein.

**8.2. Links to other websites: **The Launchyoo website may make links available to its users, to other websites managed and controlled by third parties, solely to facilitate the search for information, content and services on the Internet. However, in no case does Launchyoo commercialize, direct, and/or control in advance, nor does it own the content, services, information and declarations available on said websites.

We do not assume any type of responsibility, either indirectly or secondary, for the content, information, communications, opinions or services from websites that are not managed by us and that are accessible through Launchyoo. Nor do we guarantee the absence of viruses or other elements that may alter the user's computer system (hardware and software), or in documents or files, which excludes any liability for damages of any kind caused to the user for this reason.

**9\. Right of exclusion: **We reserve the right to deny or withdraw access to Launchyoo, without prior notice, at its own request or that or a third party, to all users who fail to comply with any of these Terms and Conditions, or any particular condition that, where appropriate, apply to them.

The denial of access may be made by any optimal technical means for that purpose.

**10\. Terms of Payment Services on Launchyoo: **The purchasing process of payment services on Launchyoo may be done in Spanish, English or Portuguese, and purchasing these services will be carried out as long as they are active.

The payment services you can purchase once active are:

- Annual profile-improvement subscription.

- Annual subscription of the service to remove advertising.

**10.1. Subscription for the improvement (upgrade) of profiles: **  To subscribe to a higher profile than the USER profile, you must successfully complete the Platform registration and press the "improve profile/upgrade” button or interface, then select payment method.

During the period of one year from the date of purchasing the subscription you can enjoy the improved/upgraded profile you have chosen.

Before the end of the subscription year, with sufficient notice, we will remind you that you need to renew the service. If you do not renew it, you will return to a USER profile.

**10.2. Subscription to the service that removes advertisements: **If you want to use Launchyoo without advertising, you must click on the "remove ads" button or interface and select the payment method.

**10.3 Payment methods on "Launchyoo's Payment Services": **PECH NETWORK provides you with the following payment methods for subscriptions to Launchyoo's Payment Services:

**              - Payment by credit/debit card: **Your card will be charged in real time through a virtual POS, once is has been verified that your data is correct. Your card information will be encrypted during transmission with the sole purpose of enabling the payment.

              If you opt for this payment method, you are guaranteeing you have full authorization for the use of the credit/debit card involved in the payment process.

**              - Payment through the Paypal gateway: **To be able to pay with this system, you must have a PayPal user account. This form of payment could carry extra costs.

              Be aware that if the issuer of the card or the payment gateway used does not authorize the payment, the purchase of the service will not be considered as completed.

              The amount to the corresponding annuity will be paid at the time of purchase or renewal of the subscription.

**10.4 Costs: **The costs of the annual subscription will be published on the Website and will include VAT that will be applicable at all times.

              The costs may be modified upwards or downwards, depending on multiple reasons, however any modification of the prices will take effect from the date of modification, not affecting the purchase requests made with prices prior to the modification.

              **10.5. Billing: **The user expressly consents to the issuance, by PECH NETWORK, of an electronic invoice. The electronic invoice will be received by email. The user, who has the status of consumer and user, may withdraw their consent by sending an email accordingly, to the address: customercare@launchyoo.com

  **10.6. Duration and update to a superior profile: **The duration of the subscription will be annual, renewable for identical periods unless one of the causes for termination of the contract provided by these Terms and Conditions occurs. 

The user who wishes to modify the profile they have purchased, to a higher profile, may do so at any time, from their own Control Panel. Once the modification is requested, a charge will be automatically generated that will take into account:

- The proportional part of the cost of the current profile for the period of the current year.

- The proportional part of the cost of the superior profile of the current year pending enjoyment.

The price difference must be paid at the time of doing the profile modification.

**10.7 Termination: **The termination of the subscription of the payment services will take place for legal reasons and for the breach of these Terms and Conditions, without prejudice to the right to claim damages that may be waived due to such breach.

**10.8. Unilateral termination by the user: **The user may terminate the contract at any time by notifying PECH NETWORK by sending an e-mail, maintaining Launchyoo's Payment Services until the end of the annual period, without being entitled to any refund.

    **10.9. Customer care**: Any query or claim related to the provision of services will be sent to the e-mail:

customerservice@launchyoo.com

              **European online dispute resolution platform: **It is reported that the user, resident in the European Union, also has the possibility to make use of the ODR platform (Online Dispute Resolution) through the following link:

https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage

Through this platform, consumers and merchants are allowed to submit claims using an electronic form available in all languages ​​of the European Union, for all matters related to electronic commerce or provision of services on the network. This is in accordance with Regulation 524/2013 of the European Parliament and Council of May 21, 2013 and Directive 2013/11/EU of the European Parliament and Council on the alternative resolution of consumer disputes.

**10.10. Right of withdrawal**:  If you hold the status of consumer or user in accordance with the Consolidated Text of the General Law for the Protection of Consumers and Users (TRLGDCYU) you can withdraw (leave the subscription without effect) within 14 days starting from the subscription date, without having to justify your decision and without penalty of any kind, through an unequivocal statement (for example, a communication sent by e-mail or mail). With that in mind, you can use the withdrawal form below, although its use is not mandatory:

***Withdrawal Model form according to the annex of the Consumers and Users Act of 2007 (the user can copy and paste the following form into an email and complete their data if they wish to withdraw from the subscription):***

For the attention of PECH Network Services, S.L.U., with registered office at C/ Ramón Llull, 31, 4, 11, 46021, Valencia (Spain) , with Tax ID Number: B40561185. Contact email: customercare@launchyoo.com

I hereby inform you/we inform you that I withdraw from the subscription whose request was made on the date ___/___/20__.

Name and surname/s of the customer: ________________________________

Address/es of the customer/s: ________________________________________

Date of communication of withdrawal from the service: ___/___/20__

**Consequences of withdrawal**: In the event of exercising the right of withdrawal, PECH NETWORK will reimburse the amounts paid at the time of the corresponding subscription.

**10.11. Promotions**: PECH NETWORK will have the power to establish promotional campaigns of its services at any time with certain advantages for users, which will remain in force during the time they are published on the Website or during the time established in the promotion in question. The promotions will not be cumulative in any case.

**11\. Creating advertising campaigns**: You can create advertising campaigns on Launchyoo in accordance with the Terms and Conditions of [advertising on Launchyoo](https://app.launchyoo.com/privacy).

**12\. Update of the Terms and Conditions of Use: **These Terms and Conditions of Use may undergo updates for legal, technical and corporate reasons, or deriving from self-regulation processes, or by voluntary adherence of PECH NETWORK to existing codes of conduct, interest for its activity and for the best protection of the rights of its users.

We will send you a notification (by email or through Launchyoo) at least 30 days before the update will take effect, having the possibility to review the changes before they take effect, unless such changes are required by law.

Once any update to the Terms and Conditions is in effect, you will be subject to these if you continue using Launchyoo.

If you do not accept the update of the Terms and Conditions and you no longer want to be part of Launchyoo, you can request the cancellation of your account at any time at customercare@launchyoo.com.

**13\. Applicable legislation and jurisdiction: **The present contracting conditions are governed by Spanish legislation.

If you hold the status of consumer or user, for the resolution of conflicts and waiving any other jurisdiction, the courts and tribunals of your domicile will be competent.

If you do not hold the status of consumer or user, the parties submit to the Courts and Tribunals of Valencia (Spain).

**14\. Translation of Legal Texts: **It is expressly stated that in the event of divergence, dispute or discrepancy, legal texts in Spanish will prevail over those published in any other language.

**Latest Version: June 2020**

`







const esText = `
**Términos y condiciones de Launchyoo**

**1\. Datos Identificativos del prestador del servicio**

**2\. ¿Qué es Launchyoo?**

**3\. Registro y configuración de perfil**

**4\. Utilización de la plataforma de Launchyoo**

**5\. Propiedad Intelectual e Industrial de los contenidos de Launchyoo**

**6\. Publicidad y promociones en Launchyoo**

**7\. Exclusión de garantías y responsabilidad**

**8\. Política de enlaces**

**9\. Derecho de exclusión**

**10\. Condiciones de los Servicios de Pago de Launchyoo**

**11\.** **Creación de campañas de anuncios**

**12\. Actualización de los Términos y Condiciones de Uso**

**13\. Legislación Aplicable**

**14\. Traducción de los Textos legales**

------------------------------------------------------

A continuación se detallan los términos y condiciones aplicables a la utilización y contratación de los servicios de este sitio web/app por los usuarios que quieran hacer uso de los mismos, debiendo ser aceptadas, en caso de conformidad con estas, mediante las funcionalidades dispuestas a tal fin:

**1\. Datos Identificativos del prestador del servicio:** La titular de la plataforma Launchyoo (en adelante, la Plataforma o Launchyoo indistintamente) y la prestadora de los servicios de la sociedad de la información a través de ésta es:

PECH Network Services, S.L.U.

C.I.F.: B40561185

C/ Ramón Llull, 31, 4, 11, 46021, Valencia (España)

Inscrita en el Registro Mercantil de Valencia, al Tomo 10.642, Libro 7923, Folio 96, Hoja V- 186.754.

Correo electrónico de contacto: customercare@launchyoo.com

En adelante, "**nosotros", "nuestro" o "PECH NETWORK"**, indistintamente.

**2\. ¿Qué es Launchyoo?:** Launchyoo es una red social a la que podrás acceder vía web o app, una vez completado el proceso de registro de forma satisfactoria con el fin de compartir contenidos en línea y poder interactuar con el resto de perfiles dependiendo de cómo configures el tuyo y siempre de acuerdo con los presentes términos y condiciones.

**2.1. Descripción de funcionalidades:**

**Muros:**  Los Muros son las diferentes áreas en las que los diferentes perfiles pueden interactuar en Launchyoo.

Puedes acceder a los diferentes muros a través de las pestañas dispuestas a tal fin en la Plataforma. Podrás configurar el color de la pestaña asociada a cada muro.

Los muros se corresponden con cada tipología de información que los perfiles pueden compartir:

- Muro de fotografías

- Muro de mensajes cortos (MiniMes)

- Muro de vídeos

- Muro de audio y podcasts

- Muro de blogs y noticias

- Muro de eventos

- Muro Mix: Permite visualizar una mezcla de lo contenidos publicados en el resto de Muros. 

Dependiendo de tu tipo de perfil, podrás realizar en cada muro las siguientes acciones:

- **Compartir contenidos:**  publicar contenidos en tus muros. El contenido que publiques en tus muros será accesible en Internet e indexado por motores de búsqueda y podrá ser visualizado por el resto de usuarios de Launchyoo, así como por cualquier internauta. En ningún caso estos últimos tendrán acceso a tu edad, género, álbum de archivos (salvo la foto de perfil y/o fondo de imagen) o nombre real.

- **Salvar publicaciones:**  guardar cualquier publicación para poder consultarla en cualquier otro momento posterior.

- **Interactuar con las publicaciones del resto de usuarios:**  indicar “me gusta” (like), “lo adoro” (love), votar mediante “estrellas” o indicar el aprecio mediante “si/no”, compartir o comentar publicaciones de otros usuarios.

- **Denunciar noticias falsas ("*fake news"*)**

- **Denunciar contenidos inadecuados,**  en el caso de incumplimiento de los presentes términos y condiciones.

- **Eliminar** los contenidos que has compartido.

Además existirán funcionalidades particulares para cada tipo de Muro dependiendo de su naturaleza:

En el Muro de fotografías se podrá etiquetar a otro usuario que aparece en ella,  siempre y cuando la persona etiquetada lo acepte a través del sistema de Launchyoo para que dicho etiquetado sea público.

En los textos se podrá etiquetar a cualquier otro usuario y se le comunicará este etiquetado, pero no se solicitará su autorización para que el mismo sea público.

**Configuración de avisos y notificaciones:**  Puedes configurar los avisos y notificaciones de la actividad en Launchyoo (si alguien te nombra en un comentario, te etiqueta en una foto, publicaciones en un grupo de usuarios del que formas parte, un perfil que sigues ha publicado algo, etc.)  de tal forma que podrás activar la recepción de esos avisos y notificaciones en la aplicación, en tu smartphone o mediante el envío de un correo electrónico. También podrás desactivar esos avisos y notificaciones en cualquier momento.

**Creación de eventos:**  En Launchyoo podrás describir un evento, el lugar y la hora de celebración y publicarla en el Muro de Eventos, para que el resto de usuarios puedan leer dicha información e indicar si quieren participar.

**Socialización activa:**  Launchyoo puede mostrarte sugerencias de personas afines a tus gustos que se encuentran cerca de ti. Para poder hacer uso de la socialización activa debes cumplir todos y cada uno de los siguientes requisitos: Debes haber facilitado a Launchyoo el mínimo de gustos o intereses (tags) configurado en el sistema. Debes activar la geolocalización en tu dispositivo. A tal fin te solicitaremos tu consentimiento para activar la geolocalización de tu dispositivo. Debes seleccionar el “rango de distancia” que quieres que tengamos en cuenta para mostrarte usuarios de Launchyoo afines a tus intereses. Es posible, asimismo, indicar un rango de edad y sexo.

**Publicidad**: En cualquiera de los Muros se mostrará publicidad de terceros anunciantes personalizada a tus gustos e intereses. No revelaremos en ningún caso tu identidad a los anunciantes. Permitimos que los anunciantes configuren sus campañas publicitarias en Launchyoo, indicando un público objetivo al que dirigir los anuncios por edad y/o sexo e incluso por intereses. Además, proporcionamos a los anunciantes informes sobre el rendimiento de sus campañas de anuncios con información anonimizada de cómo los usuarios de Launchyoo interactúan con su contenido publicitario. Por información anonimizada debe entenderse como un conjunto de datos agregados que en ningún caso identifican al usuario (no compartimos datos tales como tu nombre, apellidos, correo electrónico, teléfono, etc.). Podrás dejar de ver publicidad en Launchyoo mediante la suscripción anual al servicio de eliminación de anuncios conforme a lo previsto en los presentes términos y condiciones. Algunas de las funcionalidades pueden encontrarse inactivas de forma temporal. Te comunicaremos su disponibilidad cuando estén listas.

**2.2. Cómo se financia la red social Launchyoo: **

Los servicios gratuitos de Launchyoo son financiados, además de con los recursos propios de PECH NETWORK:

a) Con la contratación por terceros anunciantes (marcas, negocios, empresas, organizaciones, etc.) de los servicios publicitarios que ofrecemos, por lo que te mostraremos publicidad de esos anunciantes relacionada con tus intereses, salvo en el caso de que hayas contratado la suscripción anual de eliminación de anuncios antes referida.

No vendemos ni comunicamos tus datos personales a esos terceros anunciantes.

b) Con la contratación por los usuarios de la mejora de perfiles (*upgrade*) conforme a lo previsto en los presentes Términos y Condiciones.

**3\. Registro y configuración de perfil:** El registro en Launchyoo sólo está permitido para mayores de 16 años. Te guiaremos en todo momento durante el proceso de creación de tu perfil.

Para mayor claridad y a los efectos de lo previsto en las presentes condiciones, nos referiremos a “USUARIO” (en mayúscula) como uno de los tipos de perfil de Launchyoo, teniendo “usuario” (en minúscula) el significado propio de tal término.

Queda prohibido crear perfiles falsos o que suplanten la identidad de otra persona. PECH NETWORK podrá solicitar al usuario que aporte documentación acreditativa de la identidad del perfil, con el fin de evitar perjudicar a terceras personas. 

Asimismo queda prohibido que el usuario tenga más de un perfil de la misma tipología en Launchyoo. Es decir, el usuario puede tener un perfil de tipo USUARIO y cualquiera de los perfiles BUSINNESS, INFLUENCER o ASSOCIATION, si cumple con los requisitos establecidos para ello.

PECH NETWORK se reserva el derecho de bloquear y/o cancelar perfiles en el caso de que detecte el incumplimiento de las referidas prohibiciones.

**3.1. Para registrarte en Launchyoo debes acceder al menú de creación de perfil y seleccionar el tipo de Perfil que te interesa. Por defecto se crea el perfil de USUARIO, pero puedes elegir entre los diferentes tipos de perfil en función de tus características:

**a) Perfiles de USUARIO.** que te permite realizar las siguientes acciones en Launchyoo:

-   Realizar tantas publicaciones como desee en el muro de mensajes cortos (MiniMes)
-   Subir tantas fotos como desee
-   Subir un número limitado de videos a la semana
-   Subir un número limitado de archivos de audio a la semana
-   Realizar un número limitado de publicaciones semanales en el muro de Blog&News
-   Crear un número limitado de eventos a la semana
-   Pedir amistad a otros usuarios
-   Asignar a sus contactos la “temperatura”: frío, moderado y caliente en función de la actividad deseada con el contacto de que se trate. 

El sistema te permite que puedas mejorar tu perfil de USUARIO, estando dicha mejora sujeta al pago de una suscripción anual, optando entre cualquiera de los perfiles de USUARIO mejorados que a continuación se relacionan

**USUARIO VIDEO LOVER,** que puede realizar las mismas acciones que como USUARIO, pero además podrá subir vídeos a LAUNCHYOO sin límite semanal.

**USUARIO AUDIO LOVER**, que puede realizar las mismas acciones que como USUARIO, pero además podrá subir audios en el muro de Audio&Podcasts a LAUNCHYOO sin límite semanal.

**USUARIO EVENT CREATOR**, que puede realizar las mismas acciones que el USUARIO, pero además podrá crear los eventos que desee sin límite semanal.

**USUARIO BLOGGER**, que puede realizar las mismas acciones que el USUARIO, pero además puede hacer publicaciones en el Muro de Blogs & News sin la limitación semanal.

**USUARIO SUPER**, que puede realizar todas las acciones del USUARIO, pero sin ningún tipo de límite cuantitativo.

No obstante, durante el primer año a contar desde la fecha de alta en Launchyoo, dispondrás de forma gratuita de todos los beneficios del perfil de USUARIO SUPER. Transcurrido dicho plazo, aplicarán automáticamente los límites del usuario tipo USUARIO indicados con anterioridad, debiendo realizar la suscripción anual a cualquiera de los otros perfiles de usuario para gozar de sus beneficios.

**AMBASSADORS- EMBAJADORES:** Si 15 o más usuarios registrados han indicado durante el proceso de creación de su perfil que se han registrado siguiendo tu recomendación, tendrás la condición de Ambassador/Embajador que implicará:

- Que aparecerá esa distinción en tu perfil de usuario.

- Que transcurrido el período gratuito con las ventajas del perfil USUARIO SUPER (un año desde la fecha de alta en Launchyoo) podrás optar también de forma gratuita por un año por cualquier otro perfil de nivel superior al USUARIO (excepto perfiles tipo USUARIO SUPER, INFLUENCER, BUSINESS y ASSOCIATION).

Para obtener la condición de AMBASSADOR, PECH NETWORK tendrá en cuenta la actividad de los usuarios recomendados y en ningún caso otorgará tal condición si se comprueba que los usuarios recomendados son falsos o creados desde la misma IP. En cualquier caso la condición de EMBAJADOR se otorgará transcurrido un mes desde que se den de alta 15 usuarios recomendados y siempre que se mantengan de alta en Launchyoo. 

**b) Perfil INFLUENCER**, que puede realizar todas las acciones del USUARIO SUPER, sin limitaciones cuantitativas, con la salvedad de que no puede pedir amistad al resto de usuarios, sino que serán los usuarios los que, en su caso, decidan seguir a este perfil INFLUENCER. Tampoco podrá modificar los atributos de privacidad de su perfil. Se trata de perfiles públicos.

**c) Perfil BUSINESS**, pensado para empresas y/o profesionales. Si quieres que tu negocio o empresa tenga actividad en Launchyoo, deberás registrarte en esta modalidad de perfil. 

El Perfil BUSINESS te permitirá realizar todas las acciones del USUARIO SUPER con las siguientes salvedades: 

- No podrás pedir amistad con el resto de usuarios, sino que serán los usuarios los que, en su caso, decidan seguir al perfil ASSOCIATION.

- Tampoco podrá modificar los atributos de privacidad de su perfil. Se trata de perfiles públicos.

**d) Perfil ASSOCIATION**, pensado para entidades sin ánimo de lucro con diferentes finalidades. Si quieres que tu entidad sin ánimo de lucro tenga actividad en Launchyoo, deberás registrarte en esta modalidad de perfil

El Perfil ASSOCIATION te permitirá realizar todas las acciones del USUARIO SUPER con las siguientes salvedades:

- No podrás pedir amistad con el resto de usuarios, sino que serán los usuarios los que, en su caso, decidan seguir al perfil ASSOCIATION.

- Tampoco podrá modificar los atributos de privacidad de su perfil. Se trata de perfiles públicos.

En el caso de que te registres con un perfil BUSINESS o ASSOCIATION nos reservamos el derecho de pedirte que nos acredites tu condición de profesional, empresa y/o asociación. En caso de que no lo acreditaras, podríamos convertir tu perfil BUSINESS o ASSOCIATION en perfil de tipo USUARIO.

**3.2. Proporcionar datos:** Para completar el proceso de registro, deberás proporcionarnos de manera obligatoria, a través del formulario dispuesto a tal fin, los siguientes datos:

- Un *nickname* o alias que elijas (siempre y cuando no se encuentre ocupado por haber sido elegido de forma previa por otro usuario registrado)

- Un correo electrónico

Toda la información que nos proporciones deberá ser veraz, exacta, completa y actualizada. Serás responsable del incumplimiento de tales requisitos en el momento de proporcionar tal información.

No se permite bajo ningún concepto la creación de perfiles falsos, sin perjuicio de la posibilidad de hacer uso públicamente del nickname o alias, pero deberá facilitarse el nombre y apellidos auténticos con ocasión del registro en Launchyoo.

Podrás ocultar tu nombre real resto de usuarios de LAUNCHYOO o elegir quién quieres que pueda visualizarlo.

Podrás incluir una foto de perfil y una imagen de fondo, así como un texto corto descriptivo de tus gustos o de presentación (“About Me”).

Asimismo, podrás indicar tus gustos e intereses, con la finalidad de personalizar tu perfil. Es lo que denominamos tags o etiquetas personales.

Durante el proceso de registro, podrás indicar si te unes a Launchyoo por sugerencia de algún usuario ya registrado, haciendo constar el nickname o nombre de ese usuario con el fin de proporcionarle ventajas promocionales al usuario registrado que te ha recomendado Launchyoo.

Deberás asimismo elegir una contraseña que tendrás que escribir por duplicado para evitar errores mecanográficos. Serás el único responsable de la custodia de tu nombre de usuario y contraseña, comprometiéndote a mantenerlo de manera confidencial sin difundirlo a ningún tercero, debiendo adoptar las medidas necesarias para ello. Si tienes la sospecha o el conocimiento de que tus claves de acceso a Launchyoo están siendo utilizadas por un tercero, deberás cambiar tu contraseña y comunicarlo a PECH NETWORK a la mayor brevedad posible.

Ponemos a tu disposición información relativa al tratamiento que realizamos de tus datos personales, estando dicho tratamiento legitimado por las bases jurídicas que correspondan (ejecución de un contrato o precontrato, consentimiento o interés legítimo)

Disponemos de funcionalidades para que puedas prestar tu consentimiento informado, libre, específico e inequívoco para las finalidades que así lo requieran.

Todo ello conforme a lo establecido en nuestra [Política de Privacidad](https://app.launchyoo.com/privacy).

Por último, deberás leer los presentes términos y condiciones y, si estás conforme con su contenido, deberás marcar la casilla correspondiente como muestra de haber comprendido y aceptado estos.

Te remitiremos un correo electrónico con el fin de que confirmes tu dirección de correo electrónico. 

**3.3. Configuración del perfil:** Una vez que hayas completado de forma satisfactoria el registro y la activación, tendrás acceso al Panel de Control de tu perfil, a través del cual podrás editar y modificar tus datos.

**Calendario de creación de eventos:** Tendrás acceso al Calendario en el que podrás publicar eventos públicos o privados en función del perfil de que dispongas.

**Lista de contactos y perfiles seguidos:** Tendrás acceso a la lista de contactos y de perfiles seguidos.

**"How I feel today"** Si lo deseas, puedes completar en tu perfil el campo “How I feel today”, con las opciones que el sistema te ofrece: Tired, Bored, Happy, Angry, In love, etc.  Esta configuración durará 24 horas desde el momento en que la indiques.

**Geolocalización**: Si lo deseas puedes mostrar la posición de tu dispositivo de la siguiente forma:

a) Geolocalización a través del GPS de tu dispositivo

b) Indicación en el mapa de forma manual

Recabaremos siempre tu consentimiento para activar la geolocalización y podrás desactivarla en cualquier momento a través de la configuración de tu dispositivo.

Hay determinadas funcionalidades que no funcionarán correctamente si desactivas la geolocalización, como por ejemplo detectar eventos de otros usuarios o la sugerencia de contactos que se encuentren cerca de tu posición geolocalizada (socialización activa).

**Gestión de los contactos: **

La Plataforma permite crear grupos entre los usuarios registrados en Launchyoo, asignando roles de administrador (para poder, por ejemplo incluir a otros miembros) conforme a lo dispuesto en los presentes términos y condiciones.

Existen tres tipos de grupos. No se podrá modificar la tipología de grupo una vez creado:

-   Grupo público: Visible y accesible a todos.
-   Grupo privado: Visible pero accesible solo si eres invitado.
-   Grupo secreto: No visible y accesible solo si invitado.

Podrás declinar en cualquier momento formar parte de cualquiera de esos grupos.

**3.4. Configuraciones pendientes de realizar y actualizaciones:** En la Plataforma existen funcionalidades o iconos específicos que te indicarán si alguna configuración se encuentra pendiente de realizar o si existe alguna actualización.

En caso de necesidad de bloquear a un usuario, contactar a la dirección de correo electrónico customercare@launchyoo.com

**3.5. Bloquear usuarios:** En caso de necesidad de bloquear a un usuario, contactar a la dirección de correo electrónico customercare@launchyoo.com.

**3.6. Baja del servicio:** Podrás darte de baja como usuario de Launchyoo en cualquier momento dirigiéndote a la dirección de correo electrónico customercare@launchyoo.com Como consecuencia de la solicitud de baja en el servicio, eliminaremos permanentemente el perfil de usuario registrado y todos los contenidos e interacciones con el resto de usuarios, eventos creados, en Launchyoo. No podemos garantizar que esos contenidos sean eliminados de cualquier otro sitio de internet o de la indexación de los buscadores. La solicitud de baja en el servicio en ningún caso dará derecho a solicitar la devolución de los importes correspondientes a las suscripciones anuales.

**4\. Utilización de la Plataforma de Launchyoo:** La utilización de Launchyoo te obliga a respetar la legislación aplicable, los derechos de terceros y a hacer un uso adecuado de los contenidos y servicios que te ofrecemos, quedando totalmente prohibido emplear Launchyoo para realizar cualquiera de las siguientes acciones:

a) Incurrir en actividades contrarias a la Ley, la buena fe y el orden público tales como subir o compartir contenidos injuriosos o calumniosos, vulneradores de derechos de propiedad intelectual y/o industrial, vulneradores de la ley de protección de datos, etc.

b) Difundir contenidos o propaganda de carácter xenófobo, pornográfico, de apología del terrorismo, atentatorio contra los Derechos Humanos, delictivo o que vulneren las leyes de protección de menores.

c) Provocar daños en los sistemas físicos (hardware) y lógicos (software) de PECH NETWORK, de sus proveedores o de terceras personas (virus, sobrecargas del sistema, ataques informáticos, etc.)

d) Utilizar técnicas de crawling, screenscraping, web scraping o cualquier otra técnica de programación o medio automatizado que permita extraer información o datos alojados, almacenados o accesibles en el Sitio Web sin autorización expresa y por escrito de PECH NETWORK.

e) Sortear las medidas que se han establecido para proteger los servicios, dañar el sistema o realizar un acceso no autorizado a los mismos, realizar ingeniería inversa para tratar de acceder al código fuente o los algoritmos del sistema y cualquier otra acción que trate de dañar o interferir en los sistemas y/o aplicaciones de PECH NETWORK

f) Intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes.

El incumplimiento de tales obligaciones llevará aparejada la asunción de la responsabilidad de daños y perjuicios que en su caso correspondan y el bloqueo inmediato del perfil del usuario, sin derecho a solicitar la devolución de los importes satisfechos por razón de las suscripciones anuales.

PECH NETWORK se reserva el derecho de poner en conocimiento de la autoridad competente cualquier vulneración de la legislación aplicable.

**4.1. Obligaciones y limitaciones en la publicación de contenidos: **

**4.1.1. Debes ser el titular de los derechos de explotación de propiedad intelectual:** Para publicar o insertar contenidos en Launchyoo, debes ostentar la legítima titularidad de todos los derechos de explotación de estos, que te permitan su reproducción, distribución, comunicación pública -- incluso en la modalidad de puesta a disposición -- y transformación, a través de cualquier medio electrónico.

**4.1.2. Debes contar con la autorización de las personas que aparecen en tus publicaciones:** Además, aunque seas el legítimo titular de los derechos de explotación sobre los contenidos que publiques o compartas en Launchyoo, deberá de obtener las autorizaciones y/o permisos correspondientes de las personas físicas que aparezcan en tales publicaciones, en relación con sus derechos de imagen, para no generar intromisiones ilegítimas en el honor, en la intimidad personal y familiar y en la propia imagen ni vulnerar sus derechos en materia de protección de datos.

**4.1.3. Declaración expresa del usuario:** Por el mero hecho de publicar o compartir un contenido en Launchyoo declaras de forma expresa:

- Que ostentas todos los derechos de explotación sobre tales contenidos o que cuentas con la correspondiente licencia, autorización y/o permiso del legítimo titular para su publicación y cesión de los derechos a terceros.

- Que cuentas con las oportunas autorizaciones de las personas físicas que aparecen en tales contenidos para su publicación en Launchyoo.

**4.2. Cesión de los derechos sobre los contenidos:** Con la aceptación de estos términos y condiciones has declarado ser el titular de los derechos sobre cualquier contenido que publiques o compartas por cualquier vía en Launchyoo. Por el mero hecho de publicar o compartir un contenido en Launchyoo, concedes a PECH NETWORK y a los usuarios de Launchyoo una licencia para el territorio de todo el mundo, gratuita, no remunerada y libre de regalías, sublicenciable (con derecho de PECH NETWORK para ceder a su vez esos derechos), transferible y no exclusiva para alojar, usar, distribuir, modificar, publicar, copiar, mostrar o exhibir públicamente y transformar los contenidos que hayas publicado, así como para crear trabajos derivados de estos, durante la vigencia legal de la protección de los derechos cedidos, para su utilización en el entorno de Launchyoo. 

Podrás revocar la licencia borrando los contenidos sujetos a propiedad intelectual que hubieras compartido en Launchyoo.

No obstante, debido al funcionamiento técnico, la supresión total de todas las copias generadas en los diferentes servidores se producirá de forma progresiva. Si los contenidos hubieran sido compartidos por terceros permanecerán en cuanto a dichas relaciones. El titular de los derechos deberá dirigirse a esos terceros para que el contenido compartido sea eliminado. 

**4.3. Reserva del derecho a retirar contenidos inadecuados:** PECH NETWORK se reserva el derecho a retirar todos aquellos contenidos, en cualquiera de sus expresiones (texto, comentario, fotografía, fonograma, obra audiovisual, etc.), contrarios a la Ley, a la buena fe, al orden público y al respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos o que atenten contra la juventud o la infancia, así como todos aquellos que se consideren no apropiados a las características y finalidades de la Plataforma.

En tanto que PECH NETWORK no puede controlar todas y cada una de las publicaciones realizadas por los usuarios de Launchyoo, PECH NETWORK no asumirá responsabilidad alguna sobre tales contenidos a menos que tenga conocimiento efectivo de la inserción de contenidos inadecuados en los términos establecidos por la normativa de aplicación.

**4.4. Uso de marcas de terceros por los usuarios de Launchyoo:** No se permite la utilización en los perfiles de Launchyoo de signos distintivos registrados tales como marcas o nombres comerciales de los que el usuario no sea titular legítimo y que supongan una infracción de lo previsto en la legislación sobre marcas y sobre leal competencia.

PECH NETWORK se reserva el derecho de bloquear los perfiles que hagan un uso no autorizado de signos distintivos registrados titularidad de terceros para los que no cuente con la debida autorización, así como a retirar contenidos que vulneren la legislación de marcas o de leal competencia.

**4.5. Denuncia de contenidos y/o perfiles:** Cualquier uso ilícito o inadecuado de los contenidos publicados en la Plataforma o de los perfiles de Launchyoo podrá ser denunciado a través de los mecanismos disponibles en la misma plataforma o mediante la remisión de un correo electrónico a la dirección customercare@launchyoo.com.

Cualquier usuario de Launchyoo podrá denunciar el carácter inadecuado de cualquiera de los contenidos y/o actividades publicados en la Plataforma a través del mecanismo de denuncia dispuesto junto a cada publicación y/o actividad publicada en Launchyoo.

El usuario que considere que un contenido y/o actividad son inadecuadas deberá pulsar el botón o interfaz "denunciar", debiendo rellenar el formulario que aparece, indicando cuáles son las causas de la denuncia que se pretende formular, adjuntando los documentos que considere oportunos para fundamentar la denuncia realizada.

El Departamento de Control de Contenidos de Launchyoo será el encargado de estudiar el caso y resolver a la vista de los hechos puestos en conocimiento con la denuncia.

PECH NETWORK podrá poner en conocimiento de la autoridad que resulte competente determinados hechos ilícitos o delictivos.

**4.6. Prohibición de remitir comunicaciones electrónicas no deseadas:** Se prohíbe de forma expresa que los usuarios de Launchyoo utilicen los servicios que la Plataforma les proporciona para:

-   Remitir comunicaciones comerciales (SPAM) por vía electrónica no autorizadas o no expresamente solicitadas al resto de usuarios.
-   Acosar al resto de usuarios.
-   Cualquier otra finalidad distinta al objeto de Launchyoo.

En caso contrario, nos reservamos el derecho de bloquear y/o cancelar el perfil del usuario que haga caso omiso a cualquiera de estas prohibiciones.

**4.7. Condiciones para grupos de usuarios y eventos en Launchyoo:** La creación de grupos o eventos en Launchyoo deberán cumplir todos y cada uno de los siguientes requisitos:

- No tendrán una finalidad ilícita, engañosa o fraudulenta.

- No podrán suplantar la identidad de una marca, empresa, organización o figura pública salvo que se cuente con la autorización para ello.

- No promocionarán o facilitarán servicios sujetos a la legislación sobre el juego.

- No incentivarán a los usuarios a proporcionar opiniones falsas a cambio de una remuneración dineraria o en especie ni para cualquier otro uso indebido de la Plataforma.

- No podrán basarse en criterios discriminatorios que vulneren los Derechos Fundamentales.

En caso de incumplimiento de los requisitos referidos, PECH NETWORK se reserva el derecho a bloquear y/o eliminar esos grupos y/o eventos comunicando los motivos al usuario promotor de estos.

**5\. Propiedad Intelectual e Industrial de los contenidos de Launchyoo:**

**5.1. Propiedad Intelectual:** PECH NETWORK dispone en exclusiva de los derechos de explotación de la totalidad de los diseños (combinaciones de colores, estructura y diseño), dibujos, imágenes, contenido audiovisual, textos, software, código fuente y/o aplicaciones necesarias para el funcionamiento, acceso y uso de Launchyoo, por lo que se prohíbe de forma expresa la reproducción, distribución, comunicación pública -incluida su modalidad de puesta a disposición - y la transformación, en cualquier soporte y por cualquier medio técnico, de tales contenidos sin la previa autorización expresa y por escrito de PECH NETWORK.

**5.2. Propiedad Industrial:** Las marcas denominativa y figurativa de Launchyoo son signos distintivos registrados por PECH NETWORK. 

Cualquier uso no autorizado de los mismos, facultará a PECH NETWORK para ejercitar cuantas acciones legales pudieran corresponderle en defensa de sus legítimos intereses.

Se permite el uso del logo Launchyoo únicamente para comunicar en distintos soportes su utilización, pudiéndose utilizar exclusivamente las que se ponen a disposición de los usuarios en este [enlace](https://launchyoo.com/).

**6\. Publicidad y promociones en Launchyoo: **

**6.1. Publicidad:** Las publicaciones realizadas en Launchyoo por los usuarios con contenido promocional estarán sujetas a la Ley 34/1988 de 11 de noviembre General de Publicidad, a la Ley 3/1991 de 10 de enero de Competencia Desleal y a cualquier norma concordante, siendo de su exclusiva responsabilidad que la acción promocional que en su caso realice respete tales disposiciones, asumiendo de forma íntegra y exclusiva cualquier consecuencia que por la falta de observancia de estas se derive.

En especial, se deberán tomar las cautelas necesarias para que el contenido relativo al producto, marca o servicio que se promocione a través de Launchyoo, no sea considerado una práctica comercial engañosa, encubierta o de venta piramidal, debiendo incluir contenidos claramente identificables para el consumidor o usuario que identifiquen que se trata de un contenido publicitario, en caso de ser así.

Declinamos cualquier responsabilidad por el incumplimiento por parte de los usuarios de la normativa aplicable en materia de publicidad.

**6.2. Sorteos y concursos en Launchyoo:** Se permite a los usuarios la realización de sorteos y o concursos promocionales a través de Launchyoo siempre y cuando se cumplan todas y cada una de las siguientes condiciones:

- El usuario de Launchyoo acepta que la organización del sorteo o concurso la realiza bajo su exclusiva responsabilidad, siendo el único responsable del cumplimiento de todos los requisitos legales para la organización del concurso o sorteo (bases legales, obligaciones tributarias, autorizaciones administrativas en su caso necesarias, etc.)

- En las bases legales del concurso o sorteo debe hacerse constar de forma expresa que el mismo no está organizado, auspiciado, administrado, patrocinado ni relacionado de ninguna forma con Launchyoo, exonerándonos de cualquier responsabilidad derivada del sorteo o concurso.

- Los sorteos y concursos en ningún caso deberán basar su participación en compartir publicaciones en el/los muros de los usuarios, etiquetar usuarios y/o cualquier otra acción similar que se sirva de las funcionalidades de Launchyoo.

En caso de incumplimiento de cualquiera de los anteriores requisitos, PECH NETWORK podrá retirar cualquier concurso o sorteo organizado sirviéndose de Launchyoo.

**7\. Exclusión de garantías y responsabilidad:** No podemos garantizar la disponibilidad y continuidad ininterrumpida del funcionamiento de Launchyoo debido a la posibilidad de problemas en Internet, caídas de servidores, fallos, averías, lentitud o errores en el acceso y en el uso de los servicios y otras posibles contingencias o situaciones imprevisibles, ya resulten imputables PECH NETWORK o a terceros. 

Asimismo, podrá suspenderse temporalmente el servicio por motivos de seguridad o por labores de mantenimiento y actualización de la propia Plataforma.

Cuando ello sea razonablemente posible, advertiremos de forma previa sobre las interrupciones en el funcionamiento de la Plataforma, el acceso a sus contenidos, o la prestación de los servicios. 

No podemos garantizar de forma absoluta la veracidad, exactitud, exhaustividad, actualidad, licitud, fiabilidad y utilidad de los contenidos y servicios de la Plataforma aportados o cedidos por terceros ajenos a esta entidad.

No asumimos, ni nos identificamos con las opiniones, comentarios o actuaciones de los usuarios, ni detentamos actividad editorial alguna en este ámbito. Los usuarios (y, en su caso, sus representantes legales) serán los únicos responsables por dichas opiniones, comentarios o actuaciones en la Plataforma, salvo en lo que al respecto disponga la normativa de aplicación.

A pesar de las múltiples prohibiciones, reglas y recomendaciones que dirigimos a los usuarios de Launchyoo, no podemos garantizar, ni nos hacemos responsables en modo alguno de que estos hagan un uso adecuado y diligente de la Plataforma conforme a lo previsto en los presentes términos y condiciones. No obstante, podremos implementar sistemas de seguridad y detección en este ámbito y adoptaremos las medidas correctivas necesarias desde el momento que se tenga conocimiento efectivo de posibles incumplimientos al respecto, velando por la legitimidad general de los servicios, contenidos, imágenes y comentarios que puedan volcarse en la red social sin que ello represente obligación de control alguna a cargo de PECH NETWORK.

 Los usuarios de Launchyoo (y, en su caso, sus respectivos responsables legales) son, con carácter general, los únicos responsables por las actuaciones, comentarios, imágenes, contenidos e información que publiquen o aporten en la Plataforma, así como de la adecuada gestión del perfil y de las actividades y/o eventos que publiquen.

PECH NETWORK no es un servicio de hosting o de alojamiento de archivos, por lo que, en el caso de que por cualquier causa, el servicio deje de prestarse, lo comunicará con suficiente antelación para que puedas descargarte los archivos que hubieras subido a la Plataforma. Transcurrido el plazo comunicado a tal fin, PECH NETWORK eliminará todos los contenidos que hayas subido a la Plataforma.

**8\. Política de enlaces:**

**8.1. Enlaces desde otros sitios web:** Si pretendes enlazar desde una página web de otro portal de Internet a cualquiera de nuestras páginas web, deberás cumplir las siguientes condiciones:

- No se permite, la reproducción total o parcial de ninguno de los servicios ni contenidos del sitio web, ni el establecimiento de deep-links, ni enlaces IMG o de imagen, ni frames con la web de Launchyoo sin nuestra previa autorización expresa y por escrito.

- No se realizará ninguna manifestación falsa, inexacta o incorrecta sobre la web de Launchyoo, ni sobre los servicios o contenidos de ésta. Salvo aquellos signos que formen parte del enlace, la página web en la que se establezca no contendrá ninguna marca, nombre comercial, rótulo de establecimiento, denominación, logotipo, eslogan u otros signos distintivos pertenecientes a Launchyoo, salvo autorización expresa de ésta.

- El establecimiento del enlace no implicará la existencia de relación alguna entre Launchyoo y el titular de la página web o del portal desde el cual se realice, ni el conocimiento y aceptación de Launchyoo de los servicios y contenidos ofrecidos en dicha página web o portal.

- No seremos responsables de los contenidos o servicios puestos a disposición del público en la página web o portal desde el cual se realice el hipervínculo, ni de las informaciones y manifestaciones incluidas en los mismos.

**8.2. Enlaces hacia otras webs:** El sitio web de Launchyoo puede poner a disposición del usuario enlaces hacia otros sitios web gestionados y controlados por terceros, con la exclusiva función de facilitar a los usuarios la búsqueda de información, contenidos y servicios en Internet, sin que en ningún caso pueda considerarse que Launchyoo comercializa, dirige, y/o controla de forma previa, ni hace propios los contenidos, servicios, informaciones y manifestaciones disponibles en dichos sitios web.

No asumimos ningún tipo de responsabilidad, siquiera de forma indirecta o subsidiaria, por los contenidos, informaciones, comunicaciones, opiniones o servicios enlazados desde esos sitios webs no gestionados por nosotros y que resulten accesibles a través de Launchyoo, ni garantizamos la ausencia de virus u otros elementos en los mismos que puedan producir alteraciones en el sistema informático (hardware y software), en los documentos o los ficheros del usuario, excluyendo cualquier responsabilidad por los daños de cualquier clase causados al usuario por este motivo.

**9\. Derecho de exclusión:** Nos reservamos el derecho de denegar o retirar el acceso a Launchyoo, sin necesidad de comunicación previa, a instancia propia o de un tercero, a aquellos usuarios que incumplan cualquiera de los presentes términos y condiciones, o de las condiciones particulares que, en su caso, les sean de aplicación.

La denegación de acceso podrá realizarse por cualquier medio técnico óptimo para esa finalidad.

**10\. Condiciones de los Servicios de Pago de Launchyoo:** El procedimiento de contratación de los servicios de pago de Launchyoo podrá realizarse en español, inglés o portugués y la contratación de tales servicios se podrá llevar a cabo siempre y cuando los mismos se encuentren activos.

Los servicios de pago que puedes contratar, una vez activos, son:

- Suscripción anual de mejora de perfil.

- Suscripción anual del servicio para eliminar anuncios.

**10.1. Suscripción para mejora (upgrade) de perfil:** Para poder suscribirte a un perfil superior al perfil USUARIO, es necesario haber completado de forma satisfactoria el registro y pulsar en el botón o interfaz de “mejorar perfil/upgrade” y seleccionar la forma de pago.

Durante el plazo de un año a contar desde la fecha de contratación de la suscripción podrás disfrutar del perfil mejorado/upgraded que hayas elegido.

Antes del transcurso del año de suscripción, con suficiente antelación, te advertiremos de la necesidad de renovar el servicio. Si no lo renuevas volverás a un perfil de tipo USUARIO.

**10.2. Suscripción al servicio para eliminar anuncios:** Si deseas utilizar Launchyoo sin que se te muestre publicidad, deberás pulsar en el botón o interfaz "eliminar anuncios" y seleccionar la forma de pago.

**10.3. Formas de pago de los "Servicios de Pago de Launchyoo":** PECH NETWORK pone a tu disposición las siguientes formas de pago para abonar las suscripciones a los Servicios de Pago de Launchyoo:

**- Pago mediante tarjeta de crédito/débito:** El cargo en su tarjeta se realiza en tiempo real a través del TPV virtual, una vez se haya comprobado que tus datos son correctos. Los datos de tu tarjeta se transmitirán cifrados con la exclusiva finalidad de posibilitar el pago.

Si optas por esta forma de pago, estás garantizando que tienes plena autorización para el uso de la tarjeta de crédito/débito de que se trate en el proceso de contratación.

**- Pago mediante la pasarela de Paypal:** Para poder pagar con este sistema, debes disponer de una cuenta de usuario de PayPal. Esta forma de pago podría acarrear sobrecostes.

Se advierte, que si la entidad emisora de la tarjeta o la pasarela de pago utilizada no autorizan el pago, no se considerará realizada la contratación.

El importe a la anualidad que corresponda se satisfará en el momento de la contratación o renovación de la suscripción. 

**10.4. Precios:** Los precios de la suscripción anual serán los publicados en la Web e incluirán el I.V.A. que en su caso resulte aplicable en cada momento.

Los precios podrán ser modificados al alza o a la baja, en función de múltiples causas, no obstante cualquier modificación de los precios surtirá efecto a partir de la fecha de modificación de estos, no afectando a las solicitudes de contratación realizadas con el precio anterior a la modificación.

**10.5. Facturación:** El usuario consiente de forma expresa la expedición por PECH NETWORK de la factura electrónica. La factura electrónica se recibirá por correo electrónico. El usuario que tenga la consideración de consumidor y usuario podrá revocar ese consentimiento con el envío de un correo electrónico en tal sentido a la dirección: customercare@launchyoo.com

**10.6. Duración y modificación a un perfil superior:** La duración de la suscripción será anual, renovable por idénticos períodos a menos que se produzca alguna de las causas de terminación del contrato previstas en las presentes condiciones

El usuario que desee modificar el perfil contratado a un perfil superior podrá realizarlo en cualquier momento, desde su propio Panel de Control. Solicitada la modificación, se generará automáticamente una liquidación que tendrá en cuenta:

- La parte proporcional del precio del perfil vigente al período disfrutado del año en curso.

- La parte proporcional del precio del perfil superior del año en curso pendiente de disfrutar.

La diferencia de precio deberá ser satisfecha en el momento de realizar la modificación del perfil.

**10.7. Terminación:** La terminación de la suscripción de los servicios de pago tendrá lugar por las causas legales y por el incumplimiento de los presentes Términos y Condiciones, sin perjuicio del derecho a reclamarlos daños y perjuicios que se puedan irrogar por razón de tal incumplimiento.

**10.8. Resolución unilateral por parte del usuario:** El usuario podrá resolver el contrato en cualquier momento comunicándolo a PECH NETWORK remitiendo un correo electrónico, manteniéndose los Servicios de Pago de Launchyoo hasta el final del período anual, sin que tenga por ello derecho a reembolso alguno.

**10.9. Atención al usuario**: Cualquier consulta o reclamación relacionada con la prestación del servicio será atendida en la dirección de correo electrónico:

customerservice@launchyoo.com

**Plataforma europea de resolución de litigios en línea:** Se informa que el usuario, residente en la Unión Europea, también dispone de la posibilidad de acudir a la plataforma ODR (Online Dispute Resolution -- Resolución de Disputas en Línea) a través del siguiente enlace:

https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage

A través de dicha plataforma se permite a consumidores y comerciantes presentar reclamaciones mediante un formulario electrónico disponible en todas las lenguas de la Unión Europea, para todos los asuntos relacionados con comercio electrónico o prestación de servicios en la red, conforme a lo previsto en el Reglamento 524/2013 del Parlamento Europeo y del Consejo de 21 de mayo de 2013 y la Directiva 2013/11/UE del Parlamento Europeo y del Consejo sobre resolución alternativa de litigios en materia de consumo.

**10.10. Derecho de desistimiento:** Si ostentas la condición de consumidor o usuario conforme a lo dispuesto en el Texto Refundido de la Ley General de Defensa de los Consumidores y Usuarios (TRLGDCYU)puedes desistir (dejar sin efecto la suscripción) en el plazo de 14 días a contar desde la fecha de alta de la suscripción sin necesidad de justificar tu decisión y sin penalización de ninguna clase a través de una declaración inequívoca (por ejemplo, una comunicación remitida por correo electrónico o por correo postal). A tal fin, podrás utilizar el modelo de formulario de desistimiento que figura a continuación, aunque su uso no es obligatorio:

***Modelo de formulario de desistimiento conforme al anexo de la Ley de Consumidores y Usuarios de 2007 (el usuario puede copiar y pegar el siguiente formulario en un correo electrónico y completar sus datos si desea desistir de la suscripción):***

A la atención de PECH Network Services, S.L.U., con domicilio social en C/ Ramón Llull, 31, 4, 11, 46021, Valencia (España), con C.I.F. nº: B40561185. Correo electrónico de contacto: customercare@launchyoo.com

Por la presente le comunico/comunicamos que desisto de la suscripción cuya solicitud se realizó con fecha ___/___/20__.

Nombre y apellidos del/los consumidor/es: ________________________________

Domicilio del/los consumidor/es: ________________________________________

Fecha de la comunicación del desistimiento: ___/___/20__

**Consecuencias del desistimiento:** En caso de ejercicio del derecho de desistimiento, PECH NETWORK reembolsará los importes satisfechos con ocasión de la suscripción correspondiente.

**10.11. Promociones:** PECH NETWORK tendrá la facultad de establecer en cualquier momento campañas promocionales de sus servicios con determinadas ventajas para los usuarios, que permanecerán vigentes durante el tiempo que aparezcan publicadas en el Sitio Web o durante el tiempo establecido en la promoción de que se trate. Las promociones no serán en ningún caso acumulables.

**11\. Creación de campañas de anuncios:** Podrás crear campañas de anuncios en Launchyoo conforme a lo previsto en los Términos y condiciones de contratación de [publicidad en Launchyoo](https://app.launchyoo.com/privacy).

**12\. Actualización de los Términos y Condiciones de Uso:** Los presentes términos y condiciones de uso pueden experimentar actualizaciones por motivos legales, técnicos, corporativos o derivados de procesos de autorregulación, propios, o por adhesión voluntaria de PECH NETWORK a códigos de conducta existentes, de interés para su actividad y para la mejor protección de los derechos de sus usuarios. 

Te enviaremos una notificación (por correo electrónico o a través de Launchyoo) al menos 30 días antes de que la actualización vaya a surtir efecto, teniendo la posibilidad de revisar los cambios antes de que entren en vigor, a menos que tales cambios vengan impuestos por Ley. 

Una vez que cualquier actualización a los Términos y Condiciones esté vigente, quedarás sujeto a estos si continúas usando Launchyoo.

Si no aceptas la actualización de los Términos y Condiciones y ya no quieres formar parte de Launchyoo, puedes solicitar la cancelación de tu cuenta en cualquier momento en customercare@launchyoo.com.

**13\. Legislación aplicable y jurisdicción:** Las presentes condiciones de contratación se rigen por la legislación española. 

Si ostentas la condición de consumidor o usuario, para la resolución de los conflictos y con renuncia a cualquier otro fuero, serán competentes los juzgados y tribunales de tu domicilio.

Para el caso de que no ostentes la condición de consumidor o usuario, las partes se someten a los Juzgados y Tribunales de Valencia (España).

**14\. Traducción de los Textos legales:** Se hace constar de forma expresa que en caso de divergencia, disputa o discrepancia, los textos legales en español prevalecerán sobre los publicados en cualquier otro idioma.

**Última versión: Junio 2020**
` 







const ptText = enText;