import React, { Component } from 'react'

import endpoint from "../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import {store} from './../../store';

import ThePost from '../wall/components/ThePost';
import TheSharedPost from '../wall/components/TheSharedPost';

import { Redirect } from "react-router-dom";

import i18n from "../../i18n";

import Moment from 'react-moment';
import Modal from 'react-responsive-modal';
import Textarea from 'react-textarea-autosize';
import { toast } from 'react-toastify';

class AdminPost extends Component {

  constructor(props) {
    super();

    this.state = {
      	thePosts: '',
      	exist: true,
		theAdmin: false,
		theFlags: [],
		modalIsOpen: false,
		modalOptions: false,
		modalText: false
    };


    this.getPosts = this.getPosts.bind(this);

	this.onOpenModal 			= this.onOpenModal.bind(this);
	this.onCloseModal 			= this.onCloseModal.bind(this);
	this.handleOptionsChange 	= this.handleOptionsChange.bind(this);
    this.handleModalTextChange  = this.handleModalTextChange.bind(this);

    this.store = store.getState();

  }
  
	componentDidMount(){
		this.getPosts();
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.getPosts()
		}
	}  

	getPosts(){
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
			axios.get(endpoint.admin+'/posts/getpost/'+this.props.match.params.id, cfg)
	  		.then(res => {
	  			this.setState({
					thePosts: res.data.thePosts || []
				})
		  	}) 
			.catch((err) => {
				this.setState({
					exist: false
				})					
			})			  	 	
	}

    onOpenModal(options){
        this.setState({
            modalIsOpen: 	true,
            modalOptions: { ...this.state.modalOptions, status: options },
            modaltext: false,
        });
    };

    onCloseModal(){
        this.setState({
            modalIsOpen: 	false,
            modalOptions: 	false,
            modaltext: 		false
        });
    };

    handleOptionsChange(newOption){
        this.setState({
            modalOptions: { ...this.state.modalOptions, status: newOption }
        });
    };


	handleModalTextChange( event ){
	  	this.setState({ modalText : event.target.value });
	}

    moderatePost(postId, postStatus, comment){

		const reqBody = { postId: postId, postStatus: postStatus, comment: comment };
		const cfg = { 
			headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
        };

		axios.post(endpoint.admin+'/flags/moderate', reqBody, cfg)
			.then((result) => {
				toast.info(i18n.t('Moderation submitted'), { autoClose : 2500 } );		
				this.onCloseModal();
    			this.getPosts();
			})
			.catch((err) => {
				this.onCloseModal();
			})	    	
    }



  render () {


	if ( !this.store.user.auth ){
      return (<Redirect to={"/login/"}>login</Redirect>);
  	}  	

  	var postStatus = 'initial';
  	
  	if (this.state.modalOptions){
	  	if (this.state.modalOptions.hasOwnProperty('status')){
			postStatus = this.state.modalOptions.status;
	  	}

  	}else if( this.state.thePosts[0] ){
  		postStatus = this.state.thePosts[0][0].published;
  	}

  	var contentId = this.state.thePosts.length > 0 ? this.state.thePosts[0][0].id : null;


    return (
    	<div className="singlePost">


    		{ this.state.thePosts[0] ? 
		    		(
		    			<div className="adminSinglePost" onClick={ () => this.onOpenModal(this.state.thePosts[0][0].published) }>
			    			<span>
			    				{i18n.t('Post status')}{': '}
			    				<b>
					    			{ this.state.thePosts[0][0].published == 1  ? i18n.t('Published') : null }
					    			{ this.state.thePosts[0][0].published == 0  ? i18n.t('draft') : null }
					    			{ this.state.thePosts[0][0].published == -1 ? i18n.t('deleted') : null }
					    			{ this.state.thePosts[0][0].published == -2 ? i18n.t('suspended') : null }
			    				</b>
			    				<div className="">{i18n.t('Moderate post')} <i className="fas fa-arrow-right"></i></div>
			    			</span>
		    			</div>
		    		)
		    		:
		    		null
	    	}

    		{ (this.state.thePosts || []).map( (post, i) => {
		    		return post[0].type == 'share' ? <TheSharedPost thePost={post} key={'thePost'+i} /> : <ThePost thePost={post} key={'thePost'+i} />
		    		/*return <ThePost thePost={post} key={'thePost'+i} />*/
    			} 
	    	)}
	    	


			<Modal open={this.state.modalIsOpen} onClose={this.onCloseModal} classNames={{ modal: "postActionModal" }} center  showCloseIcon={false}>

    			<div className="shareNote flag">
    				<div className="theHead">{i18n.t('Moderate posts')} - {i18n.t('Set new post status')}<i className="fal fa-times" onClick={this.onCloseModal}></i></div>
    				<div></div>
		    		<div className="typeChoice">
		    			<div className={postStatus == -1 || postStatus == 'deleted'   ? 'active' : null } onClick={ () => this.handleOptionsChange('deleted')	 }><i className="fad fa-search"></i>{i18n.t('Deleted')}</div>
		    			<div className={postStatus == 0  || postStatus == 'draft'     ? 'active' : null } onClick={ () => this.handleOptionsChange('draft') 		 }><i className="fad fa-pause-circle"></i>{i18n.t('Draft')}</div>
		    			<div className={postStatus == -2 || postStatus == 'suspended' ? 'active' : null } onClick={ () => this.handleOptionsChange('suspended') 	 }><i className="fad fa-trash-alt"></i>{i18n.t('Suspended')}</div>
		    			<div className={postStatus == 1  || postStatus == 'published' ? 'active' : null } onClick={ () => this.handleOptionsChange('published')   }><i className="fad fa-play-circle"></i>{i18n.t('Published')}</div>
		    		</div>
		    		<Textarea minRows={1} maxRows={10} className="input-textarea" placeholder={"Inserisci un testo per la condivisione"} value={this.state.modalText ? this.state.modalText : '' } onChange={ this.handleModalTextChange } />
		    		<button className="sendPostButton flag" onClick={ () => this.moderatePost(this.state.thePosts[0][0].id, this.state.modalOptions.status, this.state.modalText) }>{i18n.t('Flag as inappropriate')}</button>
    			</div>

			</Modal>

    	</div>
    )
  }

}

export default AdminPost;