import React, { Component } from 'react'

import { Link } from "react-router-dom";

import endpoint from "../../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import { store } from './../../../store';

import Moment from 'react-moment';

import Rating from "react-rating";

import Textarea from 'react-textarea-autosize';

import { toast } from 'react-toastify';
import i18n from "../../../i18n";

import Picker from 'emoji-picker-react';

import Modal from 'react-responsive-modal';

import Linkify from 'linkifyjs/react';
import * as linkify from 'linkifyjs';
import hashtag from 'linkifyjs/plugins/hashtag';
import mention from 'linkifyjs/plugins/mention';
hashtag(linkify);
mention(linkify);


var linkifyOptions = {
	attributes: {
    rel: 'nofollow'
  	},
	formatHref: function (href, type) {
	    if (type === 'hashtag') {
	      //href = window.location.protocol + '//' + window.location.host+'/tag/' + href.substring(1);
	      href = '/tag/' + href.substring(1);
	    }
	    if (type === 'mention') {
	      //href = window.location.protocol + '//' + window.location.host+'/tag/' + href.substring(1);
	      href = '/mention/' + href.substring(1);

	    }
	   	return href;
	  }
};

class ThePostAction extends Component {

	constructor(props) {
		super();

		this.state = {
			thePost: false,
			newComment: '',
			commentSending: false,
			replyComment: false,
			replyCommentIndex: false,
			theComments: [],
			numberOfComments: 2,
			modalIsOpen: false,
			modalType: false,
			modalTextField: false,
			modalOptions: false,
			modalRateIsOpen: false,
			theRate: false
		};

		this.store = store.getState();

		this.handleRate = this.handleRate.bind(this);
		this.handleShare = this.handleShare.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.handleBookmark = this.handleBookmark.bind(this);
		this.handleComment = this.handleComment.bind(this);
		this.submitComment = this.submitComment.bind(this);

		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);
		this.handleModalTextChange = this.handleModalTextChange.bind(this);
		this.handleModalTextSubmit = this.handleModalTextSubmit.bind(this);
		this.handleFlag = this.handleFlag.bind(this);

		this.handleCommentClick = this.handleCommentClick.bind(this);

		this.textInput = React.createRef();

		this.getReateDetails = this.getReateDetails.bind(this);

		this.keyboard = this.keyboard.bind(this);

	}

	componentDidMount() {

	}

	componentDidUpdate(prevProps) {
	}


	onOpenModal(type, id) {
		this.setState({
			modalIsOpen: true,
			modalType: type
		}, () => {
			if (this.state.modalType == 'rate') {
				this.getReateDetails(id);
			}
		});
	};

	onCloseModal() {
		this.setState({
			modalIsOpen: false,
			modalType: false,
			modalTextField: false,
			modalOptions: false,
			theRate: false
		});
	};


	getReateDetails(id) {

		this.setState({ isRateLoading: true });


		const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(endpoint.posts + '/getratedetails/' + id, cfg)
			.then(res => {

				//console.log(res.data);
				this.setState({
					theRate: res.data.theRate,
					isRateLoading: false
				})

			})
			.catch(err => {
				//console.log(err.response.data, 'err');
				this.setState({ isRateLoading: false });
			})

	}



	handleCommentClick(postID) {
		this.textInput.current.focus();
		this.getComments(postID);

	}

	handleRate(type, postID, rate) {

		console.log("handleRate");

		const reqBody = { rateType: type, ratePostId: postID, rateValue: rate };
		const cfg = {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token }
		};

		axios.post(endpoint.posts + '/rate', reqBody, cfg)
			.then((result) => {
				this.setState({ thePost: result.data.thePosts[0][0] })
			})
			.catch((err) => {
			})
	}


	handleOptionsChange(option) {
		this.setState({ modalOptions: option });
	}


	handleModalTextChange(event) {
		this.setState({ modalTextField: event.target.value });
	}

	//handleModalTextSubmit( event, postID, shareType ){
	handleModalTextSubmit(postID, shareType) {

		if (shareType == 'share') {
			this.handleShare(postID);
		} else if (shareType == 'flag') {
			this.handleFlag(postID);
		}


	}

	handleShare(postId) {

		/*let group_slug = this.props.group_slug ? this.props.group_slug :  false;*/
		let group_slug = false;
		let content = this.state.modalTextField ? this.state.modalTextField : '';

		const reqBody = { postId: postId, group_slug: group_slug, content: content };
		const cfg = {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token }
		};

		axios.post(endpoint.posts + '/sharepost', reqBody, cfg)
			.then((result) => {
				toast.info(i18n.t('Post shared'), { autoClose: 2500 });
				this.onCloseModal();
			})
			.catch((err) => {
				this.onCloseModal();
			})
	}



	handleFlag(postId) {

		/*let group_slug = this.props.group_slug ? this.props.group_slug :  false;*/
		let group_slug = false;
		let content = this.state.modalTextField ? this.state.modalTextField : '';

		const reqBody = { postId: postId, flagComment: content, contentType: 'post', flagType: this.state.modalOptions ? this.state.modalOptions : 'generic' };
		const cfg = {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token }
		};

		axios.post(endpoint.posts + '/flagpost', reqBody, cfg)
			.then((result) => {
				toast.info(i18n.t('Flag sended'), { autoClose: 2500 });
				this.onCloseModal();
			})
			.catch((err) => {
				this.onCloseModal();
			})
	}



	handleRemove(postId) {

		/*let group_slug = this.props.group_slug ? this.props.group_slug :  false;*/
		let group_slug = false;

		const reqBody = { postId: postId };
		const cfg = {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token }
		};

		axios.post(endpoint.posts + '/removepost', reqBody, cfg)
			.then((result) => {
				toast.warning(i18n.t('Post removed'), { autoClose: 2500 });
				setTimeout(() => { window.location.reload() }, 1500)
			})
			.catch((err) => {
			})
	}


	handleBookmark(postId, action) {

		/*let group_slug = this.props.group_slug ? this.props.group_slug :  false;*/
		let group_slug = false;

		const reqBody = { postId: postId, action: action };
		const cfg = {
			headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token }
		};

		axios.post(endpoint.posts + '/setbookmark', reqBody, cfg)
			.then((result) => {
				if (action == "remove") {
					toast.warning(i18n.t('Post removed'), { autoClose: 2500 });
					setTimeout(() => { window.location.reload() }, 1500)
				} else if (action == "add") {
					toast.success(i18n.t('Post saved'), { autoClose: 2500 });
				}
			})
			.catch((err) => {
			})
	}


	handleComment(event) {
		this.setState({ newComment: event.target.value });
	}

	submitComment(event, postID) {

		let commentParentId = this.state.replyComment ? this.state.replyComment : null;

		if (event.keyCode == 13 && event.shiftKey == false && this.state.newComment.length > 1) {
			event.preventDefault();

			this.setState({ commentSending: true });

			const reqBody = { commentPostId: postID, commentText: this.state.newComment, commentParentId: commentParentId };
			const cfg = {
				headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token }
			};

			axios.post(endpoint.posts + '/comment', reqBody, cfg)
				.then((result) => {
					this.setState({ thePost: result.data.thePosts[0][0], newComment: '', numberOfComments: -1 })
				})
				.catch((err) => {
				})
				.finally(() => {
					this.setState({ commentSending: false, replyComment: false, replyCommentIndex: false });
				})

		}

	}


	getComments(postID) {

		const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(endpoint.posts + '/comments/' + postID, cfg)
			.then(result => {
				this.setState({
					thePost: result.data.thePosts[0][0], numberOfComments: -1
				});
			})

	}



	async keyboard(key) {

		await this.setState({
			newComment: this.state.newComment + key
		});
	}

	render() {

		const p = this.state.thePost == false ? this.props.thePost : this.state.thePost;

		var binary_up = '50%';
		var binary_down = '50%';
		var binary_up_class = 'medium';
		var binary_down_class = 'medium';

		if (p.rating_type == 'binary') {
			if (p.binary_count !== 0) {
				binary_up = Math.round(p.binary_up / p.binary_count * 100 * 100) / 100;
				//binary_down = 100 - binary_up;
				binary_down = Math.abs(Math.round(p.binary_down / p.binary_count * 100 * 100) / 100);

				if (binary_up >= 80) {
					binary_up_class = 'very-big';
					binary_down_class = 'very-small';
				} else if (binary_up > 60 && binary_up < 80) {
					binary_up_class = 'big';
					binary_down_class = 'small';
				} else if (binary_down >= 80) {
					binary_up_class = 'very-small';
					binary_down_class = 'very-big';
				} else if (binary_down > 60 && binary_up < 80) {
					binary_up_class = 'small';
					binary_down_class = 'big';
				}

			}

		}

		var userRating = { like: 0, love: 0, star: 0, binary: 0, comment: 0 };
		var userComment = 0;

		if (p.useraction.length > 0) {

			userComment = p.useraction[0].comment_count;

			(p.useraction).forEach((action) => {
				userRating[action.rate_type] = action.rate;
			})

		}

		return (

			<div className="theAction">
				{p.rating_type !== 'star' && p.rating_type !== 'binary' ?
					(
						<React.Fragment>
							<div className="like">
								<i className={userRating['like'] > 0 ? "fad fa-thumbs-up" : "fal fa-thumbs-up"} onClick={() => this.handleRate('like', p.id, 1)} ></i><span>{p.like_count > 0 ? p.like_count : 0}</span>
							</div>
							<div className="love">
								<i className={userRating['love'] > 0 ? "fas fa-heart" : "fal fa-heart"} onClick={() => this.handleRate('love', p.id, 1)} ></i><span>{p.love_count > 0 ? p.love_count : 0}</span>
							</div>
						</React.Fragment>
					)
					:
					null
				}
				{p.rating_type == 'star' ?
					(
						<div className="star">
							<Rating
								emptySymbol="fal fa-star"
								fullSymbol="fad fa-star"
								initialRating={userRating['star']}
								onChange={(rate) => this.handleRate('star', p.id, rate)}
							/>
							<i className="total fad fa-stars"></i>
							<span className="avg">{p.star_value == 0 ? 0 : Math.round(p.star_value / p.star_count * 10) / 10}</span>
							<i className="total fad fa-users"></i>
							<span className="avg">{p.star_count}</span>
						</div>
					)
					:
					null
				}
				{p.rating_type == 'binary' ?
					(
						<div className="binary">
							<div className="thumbs">
								<div className={'up ' + binary_up_class}><span>{binary_up}%</span><i className={userRating['binary'] == 1 ? "fad fa-thumbs-up up" : "fal fa-thumbs-up up"} onClick={() => this.handleRate('binary', p.id, 1)} ></i></div>
								<div className="sep"></div>
								<div className={'down ' + binary_down_class}><i className={userRating['binary'] == -1 ? "fad fa-thumbs-down down" : "fal fa-thumbs-down down"} onClick={() => this.handleRate('binary', p.id, -1)} ></i><span>{binary_down}%</span></div>
								<i className="total fad fa-users"></i>
								<span className="avg">{p.binary_count}</span>
							</div>
						</div>
					)
					:
					null
				}

				{
					p.like_count > 0 || p.love_count > 0 || p.star_count > 0 || p.binary_count > 0 ?
						<div className="stats" onClick={() => this.onOpenModal('rate', p.id)}>
							<i className="fal fa-analytics"></i>
						</div>
						:
						null
				}



				<div className="comments" onClick={() => this.handleCommentClick(p.id)}>
					<span>{p.comment_count}</span><i className={userComment > 0 ? "fal fa-comment-alt" : "fal fa-comment-alt"} > </i>
				</div>


				{/*<div className="share" onClick={ () => this.handleShare(p.id) }>*/}
				<div className="share" onClick={() => this.onOpenModal('share')}>
					<span>{p.share_count}</span><i className="fal fa-share-square"> </i>
				</div>

				{this.props.wallType !== 'bookmark' ?
					(
						<div className="bookmark" onClick={() => this.handleBookmark(p.id, 'add')}>
							<i className="fal fa-bookmark"></i>
						</div>
					)
					:
					(
						<div className="bookmark" onClick={() => this.handleBookmark(p.id, 'remove')}>
							<i className="fal fa-trash-alt"></i>
						</div>
					)
				}



				<div className="commentsAction">
					<Textarea minRows={1} maxRows={10} className="input-textarea" placeholder={i18n.t("Send a comment")} value={this.state.newComment} inputRef={this.textInput} onChange={this.handleComment} onKeyDown={!this.state.commentSending ? (event) => { this.submitComment(event, p.id) } : (event) => { event.preventDefault() }} />
					{this.state.replyCommentIndex !== false ?
						<div className="replyTo">
							<i onClick={() => this.setState( {replyComment: false, replyCommentIndex:false} ) } className="fa fa-times"></i>
							<span>Reply to: {p.comments[this.state.replyCommentIndex].username}</span>
						</div>
						:
						null
					}
					{this.state.commentSending && <i className="fas fa-spinner fa-spin"></i>}
					<div className="commentEmoji" >
						{this.state.emojiPanel ?
							<div className="panel">
								{/*<img className="emojiToggle" src={iconTags} onClick={ () => this.setState({emojiPanel: !this.state.emojiPanel}) }/>*/}
								<i className="emojiToggle fal fa-times-circle" onClick={() => this.setState({ emojiPanel: !this.state.emojiPanel })}></i>
								<Picker onEmojiClick={(event, emojiObject) => { this.keyboard(emojiObject.emoji) }} />
							</div>
							:
							<div className="panel">
								{/*<img className="emojiToggle" src={iconTags} onClick={ () => this.setState({emojiPanel: !this.state.emojiPanel}) }/>*/}
								<i className="emojiToggle fal fa-smile-wink" onClick={() => this.setState({ emojiPanel: !this.state.emojiPanel })}></i>
							</div>
						}
					</div>

					<div className="commentsList">
						{
							(this.state.numberOfComments !== -1 ? p.comments.slice(0, this.state.numberOfComments) : p.comments).map((comment, i) => {
								return (
									comment.parent_comment_id == null &&
									<div className="singleComment" key={"comment-" + i + comment.id}>
										<div className="user">
											<Link to={"/profile/" + comment.username}>
												<img alt={comment.username} src={comment.media_url !== null ? endpoint.mediaRoot + '/' + comment.media_url : endpoint.mediaRoot + '/placeholder-avatar.jpg'} />
											</Link>
										</div>
										<div className="content">
											<Link to={"/profile/" + comment.username}>
												<span>{comment.username} </span>
											</Link>
											{/* comment.comment */}
											<Linkify className="commentLinkified" options={linkifyOptions} >{comment.comment}</Linkify>
											<div className="date">
												<i className="fal fa-clock"></i>
												<Moment fromNow ago>{comment.date}</Moment>
												<span className="reply" onClick={() => { this.setState({ replyComment: comment.id, replyCommentIndex: i }, this.textInput.current.focus()) }}> <i className="fal fa-reply"></i>Reply</span>
											</div>
										</div>
										{comment.hasOwnProperty('nested') &&
											comment.nested.map((nested, ni) => {
												return (
													<div className="singleComment nested" key={"nested-comment-" + ni + nested.id}>
														<div className="user">
															<Link to={"/profile/" + nested.username}>
																<img alt={nested.username} src={nested.media_url !== null ? endpoint.mediaRoot + '/' + nested.media_url : endpoint.mediaRoot + '/placeholder-avatar.jpg'} />
															</Link>
														</div>
														<div className="content">
															<Link to={"/profile/" + comment.username}>
																<span>{nested.username} </span>
															</Link>
															{/*nested.comment*/}
															<Linkify className="commentLinkified" options={linkifyOptions} >{nested.comment}</Linkify>
															<div className="date">
																<i className="fal fa-clock"></i>
																<Moment fromNow ago>{nested.date}</Moment>
															</div>
														</div>
													</div>
												)
											})
										}
									</div>
								)
							})
						}
					</div>
					{this.state.numberOfComments !== -1 && p.comment_count > 2 ? (<div className="moreComments"><i className="fal fa-comment-dots" onClick={() => this.handleCommentClick(p.id)}></i></div>) : null}
				</div>

				<div className="moderationAction">
					<i className="fal fa-flag" onClick={() => this.onOpenModal('flag')}></i>
					{p.user_id == this.props.current_user ? <i className="fal fa-trash" onClick={() => this.handleRemove(p.id)}></i> : null}
				</div>


				<Modal open={this.state.modalIsOpen} onClose={this.onCloseModal} classNames={{ modal: "postActionModal" }} center showCloseIcon={false}>
					{this.state.modalType == 'share' &&
						<div className="shareNote share">
							{ /* onKeyDown={ (event) => { this.handleModalTextSubmit(event, p.id, 'share') } } */}
							<div className="theHead">{i18n.t('Add a note to the shared post')}<i className="fal fa-times" onClick={this.onCloseModal}></i></div>
							<Textarea minRows={1} maxRows={10} className="input-textarea" placeholder={"Additional information"} value={this.state.modalTextField ? this.state.modalTextField : ''} onChange={this.handleModalTextChange} />
							<button className="sendPostButton" onClick={() => this.handleModalTextSubmit(p.id, 'share')}>{i18n.t('Share')}</button>
						</div>
					}

					{this.state.modalType == 'flag' &&
						<div className="shareNote flag">
							{ /* onKeyDown={ (event) => { this.handleModalTextSubmit(event, p.id, 'share') } } */}
							<div className="theHead">{i18n.t('Add a note to your flag')}<i className="fal fa-times" onClick={this.onCloseModal}></i></div>
							<div className="typeChoice">
								<div className={this.state.modalOptions == 'copyrightViolation' ? 'active' : null} onClick={() => this.handleOptionsChange('copyrightViolation')}><i className="fad fa-copyright"></i>{i18n.t('Copyright violation')}</div>
								<div className={this.state.modalOptions == 'violent' ? 'active' : null} onClick={() => this.handleOptionsChange('violent')}><i className="fad fa-bone-break"></i>{i18n.t('Violent')}</div>
								<div className={this.state.modalOptions == 'fakeNews' ? 'active' : null} onClick={() => this.handleOptionsChange('fakeNews')}><i className="fad fa-bullhorn"></i>{i18n.t('Fake news')}</div>
								<div className={this.state.modalOptions == 'bullying' ? 'active' : null} onClick={() => this.handleOptionsChange('bullying')}><i className="fad fa-crosshairs"></i>{i18n.t('Bullying')}</div>
								<div className={this.state.modalOptions == 'sexualExplicit' ? 'active' : null} onClick={() => this.handleOptionsChange('sexualExplicit')}><i className="fad fa-pepper-hot"></i>{i18n.t('Explicit content')}</div>
								<div className={this.state.modalOptions == 'generic' ? 'active' : null} onClick={() => this.handleOptionsChange('generic')}><i className="fad fa-exclamation-circle"></i>{i18n.t('Generic')}</div>

							</div>
							<Textarea minRows={1} maxRows={10} className="input-textarea" placeholder={"Additional information"} value={this.state.modalTextField ? this.state.modalTextField : ''} onChange={this.handleModalTextChange} />
							<button className="sendPostButton flag" onClick={() => this.handleModalTextSubmit(p.id, 'flag')}>{i18n.t('Flag as inappropriate')}</button>
						</div>
					}

					{this.state.modalType == 'rate' &&
						<div className="shareNote flag">
							<div className="theHead">{i18n.t('Rate')}<i className="fal fa-times" onClick={this.onCloseModal}></i></div>
							{(this.state.theRate || []).map((rate, i) => {
								//<ListItem onPress={ () => global.navigateTo('/profile/'+rate.username)}>
								return (
									<div className="rate" key={'rate' + i}>
										<div className="user">
											<Link to={'/profile/' + rate.username}>
												<img src={rate.media_url ? endpoint.mediaRoot + '/' + rate.media_url : endpoint.mediaRoot + '/placeholder-avatar.jpg'} />
												<span>{rate.username}</span>
											</Link>
										</div>
										<div className="rate-icon">
											<Link to={'/profile/' + rate.username}>
												{
													rate.rate_type == 'like' ?
														<i className="fas fa-thumbs-up like" />
														:
														rate.rate_type == 'love' ?
															<i className="fas fa-heart love" />
															:
															rate.rate_type == 'binary' && rate.rate == 1 ?
																<i className="fas fa-thumbs-up up" />
																:
																rate.rate_type == 'binary' && rate.rate == -1 ?
																	<i className="fas fa-thumbs-down down" />
																	:
																	rate.rate_type == 'star' && rate.rate == 1 ?
																		<i className="fas fa-star star" />
																		:
																		rate.rate_type == 'star' && rate.rate == 2 ?
																			<React.Fragment><i className="fas fa-star star" /><i className="fas fa-star star" /></React.Fragment>
																			:
																			rate.rate_type == 'star' && rate.rate == 3 ?
																				<React.Fragment><i className="fas fa-star star" /><i className="fas fa-star star" /><i className="fas fa-star star" /></React.Fragment>
																				:
																				rate.rate_type == 'star' && rate.rate == 4 ?
																					<React.Fragment><i className="fas fa-star star" /><i className="fas fa-star star" /><i className="fas fa-star star" /><i className="fas fa-star star" /></React.Fragment>
																					:
																					rate.rate_type == 'star' && rate.rate == 5 ?
																						<React.Fragment><i className="fas fa-star star" /><i className="fas fa-star star" /><i className="fas fa-star star" /><i className="fas fa-star star" /><i className="fas fa-star star" /></React.Fragment>
																						:
																						null
												}
											</Link>
										</div>
									</div>
								)

							})}
							<button style={{ visibility: 'hidden' }} onClick={() => this.onCloseModal}>{i18n.t('Close')}</button>
						</div>
					}

				</Modal>


			</div>

		)
	}
}

export default ThePostAction;
