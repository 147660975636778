const endpoint = {
  _users: 'https://fzwc3twetc.execute-api.eu-central-1.amazonaws.com/dev/users',
  users: 'https://api.launchyoo.com/users',
  _posts: 'https://dj9l4zy34l.execute-api.eu-central-1.amazonaws.com/dev/posts',
  posts: 'https://api.launchyoo.com/posts',
  _admin: 'https://0jukkx2pk3.execute-api.eu-central-1.amazonaws.com/dev/admin',
  admin: 'https://api.launchyoo.com/admin',
  mediaRoot: 'https://s3.eu-central-1.amazonaws.com/media.launchyoo.com',
  websiteRoot: 'http://app.launchyoo.com/',
  _guestposts: 'https://dj9l4zy34l.execute-api.eu-central-1.amazonaws.com/dev/guest/posts',
  guestposts: 'https://api.launchyoo.com/guest/posts',
  _guestusers: 'https://fzwc3twetc.execute-api.eu-central-1.amazonaws.com/dev/guest/users',
  guestusers: 'https://api.launchyoo.com/guest/users',
};

export default endpoint;