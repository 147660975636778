import React, { Component } from 'react'

import { Link, Redirect } from "react-router-dom";
import endpoint from "../../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import {store} from './../../../store';

import Moment from 'react-moment';

import Player from '../../../components/wall/player';

import Linkify from 'linkifyjs/react';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Rating from "react-rating";

import ThePostAction from './ThePostAction';
import ThePostActionGuest from './ThePostActionGuest';

import { connect } from "react-redux";

import { toast } from 'react-toastify';
import i18n from "../../../i18n";

import iPics from '../../../img/Photo.svg';
import iPicsH from '../../../img/Photo_hover.svg';

import iVideo from '../../../img/Video.svg';
import iVideoH from '../../../img/Video_hover.svg';

import iAudio from '../../../img/Audio.svg';
import iAudioH from '../../../img/Audio_hover.svg';

import iMinimes from '../../../img/Minimes.svg';
import iMinimesH from '../../../img/Minimes_hover.svg';

import iNews from '../../../img/Blog.svg';
import iNewsH from '../../../img/Blog_hover.svg';


import * as linkify from 'linkifyjs';
import hashtag from 'linkifyjs/plugins/hashtag';
import mention from 'linkifyjs/plugins/mention';
hashtag(linkify);
mention(linkify);



var linkifyOptions = {
	attributes: {
    rel: 'nofollow'
  	},
	formatHref: function (href, type) {
	    if (type === 'hashtag') {
	      //href = window.location.protocol + '//' + window.location.host+'/tag/' + href.substring(1);
	      href = '/tag/' + href.substring(1);
	    }
	    if (type === 'mention') {
	      //href = window.location.protocol + '//' + window.location.host+'/tag/' + href.substring(1);
	      href = '/mention/' + href.substring(1);

	    }
	   	return href;
	  }
};

const mapStateToProps = state => {
  return { language: state.language, user: state.user, settings: state.settings};
};


class ConnectedThePost extends Component {

  constructor(props) {
    super();

    this.store = store.getState();

    this.handleLike 	= this.handleLike.bind(this);
    this.handleLove 	= this.handleLove.bind(this);
    this.handleRating 	= this.handleRating.bind(this);
    this.addToCollage 	= this.addToCollage.bind(this);

  }
  
	componentDidMount(){
	}

  	componentDidUpdate(prevProps) {
	}

	handleLike(){
		console.log('handleLike');
	} 

	handleLove(){
		console.log('handleLove');
	} 

	handleFlag(){
		console.log('handleFlag');
	} 

	handleRating(rate){
		console.log('handleFlag',rate);
	} 	

	timeDifference(time) 
	{
		time = new Date(time).getTime()
		var diff =(time - (new Date()).getTime() ) / 1000;
		var roundDiff = Math.abs(Math.round(diff));

		if ( Number.isInteger( Math.round(roundDiff) ) && roundDiff < 86400 && roundDiff > 0 ){
			return true;  
		}else{
			return false;
		}
	}

	addToCollage(media_id) 
	{

		let reqBody = { media_id: media_id, action: 'add' };

		const cfg = { 
			headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
	    };
		axios.post(endpoint.posts+'/moderatecollage', reqBody, cfg)
			.then((result) => {
			  	toast.info(i18n.t('Added to collage'), { autoClose : 2500 } );		
			})
			.catch((err) => {
			})	

	}

  render () {

  	// if ( !this.store.user.auth ){
    //   return (<Redirect to={"/login/"}>login</Redirect>);
  	// }  	

  	const p = this.props.thePost[0] || {}; 

  	var totalCount = 0;

  	var imageCount = 0;
  	var imageIndex = 0;

  	var videoCount = 0;
  	var videoIndex = 0;

  	var audioCount = 0;
  	var audioIndex = 0;

	{ (this.props.thePost || []).map( (media, i) => {

			if ( media.media_type == 'image' ){
				imageCount++;
				totalCount++;;
				imageIndex = i;
			}else if( media.media_type == 'video' ){
				videoCount++;
				totalCount++;;
				videoIndex = i;
			}else if( media.media_type == 'audio' ){
				audioCount++;
				totalCount++;
				audioIndex = i;
			}

		} 
	)}

	const _this = this;

	const settings = {
	  className: "theCarousel",
      /*infinite: true,*/
      /*centerPadding: "60px",*/
      slidesToShow: 1,
      speed: 500,
      slidesToScroll: 1,
      adaptiveHeight: true,
      dots: true,
      dotsClass: "dots slick-dots slick-thumb grid-"+totalCount,
      lazyLoad: 'progressive',
      arrows: false,
      customPaging: function(i) {
        return (
          <a>
            { _this.props.thePost[i].media_type == 'image' ? (<div className="square" style={{ backgroundImage : "url(" + endpoint.mediaRoot +'/'+  _this.props.thePost[i].media_url + ")" }}></div>) : null }
            { _this.props.thePost[i].media_type == 'video' ? (<div className="square"><i className="fad fa-film"></i></div>) : null }
            { _this.props.thePost[i].media_type == 'audio' ? (<div className="square"><i className="fad fa-microphone-alt"></i></div>) : null }
            { /* _this.props.thePost[i].media_type == 'image' ? <i className="fal fa-camera-alt"></i> : null */ }
            { /* _this.props.thePost[i].media_type == 'video' ? <i className="fal fa-video"></i> : null */ }
            { /* _this.props.thePost[i].media_type == 'video' ? <i className="fal fa-microphone-alt"></i> : null */ }
          </a>
        );
      }
    };

	var theMedia = '';
	
	var actionButton = ( i ) => {
		if ( this.props.settings.userSettings.length > 0 ){

			if ( p.user_id == this.props.settings.userSettings[0].user_id ){
				return(
					<div className="collage add stream-collage-action" onClick={ () => this.addToCollage(i) }>
						<i className="fa fa-images"></i>
					</div>
				)
			}else{
				return null
			}

		}else{
			return null
		}

	}

	if ( totalCount == 1 && videoCount == 1){
		theMedia = ( <div className="playerWrapper" style={{ width:"100%"}}><Player src={ endpoint.mediaRoot +'/'+ this.props.thePost[videoIndex].media_url } /> { actionButton(this.props.thePost[videoIndex].media_id) } </div>);
	}else if ( totalCount == 1 && audioCount == 1){
		theMedia = ( <div className="playerWrapper" style={{ width:"100%"}}><div className="audioPlayer"><Player src={ endpoint.mediaRoot +'/'+ this.props.thePost[audioIndex].media_url } /></div> { actionButton(this.props.thePost[audioIndex].media_id) } </div>);
	}else if ( totalCount == 1 && imageCount == 1){
		theMedia = ( <div className="imageWrapper"><img src={ endpoint.mediaRoot +'/'+ this.props.thePost[imageIndex].media_url } /> { actionButton(this.props.thePost[imageIndex].media_id) } </div>);
	}else if ( imageCount > 1 && videoCount == 0 && audioCount == 0 ){
		theMedia = (
	    	<Slider {...settings}>
				{ (this.props.thePost || []).map( (media, i) => {
						if ( media.media_type == 'image' ){
							return (<div className={"imageWrapper"} key={"theMedia-"+p.id+i}><img src={ endpoint.mediaRoot +'/'+ this.props.thePost[i].media_url } /> { actionButton(this.props.thePost[i].media_id) } </div>)
						}
					} 
				)}	    		
	    	</Slider>
		);
	}else if ( totalCount > 1){
		theMedia = (
	    	<Slider {...settings}>
				{ (this.props.thePost || []).map( (media, i) => {
						if ( media.media_type == 'image' ){
							return (<div className={"imageWrapper"} key={"theMedia-"+p.id+i}><img src={ endpoint.mediaRoot +'/'+ this.props.thePost[i].media_url } /> { actionButton(this.props.thePost[i].media_id) } </div>)
						}else if ( media.media_type == 'video' ){
							return (<div className="playerWrapper video" key={"theMedia-"+p.id+i} style={{ width:"100%"}}><Player src={ endpoint.mediaRoot +'/'+ this.props.thePost[videoIndex].media_url } /> { actionButton(this.props.thePost[videoIndex].media_id) } </div>)
						}else if ( media.media_type == 'audio' ){
							return (<div className="playerWrapper audio" key={"theMedia-"+p.id+i} style={{ width:"100%;"}}><div className="audioPlayer"><Player src={ endpoint.mediaRoot +'/'+ this.props.thePost[audioIndex].media_url } /></div> { actionButton(this.props.thePost[audioIndex].media_id) } </div>)
						}
					} 
				)}	    		
	    	</Slider>
		);
	}


	/* COLORS */
    let ui_color_photo 	 = false;
    let ui_color_video 	 = false;
    let ui_color_audio 	 = false;
    let ui_color_minimes = false;
    let ui_color_news	 = false; 
    let ui_color_mix 	 = false;

    if ( this.props.settings.userSettings.length > 0){        
        
        let ui = this.props.settings.userSettings[0];      

        let isPhotoColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_photo);
        if (isPhotoColor){
          ui_color_photo = ui.ui_color_photo;
        }

        let isVideoColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_video);
        if (isVideoColor){
          ui_color_video = ui.ui_color_video;
        }

        let isAudioColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_audio);
        if (isAudioColor){
          ui_color_audio = ui.ui_color_audio;
        }

        let isMinimesColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_minimes);
        if (isMinimesColor){
          ui_color_minimes = ui.ui_color_minimes;
        }

        let isNewsColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_news);
        if (isNewsColor){
          ui_color_news = ui.ui_color_news;
        }

        let isMixColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_mix);
        if (isMixColor){
          ui_color_mix = ui.ui_color_mix;
        }

    }
	/* COLORS */
	
	/*
	var postFilter = { icon : 'fal fa-newspaper', to : '/wall/mix', iconColor: ui_color_news};

	if ( p.type == 'photo' ){
		postFilter = { icon : 'fad fa-camera-alt', to : '/wall/photo', iconColor: ui_color_photo}
	}else if( p.type == 'video' ){
		postFilter = { icon : 'fad fa-video', to : '/wall/video', iconColor: ui_color_video}
	}else if( p.type == 'audio' || p.type == 'podcast' ){
		postFilter = { icon : 'fad fa-microphone-alt', to : '/wall/audio', iconColor: ui_color_audio}
	}else if( p.type == 'minimes' ){
		postFilter = { icon : 'fad fa-comment-alt-lines', to : '/wall/minimes', iconColor: ui_color_minimes}
	}else{
		postFilter = { icon : 'fad fa-newspaper', to : '/wall/news', iconColor: ui_color_news}
	}
	*/

	var postFilter = { icon : iNewsH, to : '/wall/mix', iconColor: ui_color_news};

	if ( p.type == 'photo' ){
		postFilter = { icon : iPicsH, to : '/wall/photo', iconColor: ui_color_photo}
	}else if( p.type == 'video' ){
		postFilter = { icon : iVideoH, to : '/wall/video', iconColor: ui_color_video}
	}else if( p.type == 'audio' || p.type == 'podcast' ){
		postFilter = { icon : iAudioH, to : '/wall/audio', iconColor: ui_color_audio}
	}else if( p.type == 'minimes' ){
		postFilter = { icon : iMinimesH, to : '/wall/minimes', iconColor: ui_color_minimes}
	}else{
		postFilter = { icon : iNewsH, to : '/wall/news', iconColor: ui_color_news}
	}



	var emotionIcon = false;

	if (p.feel == 'love'){
		emotionIcon = 'love fas fa-kiss-wink-heart';
	}else if (p.feel == 'angry'){
		emotionIcon = 'angry fas fa-angry';
	}else if (p.feel == 'sad'){
		emotionIcon = 'sad fas fa-frown';
	}else if (p.feel == 'joy'){
		emotionIcon = 'joy fas fa-smile-beam';
	}else if (p.feel == 'surprise'){
		emotionIcon = 'surprise fas fa-surprise';
	}else if (p.feel == 'lol'){
		emotionIcon = 'lol fas fa-grin-tears';
	}else if (p.feel == 'meh'){
		emotionIcon = 'meh fas fa-meh';
	}else if (p.feel == 'tongue'){
		emotionIcon = 'tongue fas fa-grin-tongue';
	}else if (p.feel == 'grimace'){
		emotionIcon = 'grimace fas fa-grimace';
	}






    return (
    	<div className={"thePostV2 "+p.type} >
			<header>
				<Link to={"/profile/"+p.username}>
    				<img src={ p.avatar_url ? endpoint.mediaRoot +'/'+ p.avatar_url : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
    			</Link>
    			<div className="title">
    				<h2>
    					<Link to={"/profile/"+p.username}>{p.username}</Link>
		    			{ this.timeDifference(p.feel_last) && emotionIcon && false ? (<div className="profile-feel-icon feel"><i className={emotionIcon}> </i></div>) : null }
    				</h2>
    				<h3>
    					<Link to={"/post/"+p.id}><span>{p.profile_type == 2 ? i18n.t("Influencer") : p.profile_type == 3 ? i18n.t("Business") : p.profile_type == 4 ? i18n.t("Association") : i18n.t("User") }</span><div className="sep"></div><Moment fromNow>{p.date}</Moment><i className="fal fa-link"></i></Link>
    				</h3>
    			</div>
    			{/*<div className="type"><Link to={postFilter.to}><i className={postFilter.icon} style={ postFilter.iconColor ? { color : postFilter.iconColor } : null } ></i></Link></div>*/}
    			<div className="type"><Link to={postFilter.to}><img src={postFilter.icon} /></Link></div>
    		</header>
    		<section className="content">

    			<div className="theContent">  						
		    		<Linkify options={linkifyOptions} >
							{window.location.pathname.includes("/post/") ?
								p.content
								:
								<>
								{p.content.length > 900 ? 
									<>
										{p.content.substring(0, 900)}
										<Link class="postReadMore" to={"/post/"+p.id}>{i18n.t("Read more")}...</Link>
									</> 
									: 
									p.content
								}
								
								</>

							}
							</Linkify>
		    	</div>

    			<div className="theMedia">  		    	
			    	{theMedia}
		    	</div>
		    			    	
		    	{this.props.settings.userSettings.length > 0 && this.store.user.token ? 
			    	<ThePostAction thePost={p} autoLoadComments={ this.props.autoLoadComments ? p.id : false } group_slug={this.props.group_slug ? this.props.group_slug : false } current_user={this.props.settings.userSettings[0].user_id} wallType={ this.props.wallType ? this.props.wallType : false }/>
		    		:
			    	<ThePostActionGuest thePost={p} autoLoadComments={ this.props.autoLoadComments ? p.id : false } group_slug={this.props.group_slug ? this.props.group_slug : false } current_user={false} wallType={ this.props.wallType ? this.props.wallType : false }/>
		    	}

		    	{ /*<div className="theAction">
		    		<div className="like">
		    			<i className="fal fa-thumbs-up" onClick={this.handleLike} ></i><span>10</span>
		    		</div>
		    		<div className="love">
		    			<i className="fal fa-heart"  onClick={this.handleLove} ></i><span>16</span>
		    		</div>
		    		{ p.enable_rating ? 
		    			(
		    		<div className="star">
						<Rating
						  emptySymbol="fal fa-star"
						  fullSymbol="fas fa-star"
						  onChange= {(rate) => this.handleRating(rate)}
						/>
		    		</div>
		    			)
		    			:
		    			null
		    		}
		    	</div> */}
    		</section>
    	{/*
    		<header>
    			<img src={ p.media_url ? endpoint.mediaRoot +'/'+ p.media_url : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
    			<div className="title">
    				<h2>{p.username}</h2>
    				<h3><Moment fromNow ago>{p.date}</Moment></h3>
    			</div>
    		</header>
    		<section className="content">
    			<Linkify options={linkifyOptions} >{content}</Linkify>
    		</section>
    	*/}
    	</div>
    )
  }
}

//export default ThePost;


const ThePost = connect(mapStateToProps, null)(ConnectedThePost);
export default ThePost;
