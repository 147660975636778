import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native

import rootReducer from "../reducers/index";
import { apiMiddleware } from "../middleware";
import thunk from "redux-thunk";

// redux devtool debug
import { composeWithDevTools } from "redux-devtools-extension";


const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/*
const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(apiMiddleware, thunk))
);

export default store;
*/


const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['utility'] // blacklist: ['users'] blacklisted state are not persisted
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
	persistedReducer,
	storeEnhancers(applyMiddleware(apiMiddleware, thunk))
);

const persistor = persistStore(store);


export {store, persistor};



/*
const reducers = {
  // ... other reducers ...
  toastr: toastrReducer // <- Mounted at toastr.
}
const reducer = combineReducers(reducers)
const store = createStore(reducer)
*/