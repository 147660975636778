import React, { Component } from "react";
import { connect } from "react-redux";
import { saveSettings } from "../../../actions/index";
import { Link } from "react-router-dom";
import Textarea from 'react-textarea-autosize';
import Select from 'react-select';

import Moment from 'react-moment';

import i18n from "../../../i18n";

import { toast } from 'react-toastify';

import endpoint from "../../../config/endpoint";
import axios from "axios";
import {store} from './../../../store';

const selectOptions = [
  { value: 'help', label: 'Ask for help' },
  { value: 'suggestion', label: 'Leave your suggestion' },
  { value: 'feedback', label: 'Leave your feedback' },
  { value: 'comment', label: 'Leave your comment' },
  { value: 'abuse', label: 'Report an abuse' },
  { value: 'tech_problem', label: 'Report a tecnical problem' }
]



class Supportform extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
		msgSubject 	: '',
		msgContent 	: '',
		msgType 	: '',
		ticketsList : []
		}
	    this.handleSubmit 		= this.handleSubmit.bind(this);
	    this.handleChange 		= this.handleChange.bind(this);
	    this.handleSelectChange = this.handleSelectChange.bind(this);
	    this.ticketSubmit 		= this.ticketSubmit.bind(this);
	    this.ticketsGet 		= this.ticketsGet.bind(this);
	}

	handleSubmit(event) {
	    event.preventDefault();
    	const { msgSubject } = this.state;
    	const { msgContent } = this.state;
    	const { msgType }	 = this.state;
    	if ( this.state.msgContent.length < 10){
		  	toast.error(i18n.t("Message too short, please explain better"), { autoClose : 3000 } );
    	}else{
	    const dataObj = { msgSubject: this.state.msgSubject, msgContent: this.state.msgContent, msgType: this.state.msgType };
    		console.log(dataObj);
    		this.ticketSubmit( dataObj );
    	}
    }

	handleChange(event) {
	    event.preventDefault();
	    this.setState({ [event.target.id]: event.target.value });
	    console.log(this.state);

	}

	handleSelectChange(selected) {
		if ( selected !== null ){
			this.setState( { msgType: selected.value } );
		}else{
			this.setState( { msgType: '' } );			
		}
	}	

	ticketSubmit(dataObj) {

	let state = store.getState();

		const reqBody = { lang: i18n.language, dataObj : dataObj };
		const cfg = { headers: { 'Content-Type': 'application/json', 'Authorization': state.user.token } };
		axios.post(endpoint.users+'/ticketsubmit', reqBody, cfg)
			.then((result) => {
		    	this.setState( { ticketsList: result.data.ticketsList, msgSubject: '', msgContent: '', msgType: '' } )
			  	toast.success(i18n.t(result.data.success), { autoClose : 3000 } );
			})
			.catch((err) => {
			  	toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 3000 } );
			})  

	}

	ticketsGet() {

	let state = store.getState();

		const cfg = { headers: { 'Content-Type': 'application/json', 'Authorization': state.user.token } };
		console.log("ERROR");
		axios.get(endpoint.users+'/ticketsget', cfg)
			.then((result) => {
		    	this.setState( { ticketsList: result.data.ticketsList, msgSubject: '', msgContent: '', msgType: '' } )
			})
			.catch((err) => {
			  	toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 3000 } );
			})  

	}	

	componentDidMount(){
    	this.setState( { msgSubject: '', msgContent: '', msgType: '' } );
    	this.ticketsGet();    	
	}



  render() {
    return (
    	<div>

			<form onSubmit={ this.handleSubmit } className="settings-form">
				<h2>{  i18n.t("Speak with us") }</h2>
				<div className="intro">
					Lorem ipsum dolor sit amet...
				</div>

				<div className="input-row">
					<Select id="msgType" options={selectOptions} isClearable={true} isSearchable={true} onChange={ this.handleSelectChange } defaultValue={this.state.msgType} />
				</div>

				<div className="input-row">
					<input
					type="text"
					className="input-text"
					id="msgSubject"
					value={this.state.msgSubject}
					onChange={ this.handleChange }
					placeholder="Subject"
					autoCorrect="off" autoCapitalize="none"
					/>
				</div>

				<div className="input-row">
					<Textarea minRows={3} maxRows={10} className="input-textarea" id="msgContent" value={this.state.msgContent} onChange={ this.handleChange } />
				</div>

				<button type="submit" className="button btn-primary">
					{ i18n.t("Send") }
				</button>
		     </form>

		     <br/><br/><br/>
		     {  Array.isArray(this.state.ticketsList) ? (<SupportTickets tickets={this.state.ticketsList} />) : 'No submitted tickets' }

	     </div>

    );
  }
}


export default Supportform;



function SupportTickets(props){

	return(
	<div className="field is-grouped is-grouped-multiline saved-tags">
		{
			props.tickets.map( (ticket, i) =>{
				let tId 	 = ticket["id"];
				let tType 	 = ticket["type"];
				let tSubject = ticket["subject"];
				let tContent = ticket["content"];
				let tOpened  = ticket["opened"];
				let tParent  = ticket["parent"];
				let tDate 	 = ticket["date"];

				return(
					<div className="tickets-list" key={i}>
						<div><b>id: {tId}</b> - type : {tType} - subject : {tSubject} - content : {tContent}
							<b> <Moment fromNow ago>{tDate}</Moment></b>
						</div>
						<br/>
					</div>
				)
			})
		}
	</div>
	)

}