import React, { Component } from "react";
import { connect } from "react-redux";
import { saveSettings } from "../../../actions/index";
import { Link } from "react-router-dom";

import i18n from "../../../i18n";

import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import {divIcon} from 'leaflet';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import _ from 'lodash';

const provider = new OpenStreetMapProvider();

const sliderVisibilityStep = {
  0: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("Nobody"),
  },
  1: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("Friends"),
  }, 
  2: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("Friend of Friends"),
  },
  3: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("All"),
  }     
};

function mapDispatchToProps(dispatch) {
  return {
    saveSettings: settings => dispatch(saveSettings(settings))
  };
}

const mapStateToProps = state => {
  return { fetchedPosition: state.settings.userSettings };
};


class Connectedpositionform extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			userLocation : this.props.fetchedPosition[0].position_label,
			suggest : [],
			lat: '',
			lon : '',
			position_visibility 		: this.props.fetchedPosition[0].position_visibility
		}
	    this.handleSubmit 		= this.handleSubmit.bind(this);
	    this.handleChange 		= this.handleChange.bind(this);
	    this.sliderVisibility 	= this.sliderVisibility.bind(this);
	    this.locationSearch 	= _.debounce(this.locationSearch.bind(this), 500);
	    this.locationInput 		= this.locationInput.bind(this);
	    this.fetchLocation 		= this.fetchLocation.bind(this);
	}

	handleSubmit(event) {
	    event.preventDefault();
    	const { userLocation } = this.state;
    	const { lat } = this.state;
    	const { lon } = this.state;
    	const type = "position";
	    const dataObj = { userLocation : userLocation, lat: lat, lon: lon, position_visibility: this.state.position_visibility };
    	this.props.saveSettings({ type, dataObj });
    }

	handleChange(event) {
	    const fieldId = event.target.id;
	    const fieldValue = (event.target.getAttribute('type') == "checkbox") ?  event.target.checked : event.target.value; 
	    this.setState({ [fieldId]: fieldValue }, () => { console.log("state callback"); });
	    if ( fieldId == 'userLocation'){
		    this.locationSearch();	    	
	    }
	}

	sliderVisibility(value) {
		this.setState({ position_visibility: value }, () => { console.log("sliderVisibility", value); });
	}

	locationSearch(){
		return new Promise((resolve, reject) => {
		let results =  provider.search({ query: this.state.userLocation }).
			then( (results) => {
				this.setState(
					{ suggest : results },
					() => {
						if (results && results.length){
							this.setState({ lat : results[0].y, lon : results[0].x });

							let ll = this.state.userLocation;
							let l = ll.split(" ");
							if ( !isNaN(l[0]) && l[0].length > 1 ){
							    this.setState({ userLocation : results[0].label });
							}
		
						}
					}
				);
			});	

		})
	}

	locationInput(label, lat, lon) {
    	this.setState({ userLocation : label, suggest : [], lat : lat, lon: lon })
    }   

	locationCoord(lat, lon) {
    	this.setState({ lat : lat, lon : lon })
    }       

	fetchLocation(){
		if ( navigator.geolocation && this.state.lat.length == 0 && this.state.lat.length == 0 && this.state.userLocation.length == 0 ) {
		    navigator.geolocation.getCurrentPosition(
		            function(position) {
		            	this.setState({ lat : position.coords.latitude, lon : position.coords.longitude, userLocation : position.coords.latitude+' '+position.coords.longitude });
		            	this.locationSearch();
		            }.bind(this),
		            function(error) {
		                console.log(error);
		            },
		            {timeout: 5000, enableHighAccuracy: true, maximumAge: 75000}
		    )
		}else{
			this.locationSearch();
		}  
	}

	componentDidMount(){
		this.fetchLocation();
	}

  render() {

    const { savedposition } = this.state;
    const customMarkerIcon = divIcon({className: 'marker'});

    return (
		<form onSubmit={ this.handleSubmit } className="settings-form">
			<h2>{  i18n.t("Position") }</h2>
			<div className="intro">
				{ i18n.t("Set your position") }
			</div>
			<div className="input-row">
				<input
				type="text"
				className="input-text"
				id="userLocation"
				value={this.state.userLocation}
				onChange={ this.handleChange }
				placeholder="Location"
				autoCorrect="off" autoCapitalize="none" autoComplete="off"
				/>
				<div className={ (this.state.suggest && this.state.suggest.length) ? 'suggestResults visible' : 'suggestResults'}>
					<div className="results">
					{ (this.state.suggest && this.state.suggest.length) ? 		
						this.state.suggest.slice(0, 5).map( ( pin, i ) => {
							const pinLat = pin.y;
							const pinLon = pin.x;		
							return(<span key={i} onClick={ () => this.locationInput(pin.label, pinLat, pinLon) } >{pin.label}</span>)
						}) 
						:
						''
					}
					</div>
				</div>
			</div>


			<div className="settingsMap">
				<Map className="map" center={ [ this.state.lat, this.state.lon] } zoom={ (this.state.userLocation !== '') ? '13' : '1' } scrollWheelZoom={false} detectRetina={true} maxZoom="18">
			        <TileLayer
			          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			          detectRetina={true}
			        />

			        { (this.state.userLocation !== '') ? 
			        	(
			        		<Marker position={ [this.state.lat, this.state.lon] } icon={customMarkerIcon}>
					          <Popup>
					            {this.state.userLocation}
					          </Popup>
			       		 	</Marker>
			       		 ) 
			        	: 
			        	'' 
			       	}
			    </Map>
    		</div>

			<h2 style={{marginTop:'40px'}}>{  i18n.t("Position visibility") }</h2>
			<div className="single-field one-line">
			    <Slider dots min={0} max={3} marks={sliderVisibilityStep} step={1} onChange={ this.sliderVisibility } defaultValue={this.state.position_visibility} />
			</div>
			<br/><br/>

			<button type="submit" className="button btn-primary">
				{ i18n.t("Save") }
			</button>
	     </form>
    );
  }
}


const positionform = connect(mapStateToProps, mapDispatchToProps)(Connectedpositionform);
export default positionform;