import React, { Component } from 'react'

import endpoint from "../../config/endpoint";
import axios from "axios";
import {store} from './../../store';

import ThePost from './components/ThePost';
import TheSharedPost from './components/TheSharedPost';

import { Redirect } from "react-router-dom";

class Post extends Component {

  constructor(props) {
    super();

    this.state = {
      thePosts: '',
      exist: true
    };
    
    this.getPosts = this.getPosts.bind(this);

    this.store = store.getState();

  }
  
	componentDidMount(){
		this.getPosts();
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.id !== prevProps.match.params.id) {
			this.getPosts()
		}
	}  

	async getPosts(){
		let cfg, postEndpoint;
		if(this.store.user.token){
			cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
			postEndpoint = 	endpoint.posts+'/getpost/';		
		}else{
			cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json' } };
			postEndpoint = 	endpoint.guestposts+'/getpost/';		
		}
		axios.get(postEndpoint+this.props.match.params.id, cfg)
		.then(res => {
			this.setState({
				thePosts: res.data.thePosts || []
			})
		}) 
		.catch((err) => {
			this.setState({
				exist: false
			})					
		})			  	 	
	}

  render () {
    return (
    	<div className="singlePost">
    		{ (this.state.thePosts || []).map( (post, i) => {
		    		return post[0].type == 'share' ? <TheSharedPost thePost={post} key={'thePost'+i} /> : <ThePost thePost={post} key={'thePost'+i} />
    			} 
	    	)}
	    	{ this.state.exist ? null : <Redirect to='/wall/mix' /> }
    	</div>
    )
  }

}

export default Post;
