import React, { Component } from "react";
import { connect } from "react-redux";
import { saveSettings } from "../../../actions/index";
import { Link } from "react-router-dom";

import i18n from "../../../i18n";

import _ from 'lodash';


function mapDispatchToProps(dispatch) {
  return {
    saveSettings: settings => dispatch(saveSettings(settings))
  };
}

const mapStateToProps = state => {
  return { fetchedNotification: state.settings.userSettings };
};

class Connectednotificationform extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			notify_mention_post			: this.props.fetchedNotification[0].notify_mention_post,
			notify_mention_photo		: this.props.fetchedNotification[0].notify_mention_photo,
			notify_new_post_follow		: this.props.fetchedNotification[0].notify_new_post_follow,
			notify_new_post_hot			: this.props.fetchedNotification[0].notify_new_post_hot,
			notify_new_post_moderate	: this.props.fetchedNotification[0].notify_new_post_moderate,
			notify_new_post_private		: this.props.fetchedNotification[0].notify_new_post_private,
			notify_new_post_group		: this.props.fetchedNotification[0].notify_new_post_group,
			notify_contact_birthday		: this.props.fetchedNotification[0].notify_contact_birthday,
		}
	    this.handleSubmit 			= this.handleSubmit.bind(this);
	    this.handleChange 			= this.handleChange.bind(this);
	}

	handleSubmit(event) {
	    event.preventDefault();
    	const type = "notification";
	    const dataObj = { 
			notify_mention_post			: this.state.notify_mention_post,
			notify_mention_photo		: this.state.notify_mention_photo,
			notify_new_post_follow		: this.state.notify_new_post_follow,
			notify_new_post_hot			: this.state.notify_new_post_hot,
			notify_new_post_moderate	: this.state.notify_new_post_moderate,
			notify_new_post_private		: this.state.notify_new_post_private,
			notify_new_post_group		: this.state.notify_new_post_group,
			notify_contact_birthday		: this.state.notify_contact_birthday
	    };
    	this.props.saveSettings({ type, dataObj });
    }

	handleChange(event) {
	    const fieldId = event.target.id;
	    const fieldValue = (event.target.getAttribute('type') == "checkbox") ?  event.target.checked : event.target.value; 
	    this.setState({ [fieldId]: fieldValue }, () => { console.log("state callback"); });
	}


	componentDidMount(){
	}

  render() {

    return (
		<form onSubmit={ this.handleSubmit } className="settings-form">
			<h3>{  i18n.t("Notify me when") }</h3>
			<div className="single-field one-line">
				<span>{  i18n.t("New post mention") }</span>
				<div className="toggle">
					<input className="tgl tgl-light" id="notify_mention_post" type="checkbox" onChange={ this.handleChange } defaultChecked={this.state.notify_mention_post} />
		    		<label className="tgl-btn" htmlFor="notify_mention_post"></label>
				</div>
			</div>

			<div className="single-field one-line">
				<span>{  i18n.t("New photo mention") }</span>
				<div className="toggle">
					<input className="tgl tgl-light" id="notify_mention_photo" type="checkbox" onChange={ this.handleChange } defaultChecked={this.state.notify_mention_photo} />
		    		<label className="tgl-btn" htmlFor="notify_mention_photo"></label>
				</div>
			</div>

			<div className="single-field one-line">
				<span>{  i18n.t("New post by a follow") }</span>
				<div className="toggle">
					<input className="tgl tgl-light" id="notify_new_post_follow" type="checkbox" onChange={ this.handleChange } defaultChecked={this.state.notify_new_post_follow} />
		    		<label className="tgl-btn" htmlFor="notify_new_post_follow"></label>
				</div>
			</div>

			<div className="single-field one-line">
				<span>{  i18n.t("New post by hot contact") }</span>
				<div className="toggle">
					<input className="tgl tgl-light" id="notify_new_post_hot" type="checkbox" onChange={ this.handleChange } defaultChecked={this.state.notify_new_post_hot} />
		    		<label className="tgl-btn" htmlFor="notify_new_post_hot"></label>
				</div>
			</div>

			<div className="single-field one-line">
				<span>{  i18n.t("New post by moderate contact") }</span>
				<div className="toggle">
					<input className="tgl tgl-light" id="notify_new_post_moderate" type="checkbox" onChange={ this.handleChange } defaultChecked={this.state.notify_new_post_moderate} />
		    		<label className="tgl-btn" htmlFor="notify_new_post_moderate"></label>
				</div>
			</div>

			<div className="single-field one-line">
				<span>{  i18n.t("New private post") }</span>
				<div className="toggle">
					<input className="tgl tgl-light" id="notify_new_post_private" type="checkbox" onChange={ this.handleChange } defaultChecked={this.state.notify_new_post_private} />
		    		<label className="tgl-btn" htmlFor="notify_new_post_private"></label>
				</div>
			</div>

			<div className="single-field one-line">
				<span>{  i18n.t("New group post") }</span>
				<div className="toggle">
					<input className="tgl tgl-light" id="notify_new_post_group" type="checkbox" onChange={ this.handleChange } defaultChecked={this.state.notify_new_post_group} />
		    		<label className="tgl-btn" htmlFor="notify_new_post_group"></label>
				</div>
			</div>	

			<div className="single-field one-line">
				<span>{  i18n.t("Contact birthday") }</span>
				<div className="toggle">
					<input className="tgl tgl-light" id="notify_contact_birthday" type="checkbox" onChange={ this.handleChange } defaultChecked={this.state.notify_contact_birthday} />
		    		<label className="tgl-btn" htmlFor="notify_contact_birthday"></label>
				</div>
			</div>	

			<button type="submit" className="button btn-primary">
				{ i18n.t("Save") }
			</button>
	     </form>
    );
  }
}


const notificationform = connect(mapStateToProps, mapDispatchToProps)(Connectednotificationform);
export default notificationform;