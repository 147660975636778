import React, { Component } from "react";
import { connect } from "react-redux";
import { usersRegister } from "../../actions/index";
import { Link } from "react-router-dom";

import i18n from "../../i18n";

import { toast } from 'react-toastify';

import Modal from 'react-responsive-modal';

import ReactMarkdown from "react-markdown";

function mapDispatchToProps(dispatch) {
	return {
		usersRegister: register => dispatch(usersRegister(register))
	};
}

class ConnectedRegisterForm extends Component {
	constructor() {
		super();
		this.state = {
			name: "",
			email: "",
			password: "",
			repassword: "",
			profileType: false,
			referrer: "",
			checkTerms: false,
			checkCookies: false,
			checkAge: false,
			modalIsOpen: false,
			profileHint: false,
			ambassadorHint: false
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);

		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);

	}

	handleChange(event) {
		this.setState({ [event.target.id]: event.target.value });
	}
	handleSubmit(event) {
		event.preventDefault();

		this.setState({ modalIsOpen: false });

		if (!this.state.profileType) {
			toast.error(i18n.t('Select profile type'), { autoClose: 2500 });
			return false;
		}

		if (!this.state.checkTerms || !this.state.checkAge) {

			let errMsg = '';

			if (!this.state.checkTerms) { errMsg += 'Accept terms and conditions to proceed. ' }
			//if (!this.state.checkCookies) { errMsg += 'Accept cookies to proceed. '}
			if (!this.state.checkAge) { errMsg += 'Confirm that you are over 16 to continue. ' }

			toast.error(i18n.t(errMsg), { autoClose: 2500 });
			return false;
		}

		const { name } = this.state;
		const { email } = this.state;
		const { password } = this.state;
		const { repassword } = this.state;
		const profileType = this.state.profileType;
		const checkCookies = this.state.checkCookies ? 'accepted' : false;
		const lang = i18n.language;
		const referrer = this.state.referrer;

		this.props.usersRegister({ name, email, password, repassword, profileType, referrer, checkCookies, lang });
		this.setState({ name: name, email: email, password: password, repassword: repassword, profileType: this.state.profileType, referrer: this.state.referrer, checkCookies: checkCookies, lang: lang });
	}


	onOpenModal() {
		this.setState({ modalIsOpen: true });
	};

	onCloseModal() {
		this.setState({ modalIsOpen: false });
	};

	LinkRenderer(props) {
		return <a href={props.href} target="_blank">{props.children}</a>
	}

	render() {
		const { name } = this.state;
		const { email } = this.state;
		const { password } = this.state;
		const { repassword } = this.state;
		const { referrer } = this.state;

		return (
			<div className="registerFormV2">
				<form className="register-form">
					<div className="form-group" style={{ backgroundColor: '#FFF' }}>
						<h2>{i18n.t("Sign up")}</h2>
						<input
							type="text"
							className="input-text"
							id="name"
							value={name}
							onChange={this.handleChange}
							placeholder={i18n.t("Nickname")}
							autoCorrect="off" autoCapitalize="none"
						/>
						<input
							type="text"
							className="input-text"
							id="email"
							value={email}
							onChange={this.handleChange}
							placeholder="Email"
							autoCorrect="off" autoCapitalize="none"
						/>
						<input
							type="password"
							className="input-text"
							id="password"
							value={password}
							onChange={this.handleChange}
							placeholder={i18n.t("Password")}
							autoCorrect="off" autoCapitalize="none"
						/>
						<input
							type="password"
							className="input-text"
							id="repassword"
							value={repassword}
							onChange={this.handleChange}
							placeholder={i18n.t("Repeat password")}
							autoCorrect="off" autoCapitalize="none"
						/>

						<h3>{i18n.t("Profile types")}</h3>
						<div className="help">
							<span className="hint profileHint fal fa-lightbulb-on" onClick={() => this.setState({ profileHint: !this.state.profileHint })}></span>
							{
								this.state.profileHint &&
								<div className="hintContent">
									<span>
										{i18n.t("helpProfileDetails")}
									</span>
									<span>
										{i18n.t("helpProfileSuper")}
									</span>
									<span>
										{i18n.t("helpProfileInfluencer")}
									</span>
									<span>
										{i18n.t("helpProfileBusiness")}
									</span>
									<span>
										{i18n.t("helpProfileAssociation")}
									</span>
								</div>
							}

						</div>
						<div className="profile-type">
							<div className={this.state.profileType == 1 ? "button profile-type-btn active" : "button profile-type-btn"} onClick={() => this.setState({ profileType: 1 })}>{i18n.t("User")}</div>
							<div className={this.state.profileType == 2 ? "button profile-type-btn active" : "button profile-type-btn"} onClick={() => this.setState({ profileType: 2 })}>{i18n.t("Influencer")}</div>
							<div className={this.state.profileType == 3 ? "button profile-type-btn active" : "button profile-type-btn"} onClick={() => this.setState({ profileType: 3 })}>{i18n.t("Business")}</div>
							<div className={this.state.profileType == 4 ? "button profile-type-btn active" : "button profile-type-btn"} onClick={() => this.setState({ profileType: 4 })}>{i18n.t("Association")}</div>
						</div>

						<input
							type="text"
							className="input-text"
							id="referrer"
							value={referrer}
							onChange={this.handleChange}
							placeholder="Ambassador (optional)"
							autoCorrect="off" autoCapitalize="none"
						/>
						<div className="help">
							<span className="hint ambassadorHint fal fa-lightbulb-on" onClick={() => this.setState({ ambassadorHint: !this.state.ambassadorHint })}></span>
							{
								this.state.ambassadorHint &&
								<div className="hintContent">
									<span>
										{i18n.t("helpAmbassador")}
									</span>
								</div>

							}
						</div>


						<div className="button btn-primary" onClick={this.onOpenModal}>
							{i18n.t("Sign up")}
						</div>
						<div className="other-action">
							<Link to="/activate">{i18n.t("Activate")}</Link>
							<Link to="/login">{i18n.t("Login")}</Link>
						</div>
					</div>


					<Modal open={this.state.modalIsOpen} onClose={this.onCloseModal} classNames={{ modal: "termsModalV2" }} center showCloseIcon={true}>

						<div className="">
							<h2>{i18n.t('Terms and Conditions')}</h2>
							{
								i18n.language == 'es' ?
									<div>
										{/*esText*/}
										<ReactMarkdown source={esText} renderers={{ link: this.LinkRenderer }} />
									</div>
									:
									i18n.language == 'pt' ?
										<div>
											{/*ptText*/}
											<ReactMarkdown source={ptText} renderers={{ link: this.LinkRenderer }} />
										</div>
										:
										<div>
											{/*enText*/}
											<ReactMarkdown source={enText} renderers={{ link: this.LinkRenderer }} />
										</div>
							}

							<div className="registrationFlag" onClick={() => { this.setState({ checkTerms: !this.state.checkTerms }) }}>
								<i className={this.state.checkTerms ? "far fa-check-square" : "far fa-square"}></i>
								<span> {i18n.language == 'es' ? esTerm : i18n.language == 'pt' ? ptTerm : enTerm} <Link to="/terms" target="_blank"><i className="fad fa-external-link"></i></Link> </span>
							</div>

							{/*
	              <div className="registrationFlag" onClick={ ()=>{ this.setState({checkCookies: !this.state.checkCookies }) } }>
	                <i className={ this.state.checkCookies ? "far fa-check-square" : "far fa-square" }></i> 
	                <span> { i18n.language == 'es' ? esCookie : i18n.language == 'pt' ? ptCookie : enCookie } <Link to="/cookies" target="_blank"><i className="fad fa-external-link"></i></Link> </span>
	              </div>
	            */}

							<div className="registrationFlag" onClick={() => { this.setState({ checkAge: !this.state.checkAge }) }}>
								<i className={this.state.checkAge ? "far fa-check-square" : "far fa-square"}></i>
								<span> {i18n.language == 'es' ? esAge : i18n.language == 'pt' ? ptAge : enAge}</span>
							</div>


						</div>

						<button type="submit" onClick={this.handleSubmit} className="button btn-primary" >
							Confirm registration
	            </button>

					</Modal>


				</form>
			</div>
		);
	}
}
const Registerform = connect(null, mapDispatchToProps)(ConnectedRegisterForm);
export default Registerform;


const enText = `
The Data Controller responsible for processing is PECH Network Services, S.L.U., in order to manage your registration, create your Launchyoo profile to enable interaction on the social network, the purchase of payment services offered, as well as showing you advertising related to your interests. All of this is based on the execution of a contract to which the data subject is party.

You have rights of access, rectification, deletion, limitation, portability or opposition to the processing of your data and the right not to be subject to automated decisions. You may exercise your rights by writing to customercare@launchyoo.com. You can find further information in our [Privacy Policy](http://app.launchyoo.com/privacy).

"PECH NETWORK installs own and third party cookies to analyse your browsing habits, obtain statistical information, show you personalised advertising, interact with other social networks, etc. If you click on the 'accept' button, you agree to their use. You can find further information in our Cookies Policy."
`;

const enTerm = `* I’ve read and accept the Terms and Conditions of Launchyoo.`;
const enCookie = `I accept the installation by PECH NETWORK of own and third party cookies.`;
const enAge = `* I am over 16 years old.`;


const esText = `
PECH Network Services, S.L.U. es la Responsable del Tratamiento de tus datos, con la finalidad de gestionar tu registro, crear tu perfil en Launchyoo para poder interactuar en la red social, la contratación de los servicios de pago ofertados, así como para mostrarte publicidad relacionada con tus intereses, todo ello basado en la ejecución de un contrato en el que el interesado es parte.

Tienes derecho de acceso, rectificación, supresión, limitación, oposición al tratamiento, portabilidad y a no ser objeto de decisiones automatizadas. Puedes ejercitar tus derechos en customercare@launchyoo.com. Más información en nuestra [Política de privacidad](http://app.launchyoo.com/privacy).

"PECH NETWORK instala cookies propias y de terceros para analizar tus hábitos de navegación, obtener información estadística, mostrarte publicidad personalizada, interactuar con otras redes sociales, etc. Si pulsas el botón "aceptar" consideramos que aceptas su uso. Más información en nuestra Política de cookies."
`;

const esTerm = `* He leído y acepto las Términos y Condiciones de Launchyoo.`;
const esCookie = `Acepto la instalación por PECH NETWORK de cookies propias y de terceros.`;
const esAge = `* Soy mayor de 16 años.`;


const ptText = `
A PECH Network Services, S.L.U. é a Responsável pelo Tratamentos dos seus dados, com a finalidade de gerir o seu registo, criar o seu perfil na Launchyoo para poder interagir na rede social, a contratação dos serviços pagos oferecidos, assim como para mostrar publicidade relacionada com os seus interesses, todo ele baseado na execução de um contrato no qual o interessado faz parte.

Tem direito de acesso, retificação, supressão, limitação, oposição ao tratamento, portabilidade e a não ser alvo de decisões automatizadas. Pode exercer os seus direitos em customercare@launchyoo.com. Mais informações na nossa [Política de privacidade](http://app.launchyoo.com/privacy).

"A PECH NETWORK instala cookies próprias e de terceiros para analisar os seus hábitos de navegação, obter informação estatística, mostrar-lhe publicidade personalizada, interagir com outras redes sociais, etc. Se carregar no botão "Aceitar" consideramos que aceita o seu uso. Mais informações na nossa Política de cookies."
`;

const ptTerm = `* Li e aceito os Termos e Condições da Launchyoo.`;
const ptCookie = `Aceito a instalação pela PECH NETWORK de cookies próprias e de terceiros.`;
const ptAge = `* Sou maior de 16 anos.`;

