import React, { Component } from "react";
import { connect } from "react-redux";
import { utilityLanguage } from "../actions/index";
import { Link } from "react-router-dom";

import i18n from "../i18n";

import Flag from "react-world-flags";

function mapDispatchToProps(dispatch) {
  return {
    utilityLanguage: language => dispatch(utilityLanguage(language))
  };
}

class ConnectedChangeLanguage extends Component {
  constructor() {
    super();
    this.state = {
      language: ""
    };
    this.setEn = this.setEn.bind(this);
    this.setPt = this.setPt.bind(this);
    this.setEs = this.setEs.bind(this);

  }

  setEn(event) {
    event.preventDefault();
    const language = 'en';
    this.props.utilityLanguage( language );
    this.setState({ language: language });
  }

  setPt(event) {
    event.preventDefault();
    const language = 'pt';
    this.props.utilityLanguage( language );
    this.setState({ language: language });
  }

  setEs(event) {
    event.preventDefault();
    const language = 'es';
    this.props.utilityLanguage( language );
    this.setState({ language: language });
  }    

  render() {
    const { language } = this.state;

    return (
      <div className="ChangeLanguage">
      	<div className="toggle"><i className="far fa-flag fa-duo"></i></div>
      	<a href="#" className="lng en" onClick={this.setEn}>
          <Flag
            code="GB"
            width="30" 
          />
          {/*<span className="f">e</span><span className="l">n</span>*/}
        </a>
      	<a href="#" className="lng es" onClick={this.setEs}>
          <Flag
            code="ES"
            width="24" 
          />        
          {/*<span className="f">e</span><span className="l">s</span>*/}
        </a>
      	<a href="#" className="lng pt" onClick={this.setPt}>
          <Flag
            code="PT"
            width="24" 
          />
          {/*<span className="f">p</span><span className="l">t</span>*/}
        </a>
      </div>
    );
  }
}
const ChangeLanguage = connect(null, mapDispatchToProps)(ConnectedChangeLanguage);
export default ChangeLanguage;