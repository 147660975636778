import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import axios from "axios";
import {store} from './../store';
import endpoint from "../config/endpoint";

import Moment from 'react-moment';

const mapStateToProps = state => {
  return { user: state.user };
};

class ConnectedFoot extends Component {

  constructor() {
    super();
    this.state = {
      activeTab: false,
      notification: [],
      notificationOpen: false,
      isSearching: false,
      theSearch: false,
      search: '',
      searchFocus: false
    };
    
    this.store = store.getState();

    this.liveUpdate = this.liveUpdate.bind(this);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.notificationRead = this.notificationRead.bind(this);

    this.onFocusSearch = this.onFocusSearch.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onSearch = this.onSearch.bind(this);

  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
    	console.log('route changed');
  		this.setState((prevState) => {
  		  return {activeTab: false, searchFocus: false};
  		})
    }
  }

  componentDidMount() {
    this.liveUpdate()
    setInterval(this.liveUpdate, 120000);
  }

  toggleNotification() {
    this.setState((prevState) => {
        if ( !prevState.notificationOpen ){
          this.notificationRead();
        }
        return {notificationOpen: !prevState.notificationOpen};
    })
    
  }  


  notificationRead(){

    const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
    axios.get(endpoint.users+'/notificationread/', cfg)
          .then(res => {
        })


  }

  liveUpdate(){

    const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
    axios.get(endpoint.users+'/live/', cfg)
          .then(res => {
            this.setState({
              notification: res.data.notification || []
           });
        })


  }


  onFocusSearch(ev) {
    this.setState({searchFocus: true})
  };  

  onBlurSearch(ev) {
    this.setState({searchFocus: false})
  };     


  onChangeSearch(ev) {
    this.setState({ search : ev.target.value }, ()=>{
        if ( this.state.search.length >= 3){
          this.onSearch(); 
        }
      }
    );   

  };    


  onSearch() {

    const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };

    let searchTerm = this.state.search.replace("#", "_");

    this.setState({isSearching: true});

    axios.get(endpoint.posts+'/search/'+searchTerm, cfg)
          .then(res => {
            this.setState({
          theSearch: res.data.theSearch || []
        });
        this.setState({isSearching: false});
          })
          .catch(err => {
        this.setState({ theSearch: false, isSearching: false });
          })

  };    




  render() {

  	const footerNotLogged = (
  		<div className="footer-menu primaryColor">
  			<ul>
          {/*
            <li><Link to="/register"><i className="fas fa-compress-arrows-alt"></i><span>Registrati</span></Link></li>        
            <li><Link to="/login"><i className="fas fa-sign-in-alt"></i><span>Login</span></Link></li>
          */}     
  				<li className="credits">Launchyoo is a PECH Network Services product</li>
  			</ul>
  		</div>
  	);


    var notificationCount = 0;
    const notification = (
      <div className={ this.state.notificationOpen ? 'notification open' : 'notification' }>
      {
        ( this.state.notification || [] ).map( (notify, i) => {
          if ( notify.hasOwnProperty("status") ){
            if (notify.status == 0) {
              notificationCount++;
            }
          }
          return(
            <div key={"notify-" + i} className={"notify "+ (notify.status == 0 ? "new" : "opened" ) }>
              <Link to={'/'+notify.target || "#"} onClick={ this.toggleNotification }>
                <div className="image">{ notify.image ? (<img src={endpoint.mediaRoot+'/'+notify.image} />) : (<i className="fas fa-bells"></i>) } </div>
                <div className="text"><div>{notify.text}</div> <div className="date"><Moment fromNow ago>{notify.date}</Moment></div></div>
              </Link>
            </div>
          )
        })
      }
      </div>
    );

  	const footerLogged = (
  		<div className="footer-menu primaryColor">
  			<ul>
          <li><Link to="/wall/mix"><i className="fal fa-paper-plane"></i>{/*<span>wall</span>*/}</Link></li>
          <li><Link to="/groups"><i className="fal fa-users"></i>{/*<span>groups</span>*/}</Link></li>
  				<li><Link to="/friends"><i className="fal fa-user-friends"></i>{/*<span>amici</span>*/}</Link></li>
  				<li className="searchWrapper">
            <input type="text" placeholder="search" className="search" id="footer-search" value={this.state.search} onChange={this.onChangeSearch} onFocus={ this.onFocusSearch } />
          </li>
          <li><Link to="/profile"><i className="fal fa-user"></i>{/*<span>Profile</span>*/}</Link></li>        
          <li><Link to="/settings"><i className="fal fa-cog"></i>{/*<span>Settings</span>*/}</Link></li>        
  				<li className="notificationButton"><i className="fal fa-dot-circle" onClick={ this.toggleNotification }></i>{/*<span>Notifiche</span>*/}  <div className="notificationWrapper">{ notificationCount > 0 ? (<div className="counter">{notificationCount}</div>) : "" } {notification}</div></li>
  			</ul>
  		</div>
  	);
 

    return (
	    <footer>
	    	{ (this.props.user.auth === true) ? footerLogged : footerNotLogged }


        { this.state.theSearch.length && this.state.searchFocus > 0 ?

          <div className="searchResults">
          {
            this.state.theSearch.map((search, i)=>{
              let searchPath = search.type == 'users' ? '/profile/'+search.slug : search.type == 'tag' ? '/tag/'+search.slug : search.type == 'group' ? '/group/'+search.slug : false;
              let searchIcon = search.type == 'users' ? 'user' : search.type == 'tag' ? 'tag' : search.type == 'group' ? 'group' : 'search';
              return(
                <div key={'search-'+i} className="item">
                  <Link to={searchPath}>
                    <div className="first">
                      <i className={'fal fa-'+searchIcon}></i>
                       <span>{search.display ? search.display : search.slug}</span>
                    </div>
                    <div className="second">                    
                      <span>{ search.type == 'users' ? '@' : search.type == 'tag' ? '#' : search.type == 'group' ? '&' : null }{search.slug}</span>
                    </div>
                  </Link>
                </div>
              )
            })
          }
            <i className="fal fa-times-circle searchClose" onClick={()=>{this.setState({searchFocus:false})}}></i>
          </div>
          
          :
          null
        }


	    </footer>
    );
  }
}

const Foot = connect(mapStateToProps)(ConnectedFoot);
export default withRouter(Foot);