import React, { Component } from 'react';
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";


export default class Terms extends Component {

  constructor() {
    super();
  }

  render() {
    return (
      <div className="terms">
<h2>Terms and Conditions</h2>
<p>
1. Identifying Data: The owner of the application called “Launchyoo” and the provider of information society services is: 
PECH Network Services, S.L.U.
Tax ID Number: B40561185
C/ Ramón Llull, 31, 4°, 11, 46021, Valencia (Spain)
Registered in the Mercantile Registryof Valencia, Volume 10.642, Book 7923, Folio 96, Sheet V- 186.754.
Contact email address: customercare@launchyoo.com
Hereinafter, “we”, “our” or “PECH NETWORK”.
</p>
<p>
2. What is Launchyoo: Launchyoo is a social network accessible via app, once successfully completing the registration process that allows you to interact with other profiles and in line content depending on user configuration, always agreeing with the present legal terms and conditions.
Launchyoo allows you to:
	•	Share content.
	•	Save publications to access them at a later time.
	•	Interact with publications from the rest of the users.
	•	Send indications to PECH NETWORK about users publications, warning them about fake news or inappropriate content.
Launchyoo allows sharing the following types of posts and associated files:
	•	Text.
	•	Short text.
	•	Audio file.
	•	Video file.
	•	Image file.
2.1. Access: By downloading, installing and creating your profile, you declare under your responsibility that you are of legal age and have sufficient legal capacity to accept the Terms and Conditions.
2.2.Appropriate use of Launchyoo: You are required to make appropriate use at all times of the resources we offer you, in accordance with the previsions in the Terms of Use, as well asrespecting morals, public order, good customs and current legislation, without damaging PECH NETWORK, or any third party,and undertake not to:
a) use these resources to engage in illegal activity, or contrary to good faith and public order;
b) cause damage to PECH NETWORK’S physical and logical systems, its suppliers or third parties
c) introduce or spread computerviruses on the network or any other physical or logical systems that could cause damage to PECH NETWORK or third parties.
d) use crawling techniques, screen scraping, web scraping, and any other programming technique or automated means that make it possible to extract information or data that is hosted, stored or accessible on the Website without the express written authorisation of PECH NETWORK.
2.3. User participation: The registry process of a new profile is available for ages above 16. 
In the event that the user wants to participate in any of the Launchyoo user interaction experiences, by commenting or generating content, it is strictly prohibited to spread content or propaganda that is illegal, racist, xenophobic, supports terrorism, attacks human rights, or that incites hatred, violates a person’s dignity and be offensive, slanderous and against order or public security. 
PECH NETWORK reserves the right to remove comments and/or content that breach the aforementioned or that are not suitable to be published on the Website. 
In any case, PECH NETWORK will not be held responsible for the opinions expressed or the content published by users in the forums, chats, blogs or other participation tools that may be created, except as expressly required by applicable law.
2.4. Accuracy of the data provided: As a user, you are responsible for ensuring that the data you provide is kept up to date, complete and accurate. We accept no responsibility for the breach of these requirements with respect to the data you provide us. 
</p>
<p>
3. Responsibility: We cannot guarantee that there will be no interruptions or errors when accessing the application. However, there is a firm commitment that as soon as we become aware of such incidents, we will carry out all the measures aimed at its restoration or repair, except for the occurrence of causes beyond our control that make it impossible or, complicate its execution. 
</p>
<p>
4. Intellectual and Industrial Property: 
4.1. Launchyoo Content: We have created all of the elements and/or content published on APP and have appropriate licenses and/or authorisation for its use.
Therefore, all the elements and/or content (such as images, audio, video, software or texts, brands and/or logos, colour combinations, structure and design, required applications for its operation, access and use, etc.) and the rights that can be held over them, are the exclusive property of PECH NETWORK.
Any form of reproduction, distribution, public communication and transformation, including any means of making available, all or part of the Application content, in any form or by any technical means, without express written authorisation by PECH NETWORK is strictly prohibited. 
In the event of undertaking any of the aforementioned prohibited actions, you will be infringing the Intellectual and/or Industrial Property rights of PECH NETWORK. Therefore, you must commit to respecting such rights, and must refrain from removing, altering, eluding or manipulating any protective device or security system that may be installed on PECH NETWORK’s Website.
4.2. User-Generated Content: By accepting these present Terms of Use, you grant PECH NETWORK as broad a license as legally required, to publish your contributions on the different participation services on the Application.
It is strictly forbidden to upload to the Application, share or use legally protected content without having the right to do so, both by Intellectual Property and Industrial Property laws, or by any other which, where appropriate, may apply. 
We offer you the possibility to report any type of violation of rights by any user or third partyby the introduction of certain content on the Website, notifying us of this circumstance, by sending an email to the address indicated in section 1. 
4.3. Obligations and limitations when publishing data and contents:
- You must hold rights property and intellectual property on the data you are exploiting and publishing.
- You must be able to demonstrate, if necessary, that you posses the authority to publish content by the person, persons or entities holding the rights of the content.
- User declaration. By the mere act of publishing or sharing content, you declare that:
	- You hold all the content rights and corresponding licenses, authorizations and/or legitimate permission of the rights holder, to share and upload the content.
	- You have the necessary authorization of each and every person that appears in these contents prior to publication in Launchyoo.
</p>
<p>
5. Linking Policy:
5.1. Links from websites: If you intend to establish a webpage link from another website to any of our application pages, you shall be subject to the following conditions:
- It is prohibited to reproduce all or part of any of theservices or content of the website, or create deep-links, IMG or image links and frames with the Websitewithout prior expressauthorisation in writing.
- No false, inaccurate or incorrect information will be included on the Website, or its services or content.With the exception of signs that form part of the link, the web page to which the link is establishedwill not contain any trademark, trade name, establishment name, company name,logo, slogan or other distinguishing signs belonging to PECH NETWORK, unless expressly authorised.
- Establishment of a link will not imply the existence of a relationship between PECH NETWORK and the owner of the website or portal with which the link is established, nor will it imply PECH NETWORK’S knowledge and acceptance of the services and content offered on said website or portal. 
- PECH NETWORK will not be held responsible for the content or services made available to the public on any web page or website from which the hyperlink is made, nor for the information and statements included therein.
5.2. Links to other websites: On Launchyoo there might be circumstances in which you find links to other websites that are managed and controlled by third parties, solely to facilitate the search for information, content and services on the internet, but in no case does PECH NETWORK market, control or own the content, services, information and declarations available on said third party websites.
We do not assume any type of responsibility, either indirectly or secondary, for the content, information, communications, opinions or services from websites that are not managed by us. Nor does PECH NETWORK guarantee the absence of viruses or other elements that may alter the user’s computer system (hardware and software), or in documents or files, which excludes any liability for damages of any kind caused for this reason. 
</p>
<p>
6.Breach. Right of Exclusion: Any breach of these present Terms of Use will be subject to prosecution, as well as any misuse of the Application, allowing PECH NETWORK to exercise the corresponding legal actions in defence of its legitimate interests. 
PECH NETWORK reserves the right to deny or withdraw access to the Application, without prior notice, at its own request or that of a third party, to all users who fail to comply with the Conditions established in this document. 
</p>
<p>
7. Modification of the present Terms: PECH NETWORK may modify the Terms contained herein at any time, being duly published as they appear here. Said Terms of Use will depend on their exposure and will be valid until they are modified by other duly published Terms. 
</p>
<p>
8. Governing Law: The present Terms of Use shall be governed by Spanish law.
</p>
<p>
Last update: November 2019
</p>

<h2>Privacy Policy</h2>
<p>	
1. Information about the data controller for processing personal data:
The Data Controller responsible for processing is PECH Network Services, S.L.U., at the address C/ Ramón Llull, 31, 4°, 11, 46021, Valencia (Spain), duly registered in the Mercantile Registry of Valencia, Volume 10.642, Book 7923, Folio 96, Sheet V- 186.754., with Tax ID Number: B40561185. Hereinafter, PECH NETWORK.
You can contact PECH NETWORK directly in relation to the present Privacy Policy, at the email: 
customercare@launchyoo.com
</p>
<p>
	2. Type of data collected:
The personal data collected is identifying data that will never be considered as special categories of data or personal data relating to criminal convictions and offences.
During the user registering process, we will ask for the following data:
	- A nickname.
	- Name and surnames.
	- An e-mail.
	- Age.
	- Sex.
	- A telephone number.
	- Profile picture.
	- Additionally, with ID verification purposes, we might request an official ID document.
	- Interests data (tags).

	2.1. Information gathered by PECH NETWORK due to the use of Launchyoo: Due to the use of the services of Launchyoo, the following information might be accessed:
	- IP of your device.
	- Communications and interactions with other members of the Platform.
	- Content you upload and render available to the public.
	- Geographical location.	
</p>
<p>
3. Purpose and legitimacy of Data Processing:
We will process the personal data that we collect for the purposes and legal basis for processing as listed below:
3.1. Users requesting information: If you request information from PECH NETWORK through forms available on the APP, your personal data will be used to meet this request. The legal basis for processing data is our legitimate interest inresponding to your request.
3.2. If you contract a service of PECH NETWORK: For the execution of a contract or pre-contract with PECH NETWORK on the legal basis of such implementation.
3.3. Segmentation for commercial purposes: Segmentation is a practice that involves creating user profiles to send information in a more personalised way, and that corresponds to the interests of the users.
Similarly, if you opt to receive email communications from us, said segmentation will ensure that the communications shallcorrespond to your interests. 
Under no circumstance shallthe development of these profiles include the introduction of automated decision-making,where it producesa legal effector a similarly significant effect on you. 
The legal basis for this processing is in the legitimate interest of PECH NETWORK for direct marketing purposes. 
We remind you that you have the right, at any time, to oppose the process of segmentation for commercial purposes.  
</p>
<p>
4. How long will we keep your personal data?: The personal data will be kept as long as there is a mutual interest in maintaining the purpose of the processing, and during the period of time in which responsibilities could be derivedfor the services provided to the interested party. Data can be maintained up to 6 years regarding accounting books, invoices, etc. (Article 30, Commercial Code); 5 years to exercise personal actions without special term (Article 1964, Civil Code); 4 years for tax obligations (Articles 66 et seq., General Tax Law). Always in accordance with Spanish legislation.
When data is no longer requiredfor such purposes,it will be deleted using adequate security measures to guarantee the pseudonymisation of the data or the total destruction thereof. 
</p>
<p>
5. Recipients: Data will not be communicated to third parties,except for legal obligation or prior authorisation.
PECH NETWORK has contracted certain services from companies with whom they have signed a Data Processing Agreement, in accordance with the applicable legislation regarding data protection, established within the European Union.
</p>
<p>
6. Do we share your data to third parties? We do not share your data to third parties from PECH NETWORK, unless obligated by law or after you have authorised us to do so.
Only PECH NETWORK and the entities in charge of the data treatment will have access to your data, and under no concept this data will be shared, transferred or sold to any third party.
</p>
<p>
7. Rights: If you have given us your personal data, you are entitled to exercise the following rights:	
	-     Right to withdraw consent at any time.
	-     Right to ACCESS your personal data.
	•	Right to request RECTIFICATION of inaccurate data. 
	•	Right to request its DELETION when, among other reasons, the data is no longer necessary for the purpose for which it was requested.
	•	In certain circumstances, you may request the RESTRICTION OF PROCESSING of your personal data, in which case we shall retain it solely to exercise or demand against claims.
	•	In certain circumstances and for reasons related to your particular situation, you may exercise the right to OBJECT to our processing of your data. We will stop processing your personal data, unlesscompelling legitimate grounds are given, or the exercise or defence of legal claims. 
	•	In certain circumstances and for reasons related to your particular situation, you may exercise the right to data PORTABILITY.This is complementary to the right of access, as the Data Subject can obtain the data given to PECH NETWORK in a format that is structured, commonly used and machine-readable, whichmay be transmitted directly to another entity at the request of the interested party. 
	You may exercise these rights by any means that enables reliable acknowledgement of the sending and receipt of your request, including a photocopy of your ID and/or any other document confirming your identity. The request should be sent to the address or email provided in section 1 of this Privacy Policy.
If you consider that any of your rights have been violated, as an interested party you have the right to file a claim with the Spanish Data Protection Agency(AEPD), located atC/ Jorge Juan, 6, 28001-Madrid https://www.agpd.es/or the electronic office: https://sedeagpd.gob.es/sede-electronica-web/. 
</p>
<p>
8. Security and update of personal data: With the aim of safeguarding the security of your personal information, we have taken all the necessary technical and organisational measures to ensure the security of the personal data provided. This is done to prevent itsalteration, loss and/or unauthorised processing or access, as required by regulation, in the case of lack of total security. 
In order to keep the data updated, it is essential for you to notify us of any modifications.

8. Confidentiality: All personnel participating in any of the processing stages shall process and handle your data under strict care and confidentiality. 
</p>
<p>
Last version: November 2019
</p>


      </div>
    );
  }
}
