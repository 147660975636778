import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import i18n from "../../../i18n";


import { toast } from 'react-toastify';

import endpoint from "../../../config/endpoint";
import media from "../../../config/media";
import axios from "axios";
import {store} from './../../../store';

import Compressor from 'compressorjs';

import FileDrop from 'react-file-drop';


const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


class ConnectedProfileCover extends Component {

	constructor(props) {
		super(props);
	
	    this.submitCover 		= this.submitCover.bind(this);
	    this.compressImage	 	= this.compressImage.bind(this);
	    this.coverDrop	 		= this.coverDrop.bind(this);
	    this.updateParent	 	= this.updateParent.bind(this);

		this.state = {
			placeholder : 'placeholder-cover.jpg'
		}

	}

	updateParent(){
		this.props.handleUpdate();
	}

	coverDrop (files, event) {
	    this.compressImage(files, true);
	}

	compressImage(e, drag = false ){
		var _this = this;
		var file = drag ?  e[0] : e.target.files[0];		
		 if (!file) {
		    return;
		  }			
		  new Compressor(file, {
		    quality: 0.9,
		    maxWidth: media.uploadMaxWidth,
		    maxHeight: media.uploadMaxHeight,
		    convertSize: 1000000,
		    success(result) {
				var reader = new FileReader();
				reader.onload = function () {
 					_this.submitCover(reader.result);
				}
				reader.readAsDataURL(result);
		    },
		    error(err) {
		      console.log(err.message);
		    },
		  });
	}

	submitCover(result) {
		let state = store.getState();
	  	toast.info(i18n.t('upload'), { autoClose : 10000 } );		
		const reqBody = { image: result };
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': state.user.token } };
		axios.post(endpoint.users+'/profileupdatecover', reqBody, cfg)
			.then((result) => {
				toast.dismiss();
			  	toast.success(i18n.t(result.data.success), { autoClose : 3000 } );
		    	//this.setState( { newCover: result.data.media_url } )
		    	this.updateParent()

			})
			.catch((err) => {
				toast.dismiss();
			  	toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 3000 } );
			  	console.log(err);
			})
	}


	componentDidMount(){
	}


	render(){

		let coverSrc = this.props.covers ? endpoint.mediaRoot+'/'+this.props.covers[0].media_url : endpoint.mediaRoot+'/'+this.state.placeholder;

	    const own = (
			<div className="own">
				<FileDrop onDrop={this.coverDrop}>
					<img className="coverImg" src={coverSrc} onClick={ this.props.lightboxVisible } />
		        </FileDrop>
				<div className="upload">
					<label>
						<input type="file" name="file" onChange={this.compressImage} name="imageLoader" style={{ display : 'none' }} />
						<i className="fal fa-camera-alt"></i>
					</label>
				</div>
			</div>
		);

	    const notOwn = (
			<div className="notOwn">
	    		<img className="coverImg" src={coverSrc} onClick={ this.props.lightboxVisible } />
	    	</div>
	    );


		return(
				<div className="profile-cover" id="profile-cover">
					{this.props.own ? own : notOwn }
				</div>
		);
	}
}



const ProfileCover = connect(mapStateToProps)(ConnectedProfileCover);
export default ProfileCover;

