import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import i18n from "../i18n";

const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


const ConnectedSlideMenu = ({ nav, cclass, user, language }) => (

	<div className={"SlideMenu "+cclass}>
	  <nav>
	  	<ul>
	  		{nav.map( (link, i) => 
	  			<li key={i} >{link}</li>
	  		)}
	    </ul>
	  </nav>
	</div>

);


const SlideMenu = connect(mapStateToProps)(ConnectedSlideMenu);
export default SlideMenu;

