import i18n from "i18next";

import EN from "./en";
import ES from "./es";
import PT from "./pt";


i18n.init({
  // we init with resources
  resources: {
    en: {
      translations: EN
    },
    es: {
      translations: ES
    },
    pt: {
      translations: PT
    }
  },
  fallbackLng: "es",
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;
