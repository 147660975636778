import React, { Component } from 'react'

import { Link } from "react-router-dom";

import endpoint from "../../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import { store } from './../../../store';

import Moment from 'react-moment';

import Rating from "react-rating";

import Textarea from 'react-textarea-autosize';

import { toast } from 'react-toastify';
import i18n from "../../../i18n";

import Modal from 'react-responsive-modal';
import GuestModal from '../../auth/GuestModal';

class ThePostActionGuest extends Component {

	constructor(props) {
		super();

		this.state = {
			thePost: false,
			theComments: [],
			numberOfComments: 2,
			modalIsOpen: false,
			modalType: false
		};

		this.store = store.getState();

		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);

		this.textInput = React.createRef();


	}

	componentDidMount() {

	}

	componentDidUpdate(prevProps) {
	}


	onOpenModal(type = false) {
		this.setState({ modalIsOpen: true , modalType: type});
	};

	onCloseModal() {
		this.setState({ modalIsOpen: false, modalType:false });

	};


	render() {

		const p = this.state.thePost == false ? this.props.thePost : this.state.thePost;

		var binary_up = '50%';
		var binary_down = '50%';
		var binary_up_class = 'medium';
		var binary_down_class = 'medium';

		if (p.rating_type == 'binary') {
			if (p.binary_count !== 0) {
				binary_up = Math.round(p.binary_up / p.binary_count * 100 * 100) / 100;
				//binary_down = 100 - binary_up;
				binary_down = Math.abs(Math.round(p.binary_down / p.binary_count * 100 * 100) / 100);

				if (binary_up >= 80) {
					binary_up_class = 'very-big';
					binary_down_class = 'very-small';
				} else if (binary_up > 60 && binary_up < 80) {
					binary_up_class = 'big';
					binary_down_class = 'small';
				} else if (binary_down >= 80) {
					binary_up_class = 'very-small';
					binary_down_class = 'very-big';
				} else if (binary_down > 60 && binary_up < 80) {
					binary_up_class = 'small';
					binary_down_class = 'big';
				}

			}

		}

		var userRating = { like: 0, love: 0, star: 0, binary: 0, comment: 0 };
		var userComment = 0;


		return (

			<div className="theAction">
				{p.rating_type !== 'star' && p.rating_type !== 'binary' ?
					(
						<React.Fragment>
							<div className="like">
								<i className={userRating['like'] > 0 ? "fad fa-thumbs-up" : "fal fa-thumbs-up"} onClick={() => this.onOpenModal('post/like')} ></i><span>{p.like_count > 0 ? p.like_count : 0}</span>
							</div>
							<div className="love">
								<i className={userRating['love'] > 0 ? "fas fa-heart" : "fal fa-heart"} onClick={() => this.onOpenModal('post/love')} ></i><span>{p.love_count > 0 ? p.love_count : 0}</span>
							</div>
						</React.Fragment>
					)
					:
					null
				}
				{p.rating_type == 'star' ?
					(
						<div className="star">
							<Rating
								emptySymbol="fal fa-star"
								fullSymbol="fad fa-star"
								initialRating={userRating['star']}
								onChange={(rate) => this.onOpenModal('post/star')}
							/>
							<i className="total fad fa-stars"></i>
							<span className="avg">{p.star_value == 0 ? 0 : Math.round(p.star_value / p.star_count * 10) / 10}</span>
							<i className="total fad fa-users"></i>
							<span className="avg">{p.star_count}</span>
						</div>
					)
					:
					null
				}
				{p.rating_type == 'binary' ?
					(
						<div className="binary">
							<div className="thumbs">
								<div className={'up ' + binary_up_class}><span>{binary_up}%</span><i className={userRating['binary'] == 1 ? "fad fa-thumbs-up up" : "fal fa-thumbs-up up"} onClick={() => this.onOpenModal('post/up')} ></i></div>
								<div className="sep"></div>
								<div className={'down ' + binary_down_class}><i className={userRating['binary'] == -1 ? "fad fa-thumbs-down down" : "fal fa-thumbs-down down"} onClick={() => this.onOpenModal('post/down')} ></i><span>{binary_down}%</span></div>
								<i className="total fad fa-users"></i>
								<span className="avg">{p.binary_count}</span>
							</div>
						</div>
					)
					:
					null
				}

				{
					p.like_count > 0 || p.love_count > 0 || p.star_count > 0 || p.binary_count > 0 ?
						<div className="stats" onClick={()=>this.onOpenModal('post/stats')}>
							<i className="fal fa-analytics"></i>
						</div>
						:
						null
				}



				<div className="comments" onClick={() => this.onOpenModal('post/comment')}>
					<span>{p.comment_count}</span><i className={userComment > 0 ? "fal fa-comment-alt" : "fal fa-comment-alt"} > </i>
				</div>


				<div className="share" onClick={() => this.onOpenModal('post/share')}>
					<span>{p.share_count}</span><i className="fal fa-share-square"> </i>
				</div>

				<div className="bookmark" onClick={() => this.onOpenModal('post/bookmark')}>
					<i className="fal fa-bookmark"></i>
				</div>



				<div className="commentsAction">
					<Textarea minRows={1} maxRows={10} className="input-textarea" placeholder={i18n.t("Send a comment")} onFocus={() => this.onOpenModal('post/comment')} />

					<div className="commentsList">
						{
							(this.state.numberOfComments !== -1 ? p.comments.slice(0, this.state.numberOfComments) : p.comments).map((comment, i) => {
								return (
									<div className="singleComment" key={"comment-" + i + comment.id}>
										<div className="user">
											<Link to={"/profile/" + comment.username}>
												<img src={comment.media_url !== null ? endpoint.mediaRoot + '/' + comment.media_url : endpoint.mediaRoot + '/placeholder-avatar.jpg'} />
											</Link>
										</div>
										<div className="content">
											<Link to={"/profile/" + comment.username}>
												<span>{comment.username} </span>
											</Link>
											{comment.comment}
											<div className="date">
												<i className="fal fa-clock"></i>
												<Moment fromNow ago>{comment.date}</Moment>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
					{this.state.numberOfComments !== -1 && p.comment_count > 2 ? (<div className="moreComments"><i className="fal fa-comment-dots" onClick={() => this.onOpenModal('post/comment')}></i></div>) : null}
				</div>

				<GuestModal modalIsOpen={this.state.modalIsOpen} onClose={this.onCloseModal} modalType={this.state.modalType}></GuestModal>

				{/* <Modal open={this.state.modalIsOpen} onClose={this.onCloseModal} classNames={{ modal: "guestModal" }} center showCloseIcon={false} focusTrapped={false} overlayId="guestModalOverlay" >
					<div className="container">
						<div className="title"><span className="b">launch</span><span className="r">yoo</span></div>
						<div className="subtitle">Join us today. Discover the Next Generation Social Networks</div>
						<div className="choice">
							<Link to={"/register/"} className="action up">Sign up <i className="fad fa-bullseye-pointer"></i></Link>
							<Link to={"/login/"} className="action in">Sign in <i className="fad fa-sign-in-alt fa-duo"></i></Link>
						</div>
					</div>
				</Modal> */}


			</div>

		)
	}
}

export default ThePostActionGuest;
