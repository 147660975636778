import React, { Component } from 'react'

import { Link, Redirect } from "react-router-dom";
import endpoint from "../../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import {store} from './../../../store';
import { connect } from "react-redux";
import Moment from 'react-moment';

import ThePost from './ThePost';

import { toast } from 'react-toastify';
import i18n from "../../../i18n";


const mapStateToProps = state => {
  return { language: state.language, user: state.user, settings: state.settings};
};


class ConnectedTheSharedHeader extends Component {

  constructor(props) {
    super();

    this.handleRemove 	= this.handleRemove.bind(this);

    this.store = store.getState();

  	}
  
	componentDidMount(){
	}

	componentDidUpdate(prevProps) {

	}  


	timeDifference(time) 
	{
		time = new Date(time).getTime()
		var diff =(time - (new Date()).getTime() ) / 1000;
		var roundDiff = Math.abs(Math.round(diff));

		if ( Number.isInteger( Math.round(roundDiff) ) && roundDiff < 86400 && roundDiff > 0 ){
			return true;  
		}else{
			return false;
		}
	}


	handleRemove( postId ){

		/*let group_slug = this.props.group_slug ? this.props.group_slug :  false;*/
		let group_slug = false;

		const reqBody = { postId: postId };
		const cfg = { 
			headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
        };

		axios.post(endpoint.posts+'/removepost', reqBody, cfg)
			.then((result) => {
				toast.warning(i18n.t('Post removed'), { autoClose : 2500 } );
				setTimeout( ()=>{ window.location.reload() }, 1500)		
			})
			.catch((err) => {
			})		
	}

	render () {

	  	const p = this.props.thePostHeader || {}; 

	/* COLORS */
    let ui_color_mix 	 = false;

    if ( this.props.settings.userSettings.length > 0){        
        
        let ui = this.props.settings.userSettings[0];      

        let isMixColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_mix);
        if (isMixColor){
          ui_color_mix = ui.ui_color_mix;
        }

	    }
		/* COLORS */
		
		var postFilter = { icon : 'fal fa-share-square', to : '/wall/mix', iconColor: ui_color_mix};


		var emotionIcon = false;

		if (p.feel == 'love'){
			emotionIcon = 'love fas fa-kiss-wink-heart';
		}else if (p.feel == 'angry'){
			emotionIcon = 'angry fas fa-angry';
		}else if (p.feel == 'sad'){
			emotionIcon = 'sad fas fa-frown';
		}else if (p.feel == 'joy'){
			emotionIcon = 'joy fas fa-smile-beam';
		}else if (p.feel == 'surprise'){
			emotionIcon = 'surprise fas fa-surprise';
		}else if (p.feel == 'lol'){
			emotionIcon = 'lol fas fa-grin-tears';
		}else if (p.feel == 'meh'){
			emotionIcon = 'meh fas fa-meh';
		}else if (p.feel == 'tongue'){
			emotionIcon = 'tongue fas fa-grin-tongue';
		}else if (p.feel == 'grimace'){
			emotionIcon = 'grimace fas fa-grimace';
		}



		return (
			<div className="header">
				<Link to={"/profile/"+p.username}>
	    			<img src={ p.avatar_url ? endpoint.mediaRoot +'/'+ p.avatar_url : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
				</Link>
    			<div className="title">
    				<h2>
    					<Link to={"/profile/"+p.username}>{p.username}</Link>
		    			{ this.timeDifference(p.feel_last) && emotionIcon ? (<div className="profile-feel-icon feel"><i className={emotionIcon}> </i></div>) : null }
    				</h2>
    				<h3>
    					<Link to={"/post/"+p.id}><i className="fal fa-link"></i> <Moment fromNow ago>{p.date}</Moment></Link>
    				</h3>
    			</div>
    			<div className="moderationAction">
					{ p.user_id == this.props.current_user ? <i className="fal fa-trash" onClick={ () => this.handleRemove(p.id) }></i> : null}
    			</div>	
    			<div className="type"><Link to={postFilter.to}><i className={postFilter.icon} style={ postFilter.iconColor ? null : null } ></i></Link></div>
    			{ p.content ? <div className="sharedNote">{p.content}</div> : null}
    		</div>
		)
	}

}

//export default ThePost;


const TheSharedHeader = connect(mapStateToProps, null)(ConnectedTheSharedHeader);
export default TheSharedHeader;
