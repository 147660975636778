import React, { Component } from 'react'

import { Link } from "react-router-dom";

import endpoint from "../../config/endpoint";
import axios from "axios";
import { store } from './../../store';

import _ from 'lodash';

// import EditorBase from '../../components/editor/base_V2';

import ThePost from './components/ThePost';
import TheSharedPost from './components/TheSharedPost';
import ThePostPlaceholder from './components/ThePostPlaceholder';


import IntersectionVisible from 'react-intersection-visible'

import i18n from "../../i18n";

import iPics from '../../img/Photo.svg';
import iPicsH from '../../img/Photo_hover.svg';

import iVideo from '../../img/Video.svg';
import iVideoH from '../../img/Video_hover.svg';

import iAudio from '../../img/Audio.svg';
import iAudioH from '../../img/Audio_hover.svg';

import iMinimes from '../../img/Minimes.svg';
import iMinimesH from '../../img/Minimes_hover.svg';

import iNews from '../../img/Blog.svg';
import iNewsH from '../../img/Blog_hover.svg';

import iMix from '../../img/Mix.svg';
import iMixH from '../../img/Mix_hover.svg';

import iBookmark from '../../img/Bookmark.svg';
import iBookmarkH from '../../img/Bookmark_hover.svg';

import iGroups from '../../img/Groups.svg';

import MenuSx from '../../img/MenuSx.svg';

import accents from 'remove-accents';

import iconPhotoVideo from '../../img/photo-video.svg';
import iconAudio from '../../img/Audio_hover.svg';
import iconHashtags from '../../img/Hashtag.svg';
import iconTags from '../../img/Tags.svg';
import iconGroups from '../../img/Groups.svg';
import iconOptions from '../../img/icona_menu.svg';

import GuestModal from '../auth/GuestModal';

class GuestWall extends Component {

	constructor(props) {
		super();

		this.state = {
			thePosts: [],
			isLoading: true,
			modalIsOpen: false,
			modalType: false
		};

		this.getPosts = this.getPosts.bind(this);
		this.onRefresh = this.onRefresh.bind(this);
		this.loadMore = _.debounce(this.loadMore.bind(this), 500);

		this.onOpenModal = this.onOpenModal.bind(this);
		this.onCloseModal = this.onCloseModal.bind(this);

		this.store = store.getState();

	}

	componentDidMount() {
		this.setState({ isLoading: true });
		this.getPosts()
			.then(setTimeout(() => { this.setState({ isLoading: false }) }, 1000))
			.catch(setTimeout(() => { this.setState({ isLoading: false }) }, 1000));
	}

	// componentDidUpdate(prevProps) {
	// 	if (this.props.match.params.id !== prevProps.match.params.id) {
	// 		this.setState({ isLoading: true });
	// 		this.getPosts()
	// 			.then(setTimeout(() => { this.setState({ isLoading: false }) }, 1000))
	// 			.catch(setTimeout(() => { this.setState({ isLoading: false }) }, 1000));
	// 	}
	// }

	componentDidUpdate(prevProps) {

		console.log(this.props.match.params, prevProps.match.params);

		if (this.props.match.params.id !== prevProps.match.params.id) {

			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	  
			this.setState({ isLoading : true });
			this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
		}else if(this.props.match.params.tag  !== prevProps.match.params.tag){
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera	  
			this.setState({ isLoading : true });
			this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
		}else if(this.props.match.path  !== prevProps.match.path){
			// da verificare
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera	  
			this.setState({ isLoading : true });
			this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
		}
	}



	getPosts(fetchNew = false) {

		return new Promise((resolve, reject) => {

			var wallEndpoint = endpoint.guestposts + '/wall/' + this.props.wallType;

			if (this.props.wallType == 'tag') {
				wallEndpoint = endpoint.guestposts + '/tag/' + accents.remove(this.props.match.params.tag);;
			}

			if (fetchNew == true) {
				var cursor = -1 * this.state.thePosts[0][0].id;
			} else {
				var cursor = 0;
			}

			if (typeof this.props.match.params.cursor !== 'undefined') {
				cursor = this.props.match.params.cursor;
			}

			const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token } };
			axios.get(wallEndpoint + '/' + cursor, cfg)
				.then(res => {
					this.setState({
						/*thePosts: res.data.thePosts || []*/
						thePosts: [...res.data.thePosts, ...this.state.thePosts]
					});
					resolve();
				})
		})

	}




	onRefresh() {
		this.setState({ isLoading: true });
		this.getPosts(true)
			.then(setTimeout(() => { this.setState({ isLoading: false }) }, 1000))
			.catch(setTimeout(() => { this.setState({ isLoading: false }) }, 1000));

	}

	loadMore(entries) {

		var wallEndpoint = endpoint.guestposts + '/wall/' + this.props.wallType;

		if (this.props.wallType == 'bookmark') {
			wallEndpoint = endpoint.guestposts + '/bookmark/';
		} else if (this.props.wallType == 'tag') {
			wallEndpoint = endpoint.guestposts + '/tag/' + accents.remove(this.props.match.params.tag);;

		}

		this.setState({ isLoading: true });

		if (this.state.thePosts.length > 0) {
			var cursor = this.state.thePosts[this.state.thePosts.length - 1][0].id;
		} else {
			var cursor = 0;
		}

		const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(wallEndpoint + '/' + cursor, cfg)
			.then(res => {
				this.setState({ thePosts: [...this.state.thePosts, ...res.data.thePosts] });
				setTimeout(() => { this.setState({ isLoading: false }) }, 1000)
			})
			.catch(setTimeout(() => { this.setState({ isLoading: false }) }, 1000))

	}

	onOpenModal(type = false) {
		this.setState({ modalIsOpen: true , modalType: type});
	};

	onCloseModal() {
		this.setState({ modalIsOpen: false, modalType:false });
	};

	render() {

		return (
			<div className="wallV2">
				<div className="container">

					<WallSX active={this.props.wallType} wallSideGroups={this.state.wallSideGroups} />

					<div className="wallContent">

						{/* <EditorBase /> */}
						<div className="theEditorWrapper" onClick={() => this.onOpenModal('wall/editor')}>
							<div className="theEditorPlaceholderV2">
								<div className="margin">
									<div className="DraftEditor-guest-root">
										<div>{i18n.t("join the discussion")}</div>
										
									</div>

									<div className="iconActionV2">
										<div className="uploadMedia">
											<img src={iconPhotoVideo} alt="Photo Video" /><img src={iconAudio} alt="Audio" /><span>Photo / Video / Audio</span>
										</div>
										<div className="textAction"><img src={iconHashtags} alt="Hashtag" /><span>Hashtag</span>
										</div>
										<div className="textAction"><img src={iconGroups} alt="Groups" /><span>Groups</span>
										</div>
										<div className="textAction"><img src={iconTags} alt="Tags" /><span>Tags</span>
										</div>
										<div className="actionChoicePanel"><div className="actualAction" onClick={this.handleActionChoiceToggle} ><img src={iconOptions} alt="Options" /></div>
										</div>
									</div>

								</div>

							</div>
						</div>

						<div className="refresh" onClick={this.onRefresh} ><i className={this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt"}></i></div>

						{
							this.state.thePosts.length == 0 && this.state.isLoading == true ? (<ThePostPlaceholder />) : null
						}

						{
							this.state.thePosts.length == 0 && this.state.isLoading == false ? (<ThePostPlaceholder nopost={true} />) : null
						}

						{(this.state.thePosts || []).map((post, i) => {
							return post[0].type == 'share' ? <TheSharedPost thePost={post} key={'thePost' + i} /> : <ThePost thePost={post} key={'thePost' + i} wallType={this.props.wallType} />
							/*return <ThePost thePost={post} key={'thePost'+i} />*/
						}
						)}

						{this.state.thePosts && this.state.isLoading == false ?
							(
								<IntersectionVisible onShow={e => this.loadMore(e)}>
									<div className="refresh" ><i className={this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt"}></i></div>
								</IntersectionVisible>
							)
							:
							null
						}

					</div>

					<WallDX />

				</div>

				<GuestModal modalIsOpen={this.state.modalIsOpen} onClose={this.onCloseModal} modalType={this.state.modalType}></GuestModal>

			</div>
		)
	}
}

export default GuestWall;










class WallSX extends Component {

	constructor(props) {
		super();

		this.state = {
			isActive: false
		};

		this.toggleSidebar = this.toggleSidebar.bind(this);

	}

	toggleSidebar() {
		this.setState({ isActive: !this.state.isActive })
	}

	render() {

		return (
			<div className={this.state.isActive ? "wallSide sx sidebar active" : "wallSide sx sidebar unactive"}>
				<ul className="wallMenu">
					<li className={this.props.active == 'photo' ? 'active' : null}>
						<Link to="/wall/photo">
							<img src={this.props.active == 'photo' ? iPicsH : iPics} alt="Pics" />
							<div>Pics</div>
						</Link>
					</li>
					<li className={this.props.active == 'video' ? 'active' : null}>
						<Link to="/wall/video">
							<img src={this.props.active == 'video' ? iVideoH : iVideo} alt="Video" />
							<div>Video</div>
						</Link>
					</li>
					<li className={this.props.active == 'audio' ? 'active' : null}>
						<Link to="/wall/audio">
							<img src={this.props.active == 'audio' ? iAudioH : iAudio} alt="Audio & Podcast" />
							<div>Audio & Podcast</div>
						</Link>
					</li>
					<li className={this.props.active == 'minimes' ? 'active' : null}>
						<Link to="/wall/minimes">
							<img src={this.props.active == 'minimes' ? iMinimesH : iMinimes} alt="Minimes" />
							<div>Minimes</div>
						</Link>
					</li>
					<li className={this.props.active == 'news' ? 'active' : null}>
						<Link to="/wall/news">
							<img src={this.props.active == 'news' ? iNewsH : iNews} alt="Blog & News" />
							<div>Blog & News</div>
						</Link>
					</li>
					<li className={this.props.active == 'mix' ? 'active' : null}>
						<Link to="/wall/mix">
							<img src={this.props.active == 'mix' ? iMixH : iMix} alt="Mix" />
							<div>Mix</div>
						</Link>
					</li>

				</ul>
				<div className="toggler" onClick={this.toggleSidebar}><img src={MenuSx} alt="Toggle sidebar" /></div>
			</div>
		)
	}
}



class WallDX extends Component {

	constructor(props) {
		super();

		this.state = {
		};

		this.toggleSidebar = this.toggleSidebar.bind(this);

	}

	toggleSidebar() {
		this.setState({ isActive: !this.state.isActive })
	}

	render() {

		return (
			<div className="wallSide dx sidebar">

				<div className="wallSideBox">

				</div>

			</div>
		)
	}
}
