import React, { Component } from 'react'

import { Link } from "react-router-dom";
import endpoint from "../../../config/endpoint";

import {store} from './../../../store';

import { connect } from "react-redux";


const mapStateToProps = state => {
  return { language: state.language, user: state.user, settings: state.settings};
};


class ConnectedWallFloatingMenu extends Component {

  constructor(props) {
    super();

    this.state = {
      toggleState: false
    };

    this.handleToggle = this.handleToggle.bind(this);

  }

  handleToggle(){
    this.setState( prevState => (
      { toggleState: !prevState.toggleState }) 
    ) 
  }


  render () {


  /* COLORS */
    let ui_color_photo      = false;
    let ui_color_video      = false;
    let ui_color_audio      = false;
    let ui_color_minimes    = false;
    let ui_color_news       = false; 
    let ui_color_mix        = false;
    let ui_color_bookmark   = false;

    if ( this.props.settings.userSettings.length > 0){        
        
        let ui = this.props.settings.userSettings[0];      

        let isPhotoColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_photo);
        if (isPhotoColor){
          ui_color_photo = ui.ui_color_photo;
        }

        let isVideoColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_video);
        if (isVideoColor){
          ui_color_video = ui.ui_color_video;
        }

        let isAudioColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_audio);
        if (isAudioColor){
          ui_color_audio = ui.ui_color_audio;
        }

        let isMinimesColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_minimes);
        if (isMinimesColor){
          ui_color_minimes = ui.ui_color_minimes;
        }

        let isNewsColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_news);
        if (isNewsColor){
          ui_color_news = ui.ui_color_news;
        }

        let isMixColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_mix);
        if (isMixColor){
          ui_color_mix = ui.ui_color_mix;
        }

        let isBookmarkColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_bookmark);
        if (isBookmarkColor){
          ui_color_bookmark = ui.ui_color_bookmark;
        }

    }

    var toggleType = { icon: "fad fa-paper-plane all", color: ui_color_mix};
    //var toggleType = "fal fa-paper-plane"; 

    if ( this.props.active == 'photo'){
      toggleType = { icon: "fad fa-camera photo", color: ui_color_photo};
      //toggleType = "fad fa-camera photo";
    }else if( this.props.active == 'video'){
      toggleType = { icon: "fad fa-video video", color: ui_color_video};
      //toggleType = "fad fa-video video";
    }else if( this.props.active == 'news'){
      toggleType = { icon: "fad fa-newspaper news", color: ui_color_news};
      //toggleType = "fad fa-newspaper news";
    }else if( this.props.active == 'audio'){
      toggleType = { icon: "fad fa-microphone audio", color: ui_color_audio};
      //toggleType = "fad fa-microphone audio";
    }else if( this.props.active == 'minimes'){
      toggleType = { icon: "fad fa-comment-alt-lines minimes", color: ui_color_minimes};
      //toggleType = "fad fa-comment-alt-lines minimes";
    }else if( this.props.active == 'bookmark'){
      toggleType = { icon: "fad fa-bookmark bookmark", color: ui_color_bookmark};
      //toggleType = "fad fa-bookmark bookmark";
    }


  /* COLORS */

    return (
      <div className={ this.state.toggleState ? "WallFloatingMenu open" : "WallFloatingMenu close"} >
        <div className="toggle" onClick={ this.handleToggle }>
          <i className={toggleType.icon} style={ toggleType.color ? { color : toggleType.color } : null }></i>
        </div>
        <ul>
          <li>
            <Link to="/wall/photo" onClick={ this.handleToggle }><i className="fad fa-camera photo" style={ ui_color_photo ? { color : ui_color_photo } : null }></i></Link>
          </li>
          <li>
            <Link to="/wall/video" onClick={ this.handleToggle }><i className="fad fa-video video" style={ ui_color_video ? { color : ui_color_video } : null }></i></Link>
          </li> 
          <li>
            <Link to="/wall/audio" onClick={ this.handleToggle }><i className="fad fa-microphone audio" style={ ui_color_audio ? { color : ui_color_audio } : null }></i></Link>
          </li> 
          <li>
            <Link to="/wall/minimes" onClick={ this.handleToggle }><i className="fad fa-comment-alt-lines minimes" style={ ui_color_minimes ? { color : ui_color_minimes } : null }></i></Link>
          </li>
          <li>
            <Link to="/wall/news" onClick={ this.handleToggle }><i className="fad fa-newspaper news" style={ ui_color_news ? { color : ui_color_news } : null }></i></Link>
          </li>
          <li>
            <Link to="/wall/mix" onClick={ this.handleToggle }><i className="fad fa-paper-plane all" style={ ui_color_mix ? { color : ui_color_mix } : null }></i></Link>
          </li>   
          <li>
            <Link to="/bookmark" onClick={ this.handleToggle }><i className="fad fa-bookmark all" style={ ui_color_mix ? { color : ui_color_bookmark } : null }></i></Link>
          </li>                                         
        </ul>
      </div>
    )
  }
}

//export default WallFloatingMenu;

const WallFloatingMenu = connect(mapStateToProps, null)(ConnectedWallFloatingMenu);
export default WallFloatingMenu;

