import React, { Component } from 'react';


import i18n from "../i18n";

import EditorBase from '../components/editor/base';
import Player from '../components/wall/player';

import endpoint from "../config/endpoint";
import axios from "axios";

import {store} from './../store';

class Test extends Component {

	constructor(props) {
		super(props);
	
		this.testFunction   = this.testFunction.bind(this);
		this.getFlags 		= this.getFlags.bind(this);

	    this.store = store.getState();

		this.state = {
			theAdmin: false,
			theFlags: []
		};

	}


	testFunction(){
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(endpoint.admin+'/test', cfg)
  		.then(res => {
  			this.setState({
				theAdmin: res.data.theAdmin || false
			})
	  	}) 
		.catch((err) => {
			this.setState({
				exist: false
			})					
			})	
	}

	getFlags(){
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.get(endpoint.admin+'/getflags', cfg)
  		.then(res => {
  			this.setState({
				theFlags: res.data.theFlags || false
			})
	  	}) 

	}	


  render() {


    return (
      <div className="test">
      	<button onClick={this.testFunction}>azione</button>
      	{this.state.theAdmin ? <div>{this.state.theAdmin.role}</div>: null}

      	<button onClick={this.getFlags}>Stampa Flags</button>

   		{ (this.state.theFlags || []).map( (flag, i) => {
		    	return <div>{flag.id} - {flag.flag_type} - {flag.content_id} - {flag.user_id} - {flag.user_credibility} - {flag.comment}</div>
    		} 
	    )}


      </div>
    );
  }
}

export default Test;



