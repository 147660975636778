import React, { Component } from 'react';
import { connect } from "react-redux";

import i18n from "../../../i18n";

import { toast } from 'react-toastify';

import endpoint from "../../../config/endpoint";
import axios from "axios";
import {store} from './../../../store';



const mapStateToProps = state => {
  return { user: state.user, language: state.language};
};


class ConnectedFeel extends Component {

	constructor(props) {
		super(props);
	
	    this.submitFeel 		= this.submitFeel.bind(this);
	    this.updateParent		= this.updateParent.bind(this);
	    this.handleChooseFeel	= this.handleChooseFeel.bind(this);
		
		this.state = {
			chooseFeel : false
		}

	    this.store = store.getState();

	}

	updateParent(){
		this.props.handleUpdate();
	}

	submitFeel(feel) {
		const reqBody = { feel: feel };
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		axios.post(endpoint.users+'/feel', reqBody, cfg)
			.then((result) => {
		    	this.updateParent();
		    	this.setState({ chooseFeel: false });
			})
			.catch((err) => {
				toast.dismiss();
			  	toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 3000 } );
			  	console.log(err);
			})
	}

	componentDidMount(){
	}

	handleChooseFeel(){
		this.setState(prevState => ({ chooseFeel: !prevState.chooseFeel }));
	}

	timeDifference(time) 
	{
		time = new Date(time).getTime()
		var diff =(time - (new Date()).getTime() ) / 1000;
		var roundDiff = Math.abs(Math.round(diff));

		if ( Number.isInteger( Math.round(roundDiff) ) && roundDiff < 86400 && roundDiff >= 0 ){
			console.log("roundDiff", roundDiff);
			return true;  
		}else{
			console.log("roundDiff", roundDiff);
			return false;
		}
	}

	render(){

		var emotionIcon = 'noEmotion fas fa-question-circle';
		var feelLast = false;

		if ( this.props.profileFeel.hasOwnProperty('feel_last') ){
			feelLast = this.timeDifference(this.props.profileFeel.feel_last);
		}

		if ( this.props.profileFeel.hasOwnProperty('feel') && feelLast ){

			if (this.props.profileFeel.feel == 'love'){
				emotionIcon = 'love fas fa-kiss-wink-heart';
			}else if (this.props.profileFeel.feel == 'angry'){
				emotionIcon = 'angry fas fa-angry';
			}else if (this.props.profileFeel.feel == 'sad'){
				emotionIcon = 'sad fas fa-frown';
			}else if (this.props.profileFeel.feel == 'joy'){
				emotionIcon = 'joy fas fa-smile-beam';
			}else if (this.props.profileFeel.feel == 'surprise'){
				emotionIcon = 'surprise fas fa-surprise';
			}else if (this.props.profileFeel.feel == 'lol'){
				emotionIcon = 'lol fas fa-grin-tears';
			}else if (this.props.profileFeel.feel == 'meh'){
				emotionIcon = 'meh fas fa-meh';
			}else if (this.props.profileFeel.feel == 'tongue'){
				emotionIcon = 'tongue fas fa-grin-tongue';
			}else if (this.props.profileFeel.feel == 'grimace'){
				emotionIcon = 'grimace fas fa-grimace';
			}

		}


	    const own = (
			<div className="own">
				<i onClick={ () => this.handleChooseFeel() } className={"emotion "+emotionIcon}></i>
				<div className={this.state.chooseFeel ? 'choice active' : 'choice inactive' }>
					<i className="love fas fa-kiss-wink-heart" onClick={ () => this.submitFeel('love') }></i>
					<i className="joy fas fa-smile-beam" onClick={ () => this.submitFeel('joy') }></i>
					<i className="surprise fas fa-surprise" onClick={ () => this.submitFeel('surprise') }></i>
					<i className="lol fas fa-grin-tears" onClick={ () => this.submitFeel('lol') }></i>
					<i className="tongue fas fa-grin-tongue" onClick={ () => this.submitFeel('tongue') }></i>
					<i className="grimace fas fa-grimace" onClick={ () => this.submitFeel('grimace') }></i>
					<i className="angry fas fa-angry" onClick={ () => this.submitFeel('angry') }></i>
					<i className="sad fas fa-frown" onClick={ () => this.submitFeel('sad') }></i>
					<i className="meh fas fa-meh" onClick={ () => this.submitFeel('meh') }></i>
				</div>
			</div>
		);

	    const notOwn = (
			<div className="notOwn">
					<i className={"emotion "+emotionIcon}></i>
	    	</div>
	    );



		return(
				<div className="profile-feel profile-feel-icon" id="profile-feel">
					{this.props.own ? own : notOwn }
				</div>
		);
	}
}



const Feel = connect(mapStateToProps)(ConnectedFeel);
export default Feel;

