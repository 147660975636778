import React, { Component } from "react";
import { connect } from "react-redux";
import { saveSettings, initConfig } from "../../../actions/index";
import { Link } from "react-router-dom";

import i18n from "../../../i18n";

import _ from 'lodash';

import {store} from './../../../store';
import endpoint from "../../../config/endpoint";
import axios from "axios";

import { TwitterPicker } from 'react-color';

function mapDispatchToProps(dispatch) {
  return {
    saveSettings: settings => dispatch(saveSettings(settings)),
    initConfig: () => dispatch(initConfig())
  };
}

const mapStateToProps = state => {
  return { fetchedUI: state.settings.userSettings, settings: state.settings };
};

class ConnectedUIform extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			ui_bkg_type			: this.props.fetchedUI[0].ui_bkg_type,
			ui_bkg_value		: this.props.fetchedUI[0].ui_bkg_value,
			ui_color_photo		: this.props.fetchedUI[0].ui_color_photo,
			ui_color_video		: this.props.fetchedUI[0].ui_color_video,
			ui_color_audio		: this.props.fetchedUI[0].ui_color_audio,
			ui_color_minimes	: this.props.fetchedUI[0].ui_color_minimes,
			ui_color_news		: this.props.fetchedUI[0].ui_color_news,
			ui_color_mix		: this.props.fetchedUI[0].ui_color_mix,
			ui_color_bookmark	: this.props.fetchedUI[0].ui_color_bookmark
		}

	    this.store = store.getState();

	    this.handleSubmit 			= this.handleSubmit.bind(this);
	    this.handleChange 			= this.handleChange.bind(this);
	    this.handleBkgColorChange 	= this.handleBkgColorChange.bind(this);

	    props.initConfig();
	}

	handleSubmit(event) {
	    event.preventDefault();
    	const type = "notification";
	    const dataObj = { 
			ui_bkg_type			: this.state.ui_bkg_type,
			ui_bkg_value		: this.state.ui_bkg_value,
			ui_color_photo		: this.state.ui_color_photo,
			ui_color_video		: this.state.ui_color_video,
			ui_color_audio		: this.state.ui_color_audio,
			ui_color_minimes	: this.state.ui_color_minimes,
			ui_color_news		: this.state.ui_color_news,
			ui_color_mix		: this.state.ui_color_mix,
			ui_color_bookmark		: this.state.ui_color_bookmark
	    };
    	this.props.saveSettings({ type, dataObj });
    }

	handleChange(event) {
	    const fieldId = event.target.id;
	    const fieldValue = (event.target.getAttribute('type') == "checkbox") ?  event.target.checked : event.target.value; 
	    this.setState({ [fieldId]: fieldValue }, () => { console.log("state callback"); });
	}


	handleBkgColorChange(color, event, type) {

		let reqBody = { type: type, color: color.hex };

		const cfg = { 
			headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token }
	    };
		axios.post(endpoint.users+'/ui', reqBody, cfg)
			.then((result) => {
				this.props.initConfig();
			})
			.catch((err) => {
			})	

	}



	componentDidMount(){
	}

	render() {


	    let bkgColor = '#FFFFFF';
	    let ui_color_photo 		= '#999999';
	    let ui_color_video 		= '#999999';
	    let ui_color_audio 		= '#999999';
	    let ui_color_minimes	= '#999999';
	    let ui_color_news 		= '#999999'; 
	    let ui_color_mix 		= '#999999';
	    let ui_color_bookmark 	= '#999999';

	    if ( this.props.settings.userSettings.length > 0){        
	        
	        let ui = this.props.settings.userSettings[0];      

	        let isBkgColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_bkg_value);
	        if (isBkgColor){
	          bkgColor = ui.ui_bkg_value;
	        }

	        let isPhotoColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_photo);
	        if (isPhotoColor){
	          ui_color_photo = ui.ui_color_photo;
	        }

	        let isVideoColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_video);
	        if (isVideoColor){
	          ui_color_video = ui.ui_color_video;
	        }

	        let isAudioColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_audio);
	        if (isAudioColor){
	          ui_color_audio = ui.ui_color_audio;
	        }

	        let isMinimesColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_minimes);
	        if (isMinimesColor){
	          ui_color_minimes = ui.ui_color_minimes;
	        }

	        let isNewsColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_news);
	        if (isNewsColor){
	          ui_color_news = ui.ui_color_news;
	        }

	        let isMixColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_mix);
	        if (isMixColor){
	          ui_color_mix = ui.ui_color_mix;
	        }

	        let isBookmarkColor  = /^#[0-9A-F]{6}$/i.test(ui.ui_color_bookmark);
	        if (isBookmarkColor){
	          ui_color_bookmark = ui.ui_color_bookmark;
	        }

	    }

	    let presetColor = ['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#FFFFFF'];

	    return (
			<form onSubmit={ this.handleSubmit } className="settings-form settings-form-ui">
				<h2>{  i18n.t("Interface customization") }</h2>

				<div className="color-field multi-line">
					<div className="bkg_preview" style={{backgroundColor:bkgColor}}>
						<span><i className="fad fa-tint"></i> {  i18n.t("Background color") }</span>
					</div>
					<TwitterPicker
						width='100%'
						color={ bkgColor }
						onChangeComplete={ ( color, event ) => this.handleBkgColorChange(color, event, 'ui_bkg_value') }
						colors={ presetColor }
					/>		    		
					<label className="tgl-btn" htmlFor="notify_mention_post"></label>
				</div>


				<div className="color-field multi-line">
					<div className="bkg_preview" style={{backgroundColor:ui_color_photo}}>
						<span><i className="fad fa-camera photo"></i> {  i18n.t("Photo icon color") }</span>
					</div>
					<TwitterPicker
						width='100%'
						color={ ui_color_photo }
						onChangeComplete={ ( color, event ) => this.handleBkgColorChange(color, event, 'ui_color_photo') }
						colors={ presetColor }
					/>		    		
					<label className="tgl-btn" htmlFor="notify_mention_post"></label>
				</div>

				<div className="color-field multi-line">
					<div className="bkg_preview" style={{backgroundColor:ui_color_video}}>
						<span><i className="fad fa-video video"></i> {  i18n.t("Video icon color") }</span>
					</div>
					<TwitterPicker
						width='100%'
						color={ ui_color_video }
						onChangeComplete={ ( color, event ) => this.handleBkgColorChange(color, event, 'ui_color_video') }
						colors={ presetColor }
					/>		    		
					<label className="tgl-btn" htmlFor="notify_mention_post"></label>
				</div>

				<div className="color-field multi-line">
					<div className="bkg_preview" style={{backgroundColor:ui_color_audio}}>
						<span><i className="fad fa-microphone audio"></i> {  i18n.t("Audio icon color") }</span>
					</div>
					<TwitterPicker
						width='100%'
						color={ ui_color_audio }
						onChangeComplete={ ( color, event ) => this.handleBkgColorChange(color, event, 'ui_color_audio') }
						colors={ presetColor }
					/>		    		
					<label className="tgl-btn" htmlFor="notify_mention_post"></label>
				</div>		
				
				<div className="color-field multi-line">
					<div className="bkg_preview" style={{backgroundColor:ui_color_minimes}}>
						<span><i className="fad fa-comment-alt-lines minimes"></i> {  i18n.t("Minimes icon color") }</span>
					</div>
					<TwitterPicker
						width='100%'
						color={ ui_color_minimes }
						onChangeComplete={ ( color, event ) => this.handleBkgColorChange(color, event, 'ui_color_minimes') }
						colors={ presetColor }
					/>		    		
					<label className="tgl-btn" htmlFor="notify_mention_post"></label>
				</div>							
				
				<div className="color-field multi-line">
					<div className="bkg_preview" style={{backgroundColor:ui_color_news}}>
						<span><i className="fad fa-newspaper news"></i> {  i18n.t("News icon color") }</span>
					</div>
					<TwitterPicker
						width='100%'
						color={ ui_color_news }
						onChangeComplete={ ( color, event ) => this.handleBkgColorChange(color, event, 'ui_color_news') }
						colors={ presetColor }
					/>		    		
					<label className="tgl-btn" htmlFor="notify_mention_post"></label>
				</div>	
				
				<div className="color-field multi-line">
					<div className="bkg_preview" style={{backgroundColor:ui_color_mix}}>
						<span><i className="fad fa-paper-plane mix"></i> {  i18n.t("Mix icon color") }</span>
					</div>
					<TwitterPicker
						width='100%'
						color={ ui_color_mix }
						onChangeComplete={ ( color, event ) => this.handleBkgColorChange(color, event, 'ui_color_mix') }
						colors={ presetColor }
					/>		    		
					<label className="tgl-btn" htmlFor="notify_mention_post"></label>
				</div>	

				<div className="color-field multi-line">
					<div className="bkg_preview" style={{backgroundColor:ui_color_bookmark}}>
						<span><i className="fad fa-bookmark bookmark"></i> {  i18n.t("Bookmark icon color") }</span>
					</div>
					<TwitterPicker
						width='100%'
						color={ ui_color_bookmark }
						onChangeComplete={ ( color, event ) => this.handleBkgColorChange(color, event, 'ui_color_bookmark') }
						colors={ presetColor }
					/>		    		
					<label className="tgl-btn" htmlFor="notify_mention_post"></label>
				</div>	


		     </form>
	    );
	}
}


const UIform = connect(mapStateToProps, mapDispatchToProps)(ConnectedUIform);
export default UIform;
