import React, { Component } from 'react';
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import i18n from "../../../i18n";

import { store } from './../../../store';
import endpoint from "../../../config/endpoint";
import axios from "axios";

import ThePost from '../../wall/components/ThePost';
import TheSharedPost from '../../wall/components/TheSharedPost';

import IntersectionVisible from 'react-intersection-visible'

import Player from '../../../components/wall/player';

import Moment from 'react-moment';

const mapStateToProps = state => {
	return { user: state.user, language: state.language };
};


class ConnectedStream extends Component {

	constructor(props) {
		super(props);

		this.state = {
			thePosts: []
		}

		this.store = store.getState();

	}

	componentDidMount() {
		let streamEndpoint = endpoint.guestposts + '/user/' + this.props.paramsUser;
		window.streamEndpoint = streamEndpoint;
		this.userStreamInit(streamEndpoint);
	}

	componentDidUpdate(prevProps) {
		let streamEndpoint = endpoint.guestposts + '/user/' + this.props.paramsUser;
		window.streamEndpoint = streamEndpoint;
		this.userStreamInit(streamEndpoint);
	}

	userStreamInit(streamEndpoint) {

		//const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': this.store.user.token } };
		const cfg = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' } };
		axios.get(streamEndpoint + '/0', cfg)
			.then((result) => {
				this.setState({
					thePosts: [...result.data.thePosts]
				});
			})
			.catch((err) => {
				console.log(err);
			})

	}


	render() {


		return (
			<div className="profile-stream">

				<div className="profile-section-title"><span>Feed</span><Link to={this.props.paramsUser ? "/userstream/" + this.props.paramsUser : "/userstream/"} className="feedAll">See All <i className="fal fa-chevron-right"></i></Link></div>

				<div className="profile-stream-content">

					<div className="scroller">
						{(this.state.thePosts || []).map((post, i) => {
							//return <ThePost thePost={post} key={'thePost'+i} />
							return post[0].type == 'share' ? null : <StreamPreviewPost key={post[0].id} thePost={post} />
							{/*<div className="single" key={'thePost'+i}>{post[0].content}</div>*/ }
						}
						)}
					</div>

					{this.state.thePosts.length < 1 ? <div style={{ flex: 1, alignItems: 'center' }}>No post yet</div> : null}


				</div>

			</div>
		);
	}
}


const Streampreview = connect(mapStateToProps)(ConnectedStream);
export default Streampreview;








class StreamPreviewPost extends Component {

	constructor(props) {
		super(props);

	}

	render() {

		const p = this.props.thePost[0] || {};
		const avatarSrc = p.avatar_url ? endpoint.mediaRoot + '/' + p.avatar_url : endpoint.mediaRoot + '/placeholder-avatar.jpg';

		var postIcon = { path: '/wall/mix', icon: 'rss' }
		if (p.type == 'photo') {
			postIcon = { path: '/wall/photo', icon: 'fad fa-camera' }
		} else if (p.type == 'video') {
			postIcon = { path: '/wall/video', icon: 'fad fa-video' }
		} else if (p.type == 'audio') {
			postIcon = { path: '/wall/audio', icon: 'fad fa-microphone' }
		} else if (p.type == 'news') {
			postIcon = { path: '/wall/news', icon: 'fad fa-newspaper' }
		} else if (p.type == 'minimes') {
			postIcon = { path: '/wall/minimes', icon: 'fad fa-comment-alt-lines' }
		}

		var binary_up = '50';
		var binary_down = '50';

		if (p.rating_type == 'binary') {
			if (p.binary_count !== 0) {
				binary_up = Math.round(p.binary_up / p.binary_count * 100 * 100) / 100;
				//binary_down = 100 - binary_up;
				binary_down = Math.round((100 - binary_up) * 100) / 100;
			}

		}

		return (

			<Link to={"/post/" + p.id} className="single">

				<div className="preview-head">
					<div className="left">
						<span className="name">{p.username}</span>
						<span className="date"><Moment fromNow ago>{p.date}</Moment></span>
					</div>
					<div className="right">
						<i className={postIcon.icon}></i>
					</div>

				</div>

				{

					this.props.thePost.map((item, index) => {
						if (item.media_type == 'image' && index == 0 && p.type == 'photo') {
							return (<div key={'media' + index} style={{ backgroundImage: "url(" + endpoint.mediaRoot + '/' + item.media_url + ")" }} className="preview-photo-full"></div>)
						} else if (item.media_type == 'image' && index == 0 && p.type !== 'photo') {
							return (<div key={'media' + index} style={{ backgroundImage: "url(" + endpoint.mediaRoot + '/' + item.media_url + ")" }} className="preview-photo-half"></div>)
						} else if (item.media_type == 'video' && index == 0 && p.type == 'video') {
							return (<div key={'media' + index} className="preview-video-full" style={{ width: "100%" }}><Player src={endpoint.mediaRoot + '/' + item.media_url} /></div>)
						} else if (item.media_type == 'video' && index == 0 && p.type !== 'video') {
							return (<div key={'media' + index} className="preview-video-full" style={{ width: "100%" }}><Player src={endpoint.mediaRoot + '/' + item.media_url} /></div>)
						} else if (item.media_type == 'audio' && index == 0 && p.type == 'audio') {
							return (<div key={'media' + index} className="preview-audio-full"></div>)
						} else if (item.media_type == 'audio' && index == 0 && p.type !== 'audio') {
							return (<div key={'media' + index} className="preview-audio-full"></div>)
						}

					})}

				{
					p.type !== 'photo' && p.type !== 'video' && p.type !== 'minimes' && this.props.thePost[0].media_type !== null ?
						<div className="preview-text-half">
							<span>{p.content}</span>
						</div>
						:
						p.type == 'minimes' ?
							<div className="preview-text-minimes">
								<span>{p.content}</span>
							</div>
							:
							p.media_type == null ?
								<div className="preview-text-full">
									<span>{p.content}</span>
								</div>
								:
								null
				}

				<div className="preview-rate">
					{p.rating_type !== 'star' && p.rating_type !== 'binary' ?
						<React.Fragment>
							<div><i className="fad fa-thumbs-up"></i><span>{p.like_count > 0 ? p.like_count : '0'}</span></div>
							<div><i className="fas fa-heart"></i><span>{p.love_count > 0 ? p.love_count : '0'}</span></div>
						</React.Fragment>
						:
						p.rating_type == 'star' ?
							<React.Fragment>
								<div><i className="fad fa-stars"></i><span>{p.star_value == 0 ? 0 : Math.round(p.star_value / p.star_count * 10) / 10}</span></div>
								<div><i className="fad fa-users"></i><span>{p.star_count}</span></div>
							</React.Fragment>
							:
							p.rating_type == 'binary' ?
								<React.Fragment>
									<div><i className="fad fa-thumbs-up"></i><span>{binary_up}%</span></div>
									<div><i className="fad fa-thumbs-down"></i><span>{binary_down}%</span></div>
								</React.Fragment>
								:
								null
					}
				</div>

			</Link>
		);
	}
}



/*


					<View style={{flexDirection:'row', alignItems:'flex-start', borderBottomLeftRadius:20, borderBottomRightRadius:20, backgroundColor:'#FCFCFC', padding:20, paddingTop:20, paddingBottom:20 }}>
						{ p.rating_type !== 'star' &&  p.rating_type !== 'binary' ?
							(
								<React.Fragment>
									<View style={appStyles.postActionStats}>
										<Icon type="FontAwesome" name="thumbs-up" style={appStyles.postActionStatsIconYellow} />
										<Text style={appStyles.postActionStatsText}>{ p.like_count > 0 ? p.like_count : '0' }</Text>
									</View>
									<View style={appStyles.postActionStats}>
										<Icon type="FontAwesome" name="heart" style={appStyles.postActionStatsIconRed} />
										<Text style={appStyles.postActionStatsText}>{ p.love_count > 0 ? p.love_count : '0' }</Text>
									</View>
								</React.Fragment>
							)
							:
							null
						}
						{ p.rating_type == 'star' ?
							(
								<React.Fragment>
									<View style={appStyles.postActionStats}>
										<Icon type="FontAwesome" name="star" style={appStyles.postActionStatsIconYellow} />
										<Text style={appStyles.postActionStatsText}>{ p.star_value == 0 ? 0 : Math.round(p.star_value / p.star_count * 10) / 10 }</Text>
									</View>
									<View style={appStyles.postActionStats}>
										<Icon type="FontAwesome" name="users" style={appStyles.postActionStatsIcon} />
										<Text style={appStyles.postActionStatsText}>{ p.star_count }</Text>
									</View>
								</React.Fragment>
							)
							:
							null
						}
						{ p.rating_type == 'binary' ?
							(
								<React.Fragment>
									<View style={appStyles.postActionStats}>
										<Icon type="FontAwesome" name="thumbs-up" style={appStyles.postActionStatsIconGreen} />
										<Text style={appStyles.postActionStatsText} >{ binary_up }%</Text>
									</View>
									<View style={appStyles.postActionStats}>
										<Icon type="FontAwesome" name="thumbs-down" style={appStyles.postActionStatsIconRed} />
										<Text style={appStyles.postActionStatsText} >{ binary_down }%</Text>
									</View>
									<View style={appStyles.postActionStats}>
										<Icon type="FontAwesome" name="users" style={appStyles.postActionStatsIcon} />
										<Text style={appStyles.postActionStatsText}>{ p.binary_count }</Text>
									</View>
								</React.Fragment>

							)
							:
							null
						}
					</View>

*/
