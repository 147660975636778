import React, { Component } from "react";
import { connect } from "react-redux";
import { usersLogout } from "../../actions/index";

import i18n from "../../i18n";

function mapDispatchToProps(dispatch) {
  return {
    usersLogout: logout => dispatch(usersLogout(logout))
  };
}

class ConnectedLogoutButton extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    this.props.usersLogout();
  }
  render() {
    return (
      <div className="logoutButtonV2">
    	   <button className="button btn-primary logout" onClick={this.handleClick}>
          { i18n.t("Logout") }
          <i style={{paddingLeft: '10px'}} className="fal fa-sign-out-alt fa-duo"></i>
        </button>
      </div>
    );
  }
}
const Logoutbutton = connect(null, mapDispatchToProps)(ConnectedLogoutButton);
export default Logoutbutton;