import React, { Component } from "react";
import { connect } from "react-redux";
import { saveSettings } from "../../../actions/index";
import { Link } from "react-router-dom";

import i18n from "../../../i18n";


import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import Select from 'react-select';

import _ from 'lodash';

const sliderActiveRadiusStep = {
  10: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("10"),
  },
  20: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("20"),
  }, 
  30: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("30"),
  },
  40: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("40"),
  },
  50: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("50"),
  }           
};

const sliderAgeRangeStep = {
  16: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("16"),
  },
  20: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("20"),
  },
  30: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("30"),
  },
  40: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("40"),
  },
  50: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("50"),
  },
  60: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("60"),
  },
  70: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("70"),
  },
  80: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("80"),
  },
  90: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("90"),
  },
  99: {
    style: {
      color: '#00365f',
    },
    label: i18n.t("99"),
  }           
};

const selectGenderOptions = [
  { value: 'all', label: i18n.t('All') },
  { value: 'male', label: i18n.t('Just Male') },
  { value: 'female', label: i18n.t('Just Female') },
  { value: 'other', label: i18n.t('Other') }
]

function mapDispatchToProps(dispatch) {
  return {
    saveSettings: settings => dispatch(saveSettings(settings))
  };
}

const mapStateToProps = state => {
  return { fetchedSocialization: state.settings.userSettings };
};


class Connectedsocializationform extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			active_socialization		 : this.props.fetchedSocialization[0].active_socialization,
			active_socialization_radius	 : this.props.fetchedSocialization[0].active_socialization_radius,
			active_socialization_age_min : this.props.fetchedSocialization[0].active_socialization_age_min,
			active_socialization_age_max : this.props.fetchedSocialization[0].active_socialization_age_max,
			active_socialization_gender	 : this.props.fetchedSocialization[0].active_socialization_gender
		}
	    this.handleSubmit 			= this.handleSubmit.bind(this);
	    this.handleChange 			= this.handleChange.bind(this);
	    this.sliderActiveRadius 	= this.sliderActiveRadius.bind(this);
	    this.sliderAgeRange		 	= this.sliderAgeRange.bind(this);
	    this.handleSelectChange		= this.handleSelectChange.bind(this);
	}

	handleSubmit(event) {
	    event.preventDefault();
    	const type = "socialization";
	    const dataObj = { 
	    	active_socialization 		: this.state.active_socialization, 
	    	active_socialization_radius : this.state.active_socialization_radius,
	    	active_socialization_age_min: this.state.active_socialization_age_min,
	    	active_socialization_age_max: this.state.active_socialization_age_max,
	    	active_socialization_gender : this.state.active_socialization_gender

	    };
    	this.props.saveSettings({ type, dataObj });
    }

	handleChange(event) {
	    const fieldId = event.target.id;
	    const fieldValue = (event.target.getAttribute('type') == "checkbox") ?  event.target.checked : event.target.value; 
	    this.setState({ [fieldId]: fieldValue }, () => { console.log("state callback"); });
	}

	sliderActiveRadius(value) {
		this.setState({ active_socialization_radius: value }, () => { console.log("sliderActiveRadius", value); });
	}	

	sliderAgeRange(value) {
		this.setState({ active_socialization_age_min: value[0], active_socialization_age_max: value[1] }, () => { console.log("sliderAgeRange", value); });
	}	

	handleSelectChange(selected) {
		console.log(selected);	
		if ( selected ){
			this.setState({ active_socialization_gender: selected.value }, () => { console.log("active_socialization_gender", selected.value); });
		}else{
			this.setState({ active_socialization_gender: 'all' }, () => { console.log("active_socialization_gender", 'all'); });			
		}
	}

	componentDidMount(){
	}

  render() {

	let selectedIndex = 0;
  	if( this.state.active_socialization_gender == 'male'){
  		selectedIndex = 1;
  	}else if( this.state.active_socialization_gender == 'female'){
  		selectedIndex = 2;
  	}else if( this.state.active_socialization_gender == 'other'){
  		selectedIndex = 3;
  	}

    return (
		<form onSubmit={ this.handleSubmit } className="settings-form">
			<h3>{  i18n.t("Active socialization") }</h3>
			<div className="single-field one-line">
				<span>{  i18n.t("Enable active socialization based on position?") }</span>
				<div className="toggle">
					<input className="tgl tgl-light" id="active_socialization" type="checkbox" onChange={ this.handleChange } defaultChecked={this.state.active_socialization} />
		    		<label className="tgl-btn" htmlFor="active_socialization"></label>
				</div>
			</div>
			<br/>

			<h3>{  i18n.t("Active socialization radius") }</h3>
			<div className="single-field one-line">
			    <Slider dots disabled={!this.state.active_socialization} min={10} max={50} marks={sliderActiveRadiusStep} step={10} onChange={ this.sliderActiveRadius } defaultValue={this.state.active_socialization_radius} />
			</div>
			<br/><br/><br/>
			
			<h3>{  i18n.t("Gender") }</h3>
			<div className="single-field one-line">
				<Select id="genderChoiceSocialization"
					options={selectGenderOptions}
					isClearable={true} isSearchable={true}
					onChange={ this.handleSelectChange }
					defaultValue={selectGenderOptions[selectedIndex]}
					className="genderSelectSocialization"
					classNamePrefix="genderSelectSocialization"
				/>
			</div>
			<br/><br/><br/>

			<h3>{  i18n.t("Age range") }</h3>
			<div className="single-field one-line">
			    <Range allowCross={false} disabled={!this.state.active_socialization} marks={sliderAgeRangeStep} min={16} max={99} 
			    	defaultValue={
			    		[
			    			this.state.active_socialization_age_min > 16 && this.state.active_socialization_age_min < 99 ? this.state.active_socialization_age_min : 16,
			    			this.state.active_socialization_age_max < 99 && this.state.active_socialization_age_max > 16 ? this.state.active_socialization_age_max : 99
			    		]} 
			    	onChange={this.sliderAgeRange} 
			    />
			</div>
			<br/><br/><br/>


			<button type="submit" className="button btn-primary">
				{ i18n.t("Save") }
			</button>
	     </form>
    );
  }
}


const socializationform = connect(mapStateToProps, mapDispatchToProps)(Connectedsocializationform);
export default socializationform;