import React, { Component } from 'react'
import {Link} from "react-router-dom";

import endpoint from "../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import {store} from './../../store';

import _ from 'lodash';

import EditorBase from '../../components/editor/base';

import i18n from "../../i18n";

import { toast } from 'react-toastify';

import Textarea from 'react-textarea-autosize';

import Modal from 'react-responsive-modal';

class Groups extends Component {

  constructor(props) {
    super();

    this.state = {
      myGroups			: [],
      subscribedGroups	: [],
      pendingGroups		: [],
      isLoading			: false,
      toggleCreate		: false,
      groupTitle		: '',
      groupDescription	: '',
      groupType 		: 2,
      confirmation		: false
    };
    
    this.getGroups 			= this.getGroups.bind(this);
    this.handleDescription	= this.handleDescription.bind(this);
    this.handleTitle		= this.handleTitle.bind(this);
    this.handleType			= this.handleType.bind(this);
    this.handleCreate		= this.handleCreate.bind(this);
    this.createGroup 		= this.createGroup.bind(this); 

    this.askConfirmation 	= this.askConfirmation.bind(this); 
    this.deleteGroup 		= this.deleteGroup.bind(this); 

    this.store 				= store.getState();

  }
  
	componentDidMount(){
		this.setState({ isLoading : true });
		this.getGroups()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
	}

	componentDidUpdate(prevProps) {
		if (this.props.match.params.group !== prevProps.match.params.group) {
			this.setState({ isLoading : true });
			this.getGroups()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
		}
	}


	getGroups( fetchNew = false ){

        return new Promise((resolve, reject) => {

			const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
				axios.get(endpoint.posts+'/getgroups', cfg)
		  		.then(res => {
		  			this.setState({
						myGroups			: res.data.myGroups,
						subscribedGroups	: res.data.subscribedGroups,						
						pendingGroups		: res.data.pendingGroups,						
					});
					resolve();
		  		})
		  		.catch(err => {
		  			console.log(err);
					resolve();
				})  
		})

	}

	createGroup(){
		const reqBody = { title: this.state.groupTitle, description: this.state.groupDescription, group_type: this.state.groupType };
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };

		axios.post(endpoint.posts+'/groupcreate', reqBody, cfg)
			.then((result) => {
	  			this.setState({
					toggleCreate		: false,
					groupTitle			: '',
					groupDescription	: '',
					groupType 			: 2  		  				
				}, () => this.getGroups());
			  	toast.success(i18n.t('New group created'), { autoClose : 2500 } );

			})
			.catch((err) => {
			  	console.log(err.response);
			  	toast.error(i18n.t(err.response.data.errorMessage), { autoClose : 2500 } );
			})
	}


	askConfirmation(slug){
		this.setState({confirmation: slug});
	}


	deleteGroup(slug){
		const reqBody = { group_slug: slug };
		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };

		axios.post(endpoint.posts+'/groupdelete', reqBody, cfg)
			.then((result) => {
	  			this.setState({confirmation: false}, () => this.getGroups() );
			  	toast.success(i18n.t('Group deleted'), { autoClose : 2500 } );

			})
			.catch((err) => {
	  			this.setState({confirmation: false}, () => console.log(err.response) );
			  	toast.success(i18n.t('Error, try again later'), { autoClose : 2500 } );
			})
	}


	handleDescription( event ){
	  	this.setState({ groupDescription : event.target.value });
	}

	handleTitle( event ){
	  	this.setState({ groupTitle : event.target.value });
	}

	handleType( type ){
	  	this.setState({ groupType : type });
	}

	handleCreate(){
		if (!this.state.toggleCreate){ this.inputTitle.focus(); }
  		this.setState((prevState) => {
  		  return {toggleCreate: !prevState.toggleCreate};
  		})
	}

  render () {

    return (
    	<div className="allGroups">

    		<button className={ this.state.toggleCreate ? 'toggleCreateGroups' : 'toggleCreateGroups active' } onClick={ () => this.handleCreate()}>{i18n.t('Create new group')}</button>
    		<div className={ this.state.toggleCreate ? 'createGroups active' : 'createGroups' }>
    			<input ref={(input) => { this.inputTitle = input; }} className="title" id="groupTitle" type="text" name="groupTitle" value={this.state.groupTitle} onChange={this.handleTitle} placeholder={i18n.t('Group title')} />
	    		<Textarea minRows={2} maxRows={10} className="description" placeholder={i18n.t("Group description")} value={this.state.groupDescription} onChange={this.handleDescription} onKeyDown={ (event) => { console.log(event) } } />
	    		<div className="groupType">
	    			<div className={ this.state.groupType == 2 ? 'type public active' : 'type public' } onClick={ () => this.handleType(2) }>
		    			<i className="fad fa-lock-open public"></i>
	    				<span className="btnTitle">{i18n.t('Public')}</span>
	    				<span className="btnDesc">{i18n.t('Searchable, content visible to all')}</span>
	    			</div>
	    			<div className={ this.state.groupType == 1 ? 'type private active' : 'type private' } onClick={ () => this.handleType(1) }>
		    			<i className="fad fa-lock-alt private"></i>
	    				<span className="btnTitle">{i18n.t('Private')}</span>
	    				<span className="btnDesc">{i18n.t('Searchable, content visible to members')}</span>
	    			</div>
	    			<div className={ this.state.groupType == 0 ? 'type secret active' : 'type secret' }  onClick={ () => this.handleType(0) }>
		    			<i className="fad fa-user-secret secret"></i>
	    				<span className="btnTitle">{i18n.t('Secret')}</span>
	    				<span className="btnDesc">{i18n.t('Unsearchable, content visible to members')}</span>
	    			</div>
	    		</div>
	    		<button className="groupSubmit sendPostButton" onClick={this.createGroup}>
	    			{i18n.t('Create group')}
	    		</button>
    		</div>

	    	{ this.state.myGroups.length > 0 && this.state.isLoading == false ? 
	    		(
	    			<div className="section">
	    				<div className="headline">{i18n.t('My groups')}{this.state.myGroups.length > 0 ? (<span>{this.state.myGroups.length}</span>) : null}</div>
	    					<div className="content">
	    					{
							(this.state.myGroups || []).map( (group, i) => {
								return (
									<div className="single" key={group.group_id}>
										<Link to={'group/'+group.slug}>
											<h2>{group.title}</h2>
										</Link>
										<div className="desc">{group.description}</div>
										<div className="owner">
											<span>{i18n.t('created by')}</span>
											<Link to={"/profile/"+group.owner_username}>
		    								<img src={ group.owner_avatar ? endpoint.mediaRoot +'/'+ group.owner_avatar : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
												{group.owner_username}
											</Link>
											<div className="manage">
												<i className="fad fa-trash-alt" onClick={ ()=> this.askConfirmation(group.slug) }></i>
											</div>
										</div>
										<div className="type">{group.group_type == 0 ? <i className="fad fa-user-secret secret"></i> : group.group_type == 1 ? <i className="fad fa-lock-alt private"></i> : <i className="fad fa-lock-open public"></i> }</div>
									</div>
								)
							})}
						</div>
					</div>
	    		)
	    		:
	    		null
	    	}

	    	{ this.state.subscribedGroups.length > 0 && this.state.isLoading == false ? 
	    		(
	    			<div className="section">
	    				<div className="headline">{i18n.t('Subscribed groups')}{this.state.subscribedGroups.length > 0 ? (<span>{this.state.subscribedGroups.length}</span>) : null}</div>
	    					<div className="content">
		    					{
								(this.state.subscribedGroups || []).map( (group, i) => {
									return (
										<div className="single" key={group.group_id}>
											<Link to={'group/'+group.slug}>
												<h2>{group.title}</h2>
											</Link>
											<div className="desc">{group.description}</div>
											<div className="owner">
												<span>{i18n.t('created by')}</span>
												<Link to={"/profile/"+group.owner_username}>
			    								<img src={ group.owner_avatar ? endpoint.mediaRoot +'/'+ group.owner_avatar : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
													{group.owner_username}
												</Link>
											</div>
											<div className="type">{group.group_type == 0 ? <i className="fad fa-user-secret secret"></i> : group.group_type == 1 ? <i className="fad fa-lock-alt private"></i> : <i className="fad fa-lock-open public"></i> }</div>
										</div>
									)
								})}
							</div>
					</div>
	    		)
	    		:
	    		null
	    	}

	    	{ this.state.pendingGroups.length > 0 && this.state.isLoading == false ? 
	    		(
	    			<div className="section">
	    				<div className="headline">{i18n.t('Pending')}{this.state.pendingGroups.length > 0 ? (<span>{this.state.pendingGroups.length}</span>) : null}</div>
	    					<div className="content">
		    					{
								(this.state.pendingGroups || []).map( (group, i) => {
									return (
										<div className="single" key={group.group_id}>
											<Link to={'group/'+group.slug}>
												<h2>{group.title}</h2>
											</Link>
											<div className="desc">{group.description}</div>
											<div className="owner">
												<span>{i18n.t('created by')}</span>
												<Link to={"/profile/"+group.owner_username}>
			    								<img src={ group.owner_avatar ? endpoint.mediaRoot +'/'+ group.owner_avatar : endpoint.mediaRoot +'/placeholder-avatar.jpg' } />
													{group.owner_username}
												</Link>
											</div>
											<div className="type">{group.group_type == 0 ? <i className="fad fa-user-secret secret"></i> : group.group_type == 1 ? <i className="fad fa-lock-alt private"></i> : <i className="fad fa-lock-open public"></i> }</div>
										</div>
									)
								})}
							</div>
					</div>
	    		)
	    		:
	    		null
	    	}	    	


	        <Modal open={ this.state.confirmation ? true : false } onClose={ () => this.setState({confirmation: false}) } classNames={{ modal: "confirmation" }} center  showCloseIcon={false}>
	        	<button onClick={ () => this.deleteGroup(this.state.confirmation) } className="yes" >Confirm</button>
	        	<button onClick={ () => this.setState({confirmation: false}) } className="no">Cancel</button>
	        </Modal>


    	</div>
    )
  }
}

export default Groups;
