import React, { Component } from 'react'

import { Link } from "react-router-dom";

import endpoint from "../../config/endpoint";
import axios from "axios";
import qs from "querystring";
import {store} from './../../store';

import _ from 'lodash';

//import EditorBase from '../../components/editor/base';
import EditorBase from '../../components/editor/base_V2';

import ThePost from './components/ThePost';
import TheSharedPost from './components/TheSharedPost';
import ThePostPlaceholder from './components/ThePostPlaceholder';

import WallFloatingMenu from './components/WallFloatingMenu';

import IntersectionVisible    from 'react-intersection-visible'

import i18n from "../../i18n";

import iPics from '../../img/Photo.svg';
import iPicsH from '../../img/Photo_hover.svg';

import iVideo from '../../img/Video.svg';
import iVideoH from '../../img/Video_hover.svg';

import iAudio from '../../img/Audio.svg';
import iAudioH from '../../img/Audio_hover.svg';

import iMinimes from '../../img/Minimes.svg';
import iMinimesH from '../../img/Minimes_hover.svg';

import iNews from '../../img/Blog.svg';
import iNewsH from '../../img/Blog_hover.svg';

import iMix from '../../img/Mix.svg';
import iMixH from '../../img/Mix_hover.svg';

import iBookmark from '../../img/Bookmark.svg';
import iBookmarkH from '../../img/Bookmark_hover.svg';

import iGroups from '../../img/Groups.svg';

import MenuSx from '../../img/MenuSx.svg';

import accents from 'remove-accents';

class Wall extends Component {

  constructor(props) {
    super();

    this.state = {
      thePosts: [],
	  wallSideData: {friends:[],following:[],follower:[]},
	  wallSideUserSuggest: [],
	  wallSideTags: [],
      wallSideGroups: {myGroups:[],subscribedGroups:[],pendingGroups:[]},
      isLoading: true
    };
    
    this.getPosts = this.getPosts.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
	this.loadMore = _.debounce(this.loadMore.bind(this), 500);

    this.getSideData = this.getSideData.bind(this);
    this.getGroups = this.getGroups.bind(this);

    this.store = store.getState();

  }
  
	componentDidMount(){
		this.setState({ isLoading : true });
		this.getPosts()
            	.then( ()=> this.getSideData() )
            	.then( ()=> this.getGroups() )
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
	}

	componentDidUpdate(prevProps) {

		if (this.props.match.params.id !== prevProps.match.params.id) {

			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	  
			this.setState({ isLoading : true });
			this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
		}else if(this.props.match.params.tag  !== prevProps.match.params.tag){
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera	  
			this.setState({ isLoading : true });
			this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
		}else if(this.props.match.path  !== prevProps.match.path){
			// da verificare
			document.body.scrollTop = 0; // For Safari
			document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera	  
			this.setState({ isLoading : true });
			this.getPosts()
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );
		}
	}


	getPosts( fetchNew = false ){

        return new Promise((resolve, reject) => {

        	var wallEndpoint = endpoint.posts+'/wall/'+this.props.wallType;

        	if (this.props.wallType == 'bookmark'){
        		wallEndpoint = endpoint.posts+'/bookmark';
        	}else if(this.props.wallType == 'tag'){
        		wallEndpoint = endpoint.posts+'/tag/'+accents.remove(this.props.match.params.tag);;		
        	}

        	if ( fetchNew == true ){
				var cursor = -1 * this.state.thePosts[0][0].id;
        	}else{
        		var cursor = 0;
        	}

			if ( typeof this.props.match.params.cursor !== 'undefined'){
				cursor = this.props.match.params.cursor;
			}

			const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
				axios.get(wallEndpoint+'/'+cursor, cfg)
		  		.then(res => {
		  			this.setState({
						/*thePosts: res.data.thePosts || []*/
						thePosts: [...res.data.thePosts, ...this.state.thePosts]
					});
					resolve();
		  	})  
		})

	}


	getSideData(){
        return new Promise((resolve, reject) => {

			const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
				axios.get(endpoint.users+'/wallsidedata', cfg)
		  		.then(res => {

					let friends = res.wallSideData ? res.data.wallSideData.filter(r => r.qType == 'friends' ) : [];
					let following = res.wallSideData ? res.data.wallSideData.filter(r => r.qType == 'following' ) : [];
					let follower = res.wallSideData ? res.data.wallSideData.filter(r => r.qType == 'followers' ) : [];
		
					this.setState({
						wallSideData: {friends: friends, following:following, follower:follower},
						wallSideUserSuggest: res.data.wallSideUserSuggest,
						wallSideTags: res.data.wallSideTags
					});

					resolve();
		  	})  
		})
	}	

	getGroups( fetchNew = false ){

        return new Promise((resolve, reject) => {

			const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
				axios.get(endpoint.posts+'/getgroups', cfg)
		  		.then(res => {
		  			this.setState({
		  				wallSideGroups:{ myGroups: res.data.myGroups, subscribedGroups: res.data.subscribedGroups, pendingGroups: res.data.pendingGroups}
																		
					});
					resolve();
		  		})
		  		.catch(err => {
		  			console.log(err);
					resolve();
				})  
		})

	}

	onRefresh(){
		this.setState({ isLoading : true });
            this.getPosts( true )
            	.then( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )
            	.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) );

	}  

	loadMore( entries )
    {

    	var wallEndpoint = endpoint.posts+'/wall/'+this.props.wallType;

    	if (this.props.wallType == 'bookmark'){
    		wallEndpoint = endpoint.posts+'/bookmark/';
    	}else if(this.props.wallType == 'tag'){
    		wallEndpoint = endpoint.posts+'/tag/'+accents.remove(this.props.match.params.tag);;
    		
    	}

        this.setState({ isLoading : true });

        if (this.state.thePosts.length > 0){
			var cursor = this.state.thePosts[this.state.thePosts.length - 1][0].id;
        }else{
        	var cursor = 0;
        }

		const cfg = { headers: { 'Accept': 'application/json','Content-Type': 'application/json', 'Authorization': this.store.user.token } };
			axios.get(wallEndpoint+'/'+cursor, cfg)
	  		.then(res => {
				this.setState({ thePosts: [...this.state.thePosts, ...res.data.thePosts]});
				setTimeout( () => { this.setState({ isLoading : false }) },1000 )
	  		})
	  		.catch( setTimeout( () => { this.setState({ isLoading : false }) },1000 ) )

    }

  render () {

    return (
    	<div className="wallV2">
    		<div className="container">
	
				<WallSX active={this.props.wallType} wallSideGroups={this.state.wallSideGroups}/>
	
	    		<div className="wallContent">
		
		    		<EditorBase/>

		    		<div className="refresh" onClick={this.onRefresh} ><i className={ this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt" }></i></div>

		    		{ 
		    			this.state.thePosts.length == 0  && this.state.isLoading == true ? (<ThePostPlaceholder/>) : null
		    		}

		    		{ 
		    			this.state.thePosts.length == 0 && this.state.isLoading == false ? (<ThePostPlaceholder nopost={true} />) : null
		    		}

		    		{ (this.state.thePosts || []).map( (post, i) => {
				    		return post[0].type == 'share' ? <TheSharedPost thePost={post} key={'thePost'+i} /> : <ThePost thePost={post} key={'thePost'+i} wallType={this.props.wallType} />
				    		/*return <ThePost thePost={post} key={'thePost'+i} />*/
		    			} 
			    	)}

			    	{ this.state.thePosts && this.state.isLoading == false ? 
			    		(
				    		<IntersectionVisible onShow={ e => this.loadMore( e ) }>
					    		<div className="refresh" ><i className={ this.state.isLoading ? "fal fa-sync-alt fa-spin" : "fal fa-sync-alt" }></i></div>
				        	</IntersectionVisible>
			    		)
			    		:
			    		null
			    	}

		    	</div>
	    
	    		<WallDX wallSideTags={this.state.wallSideTags} wallSideUserSuggest={this.state.wallSideUserSuggest} friends={this.state.wallSideData.friends} following={this.state.wallSideData.following} follower={this.state.wallSideData.follower}/>

    		</div>

	    	{/* <WallFloatingMenu active={this.props.wallType}/> */}

    	</div>
    )
  }
}

export default Wall;










class WallSX extends Component {

  constructor(props) {
    super();

    this.state = {
    	isActive: false
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
    
  }

  toggleSidebar(){
  	this.setState({isActive:!this.state.isActive})
  }

  render () {

  	const myGroups = _.shuffle(this.props.wallSideGroups.myGroups).slice(0,3);
  	const subscribedGroups = _.shuffle(this.props.wallSideGroups.subscribedGroups).slice(0,3);


    return (
    	<div className={ this.state.isActive ? "wallSide sx sidebar active" : "wallSide sx sidebar unactive"}>
    		<ul className="wallMenu">
    			<li className={this.props.active == 'photo' ? 'active' : null }>
    				<Link to="/wall/photo">
    					<img src={ this.props.active == 'photo' ? iPicsH : iPics } alt="Pics"/>
    					<div>Pics</div>
    				</Link>
    			</li>
    			<li className={this.props.active == 'video'  ? 'active' : null}>
    				<Link to="/wall/video">
	    				<img src={ this.props.active == 'video' ? iVideoH : iVideo } alt="Video"/>
    					<div>Video</div>
    				</Link>
    			</li>
    			<li className={this.props.active == 'audio' ? 'active' : null}>
    				<Link to="/wall/audio">
	    				<img src={ this.props.active == 'audio' ? iAudioH : iAudio } alt="Audio & Podcast"/>
    					<div>Audio & Podcast</div>
    				</Link>
    			</li>
    			<li className={this.props.active == 'minimes' ? 'active' : null}>
    				<Link to="/wall/minimes">
	    				<img src={ this.props.active == 'minimes' ? iMinimesH : iMinimes } alt="Minimes"/>
    					<div>Minimes</div>
    				</Link>
    			</li>
    			<li className={this.props.active == 'news' ? 'active' : null}>
    				<Link to="/wall/news">
	    				<img src={ this.props.active == 'news' ? iNewsH : iNews } alt="Blog & News"/>
    					<div>Blog & News</div>
    				</Link>
    			</li>
    			<li className={this.props.active == 'mix' ? 'active' : null}>
    				<Link to="/wall/mix">
	    				<img src={ this.props.active == 'mix' ? iMixH : iMix } alt="Mix"/>
    					<div>Mix</div>
    				</Link>
    			</li>


    			<li className='sep'></li>

				<li className={this.props.active == 'groups' ? 'active' : null}>
					<Link to="/groups">
    					<img src={ this.props.active == 'groups' ? iGroups : iGroups } alt="Groups"/>
						<div>Groups</div>
					</Link>
				</li>

    			{ myGroups.length > 0 &&
    				<ul className="groups">
    				
    				{ myGroups.map( (g,i)=>{
    				    return <li key={"myg-"+g.slug}><Link to={'/group/'+g.slug}>{g.title}</Link></li>
    				
    				})}

    				</ul>


    			}

    			{ subscribedGroups.length > 0 &&
    				<ul className="groups">
    				
    				{ subscribedGroups.map( (g,i)=>{
    				    return <li key={"sg-"+g.slug}><Link to={'/group/'+g.slug}>{g.title}</Link></li>
    				
    				})}

    				</ul>
    			}

    			{ ( myGroups.length > 0 || subscribedGroups.length > 0 ) &&
    				<Link className="groupsMore" to="/groups">...</Link>
    			}

    			<li className='sep'></li>

    			<li className={this.props.active == 'bookmark' ? 'active' : null}>
    				<Link to="/bookmark">
	    				<img src={ this.props.active == 'bookmark' ? iBookmarkH : iBookmark } alt="Bookmark"/>
    					<div>Bookmark</div>
    				</Link>
    			</li>
    		</ul>
    		<div className="toggler" onClick={this.toggleSidebar}><img src={MenuSx} alt="Toggle sidebar" /></div>
    	</div>
    )
  }
}



class WallDX extends Component {

  constructor(props) {
    super();

    this.state = {
    };

    this.toggleSidebar = this.toggleSidebar.bind(this);
    
  }
  
  toggleSidebar(){
  	this.setState({isActive:!this.state.isActive})
  }

  render () {

    return (
    	<div className="wallSide dx sidebar">

       <div className="wallSideBox">
	   	{ this.props.wallSideUserSuggest.length > 0 && <DxSuggestBox title="Discover" suggestions={this.props.wallSideUserSuggest.slice(0, 4)} /> }

		{ this.props.wallSideTags.length > 0 && <DxTagsBox title="Trending tags" tags={this.props.wallSideTags.slice(0, 10)} /> }

        { this.props.friends.length > 0 && <DxBox title="Friends" relations={this.props.friends.slice(0, 4)} /> }

        { this.props.following.length > 0 && <DxBox title="Following" relations={this.props.following.slice(0, 4)} /> }

        { this.props.follower.length > 0 && <DxBox title="Followers" relations={this.props.follower.slice(0, 4)} /> }

       </div>

    	</div>
    )
  }
}


class DxBox extends Component {
  constructor(props) {
    super();
  }

  render(){

  	return(
  		<div className="DxBox">
  			<div className="head">
  				<span>{this.props.title}</span>
  				<Link to="/friends">{ i18n.t("See all") }<i className="fal fa-chevron-right"></i></Link>
  			</div>
			<div className="relations">
				{this.props.relations.map((r,i)=>{
					return (<Link key={'relation'+i} to={"/profile/"+r.username}><img src={r.media_url != null ? endpoint.mediaRoot+'/'+r.media_url : endpoint.mediaRoot+'/placeholder-avatar.jpg' } alt={r.username}/></Link>)
				})}
			</div>
  		</div>
  	)

  }

}


class DxSuggestBox extends Component {
	constructor(props) {
	  super();
	}
  
	render(){
  
		return(
			<div className="DxBox">
				<div className="head">
					<span>{this.props.title}</span>
				</div>
			  <div className="relations">
				  {this.props.suggestions.map((r,i)=>{
					  return (<Link key={'relation'+i} to={"/profile/"+r.username}><img src={r.media_url != null ? endpoint.mediaRoot+'/'+r.media_url : endpoint.mediaRoot+'/placeholder-avatar.jpg' } alt={r.username}/></Link>)
				  })}
			  </div>
			</div>
		)
  
	}
  
  }
  
  class DxTagsBox extends Component {
	constructor(props) {
	  super();
	}
  
	render(){
  
		return(
			<div className="DxBox">
				<div className="head">
					<span>{this.props.title}</span>
				</div>
			  <div className="ttags">
				  {this.props.tags.map((t,i)=>{
					  return (<Link key={'ttags'+i} to={"/tag/"+t.tag}><span>#{t.tag}</span></Link>)
				  })}
			  </div>
			</div>
		)
  
	}
  
  }
  
